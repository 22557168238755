
import { colors } from "../../styles";
import IconProps from "./icon-interface";

const ReplaceSlideIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.whiteText;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 18 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.89928 15C6.76342 15 4.62832 14.8022 2.50784 14.4058C1.5414 14.2253 0.793311 13.4003 0.644923 12.3528C0.182076 9.08081 0.180539 5.75196 0.64031 2.17504C0.789466 1.0146 1.68671 0.123668 2.77309 0.0552618C4.13319 -0.0296283 5.47175 -0.0172657 6.75035 0.0931739C7.43923 0.152515 8.06046 0.534108 8.45334 1.13988L9.143 2.20224H14.8471C16.0334 2.20224 17.0145 3.15334 17.1275 4.41433C17.362 7.01707 17.352 9.65279 17.0998 12.2465C16.9929 13.3402 16.2126 14.2344 15.2015 14.4206C13.111 14.8055 11.0052 14.9984 8.89928 14.9984V15ZM4.53452 0.989053C3.97173 0.989053 3.40124 1.00718 2.82691 1.04262C2.17877 1.08301 1.64366 1.61625 1.55447 2.30938C1.107 5.78988 1.10777 9.02642 1.55755 12.2036C1.64596 12.83 2.09189 13.3237 2.66699 13.4308C6.77418 14.1981 10.939 14.2039 15.0454 13.4473C15.6513 13.336 16.1188 12.8003 16.1826 12.1451C16.4294 9.61653 16.4378 7.04757 16.2095 4.51076C16.1418 3.75911 15.556 3.1929 14.8471 3.1929H4.4784C4.22391 3.1929 4.01709 2.9712 4.01709 2.69839C4.01709 2.42559 4.22391 2.20389 4.4784 2.20389H8.01971L7.6968 1.70609C7.46153 1.34345 7.09018 1.11598 6.6773 1.08054C5.97996 1.02037 5.26339 0.990701 4.53452 0.990701V0.989053Z" fill={color}/>
      <path d="M12.5967 7.61864C12.4398 7.48595 12.2615 7.49501 12.1084 7.64419C12.0846 7.66726 12.0639 7.69199 12.0431 7.71919C12.0431 7.71671 12.0416 7.71424 12.0408 7.71094C11.714 6.3354 10.929 5.44941 9.70734 5.07523C8.47103 4.69776 7.36312 5.01012 6.41436 6.0049C6.26367 6.16314 6.13681 6.35023 6.02148 6.5299C5.90769 6.70792 5.94921 6.93869 6.11835 7.06726C6.28058 7.19089 6.48279 7.16122 6.62118 6.99309C6.64348 6.96589 6.66193 6.93787 6.67884 6.91067C6.68961 6.89419 6.70037 6.87688 6.71191 6.86122C7.37158 5.9571 8.21808 5.59281 9.22681 5.77908C10.2563 5.96864 10.946 6.63292 11.2781 7.75298C11.2896 7.79089 11.2973 7.83045 11.3058 7.87496C11.2627 7.84034 11.222 7.81067 11.1789 7.78347C11.0028 7.67633 10.8275 7.70765 10.6976 7.86919C10.6338 7.94913 10.6023 8.05463 10.6107 8.15765C10.6192 8.25985 10.6653 8.35133 10.7407 8.41726C11.0144 8.65463 11.285 8.87551 11.5449 9.07248C11.6256 9.13347 11.7094 9.16397 11.7878 9.16397C11.8786 9.16397 11.9608 9.12358 12.0239 9.04281C12.2622 8.73622 12.4844 8.42551 12.6859 8.12056C12.7943 7.95655 12.7581 7.7538 12.5959 7.61699L12.5967 7.61864Z" fill={color}/>
      <path d="M11.4749 9.9305C11.3211 9.81017 11.1497 9.82336 11.0044 9.96676C10.9659 10.0047 10.9375 10.0484 10.9098 10.0904C10.8967 10.1102 10.8844 10.1299 10.8706 10.1481C10.1971 11.0596 9.33675 11.4148 8.31418 11.2063C7.30468 11.0003 6.63041 10.3335 6.31056 9.22583C6.30057 9.19204 6.29365 9.15742 6.28596 9.11869C6.32825 9.15248 6.369 9.18215 6.41051 9.21017C6.58581 9.32391 6.80647 9.27693 6.9118 9.10303C7.02713 8.91347 7.00483 8.71237 6.8526 8.57967C6.58966 8.34973 6.31902 8.12885 6.04839 7.92363C5.87386 7.79094 5.68549 7.80248 5.5694 7.95165C5.33797 8.24918 5.11501 8.55907 4.90665 8.87308C4.79747 9.03709 4.83361 9.23984 4.99507 9.37665C5.15268 9.51017 5.33028 9.5011 5.48328 9.35275C5.50635 9.32967 5.52711 9.30577 5.54787 9.27858C5.55017 9.28764 5.55248 9.29588 5.55402 9.30495C5.92845 10.8 6.80493 11.7033 8.16041 11.9893C8.41183 12.042 8.65555 12.0684 8.89159 12.0684C9.90724 12.0684 10.7768 11.578 11.4849 10.603C11.5195 10.5552 11.5633 10.4901 11.5941 10.4192C11.674 10.2313 11.6302 10.0533 11.4741 9.9305H11.4749Z" fill={color}/>
    </svg>
  )
}

export default ReplaceSlideIcon;
