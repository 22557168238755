import styled from 'styled-components';
import {colors, shadows, spacing} from '../../styles';
import {HalfFoodDomeAnimation} from '../../assets/animations/food-dome';

interface SlidePlaceholderCard {
  className ?: string;
}

const SlidePlaceholderCard : React.FC<SlidePlaceholderCard> = (p) => {
  return (
    <CardContainer className={p.className}>
      <PageCard>
        <ImageContainer>
          <HalfFoodDomeAnimation />
        </ImageContainer>
      </PageCard>
    </CardContainer>
  );
};

export default SlidePlaceholderCard;

const CardContainer = styled.div`
  position :relative;
  margin : ${spacing}rem;
  padding-bottom : ${spacing}rem;
`

const PageCard = styled.div`
  display: inline-flex;
  flex-direction: column;
  border-radius: 1.5rem;
  padding : 0.3rem;
  border : 0.3rem solid ${colors.background2};
  background-color: ${colors.background2};
  z-index : 0;
  box-shadow: ${shadows.default};
`

const ImageContainer = styled.div`
  display: flex;
  align-items : center;
  justify-content: center;
  width : 20rem;
  height : 11.3rem;
  background-color: ${colors.lightPrimary};
  border-radius: 1rem;
`
