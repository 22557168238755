
import { colors } from "../../styles";
import IconProps from "./icon-interface";

const RemoveCrossIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.text;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 8 9" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.00004 7.5L1 1.5" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
      <path d="M1 7.5L7.00004 1.5" stroke={color} strokeWidth="1.5" strokeLinecap="round"/>
    </svg>
  )
}

export default RemoveCrossIcon;

