import { colors } from "../../styles";
import IconProps from "./icon-interface";

const NextPageIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.primary;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.739742 11.0067C0.663712 10.9297 0.603398 10.8383 0.562247 10.7377C0.521097 10.6371 0.499917 10.5292 0.499917 10.4203C0.499917 10.3114 0.521097 10.2036 0.562247 10.103C0.603398 10.0024 0.663712 9.91097 0.739743 9.83399L4.52411 5.99983L0.739744 2.16457C0.59522 2.00743 0.51654 1.79959 0.52028 1.58483C0.52402 1.37008 0.609888 1.16518 0.759793 1.0133C0.909698 0.861423 1.11194 0.774425 1.3239 0.770636C1.53587 0.766847 1.74101 0.846562 1.89611 0.992988L6.25974 5.41404C6.41296 5.56947 6.49902 5.78016 6.49902 5.99983C6.49902 6.2195 6.41296 6.43019 6.25974 6.58562L1.89611 11.0067C1.7427 11.1619 1.53474 11.2491 1.31792 11.2491C1.10111 11.2491 0.893152 11.1619 0.739742 11.0067Z" fill={color}/>
    </svg>
  )
}

export default NextPageIcon;


