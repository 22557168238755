import styled from 'styled-components';
import {colors, spacing, typography} from '../../styles';

interface NotebookNameInputProps {
  value : string;
  onChange : (value : string) => void;
  placeholder : string;
  className ?: string;
}

const NotebookNameInput : React.FC<NotebookNameInputProps> = (p) => {
  return (
    <Input
      className={p.className}
      value={p.value}
      onChange={e => p.onChange(e.target.value)}
      placeholder={p.placeholder}
    />
  );
};

export default NotebookNameInput;


const Input = styled.input`
  /* height : 3.6rem; */
  width : 40rem;
  margin-right : ${spacing}rem;
  outline : none;
  ${typography.bigTitle.css}
  border : none;
  background-color: ${colors.background2};
  border-radius: ${spacing}rem;
  padding : 1rem;
  margin-left : -${spacing}rem;
  margin-top : -0.5rem;
`