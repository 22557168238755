import styled from 'styled-components';
import PageLayout from '../components/layout/page-layout';
import { useTranslation } from 'react-i18next';
import { colors, spacing } from '../styles';
import TextButton from '../components/atoms/text-button';
import { useAssertLogged } from '../hooks/use-assert-logged';
import { useNavigate } from 'react-router-dom';
import { NEW_PROPOSAL, PROPOSAL } from '../constants/routes';
import PageTopSelector from '../components/atoms/pagetop-selector';
import { useEffect, useState } from 'react';
import { Customer, Proposal, User } from '../store/store-types';
import { useModelActions } from '../store/hooks/use-actions';
import Pagination from '../components/atoms/pagination';
import Search from '../components/atoms/search';
import Table from '../components/atoms/table/table';
import { useModelStore, useUserStore } from '../store/store';
import Typography from '../components/atoms/typography';
import ToolButton from '../components/atoms/tool-button';
import TrashIcon from '../assets/icons/trash';
import EditIcon from '../assets/icons/edit';
import CopyIcon from '../assets/icons/copy';
import useDialog from '../hooks/use-dialog';
import HTMLTypography from '../components/atoms/html-typography';
import EyeIcon from '../assets/icons/eye';
import { useAssertAdmin } from '../hooks/use-assert-admin';
import useLogged from '../hooks/use-logged';
import { SUPERVISOR, USER } from '../constants/roles';

interface ProposalsPageProps {

}

const ProposalsPage : React.FC<ProposalsPageProps> = () => {
  useAssertLogged();
  const logged = useLogged();
  const role = useUserStore(state => state.user.role);
  const {t} = useTranslation();
  const navigate = useNavigate();

  const {load : loadMarkets} = useModelActions('markets');
  const {load, create, delete : deleteProposal, clone} = useModelActions('proposals');
  const {loadMany : loadManyCustomers} = useModelActions('customers');
  const {loadMany : loadManyUsers} = useModelActions('users');
  const user = useUserStore(state => state.user);
  const markets = useModelStore(state => state.markets.filter(({inactive}) => !inactive));
  const regions = useModelStore(state => state.regions.filter(({inactive}) => !inactive));

  const [page, setPage] = useState<number>(0);
  const [query, setQuery] = useState<string | undefined>('');
  const [pagesCount, setPagesCount] = useState<number>(1);

  const [proposals, setProposals] = useState<Proposal[]>([]);
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [proposalState, setProposalState] = useState<'isDraft' | 'isNotDraft'>('isNotDraft');

  const {showDialog} = useModelActions('modals');

  useEffect(() => {
    if(logged) {
      refresh();
      loadMarkets();
    }
  }, [proposalState, query, page])

  const refresh = async () => {
    const {data : proposals, pagination} = await load(page, {
      draft : proposalState === 'isDraft',
      query,
      creatorId : role === USER || role === SUPERVISOR ? user.id : undefined,
      regionsIds : role === SUPERVISOR ? user.regionsIds : undefined
    });
    setProposals(proposals);
    setPagesCount(pagination.pagesCount)

    const customers = await loadManyCustomers(proposals.map(proposal => proposal.customerId).filter(c => !!c) as number[])
    setCustomers(customers || []);

    const users = await loadManyUsers(proposals.map(proposal => proposal.creatorId).filter(c => !!c));
    setUsers(users);
  }

  const handleShowCreate = () => {
    navigate(NEW_PROPOSAL);
  }

  const handleDelete = (proposal : Proposal) => {
    showDialog({
      content :<HTMLTypography variant="textRegular" color="text" html={t('pages.proposals.deleteDialogContent', {name : proposal.name})}/>,
      callback : async () => {
        await deleteProposal(proposal.id);
        await refresh();
      }
    })
  }

  const handleShowEdit = (proposal : Proposal) => {
    navigate(PROPOSAL.replace(':id', `${proposal.id}`))
  }

  const handleCopy = async (proposal : Proposal) => {
    const newProposal = await clone(proposal.id);
    // const newProposal = await create({
    //   draft : true,
    //   name : proposal.name + '*',
    //   offerId : proposal.offerId,
    //   marketId : proposal.marketId
    // })
    await refresh();
    navigate(PROPOSAL.replace(':id', `${newProposal.id}`))
  }

  return (
    <PageLayout
      title={role === SUPERVISOR ? t('pages.proposals.name') :  t('pages.proposals.name_user')}
      subtitle={t('pages.proposals.subtitle')}
      controls={
        <>
          <Search placeholder={t('pages.proposals.searchPlaceholder')} onChange={setQuery}/>
          <TextButton onClick={handleShowCreate} color="dark">{t('pages.proposals.createNew')}</TextButton>
        </>
      }
    >
      <Container>
        <SelectorsContainer>
          <PageTopSelector<typeof proposalState>
            selected={proposalState}
            onChange={(val) => {setProposalState(val); setPage(0);}}
            options={
              [{id : 'isNotDraft', label : t('pages.proposals.published')}, {id : 'isDraft', label : t('pages.proposals.drafts')}]
            }
          />
        </SelectorsContainer>
        <Table<Proposal>
          columns={
            [
              {key : 'name', value : t('pages.proposals.fields.name')},
              {key : 'customerId', value : t('pages.proposals.fields.customers')},
              {key : 'creatorId', value : t('pages.proposals.fields.creator')},
              // {key : 'notebooks', value : t('pages.proposals.fields.notebooks')},
              // {key : 'createdAt', value : t('pages.proposals.fields.createdAt')},
              {key : 'sentAt', value : t('pages.proposals.fields.sentAt')},
              // {key : 'validUntil', value : t('pages.proposals.fields.validUntil')},
              {key : 'marketId', value : t('pages.proposals.fields.market')},
              {key : 'regionsIds', value : t('pages.proposals.fields.regionsIds')},
              {key : '_controls', value : null}
            ]
          }
          records={proposals}
          renderers={{
            'name' : (value) => <Typography variant="textRegular">{value || '-'}</Typography>,
            'customerId' : (value) => <Typography variant="textBold">{customers.find(c => c.id === value)?.name || ''}</Typography>,
            'creatorId' : (value) => <Typography variant="textRegular">{users.find(c => c.id === value)?.firstName || ''} {users.find(c => c.id === value)?.lastName || ''}</Typography>,
            'createdAt' : (value) => <Typography variant="textRegular">{new Date(value).toLocaleDateString('fr')}</Typography>,
            'sentAt' : (value) => <Typography variant="textRegular">{value ? new Date(value).toLocaleDateString('fr') : ''}</Typography>,
            'validUntil' : (value) => <Typography variant="textRegular">{value ? new Date(value).toLocaleDateString('fr') : ''}</Typography>,
            'marketId' : (value) => <Typography variant="textRegular">{markets.find(m => m.id === value)?.name?.toUpperCase() ||''}</Typography>,
            'regionsIds' : (values) => <Typography variant="textRegular">{regions.filter(region => (values || []).includes(region.id)).map(m => m.name).join(', ')}</Typography>,
            '_controls' : (value : any, record : Proposal) => (
              <ControlsContainer>
                <ToolButton onClick={() => handleShowEdit(record)}>
                  {
                    proposalState === 'isDraft' ?
                    <EditIcon color={colors.primary}/> :
                    <EyeIcon color={colors.primary}/>
                  }
                </ToolButton>
                <ToolButton onClick={() => handleCopy(record)}><CopyIcon color={colors.primary}/></ToolButton>
                {
                  // !record.draft ? null :
                    <ToolButton onClick={() => handleDelete(record)}><TrashIcon color={colors.primary}/></ToolButton>
                }
              </ControlsContainer>
            )
          }}
        />

        {
          pagesCount > 1 ?
          <PaginationContainer>
            <Pagination total={pagesCount} current={page+1} onChange={(page) => setPage(page-1)}/>
          </PaginationContainer> :
          null
        }

     </Container>
    </PageLayout>
  )
}

export default ProposalsPage;

const Container = styled.div`
  padding-top : ${3*spacing}rem;
;`

const SelectorsContainer = styled.div`
  display : flex;
  justify-content: space-between;
  margin-bottom : ${2*spacing}rem;
`

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const PaginationContainer = styled.div`
  display : flex;
  align-items: center;
  justify-content: center;
  margin-top : ${2*spacing}rem;
`

const ControlsContainer = styled.div`
  display: flex;
  & button {
    margin-left : ${spacing}rem;
  }
`
