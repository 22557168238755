
import { colors } from "../../styles";
import IconProps from "./icon-interface";

const ArchiveIcon : React.FC<IconProps & {onClick?:()=>void}> = (p) => {
  const color = p.color || colors.text;

  return (
    <svg onClick={p.onClick} className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9.06166 16.0101C6.9456 16.0101 4.82954 16.0101 2.71348 16.0101C1.588 16.0101 0.713051 15.3594 0.504913 14.3781C0.474078 14.23 0.474078 14.0785 0.474078 13.927C0.474078 11.2104 0.474078 8.49385 0.46637 5.7807C0.46637 5.48804 0.531894 5.22981 0.678361 4.97158C1.34902 3.80093 2.00042 2.62339 2.65566 1.44586C2.94474 0.922513 3.39956 0.667725 4.05866 0.667725C7.39271 0.671168 10.7268 0.671168 14.0569 0.667725C14.716 0.667725 15.167 0.922513 15.4599 1.44586C16.1614 2.70603 16.8745 3.9662 17.576 5.22636C17.6223 5.31244 17.6531 5.41918 17.6531 5.51558C17.6531 8.34235 17.6531 11.1691 17.6454 13.9959C17.6454 14.9531 17.0056 15.714 15.9996 15.9447C15.7837 15.9929 15.5563 16.0067 15.3328 16.0067C13.2398 16.0101 11.1507 16.0067 9.05781 16.0067L9.06166 16.0101ZM16.4081 6.06303C16.3696 6.03204 16.3195 6.03893 16.3118 6.03893C7.93232 6.03205 1.93104 6.02516 1.81541 6.01827C1.80384 6.01827 1.73832 6.01139 1.69977 6.04926C1.67665 6.07336 1.66894 6.10435 1.67279 6.14567C1.6381 10.2774 1.64581 13.2969 1.67279 13.9752C1.67665 14.1164 1.69592 14.4469 1.95416 14.6811C2.13917 14.8498 2.41284 14.9393 2.75588 14.9393C6.95716 14.9393 11.1584 14.9393 15.3597 14.9393C15.4715 14.9393 15.5871 14.9359 15.6951 14.9118C15.9032 14.867 16.0805 14.7671 16.2077 14.6363C16.4004 14.4366 16.4351 14.1887 16.4428 14.0441C16.4775 13.538 16.4852 10.422 16.4428 6.14911C16.4428 6.14222 16.4428 6.09746 16.4043 6.06648L16.4081 6.06303ZM16.0265 4.9096C16.0574 4.87173 15.9919 4.78565 15.9803 4.76843C15.8145 4.54119 14.5118 2.13792 14.4038 1.94511C14.319 1.79361 14.2188 1.73852 14.0261 1.73852C10.7113 1.74197 7.39656 1.74197 4.08564 1.73852C3.99314 1.73852 3.92376 1.7523 3.86209 1.78328C3.77344 1.83493 3.73489 1.91068 3.72333 1.93478C3.63082 2.11382 2.28564 4.57562 2.13917 4.77532C2.12761 4.78909 2.0775 4.85107 2.10449 4.89239C2.10449 4.89239 2.12376 4.92337 2.19314 4.93026C2.33189 4.94403 10.0677 4.93715 15.961 4.93026C15.9841 4.93026 16.0188 4.93026 16.0304 4.9096H16.0265Z" fill={color}/>
      <path d="M8.46038 11.3207C8.46038 11.3207 8.46038 11.2725 8.46038 11.2691C8.44882 11.2105 8.44497 10.367 8.46038 8.40442C8.46038 8.38376 8.45267 8.04978 8.73019 7.88796C8.86895 7.80532 9.01542 7.80188 9.05396 7.80188C9.40856 7.80188 9.65524 8.0429 9.65524 8.39753C9.65524 9.25142 9.65524 10.1053 9.65524 10.9557V11.3173C9.72848 11.2587 9.77088 11.2243 9.81327 11.1864C10.2064 10.8352 10.5957 10.484 10.9927 10.1328C11.251 9.90216 11.621 9.89872 11.8638 10.1156C12.0989 10.3257 12.0951 10.6562 11.8484 10.88C11.0621 11.5893 10.2719 12.2951 9.4818 13.0009C9.23512 13.2213 8.87666 13.2178 8.62998 12.994C7.84368 12.2917 7.05739 11.5893 6.27494 10.8869C6.0167 10.6562 6.01285 10.3257 6.25953 10.1087C6.50235 9.89527 6.86467 9.9056 7.13062 10.1363C7.51991 10.4806 7.90921 10.8283 8.2985 11.1761C8.32933 11.2036 8.36788 11.2243 8.41799 11.2794C8.4334 11.2966 8.45267 11.3207 8.46424 11.3173L8.46038 11.3207Z" fill={color}/>
    </svg>
    
  )
}

export default ArchiveIcon;

