import { User } from "../../../store/store-types";

const colors = [
  "FF5C38",
  "FB9638",
  "A4C587",
  "3A5865",
  "E6AA9C",
  "173A32",
  "601232"
]

export const getFirstLetter = (user : User) => (user.firstName || user.lastName || user.email)[0].toUpperCase();
export const getLabel = (user : User) => (user.firstName || user.lastName) ? `${user.firstName} ${user.lastName}` : user.email;
export const getColor = (user : User) =>
  "#"+colors[getLabel(user).split('').reduce((cumul, next) => cumul + next.charCodeAt(0), 0) % colors.length];

