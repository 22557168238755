import { ReactNode, CSSProperties } from "react";
import styled from "styled-components";
import { colors, shadows, spacing } from "../../../styles";

interface TableRowProps {
  className ?: string;
  style ?: CSSProperties;
  children ?: ReactNode;
  highlighted ?: boolean;
}

const TableRow : React.FC<TableRowProps> = (p) => {
  const {highlighted, ...props} = p;
  return (
    <TR {...props} className={highlighted ? 'highlighted' : ''}/>
  );
}

export default TableRow;

const TR = styled.tr`
  box-shadow: ${shadows.default};
  background-color: ${colors.background2};
  border-radius: ${spacing}rem;
  
  margin-top: ${spacing}rem;

  &.highlighted td:first-child::after {
    content : ' ';
    position : absolute;
    height : 100%;
    width : 1rem;
    top : 0;
    left : 0;
    background-color: ${colors.primary};
    /* border-left : 1rem solid ${colors.primary}; */
  }
`