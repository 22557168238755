import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import fr from './fr';

const languages = navigator.languages;

i18n
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  .init({
    debug: false,
    returnObjects : true,
    fallbackLng: 'fr',
    lng : languages[0],
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      fr: {translation : fr}
    }
  });

export default i18n;