import { useEffect, useState } from "react";
import InlineDropdown from "../../atoms/inline-dropdown";
// import SharePickerMenu from "./date-picker-menu";
import useToggler from "../../../hooks/use-toggler";
import SharePickerMenu from "./share-picker-menu";
import UserAddIcon from "../../../assets/icons/user-add";
import PlusInCircleIcon from "../../../assets/icons/plus-in-circle";
import { useModelActions } from "../../../store/hooks/use-actions";
import { Proposal, User } from "../../../store/store-types";
import { useUserStore } from "../../../store/store";
import styled from "styled-components";
import { getColor, getFirstLetter } from "./tools";
import Typography from "../../atoms/typography";

interface SharePickerProps {
  inverted ?: boolean;
  disabled ?: boolean;
  proposal : Proposal;
}

const SharePicker : React.FC<SharePickerProps> = (p) => {
  const {update} = useModelActions('proposals')
  const {loadMany : loadUsers} = useModelActions('users');

  const userId = useUserStore(state => state.user.id);
  const [open, setOpen] = useState<boolean>(false);
  const [updatedProposal, setUpdatedProposal] = useState<Proposal>(p.proposal);
  const [users, setUsers] = useState<User[]>([]);

  const {toggle, addCallback, removeCallback} = useToggler('dropdown');

  useEffect(() => {
    addCallback(() => setOpen(false));

    const usersIds = (p.proposal.sharedWith || '').replace(/-/g, '').split(',').map(id => parseInt(id)).filter(id => id !== userId);

    if(usersIds.length) {
      loadUsers(usersIds).then(setUsers);
    }

    return removeCallback;
  }, [])


  const handleOpen = (open : boolean) => {
    if(p.disabled) {return;}
    if(open) {toggle();}
    setOpen(open);
  }

  const handleAddUser = (user : User) => {
    if(users.map(u => u.id).includes(user.id) || user.id === userId) {return;}

    update(p.proposal.id, {
      sharedWith : [userId, ...[...users, user].map(u => u.id)].map(id => `-${id}-`).join(',')
    })

    setUsers(users => [...users, user]);
  }

  const handleRemoveUser = (user : User) => {
    update(p.proposal.id, {
      sharedWith : [userId, ...users.filter(u => u.id !== user.id)].map(id => `-${id}-`).join(',')
    })

    setUsers(users => users.filter(u => u.id !== user.id));
  }

  return (
    <InlineDropdown
      open={!p.disabled ? open : false}
      setOpen={handleOpen}
      inverted={p.inverted}
      label={
      <Label>
        <StyledUserAddIcon width="1.6rem" height="1.6rem"/>
        {
          users.map(user => (
            <UserLetter style={{background : getColor(user)}}>
              <Typography variant="smallTextRegular" color="whiteText">{getFirstLetter(user)}</Typography>
            </UserLetter>
          ))
        }
        <PlusInCircleIcon width="1.8rem" height="1.8rem"/>
      </Label>}
      color={'accent'}
      hideChevron
    >
      <SharePickerMenu
        selectedUsers={users}
        onRemoveUser={handleRemoveUser}
        onAddUser={handleAddUser}
      />
    </InlineDropdown>
  );
}

export default SharePicker;

const UserLetter = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width : 1.8rem;
  height : 1.8rem;
  border-radius : 1rem;
  margin-right : -0.4rem;
`

const Label = styled.div`
  display : inline-flex;
  align-items: center;
`

const StyledUserAddIcon = styled(UserAddIcon)`
  margin-right : 0.7rem;
`