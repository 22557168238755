
import { colors } from "../../styles";
import IconProps from "./icon-interface";

const TrashIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.text;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 16 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_1187_43745)">
      <path d="M6.05185 0.6875C5.85764 0.689827 5.67232 0.76674 5.5366 0.901344C5.40088 1.03595 5.32586 1.21723 5.32802 1.40538V2.12327H1.22395C1.02741 2.12327 0.838928 2.1989 0.699956 2.33353C0.560984 2.46816 0.48291 2.65076 0.48291 2.84115C0.48291 3.03154 0.560984 3.21414 0.699956 3.34877C0.838928 3.4834 1.02741 3.55903 1.22395 3.55903H14.7429C14.9394 3.55903 15.1279 3.4834 15.2669 3.34877C15.4058 3.21414 15.4839 3.03154 15.4839 2.84115C15.4839 2.65076 15.4058 2.46816 15.2669 2.33353C15.1279 2.1989 14.9394 2.12327 14.7429 2.12327H10.6388V1.40538C10.641 1.21723 10.566 1.03595 10.4302 0.901344C10.2945 0.76674 10.1092 0.689827 9.91498 0.6875H6.05185Z" fill={color}/>
      <path d="M6.05206 8.72739C6.24627 8.72971 6.43159 8.80663 6.56731 8.94123C6.70303 9.07583 6.77805 9.25712 6.77589 9.44527V16.1455C6.77749 16.239 6.76002 16.3319 6.72448 16.4188C6.68894 16.5057 6.63602 16.585 6.56876 16.6521C6.50151 16.7192 6.42124 16.7727 6.33257 16.8096C6.2439 16.8466 6.14857 16.8662 6.05206 16.8673C5.95554 16.8662 5.86022 16.8466 5.77155 16.8096C5.68288 16.7727 5.60261 16.7192 5.53535 16.6521C5.4681 16.585 5.41518 16.5057 5.37963 16.4188C5.34409 16.3319 5.32662 16.239 5.32823 16.1455V9.44527C5.32607 9.25712 5.40108 9.07583 5.5368 8.94123C5.67252 8.80663 5.85784 8.72971 6.05206 8.72739Z" fill={color}/>
      <path d="M10.6387 9.44527C10.6398 9.35202 10.6219 9.25949 10.586 9.17295C10.5502 9.0864 10.4971 9.00755 10.4298 8.94089C10.3625 8.87422 10.2823 8.82106 10.1937 8.78443C10.1052 8.74779 10.0101 8.72841 9.91387 8.72739C9.71966 8.72971 9.53434 8.80663 9.39862 8.94123C9.2629 9.07583 9.18788 9.25712 9.19004 9.44527V16.1455C9.18844 16.239 9.20591 16.3319 9.24145 16.4188C9.277 16.5057 9.32991 16.585 9.39717 16.6521C9.46442 16.7192 9.54469 16.7727 9.63336 16.8096C9.72203 16.8466 9.81736 16.8662 9.91387 16.8673C10.1081 16.865 10.2934 16.7881 10.4291 16.6535C10.5648 16.5189 10.6399 16.3376 10.6377 16.1494L10.6387 9.44527Z" fill={color}/>
      <path d="M7.98348 20.0635C6.77217 20.0632 5.56241 19.9796 4.36331 19.8134C3.75092 19.7233 3.18693 19.4382 2.76036 19.0031C2.33379 18.5681 2.06892 18.0078 2.00757 17.4106L1.98834 17.242C1.63865 14.1161 1.63865 10.9628 1.98834 7.83691L2.1817 6.11183C2.19918 5.93812 2.28237 5.77681 2.41525 5.65894C2.54813 5.54108 2.72132 5.47499 2.90148 5.47339H13.0645C13.2446 5.47499 13.4178 5.54108 13.5507 5.65894C13.6836 5.77681 13.7668 5.93812 13.7842 6.11183L13.9756 7.83593C14.3261 10.9618 14.3261 14.1152 13.9756 17.241L13.9574 17.4106C13.896 18.0079 13.6309 18.5683 13.2042 19.0034C12.7774 19.4385 12.2132 19.7235 11.6006 19.8134C10.4025 19.9795 9.19377 20.0631 7.98348 20.0635ZM3.54837 6.90916L3.42689 7.99481C3.0885 11.0154 3.0885 14.0625 3.42689 17.0831L3.44612 17.2528C3.47518 17.5354 3.60031 17.8007 3.80186 18.0069C4.00342 18.2132 4.26999 18.3488 4.55971 18.3923C5.69211 18.5489 6.83454 18.6276 7.97841 18.6277C9.12229 18.6276 10.2647 18.5489 11.3971 18.3923C11.6868 18.3488 11.9534 18.2132 12.155 18.0069C12.3565 17.8007 12.4817 17.5354 12.5107 17.2528L12.5299 17.0831C12.8683 14.0625 12.8683 11.0154 12.5299 7.99481L12.4085 6.90916H3.54837Z" fill={color}/>
      </g>
      <defs>
      <clipPath id="clip0_1187_43745">
      <rect width="14.9666" height="19.375" fill="white" transform="translate(0.5 0.6875)"/>
      </clipPath>
      </defs>
    </svg>
  )
}

export default TrashIcon;

