import { colors } from "../../styles";
import IconProps from "./icon-interface";

const EyeIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.primary;

  return (
  <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 18 13" fill="none" xmlns="http://www.w3.org/2000/svg">
  <g clipPath="url(#clip0_657_361)">
  <mask id="path-1-outside-1_657_361" maskUnits="userSpaceOnUse" x="0" y="-0.112061" width="18" height="13" fill="black">
  <rect fill="white" y="-0.112061" width="18" height="13"/>
  <path d="M9.78219 0.887939C12.3349 0.963339 14.7916 1.91489 16.5714 4.06581C17.1154 4.72336 17.4962 5.4569 17.7198 6.24888C17.7632 6.4026 17.7665 6.58555 17.7172 6.73693C16.9266 9.17659 15.129 10.8062 12.4625 11.611C9.23429 12.5853 6.21459 12.1476 3.53955 10.2199C2.27603 9.30927 1.4499 8.12159 1.03224 6.72057C0.988826 6.57444 0.988826 6.39851 1.03487 6.25297C1.75904 3.97171 3.4205 2.42924 5.84165 1.50808C6.97034 1.07848 8.16085 0.891447 9.78088 0.888524L9.78219 0.887939ZM9.13958 11.1609C10.1657 11.1516 10.9563 11.0727 11.7192 10.8588C14.1121 10.1895 15.8236 8.87266 16.6306 6.71823C16.6826 6.5797 16.6793 6.40027 16.6306 6.2594C16.2083 5.03724 15.4263 4.01672 14.2641 3.27266C11.5423 1.53029 8.63048 1.34267 5.63183 2.63206C3.89671 3.37845 2.7154 4.61056 2.12541 6.25882C2.07542 6.3991 2.07936 6.57561 2.12541 6.71764C2.32931 7.34597 2.65686 7.9252 3.10084 8.44598C4.67284 10.2877 6.81906 11.0902 9.13826 11.1604L9.13958 11.1609Z"/>
  </mask>
  <path d="M9.78219 0.887939C12.3349 0.963339 14.7916 1.91489 16.5714 4.06581C17.1154 4.72336 17.4962 5.4569 17.7198 6.24888C17.7632 6.4026 17.7665 6.58555 17.7172 6.73693C16.9266 9.17659 15.129 10.8062 12.4625 11.611C9.23429 12.5853 6.21459 12.1476 3.53955 10.2199C2.27603 9.30927 1.4499 8.12159 1.03224 6.72057C0.988826 6.57444 0.988826 6.39851 1.03487 6.25297C1.75904 3.97171 3.4205 2.42924 5.84165 1.50808C6.97034 1.07848 8.16085 0.891447 9.78088 0.888524L9.78219 0.887939ZM9.13958 11.1609C10.1657 11.1516 10.9563 11.0727 11.7192 10.8588C14.1121 10.1895 15.8236 8.87266 16.6306 6.71823C16.6826 6.5797 16.6793 6.40027 16.6306 6.2594C16.2083 5.03724 15.4263 4.01672 14.2641 3.27266C11.5423 1.53029 8.63048 1.34267 5.63183 2.63206C3.89671 3.37845 2.7154 4.61056 2.12541 6.25882C2.07542 6.3991 2.07936 6.57561 2.12541 6.71764C2.32931 7.34597 2.65686 7.9252 3.10084 8.44598C4.67284 10.2877 6.81906 11.0902 9.13826 11.1604L9.13958 11.1609Z" fill={color}/>
  <path d="M9.78219 0.887939C12.3349 0.963339 14.7916 1.91489 16.5714 4.06581C17.1154 4.72336 17.4962 5.4569 17.7198 6.24888C17.7632 6.4026 17.7665 6.58555 17.7172 6.73693C16.9266 9.17659 15.129 10.8062 12.4625 11.611C9.23429 12.5853 6.21459 12.1476 3.53955 10.2199C2.27603 9.30927 1.4499 8.12159 1.03224 6.72057C0.988826 6.57444 0.988826 6.39851 1.03487 6.25297C1.75904 3.97171 3.4205 2.42924 5.84165 1.50808C6.97034 1.07848 8.16085 0.891447 9.78088 0.888524L9.78219 0.887939ZM9.13958 11.1609C10.1657 11.1516 10.9563 11.0727 11.7192 10.8588C14.1121 10.1895 15.8236 8.87266 16.6306 6.71823C16.6826 6.5797 16.6793 6.40027 16.6306 6.2594C16.2083 5.03724 15.4263 4.01672 14.2641 3.27266C11.5423 1.53029 8.63048 1.34267 5.63183 2.63206C3.89671 3.37845 2.7154 4.61056 2.12541 6.25882C2.07542 6.3991 2.07936 6.57561 2.12541 6.71764C2.32931 7.34597 2.65686 7.9252 3.10084 8.44598C4.67284 10.2877 6.81906 11.0902 9.13826 11.1604L9.13958 11.1609Z" stroke={color} strokeWidth="0.4" mask="url(#path-1-outside-1_657_361)"/>
  <mask id="path-2-outside-2_657_361" maskUnits="userSpaceOnUse" x="5.27698" y="2.52698" width="8" height="8" fill="black">
  <rect fill="white" x="5.27698" y="2.52698" width="8" height="8"/>
  <path d="M12.4763 6.49439C12.4776 8.12923 11.0891 9.46863 9.38797 9.47297C7.67395 9.4767 6.27504 8.13668 6.27698 6.49315C6.27957 4.86141 7.6746 3.52636 9.37762 3.52698C11.0806 3.52698 12.475 4.86265 12.4763 6.49501V6.49439ZM11.4449 6.49873C11.4443 5.41443 10.5145 4.52005 9.38215 4.51508C8.25435 4.51012 7.31872 5.40077 7.30837 6.48818C7.29802 7.57497 8.24917 8.48984 9.38344 8.48363C10.5158 8.47742 11.4456 7.5818 11.4449 6.49873Z"/>
  </mask>
  <path d="M12.4763 6.49439C12.4776 8.12923 11.0891 9.46863 9.38797 9.47297C7.67395 9.4767 6.27504 8.13668 6.27698 6.49315C6.27957 4.86141 7.6746 3.52636 9.37762 3.52698C11.0806 3.52698 12.475 4.86265 12.4763 6.49501V6.49439ZM11.4449 6.49873C11.4443 5.41443 10.5145 4.52005 9.38215 4.51508C8.25435 4.51012 7.31872 5.40077 7.30837 6.48818C7.29802 7.57497 8.24917 8.48984 9.38344 8.48363C10.5158 8.47742 11.4456 7.5818 11.4449 6.49873Z" fill={color}/>
  <path d="M12.4763 6.49439C12.4776 8.12923 11.0891 9.46863 9.38797 9.47297C7.67395 9.4767 6.27504 8.13668 6.27698 6.49315C6.27957 4.86141 7.6746 3.52636 9.37762 3.52698C11.0806 3.52698 12.475 4.86265 12.4763 6.49501V6.49439ZM11.4449 6.49873C11.4443 5.41443 10.5145 4.52005 9.38215 4.51508C8.25435 4.51012 7.31872 5.40077 7.30837 6.48818C7.29802 7.57497 8.24917 8.48984 9.38344 8.48363C10.5158 8.47742 11.4456 7.5818 11.4449 6.49873Z" stroke={color} strokeWidth="0.4" mask="url(#path-2-outside-2_657_361)"/>
  </g>
  <defs>
  <clipPath id="clip0_657_361">
  <rect width="18" height="13" fill="white"/>
  </clipPath>
  </defs>
  </svg>  
  )
}

export default EyeIcon;

