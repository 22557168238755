import { MouseEvent } from 'react';
import { useModelActions } from '../store/hooks/use-actions';
import { ContextMenuItem } from '../store/store-types';

export type ContextMenuOptions<T> = {
  onContextMenu ?: (e : MouseEvent<T>) => void;
  onMouseDown ?: (e : MouseEvent<T>) => void;
  onMouseUp ?: (e : MouseEvent<T>) => void;
  onMouseMove ?: (e : MouseEvent<T>) => void;
  onMouseLeave ?: (e : MouseEvent<T>) => void;
  options : ContextMenuItem[];
  disabled ?: boolean;
}

let timer : NodeJS.Timeout | null;

const useContextMenu = <T = HTMLDivElement>(options : ContextMenuOptions<T>) => {
  const {showContextMenu} = useModelActions('modals');

  const onContextMenu = (e : MouseEvent<T>) => {
    e.preventDefault();

    if(options.disabled) {return;}

    options.onContextMenu?.(e);

    if(timer) {
      clearTimeout(timer);
      timer = null;
    }
    showContextMenu(e.pageX, e.pageY, options.options);
  }

  const onMouseDown = (e : MouseEvent<T>) => {
    if(options.disabled) {return;}

    options.onMouseDown?.(e);

    if(timer) {clearTimeout(timer);}
    timer = setTimeout(() => {
      showContextMenu(e.pageX, e.pageY, options.options);
      timer = null;
    }, 1000);
  }

  const onMouseUp = (e : MouseEvent<T>) => {
    if(options.disabled) {return;}

    options.onMouseUp?.(e);

    if(timer) {clearTimeout(timer);}
    timer = null;
  }

  const onMouseMove = (e : MouseEvent<T>) => {
    if(options.disabled) {return;}

    options.onMouseMove?.(e);

    if(timer) {clearTimeout(timer);}
    timer = null;
  }

  const onMouseLeave = (e : MouseEvent<T>) => {
    if(options.disabled) {return;}

    options.onMouseLeave?.(e);

    if(timer) {clearTimeout(timer);}
    timer = null;
  }

  return {onContextMenu, onMouseDown, onMouseUp, onMouseMove, onMouseLeave};
}

export default useContextMenu;