import styled from "styled-components";
import { TypographyVariants, colors } from "../../styles";
import Typography from "./typography";

interface CheckboxProps {
  value : boolean;
  onChange : (value : boolean) => void;
  label ?: string;
  variant ?: TypographyVariants
  radio ?: boolean;
  mixed ?: boolean;
}

const Checkbox : React.FC<CheckboxProps> = (p) => {
  return (
    <Container onClick={() => p.onChange(!p.value)}>
      <Box $radio={p.radio}>
        {p.value || p.mixed ? <Fill $radio={p.radio} $mixed={!!p.mixed}/> : null}
      </Box>
      {
        p.label ?
          <Typography variant={p.variant || "textRegular"} color="text">{p.label}</Typography> :
          null
      }
    </Container>
  )
}

export default Checkbox;

const Container = styled.div`
  display: inline-flex;
  cursor : pointer;
`

const Box = styled.div<{$radio ?: boolean}>`
  width : 1.4rem;
  height : 1.4rem;
  border : 0.2rem solid ${colors.primary};
  border-radius : ${p => p.$radio ? 3 : 0.3}rem;
  padding : 0.1rem;
  background-color : ${colors.background2};
  position: relative;
  margin-right : 1rem;
`

const Fill = styled.div<{$radio ?: boolean; $mixed ?: boolean}>`
  width : 100%;
  height : 100%;
  background-color: ${p => p.$mixed ? colors.lightSecondary : colors.primary};
  border-radius : ${p => p.$radio ? 3 : 0}rem;
`