
import { colors } from "../../styles";
import IconProps from "./icon-interface";

const ArrowDownIcon : React.FC<IconProps & {onClick?:()=>void}> = (p) => {
  const color = p.color || colors.text;

  return (
    <svg onClick={p.onClick} className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 18 10" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.416842 0.39998C0.548811 0.273262 0.705506 0.172739 0.877975 0.104155C1.05044 0.0355714 1.23531 0.00027151 1.422 0.00027151C1.60869 0.00027151 1.79355 0.0355714 1.96602 0.104155C2.13849 0.172739 2.29519 0.273262 2.42716 0.39998L9 6.70725L15.5747 0.39998C15.8441 0.159107 16.2004 0.0279745 16.5686 0.0342076C16.9367 0.0404407 17.288 0.183553 17.5483 0.433395C17.8087 0.683237 17.9578 1.0203 17.9643 1.37358C17.9708 1.72685 17.8342 2.06875 17.5832 2.32725L10.0042 9.59998C9.73776 9.85534 9.37658 9.99878 9 9.99878C8.62342 9.99878 8.26224 9.85534 7.99579 9.59998L0.416842 2.32725C0.150725 2.07157 0.00124987 1.72498 0.00124987 1.36362C0.00124987 1.00225 0.150725 0.655662 0.416842 0.39998Z" fill={color}/>
    </svg>
  )
}

export default ArrowDownIcon;

