import styled from 'styled-components';
import {colors, shadows, spacing} from '../../styles';
import Typography from './typography';

interface InactivePageCardProps {
  className ?: string;
  imgUrl : string;
  label : string;
}

const InactivePageCard : React.FC<InactivePageCardProps> = (p) => {
  return (
    <CardContainer className={p.className}>
      <PageCard>
        <PageThumbnail src={p.imgUrl} />
        <Label>
          <Typography variant="textRegular" color="secondaryText">{p.label}</Typography>
        </Label>
      </PageCard>
    </CardContainer>
  );
};

export default InactivePageCard;

const CardContainer = styled.div`
  position :relative;
  margin : ${spacing}rem;
  padding-bottom : ${spacing}rem;
`

const PageCard = styled.div`
  display: inline-flex;
  flex-direction: column;
  border-radius: 1.5rem;
  padding : 0.3rem;
  padding-bottom : 0;
  border : 0.3rem solid ${colors.background2};
  background-color: ${colors.background2};
  z-index : 0;

  &.selected {
    border : 0.3rem solid ${colors.primary};
  }
  box-shadow: ${shadows.default};
`

const PageThumbnail = styled.img`
  max-width: 20rem;
  border-radius: 1rem;
`

const Label = styled.div`
  margin-top : 1rem;
  margin-bottom : 1rem;
  padding-left : 1rem;
  padding-right : 1rem;
`
