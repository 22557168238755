

const callbacks : Record<string, Record<string, (() => void)>> = {};
let lastId : number = 0;

/**
 * Register callbacks that are called when other components in the same
 * family (key param) calls toggle.
 *
 * @param key Unique key for this toggler
 * @returns {toggle, removeCallback, addCallback}
 */
const useToggler = (key : string) => {
  if(!callbacks[key]) {
    callbacks[key] = {}
  }

  const id = `handler_${++lastId}`;

  const addCallback = (callback : () => void) => {
    callbacks[key][id] = callback;
  }

  const removeCallback = () => {
    delete callbacks[key][id];
  }

  const toggle = () => {
    Object.keys(callbacks[key]).forEach(_id => {
      if(_id !== id) {
        callbacks[key][_id]?.();
      }
    })
  }

  return {toggle, removeCallback, addCallback};
}

export default useToggler;
