import { ReactNode } from "react";
import styled from "styled-components";
import { colors, shadows, spacing } from "../../styles";

interface PaperProps {
  className ?: string;
  style ?: React.CSSProperties;
  children : ReactNode;
}

const Paper : React.FC<PaperProps> = (p) => {
  return (
    <Container className={p.className} style={p.style}>
      {p.children}
    </Container>
  );
}

export default Paper;

const Container = styled.div`
  border-radius : ${spacing}rem;
  background-color: ${colors.background2};
  padding : ${spacing}rem;
  box-shadow: ${shadows.default};
`;