import styled from "styled-components"
import Typography from "../../atoms/typography";
import { useTranslation } from "react-i18next";
import { colors, spacing } from "../../../styles";
import dateString from "../../../store/helpers/date-string";

interface CalendarProps {
  reference : Date;
  onSelect : (date : Date) => void;
  selected ?: Date;
  isActive ?: (date : Date) => boolean;
}

const Calendar : React.FC<CalendarProps> = (p) => {
  const {t} = useTranslation();

  const month = p.reference.getMonth();
  const year = p.reference.getFullYear();

  const firstDayDate = new Date(p.reference);
  firstDayDate.setDate(1);
  const firstDay = (firstDayDate.getDay() + 6) % 7;
  const lastDayDate = new Date(p.reference);
  lastDayDate.setMonth(p.reference.getMonth() + 1);
  lastDayDate.setDate(0);
  const lastDayNumber = lastDayDate.getDate();

  const selectedDateString = p.selected ? dateString(p.selected) : '';

  const isPast = (dateOfMonth : number) => {
    const date = new Date(firstDayDate);
    date.setDate(dateOfMonth);
    const dateYear = date.getFullYear();
    const dateMonth = date.getMonth();
    const dateDate = date.getDate();

    const today = new Date();
    const todayYear = today.getFullYear();
    const todayMonth = today.getMonth();
    const todayDate = today.getDate();


    return dateYear < todayYear ||
      (dateYear === todayYear && dateMonth < todayMonth) ||
      (dateYear === todayYear && dateMonth === todayMonth && dateDate < todayDate)
  }

  const isToday = (dateOfMonth : number) => {
    const date = new Date(firstDayDate);
    date.setDate(dateOfMonth);
    const today = new Date();

    return dateString(date) === dateString(today);
  }

  const isSelected = (dateOfMonth : number) => {
    const date = new Date(firstDayDate);
    date.setDate(dateOfMonth);
    const today = new Date();

    return dateString(date) === selectedDateString;
  }

  const handleSelect = (dateOfMonth : number) => {
    const date = new Date(firstDayDate);
    date.setDate(dateOfMonth);

    p.onSelect?.(date);
  }

  const isActive = (dateOfMonth : number) => {
    const date = new Date(firstDayDate);
    date.setDate(dateOfMonth);

    return p.isActive ? p.isActive(date) : true;
  }

  return (
    <Container>
      <Typography variant="mediumTitle" color="primary">{t('components.datePicker.months.'+month)} {year}</Typography>
      <Days>
        {
          ['L', 'M', 'M', 'J', 'V', 'S', 'D'].map((day, index) => (
            <DayBox key={index}>
              <Typography variant="textBold" color="tertiaryText">{day}</Typography>
            </DayBox>
          ))
        }
      </Days>
      <CalendarContainer>
        {
          new Array(firstDay).fill('').map((_, index) => (
            <DayBox key={index}/>
          ))
        }
        {
          new Array(lastDayNumber).fill('').map((date, index) => (
            <ActiveDayBox
              key={'date-' + index}
              onClick={isActive(index+1) ? () => handleSelect(index+1) : () => {}}
              className={[isSelected(index+1) ? 'selected' : '', !isActive(index+1) ? 'disabled' : ''].join(' ')}
            >
              <Typography
                variant={isPast(index+1) ? "textRegular" : "textBold"}
                color={isPast(index+1) || !isActive(index+1) ? "tertiaryText" : isToday(index+1) ? "primary" : "secondaryText"}
              >
                {index+1}
              </Typography>
            </ActiveDayBox>
          ))
        }

      </CalendarContainer>
    </Container>
  )
}

export default Calendar;

const Container = styled.div`

`

const Days = styled.div`
  border-bottom : 0.2rem solid ${colors.tertiaryText};
  display: flex;
  margin-top : ${spacing}rem;
  padding-bottom : ${0.5}rem;
`

const DayBox = styled.div`
  width : 2.8rem;
  height : 2.8rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const ActiveDayBox = styled(DayBox)`
  cursor : pointer;
  &:hover, &.selected {
    background-color: ${colors.lightPrimary};
  }

  &.disabled {
    background-color: inherit !important;
    cursor : auto;
  }
`

const CalendarContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(7, 1fr);
`