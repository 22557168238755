import styled from "styled-components"
import Typography from "../../atoms/typography";
import { useTranslation } from "react-i18next";
import { colors, shadows, spacing } from "../../../styles";
import { useEffect, useState } from "react";
import ArrowDownIcon from "../../../assets/icons/arrow-down";
import { ID } from "../../../store/store-types";
import CloseIcon from "../../../assets/icons/close";

interface MultiSelectMenuProps {
  choices : {id : ID, label : string}[],
  value ?: ID[];
  onChange ?: (value : ID[]) => void;
  onClose ?: () => void;
}


const MultiSelectMenu : React.FC<MultiSelectMenuProps> = (p) => {
  const handleToggle = (id : ID) => {
    const value = p.value || [];
    if(value.includes(id)) {
      p.onChange?.(value.filter(_id => _id !== id))
    }
    else (
      p.onChange?.([...value, id])
    )
  }

  return (
    <Container>
      <IconContainer><div onClick={p.onClose}><CloseIcon width="1.2rem" height="1.2rem"/></div></IconContainer>
      {
        p.choices.map(choice => (
          <ChoiceChip key={choice.id} onClick={() => handleToggle(choice.id)}>
            <Typography variant="textRegular" color={p.value?.includes(choice.id) ? 'primary' : 'text'}>{choice.label}</Typography>
          </ChoiceChip>
        ))
      }
    </Container>
  )
}

export default MultiSelectMenu;

const Container = styled.div`
  position : relative;
  width : 60rem;
  border-radius: ${spacing}rem;
  background-color: ${colors.background2};
  box-shadow: ${shadows.default};
  padding : ${spacing}rem;
  user-select: none;
`

const ChoiceChip = styled.div`
  display : inline-block;
  box-shadow: ${shadows.heavy};
  border-radius: ${spacing}rem;
  margin-right : ${spacing}rem;
  margin-bottom : ${spacing}rem;
  padding : 1rem ${spacing}rem;

`

const IconContainer = styled.div`
  display : flex;
  justify-content: flex-end;
`