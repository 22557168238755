import { ReactNode, useEffect, useState } from "react";
import InlineDropdown from "../../atoms/inline-dropdown";
import MultiSelectMenu from "./multi-select-menu";
import useToggler from "../../../hooks/use-toggler";
import { ID } from "../../../store/store-types";

interface MultiSelectProps {
  placeholder ?: ReactNode;
  value ?: ID[];
  onChange ?: (value : ID[]) => void;
  choices : {id : ID, label : string}[];
  inverted ?: boolean;
  disabled ?: boolean;
}

const MultiSelect : React.FC<MultiSelectProps> = (p) => {
  const [open, setOpen] = useState<boolean>(false);

  const {toggle, addCallback, removeCallback} = useToggler('dropdown');

  useEffect(() => {
    addCallback(() => setOpen(false));

    return removeCallback;
  }, [])


  const handleSelect = (value : ID[]) => {
    p.onChange?.(value);
    // setOpen(false);
  }

  const handleOpen = (open : boolean) => {
    if(p.disabled) {return;}
    if(open) {toggle();}
    setOpen(open);
  }

  return (
    <InlineDropdown
      open={!p.disabled ? open : false}
      setOpen={handleOpen}
      inverted={p.inverted}
      label={p.placeholder || '---'}
      color={!!p.value?.length ? 'text' : 'tertiaryText'}
      hideChevron={p.disabled}
    >
      <MultiSelectMenu
        choices={p.choices}
        value={p.value}
        onChange={handleSelect}
        onClose={() => setOpen(false)}
      />
    </InlineDropdown>
  );
}

export default MultiSelect;

