import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import {colors, ColorsNames, typography, TypographyVariants} from '../../styles';

interface TypographyProps {
  variant : TypographyVariants;
  color ?: ColorsNames;
  children : ReactNode;
  className ?: string;
  style ?: React.CSSProperties
  onClick ?: () => void;
}

const Typography : React.FC<TypographyProps> = (p) => {
  const t = typography[p.variant].css as string;
  const color = colors[p.color || 'text'];

  return <Container onClick={p.onClick} style={p.style} className={p.className} $typography={t} $color={color}>{p.children}</Container>;
};

export default Typography;

const Container = styled.span<{$typography : string, $color : string}>`
  ${p => p.$typography}
  color : ${p => p.$color};
`