import { colors } from "../../styles";
import IconProps from "./icon-interface";

const PlusIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.text;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.7905 9.209C15.6318 9.36802 15.4432 9.49412 15.2357 9.58006C15.0281 9.66599 14.8056 9.71007 14.5809 9.70976L3.41869 9.70976C2.96509 9.70969 2.53009 9.52947 2.20934 9.20874C1.8886 8.88801 1.70838 8.45303 1.70831 7.99945C1.70838 7.54587 1.8886 7.11089 2.20934 6.79016C2.53009 6.46943 2.96509 6.28921 3.41869 6.28915L14.5809 6.28915C14.9192 6.2892 15.2498 6.38954 15.5311 6.57748C15.8124 6.76543 16.0316 7.03254 16.161 7.34506C16.2905 7.65757 16.3244 8.00145 16.2584 8.33322C16.1925 8.66499 16.0296 8.96976 15.7905 9.209Z" fill={color}/>
      <path d="M10.3608 14.7902C10.1823 14.9492 9.97013 15.0753 9.7366 15.1612C9.50306 15.2471 9.25273 15.2912 8.99997 15.2909C8.48966 15.2908 8.00027 15.1106 7.63943 14.7899C7.27858 14.4692 7.07583 14.0342 7.07575 13.5806L7.07575 2.41913C7.07583 1.96556 7.27858 1.53058 7.63943 1.20986C8.00027 0.889139 8.48966 0.708928 8.99997 0.70886C9.51028 0.708928 9.99967 0.889139 10.3605 1.20986C10.7214 1.53058 10.9241 1.96556 10.9242 2.41913L10.9242 13.5806C10.9245 13.8053 10.875 14.0278 10.7783 14.2353C10.6816 14.4429 10.5397 14.6315 10.3608 14.7902Z" fill={color}/>
    </svg>
  )
}

export default PlusIcon;

