import { useState } from 'react';
import styled from 'styled-components';
import { useUserActions } from '../store/hooks/use-actions';
import { useUserStore } from '../store/store';
import { useNavigate } from 'react-router-dom';

interface LoginPageProps {

}

const LoginPage : React.FC<LoginPageProps> = (p) => {
  const navigate = useNavigate();
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const { login } = useUserActions('user');
  const { loading, error } = useUserStore(state => ({
    loading : !!state.process.loading['login'],
    error   : state.process.errors['login']
  }))

  const handleSubmit = async () => {
    const done = await login(email, password);
    if(done) {
      navigate('/');
    }
  }

  return (
    <Container>
      <input type="email" name="email" onChange={(e) => setEmail(e.target.value)} value={email}/>
      <input type="password" name="password" onChange={(e) => setPassword(e.target.value)} value={password}/>
      <button onClick={handleSubmit}>Submit</button>
      {
        error ? <div>{error}</div> : null
      }
      {
        loading ? <div>loading</div> : null
      }
    </Container>
  )
}

export default LoginPage;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

