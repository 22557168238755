import { colors } from "../../styles";
import IconProps from "./icon-interface";

const LinkIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.primary;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 31 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.32031 19.592C5.35031 17.722 5.97031 16.212 7.20031 14.962C7.77031 14.382 8.35031 13.802 8.93031 13.222C9.46031 12.702 10.1803 12.682 10.6703 13.162C11.1603 13.652 11.1403 14.372 10.6103 14.902C10.0303 15.482 9.45031 16.052 8.88031 16.642C7.74031 17.812 7.37031 19.202 7.87031 20.742C8.37031 22.292 9.48031 23.222 11.0903 23.472C12.3903 23.672 13.5403 23.292 14.4803 22.362C15.1003 21.762 15.7003 21.132 16.3203 20.532C16.6903 20.172 17.2103 20.092 17.6603 20.302C18.1003 20.512 18.4003 20.962 18.3203 21.452C18.2803 21.722 18.1503 22.022 17.9703 22.212C17.3403 22.892 16.6903 23.562 16.0003 24.182C14.1003 25.852 11.9103 26.312 9.54031 25.462C7.18031 24.612 5.81031 22.862 5.39031 20.372C5.34031 20.072 5.33031 19.762 5.32031 19.572V19.592Z" fill={color}/>
      <path d="M23.0602 2.17062C25.3502 2.30062 27.3902 3.52062 28.4702 6.03062C29.5402 8.52063 29.1002 10.8606 27.3002 12.8906C26.6902 13.5806 26.0102 14.2106 25.3602 14.8506C24.8602 15.3406 24.1302 15.3406 23.6602 14.8606C23.1902 14.3806 23.1902 13.6606 23.6902 13.1606C24.3002 12.5406 24.9402 11.9306 25.5402 11.3006C26.6302 10.1506 26.9902 8.80062 26.5202 7.29063C26.0502 5.79063 25.0002 4.86062 23.4602 4.55062C22.1202 4.28062 20.9302 4.65062 19.9402 5.58063C19.2802 6.20062 18.6502 6.87063 18.0002 7.50063C17.6402 7.85063 17.2102 7.95062 16.7402 7.77063C16.2802 7.59063 16.0002 7.25062 15.9902 6.76062C15.9902 6.49062 16.0502 6.14062 16.2202 5.96063C17.1202 5.04063 18.0202 4.10063 19.0202 3.29063C20.0502 2.46063 21.3202 2.15062 23.0602 2.14062V2.17062Z" fill={color}/>
      <path d="M13.4902 18.8827C13.0102 18.8727 12.6502 18.6527 12.4302 18.2327C12.2102 17.8027 12.2602 17.3727 12.5402 16.9727C12.6002 16.8927 12.6702 16.8227 12.7502 16.7427C15.1402 14.3527 17.5402 11.9627 19.9202 9.57266C20.2902 9.20266 20.7102 9.04266 21.2102 9.19266C21.9802 9.42266 22.2902 10.3427 21.8302 11.0027C21.7602 11.1127 21.6602 11.2027 21.5702 11.2927C19.1902 13.6727 16.8102 16.0527 14.4402 18.4327C14.1702 18.7027 13.8802 18.8927 13.4902 18.8827Z" fill={color}/>
    </svg>
    
  )
}

export default LinkIcon;

