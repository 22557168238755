import { useTranslation } from "react-i18next";
import Modal from "../modal";
import { colors, shadows, spacing, typography } from "../../../styles";
import styled from "styled-components";
import { useModelStore, useUserStore } from "../../../store/store";
import { useState } from "react";
import TextButton from "../../atoms/text-button";
import { ID, User } from "../../../store/store-types";
import Select from "../../atoms/select";
import { ADMIN, ROLES, SUPERADMIN, USER } from "../../../constants/roles";
import NewUserIcon from "../../../assets/icons/new-user";
import { useModelActions } from "../../../store/hooks/use-actions";
import { msal } from "../../../providers/msal";
import useDebounce from "../../../hooks/use-debounce";
import Typography from "../../atoms/typography";
import ShareIcon from "../../../assets/icons/share";
import CloseIcon from "../../../assets/icons/close";

interface ShareModalProps {
  onClose : () => void;
  onShare : (userId : number) => void;
}

const ShareModal : React.FC<ShareModalProps> = (p) => {
  const {t} = useTranslation();
  const [editedQuery, setEditedQuery] = useState<string>('');
  const {search} = useModelActions('users');
  const [users, setUsers] = useState<User[] | null>(null);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);

  const {debounce} = useDebounce(async (value ?: string) => {
    if(value) {
      search(value).then(setUsers);
    }
    else {
      setUsers([]);
    }
  }, 400)

  const handleSubmit = async () => {
    if(selectedUser) {
      p.onShare(selectedUser.id)
      p.onClose();
    }
  }


  const handleChangeQuery = (query : string) => {
    setEditedQuery(query);
    setSelectedUser(null);
    setUsers(null);
    debounce(query);
  }

  const handleSelectUser = (user : User) => {
    setSelectedUser(user);
    setEditedQuery('');
    setUsers(null);
  }

  return (
    <Modal
      title={t('modals.shareVideoModal.title')}
      subtitle={t('modals.shareVideoModal.subtitle')}
      icon={<ShareIcon color={colors.text}/>}
      onClose={p.onClose}
      controls={[
        <TextButton key="validate" disabled={!selectedUser} onClick={handleSubmit}>{t('modals.shareVideoModal.validate')}</TextButton>
      ]}
    >
      <InputsContainer>
        <Typography variant="textRegular" color="secondaryText">À : </Typography>
        {
          selectedUser ?
          <UserChip>
            <Typography variant="textBold" color="whiteText">{selectedUser.email}</Typography>
            &nbsp;&nbsp;&nbsp;<span onClick={() => setSelectedUser(null)}><CloseIcon width="1.3rem" color={colors.whiteText}/></span>
          </UserChip> :
          <EmailInput
            placeholder={t('modals.shareVideoModal.placeholder')}
            value={editedQuery}
            onChange={e => handleChangeQuery(e.target.value)}
          />
        }
        {
          users ?
            <SelectionDropdown>
              {
                editedQuery && users && users.length === 0 ?
                  <NoUserFoundContainer>
                    <Typography variant="textBold">{t('pages.users.noUserFound')}</Typography>
                  </NoUserFoundContainer> :
                users && users.length ?
                users.map(user => (
                  <SelectionItem key={user.id} onClick={() => handleSelectUser(user)}>
                    <Typography variant="textBold">{user.firstName} {user.lastName}</Typography> <Typography variant="textRegular">&lt;{user.email}&gt;</Typography>
                  </SelectionItem>
                )) : null
              }
            </SelectionDropdown> : null
        }
      </InputsContainer>
    </Modal>
  );
}

export default ShareModal;

const InputsContainer = styled.div`
  min-width : 50rem;
  height : 6rem;
  margin-top: ${2*spacing}rem;
  background-color: ${colors.background};
  border-radius: ${spacing}rem;
  padding: ${spacing}rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  position : relative;
`

const EmailInput = styled.input`
  flex : 1;
  z-index: 10;
  color : ${colors.text};
  &::placeholder {
    color : ${colors.tertiaryText};
  }
  ${typography.textBold.css};
  border : none;
  background-color: transparent;
  outline: none;
  padding-left : 3rem;
`



const SelectionDropdown = styled.div`
  position : absolute;
  border-radius : 1.5rem;
  box-shadow: ${shadows.heavy};
  background-color: ${colors.background2};
  padding : ${spacing}rem;
  padding-left : 0;
  padding-right : 0;
  z-index: 10;
  bottom: 0;
  transform: translateY(100%);
`

const SelectionItem = styled.div`
  padding : 0.7rem;
  padding-left : ${spacing}rem;
  padding-right : ${spacing}rem;
  cursor : pointer;

  &:hover {
    background-color: ${colors.primary};
  }

  &:hover span{
    color: ${colors.whiteText};
  }
`

const NoUserFoundContainer = styled.div`
  padding : 0.7rem;
  padding-left : ${spacing}rem;
  padding-right : ${spacing}rem;
`


const UserChip = styled.div`
  border-radius : 2rem;
  height : 3rem;
  background: ${colors.primary};
  padding : 0.5rem ${spacing}rem;
  display: flex;
  flex-wrap: nowrap;
  white-space: nowrap;
  margin-left : ${spacing}rem;
`
