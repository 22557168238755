import { ReactNode } from "react";
import { useModelActions } from "../store/hooks/use-actions"

export type DialogOptions = {
  content ?: ReactNode;
  callback ?: () => void;
  cancelText ?: string | false;
  validateText ?: string | false;
};

const useDialog = (options ?: DialogOptions) => {
  const {showDialog} = useModelActions('modals');

  return (callback ?: () => void, _options ?: DialogOptions) => showDialog(callback ? {...options, ..._options, callback} : {...options, ..._options});
}

export default useDialog;
