import { useTranslation } from "react-i18next";
import Modal from "../modal";
import { colors, spacing, typography } from "../../../styles";
import styled from "styled-components";
import { useModelStore, useUserStore } from "../../../store/store";
import { useState } from "react";
import TextButton from "../../atoms/text-button";
import MarketIcon from "../../../assets/icons/markets";
import { Filter, ID, Slide, SlideIndex } from "../../../store/store-types";
import { useModelActions } from "../../../store/hooks/use-actions";
import TagsIcon from "../../../assets/icons/tags";
import IconChip from "../../atoms/icon-chip";
import AddIcon from "../../../assets/icons/add";
import RemoveCrossIcon from "../../../assets/icons/remove-cross";
import Typography from "../../atoms/typography";
import Checkbox from "../../atoms/checkbox";
import ToolButton from "../../atoms/tool-button";
import CopyIcon from "../../../assets/icons/copy";
import PasteIcon from "../../../assets/icons/paste";

interface TagsSelectionModalProps {
  onClose : () => void;
  slideIndex : SlideIndex;
  marketsIds : ID[]
}


const TagsSelectionModal : React.FC<TagsSelectionModalProps> = (p) => {
  const {t} = useTranslation();
  const filters = useModelStore(state => state.filters.filter(filter => !filter.inactive && p.marketsIds.some(mid => filter.marketsIds?.includes(mid))));
  const slide  = useModelStore(state => p.slideIndex ? state.actions.notebookEditor.slideAtIndex(p.slideIndex) : null);
  const clipboard = useModelStore(state => state.notebooks.editor.tagsClipboard);
  const {setTags : storeTags, copyTags} = useModelActions('notebookEditor');

  const [tags, setTags] = useState<ID[]>(slide?.tags || []);

  const setFilterValue = (id : ID, value : boolean) => {
    setTags(tags => {
      const values = new Set<ID>(tags);

      if(value) {
        values.add(id);
      }
      else {
        values.delete(id);
      }

      return [...values.values()]
    });
  }

  const handleSubmit = () => {
    storeTags(p.slideIndex, tags)
    p.onClose();
  }

  const handleCopy = () => {
    copyTags(tags);
  }

  const handlePaste = () => {
    if(clipboard) {
      setTags(clipboard);
    }
  }


  return (
    <Modal
      title={t('pages.notebook.tagsModalTitle')}
      subtitle={t('pages.notebook.tagsModalSubtitle')}
      icon={<TagsIcon color={colors.text}/>}
      onClose={p.onClose}
      onSubmit={handleSubmit}
      controls={[
        <ToolButton key="copy" color="medium" type="button" onClick={handleCopy}><CopyIcon /></ToolButton>,
        <ToolButton key="paste" color="medium" type="button" onClick={handlePaste} disabled={!(clipboard && clipboard.length)}><PasteIcon /></ToolButton>,
        <TextButton key="validate" type="submit">{t('pages.notebook.tagsModalValidate')}</TextButton>
      ]}
    >
      <Container>
        {
          filters.map(filter => (
            <FilterContainer key={filter.id}>
              <Typography variant="textRegular" color="text">{filter.name}</Typography>
              <CheckboxContainer>
              {
                filter.values.map(choice => (
                  <Checkbox key={choice.id} variant="smallTextItalic" label={choice.value} onChange={(checked) => setFilterValue(choice.id, checked)} value={!!tags.includes(choice.id)}/>
                ))
              }
              </CheckboxContainer>
            </FilterContainer>
          ))
        }

      </Container>
    </Modal>
  );
}

export default TagsSelectionModal;

const Container = styled.div`
  columns : 2;
  width: 90rem;
`

const FilterContainer = styled.div`
  margin-top : ${2*spacing}rem;
  break-inside: avoid;

  &:first-child {
    margin-top: 0;
  }
`

const CheckboxContainer = styled.div`
  margin-top : ${spacing}rem;

  & > div {
    margin-right : ${2*spacing}rem;
    margin-bottom : 1rem;
  }
`