import { colors } from "../../styles";
import IconProps from "./icon-interface";

const UserAddIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.primary;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_2982_43597)">
    <path d="M9.35058 6.70574C9.78386 6.46829 10.2178 6.23147 10.6755 5.98083C9.90111 5.20882 9.56565 4.27725 9.70673 3.1968C9.8127 2.38773 10.2008 1.72062 10.8235 1.19484C12.0638 0.147689 13.9229 0.232492 15.0942 1.37638C16.2059 2.46248 16.5113 4.49145 15.0472 5.99026C15.4328 6.21011 15.8259 6.41427 16.1972 6.65234C16.5307 6.86592 16.6041 7.2202 16.416 7.51921C16.2147 7.83895 15.8529 7.90993 15.4892 7.67248C14.8779 7.27297 14.2195 6.99783 13.4946 6.90926C12.0638 6.734 10.7921 7.10525 9.70799 8.07074C9.64215 8.12916 9.57004 8.21459 9.55938 8.29562C9.45341 9.06701 9.12234 9.72407 8.55989 10.2718C8.92859 10.4779 9.28599 10.6619 9.6271 10.873C10.0384 11.1274 10.15 11.493 9.94125 11.8146C9.73119 12.1387 9.36187 12.1796 8.95304 11.9226C6.45494 10.3516 3.18685 11.3096 1.96225 13.951C1.5459 14.8493 1.86381 15.272 2.78178 15.2482C4.37507 15.2061 5.97024 15.2362 7.56479 15.2375C7.68957 15.2375 7.81623 15.2375 7.9385 15.2576C8.2539 15.3103 8.47148 15.5955 8.4464 15.9071C8.42006 16.2319 8.18179 16.4567 7.84507 16.4574C5.95896 16.4593 4.07284 16.4674 2.18673 16.4549C1.0587 16.448 0.230385 15.1721 0.611621 14.0207C1.17093 12.3303 2.30712 11.1563 3.91107 10.4188C4.00324 10.3767 4.09542 10.3346 4.19198 10.2907C2.42312 8.39927 3.30473 6.11463 4.6541 5.25342C6.1734 4.28416 8.40815 4.68116 9.35121 6.70636L9.35058 6.70574ZM6.3891 5.99717C5.32377 5.99654 4.45659 6.85398 4.44906 7.91558C4.44154 8.99289 5.31186 9.87483 6.38283 9.87609C7.44189 9.87734 8.31535 9.0111 8.32162 7.95264C8.32789 6.87094 7.46509 5.99779 6.3891 5.99717ZM12.9027 1.70931C11.8512 1.67539 10.9658 2.51524 10.9226 3.58752C10.8793 4.65415 11.7283 5.55242 12.8118 5.58697C13.8652 5.62027 14.7468 4.78418 14.7926 3.70939C14.8377 2.64716 13.9844 1.74386 12.9027 1.70931Z" fill={color}/>
    <path d="M14.1648 14.1676C14.1648 14.7248 14.1729 15.2757 14.1623 15.826C14.1541 16.228 13.9384 16.4479 13.5816 16.4567C13.1866 16.4661 12.9471 16.2632 12.9314 15.8674C12.9138 15.4302 12.9251 14.9918 12.9239 14.554C12.9239 14.4302 12.9239 14.3071 12.9239 14.1394C12.5809 14.1394 12.263 14.1394 11.9457 14.1394C11.7168 14.1394 11.4873 14.1444 11.2585 14.1381C10.8578 14.1268 10.58 13.868 10.5781 13.5137C10.5763 13.1588 10.8553 12.8931 11.2534 12.8868C11.6905 12.8799 12.1275 12.8849 12.5652 12.8849C12.6687 12.8849 12.7721 12.8849 12.9245 12.8849C12.9245 12.5815 12.9245 12.2938 12.9245 12.0061C12.9245 11.7354 12.9176 11.4634 12.9283 11.1933C12.9439 10.7969 13.1866 10.5481 13.5427 10.5469C13.9008 10.545 14.1516 10.7919 14.1635 11.1857C14.176 11.6229 14.1673 12.0614 14.1679 12.4992C14.1679 12.6123 14.1679 12.726 14.1679 12.8855C14.6758 12.8855 15.1599 12.8855 15.6433 12.8855C16.2089 12.8855 16.5105 13.0997 16.5149 13.5055C16.5193 13.9132 16.2133 14.1394 15.6558 14.14C15.1981 14.1406 14.7398 14.1412 14.282 14.1431C14.262 14.1431 14.2419 14.1513 14.166 14.1683L14.1648 14.1676Z" fill={color}/>
    </g>
    <defs>
    <clipPath id="clip0_2982_43597">
    <rect width="16" height="16" fill="white" transform="translate(0.517578 0.460938)"/>
    </clipPath>
    </defs>
    </svg>
  )
}

export default UserAddIcon;

