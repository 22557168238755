import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useNavigate, useParams } from 'react-router-dom';
import { useAssertAdmin } from '../hooks/use-assert-admin';
import PageLayout from '../components/layout/page-layout';
import NotebookNameInput from '../components/atoms/notebook-name-input';
import TextButton from '../components/atoms/text-button';
import Select from '../components/atoms/select';
import { useModelStore } from '../store/store';
import { LIBRARY, NOTEBOOK_TEMPLATE_TAGS } from '../constants/routes';
import Typography from '../components/atoms/typography';
import { useModelActions } from '../store/hooks/use-actions';
import { colors, spacing } from '../styles';
import EmptySlideImport from '../components/atoms/empty-slide-import';
import InactivePageCard from '../components/atoms/inactive-page-card';
import configProvider from '../providers/configprovider';
import SlideCard from '../components/molecules/slide-card';
import ProgressBar from '../components/atoms/progress-bar';
import ToolButton from '../components/atoms/tool-button';
import UndoIcon from '../assets/icons/undo';
import RedoIcon from '../assets/icons/redo';
import SlidePlaceholderCard from '../components/atoms/slide-placeholder-card';
import { DynamicSlideViewMeta, ID, PageSlide, Slide, SlideIndex } from '../store/store-types';
import ContentLevelSlideCard from '../components/molecules/content-level-slide-card';
import ContentLevelContainer from '../components/atoms/content-level-container';
import TextAreaModal from '../components/molecules/modals/text-area-modal';
import TagsSelectionModal from '../components/molecules/modals/tags-selection-modal';
import SummaryCard from '../components/molecules/summary-card';
import ContenetLevelSummaryCard from '../components/molecules/content-level-summary-card';
import AlternativesModal from '../components/molecules/modals/alternatives-modal';
import ListViewIcon from '../assets/icons/list-view';
import MosaicViewIcon from '../assets/icons/mosaic-view';
import FlatButton from '../components/atoms/flat-button';
import ListViewItem from '../components/atoms/list-view-item';
import ListViewContentLevelContainer from '../components/atoms/list-view-content-level-container';
import { SlideCluster, SlideGroup } from '../types';
import useLogged from '../hooks/use-logged';
import ContentLevelAlternativeSlideCard from '../components/molecules/content-level-alternative-slide-card';
import AlternativeSlideCard from '../components/molecules/alternative-slide-card';
import LinkedSlideCard from '../components/molecules/linked-slide-card';
import useAutosave from '../hooks/use-autosave';
import UploadErrorCard from '../components/atoms/upload-error-card';

interface NotebookPageProps {

}


const NotebookPage : React.FC<NotebookPageProps> = (p) => {
  useAssertAdmin();
  const logged = useLogged();
  const {t} = useTranslation();
  let { id } = useParams();
  const navigate = useNavigate();

  const {loadTemplate, loadTemplateContent, update} = useModelActions('notebooks');
  const {start, load} = useModelActions('uploads');
  const {selectNotebook, addUpload, undo, redo, save, isModified, updateUploads, alternativeType} = useModelActions('notebookEditor');

  const template = useModelStore(state => state.notebooks.templates[parseInt(`${id}`)] || {});
  const markets   = useModelStore(state => state.markets.filter(({inactive}) => !inactive));
  const uploads  = useModelStore(state => state.uploads);

  const [name, setName] = useState<string>('');
  const [marketsIds, setMarketsIds] = useState<ID[] | undefined>();
  const marketsChoices = markets.map(market => ({label : market.name, value : market.id}));

  // const progress = useModelStore(state => state.process.progress[`uploads-${template?.uploadId}`]);

  const content  = useModelStore(state => state.actions.notebookEditor.current() || state.notebooks.templatesContent[parseInt(`${id}`)] || {});
  const viewMeta = useModelStore(state => state.actions.notebookEditor.viewMeta());

  // Id of the notebook in editor state. If this is different from the page id then the state should be reset.
  const editedNotebookId = useModelStore(state => state.notebooks.editor.notebookId);
  const canUndo = useModelStore(state => state.notebooks.editor.historyPosition < state.notebooks.editor.history.length - 1 );
  const canRedo = useModelStore(state => !!state.notebooks.editor.historyPosition);
  const readonly = useModelStore(state => state.notebooks.editor.readonly);

  const [textAreaModalVisibility, setTextAreaModalVisibility] = useState<SlideIndex | false>(false);
  const [tagsModalVisibility, setTagsModalVisibility] = useState<SlideIndex | false>(false);
  const [alternativesModalVisibility, setAlternativesModalVisibility] = useState<PageSlide | false>(false);

  const [viewStyle, setViewStyle] = useState<"list" | "mosaic">("mosaic");

  const _isModified = isModified() || name != template.name || marketsIds?.length !== template.marketsIds?.length || marketsIds?.some(mid => !template.marketsIds.includes(mid));

  // Group slides together when they are at level 4
  const slideClusters : SlideCluster[] = [];
  let lastGroup : SlideGroup | null;
  let lastLabel : string;
  (content.slides || []).forEach((slide, index) => {
    const meta = viewMeta[index];

    if(meta?.hiddenAlternative) {
      // Do not render alternative slides that come after the first one
      return;
    }

    if(slide.level < 4) {
      slideClusters.push({type : 'slide', slide, meta, index});
      lastGroup = null;
      lastLabel = slide.label;
    }
    else {
      if(!lastGroup) {
        lastGroup = {type : 'group', slides : [], parentLabel : lastLabel || '---'};
        slideClusters.push(lastGroup);
      }
      lastGroup.slides.push({slide, meta, index});
    }
  })


  /*
    Load template metadata and content when page loads
   */
  useEffect(() => {
    (async () => {
      if(id && logged) {
        const template = await loadTemplate(parseInt(id));

        setName(template.name);
        setMarketsIds(template.marketsIds);

        const content = await loadTemplateContent(parseInt(id));

        if(parseInt(id) !== editedNotebookId) {
          // Reset editor state if the id in state is different from the url id
          selectNotebook(parseInt(id));
        }

        const uploadsPromises = [];
        for(let uploadId of content.uploadIds || []) {
          uploadsPromises.push(load(uploadId));
        }
        await Promise.all(uploadsPromises);

        /*
          Test if some of the ongoing upload is finished. If yes then replace the
          uplod node with one or more slides. If this opeation takes places, isModified
          will return true. In this case the tempalte content is autoatically saved
          and the notebook selected again to clean up the modification history
        */
        const updated = updateUploads();
        if(updated || isModified()) {
          await save();
          // Clear edit history when upload nodes are replaced with slides and the content is saved
          selectNotebook(parseInt(id));
        }
      }
    })();
  }, [id, logged]);

  // Handles the creation of first import when the file is empty
  const handleSelectFile = async (file : File) => {
    if(id) {
      const uploadId = await start(file);
      addUpload({position : 0}, uploadId, 1);
      save();
    }
  }

  const handleSave = async () => {
    if(id) {
      if(name != template.name || marketsIds !== template.marketsIds) {
        await update(parseInt(id), name, marketsIds);
      }

      save();
    }
  }

  useAutosave(_isModified, handleSave);

  return (
    <PageLayout
      breadcrumbs={[t('pages.notebook.breadcrumb1'), t('pages.notebook.breadcrumb2')]}
      title={
        // <TitleContainer>
        //   <TitleTypography variant="bigTitle" color="text">{template.name || ''}</TitleTypography>
        //   <Typography variant="textItalic" color="secondaryText">{markets?.map(m => m.name)?.join(', ') || ''}</Typography>
        // </TitleContainer>
        <TitleContainer>
          <NotebookNameInput value={name} onChange={setName} placeholder={t('pages.new-notebook.namePlaceholder')}/>
          <Select<ID>
            choices={marketsChoices}
            value={marketsIds}
            onChange={setMarketsIds}
            placeholder='Marché'
            multiselect
          />
        </TitleContainer>

      }
      subtitle={t('pages.notebook.subtitle')}
      controls={
        <>
          <div>
            <HistoryToolButton disabled={readonly || !canUndo} onClick={undo}><UndoIcon color={colors.text}/></HistoryToolButton>
            <HistoryToolButton disabled={readonly || !canRedo} onClick={redo}><RedoIcon color={colors.text}/></HistoryToolButton>
          </div>
          <TextButton disabled={!_isModified} color="light" onClick={handleSave}>{template.draft ? t('pages.notebook.saveDraft') : t('pages.notebook.save')}</TextButton>
          <TextButton disabled={!content.slides?.length || _isModified} color="dark" onClick={() => navigate(NOTEBOOK_TEMPLATE_TAGS.replace(':id', id || ''))}>{t('pages.notebook.next')}</TextButton>
        </>
      }
      viewControls={
        [
          <FlatButton key="mosaic" onClick={() => setViewStyle("mosaic")} disabled={viewStyle === "mosaic"}>
            <MosaicViewIcon color={viewStyle === "mosaic" ? colors.primary : colors.lightPrimary}/>
          </FlatButton>,
          <ListFlatButton key="list" onClick={() => setViewStyle("list")} disabled={viewStyle === "list"}>
            <ListViewIcon color={viewStyle === "list" ? colors.primary : colors.lightPrimary}/>
          </ListFlatButton>
        ]
      }
      modal={
        !!textAreaModalVisibility ?
          <TextAreaModal slideIndex={textAreaModalVisibility} onClose={() => setTextAreaModalVisibility(false)} /> :
        !!tagsModalVisibility?
          <TagsSelectionModal slideIndex={tagsModalVisibility} marketsIds={template.marketsIds} onClose={() => setTagsModalVisibility(false)} /> :
        !!alternativesModalVisibility ?
          <AlternativesModal
            slide={alternativesModalVisibility}
            onClose={() => setAlternativesModalVisibility(false)}
            onShowTagsModal={setTagsModalVisibility}
            onShowTextAreaModal={setTextAreaModalVisibility}
            onMultichoiceChange={multichoice => alternativesModalVisibility.alternativeGroupId && alternativeType(alternativesModalVisibility.alternativeGroupId, multichoice)}
            multichoice={(content.multichoiceSlidesGroups || {})[alternativesModalVisibility.alternativeGroupId || '']}
            /> :
          null
      }
      showBackArrow
      confirmNavigation={_isModified}
    >
      {
        viewStyle === 'mosaic' ?
        <>
          <Container $blurred={false}>
            <ProgressBarContainer>
              {
                // !progress ? null: <ProgressBar total={progress.total} progress={progress.progress}/>
              }

            </ProgressBarContainer>
            {
              content.slides && content.slides.length ? null : <EmptySlideImport onSelect={handleSelectFile}/>
            }
            {
              (slideClusters).map(cluster => (
                // Single slide of type upload, summary or page
                cluster.type === 'slide' ? (
                  cluster.slide.type === 'upload' ?
                  (uploads[cluster.slide.uploadId] && uploads[cluster.slide.uploadId].error ?
                    <UploadErrorCard
                      index={cluster.index}
                    /> :
                  !uploads[cluster.slide.uploadId] || uploads[cluster.slide.uploadId].pages.length === 0 ?
                    <SlidePlaceholderCard key={cluster.index}/> :
                    // Iterate through upload pages...
                    (uploads[cluster.slide.uploadId].pages).map(page => (
                      <InactivePageCard
                        key={page.id}
                        imgUrl={`${configProvider('PDF_SERVICE_URL')}/pages/${page.id}/thumbnail`}
                        label={`Page ${page.page}`}
                      />
                    ))
                  ) :
                  cluster.slide.type === 'page' && cluster.slide.isSummary ?
                  <SummaryCard
                    key={'SummaryCard' + cluster.slide.pageId}
                    index={cluster.index}
                    slide={cluster.slide}
                    view={cluster.meta}
                  /> :
                  cluster.slide.alternativeGroupId ?
                  <AlternativeSlideCard
                    key={'AlternativeSlideCard' + cluster.slide.pageId}
                    index={cluster.index}
                    slide={cluster.slide}
                    view={cluster.meta}
                    onShowAlternativesModal={setAlternativesModalVisibility}
                  />:
                  // ...or insert a single slide
                  <SlideCard
                    key={'SlideCard' + cluster.slide.pageId}
                    index={cluster.index}
                    slide={cluster.slide}
                    view={cluster.meta}
                    onShowTextAreaModal={setTextAreaModalVisibility}
                    onShowTagsModal={setTagsModalVisibility}
                  />
                ) :
                // Cluster of level 4 slides
                (
                  <ContentLevelContainer
                    key={'ContentLevelContainer' + (cluster.slides?.[0].index || 0)}
                    index={cluster.slides?.[0]?.index || 0}
                    hidden={cluster.slides?.[0]?.meta?.hidden}
                    view={cluster.slides?.[0]?.meta}
                    parentLabel={cluster.parentLabel}
                    >
                    {
                      cluster.slides.map(slideData =>
                        slideData.slide.type === 'upload' ?
                        (uploads[slideData.slide.uploadId] && uploads[slideData.slide.uploadId].error ?
                          <GroupedUploadErrorCard
                            index={slideData.index}
                          /> :
                          !uploads[slideData.slide.uploadId] || uploads[slideData.slide.uploadId].pages.length === 0 ?
                          <GroupedSlidePlaceholderCard key={'GroupedSlidePlaceholderCard' + slideData.index} /> :
                          // Iterate through upload pages...
                          (uploads[slideData.slide.uploadId].pages).map(page => (
                            <GroupedInactivePageCard
                              key={'GroupedInactivePageCard' + page.id}
                              imgUrl={`${configProvider('PDF_SERVICE_URL')}/pages/${page.id}/thumbnail`}
                              label={`Page ${page.page}`}
                            />
                          ))
                        ) :
                        slideData.slide.type === 'page' && slideData.slide.isSummary?
                        <ContenetLevelSummaryCard
                          key={'ContenetLevelSummaryCard' + slideData.slide.pageId}
                          index={slideData.index}
                          slide={slideData.slide}
                          view={slideData.meta}
                        /> :
                        slideData.slide.alternativeGroupId ?
                        <ContentLevelAlternativeSlideCard
                          key={'ContentLevelAlternativeSlideCard' + slideData.slide.pageId}
                          index={slideData.index}
                          slide={slideData.slide}
                          view={slideData.meta}
                          onShowAlternativesModal={setAlternativesModalVisibility}
                        /> :
                        slideData.slide.linkedGroupdId ?
                        <LinkedSlideCard
                          key={'LinkedSlideCard' + slideData.slide.pageId}
                          index={slideData.index}
                          slide={slideData.slide}
                          view={slideData.meta}
                          onShowTextAreaModal={setTextAreaModalVisibility}
                          onShowTagsModal={setTagsModalVisibility}
                        /> :
                        <ContentLevelSlideCard
                          key={'ContentLevelSlideCard' + slideData.slide.pageId}
                          index={slideData.index}
                          slide={slideData.slide}
                          view={slideData.meta}
                          onShowTextAreaModal={setTextAreaModalVisibility}
                          onShowTagsModal={setTagsModalVisibility}
                        />

                      )
                    }
                  </ContentLevelContainer>
                )
              ))
            }
          </Container>
        </> :
        <>
          <Container $blurred={false}>
            {
              (slideClusters).map(cluster => (
                cluster.type === 'slide' ? (
                  cluster.slide.type === 'page' ?
                  <ListViewItem
                    key={cluster.index}
                    slide={cluster.slide}
                    view={cluster.meta}
                    index={cluster.index}
                  />
                  : null
                ) : (
                  <ListViewContentLevelContainer
                    key={cluster.slides?.[0]?.index || 0}
                    index={cluster.slides?.[0]?.index || 0}
                    hidden={cluster.slides?.[0]?.meta?.hidden}
                    view={cluster.slides?.[0]?.meta}
                  >
                    {
                      cluster.slides.map(slide => (
                        slide.slide.type === 'page' ?
                          <ListViewItem
                            key={slide.index}
                            slide={slide.slide}
                            view={slide.meta}
                            index={slide.index}
                          /> : null
                      ))
                    }
                  </ListViewContentLevelContainer>
                )
              ))
            }
          </Container>
        </>
      }
   </PageLayout>
  )
}

export default NotebookPage;

const Container = styled.div<{$blurred : boolean}>`
  ${p => p.$blurred ? 'filter : blur(0.8rem) brightness(0.8);' : ''}
`
const TitleContainer = styled.div`
  display: flex;
  /* align-items: baseline; */
`

const TitleTypography = styled(Typography)`
  margin-right : ${spacing}rem;
`

const ProgressBarContainer = styled.div`
  height : 1rem;
`
const HistoryToolButton = styled(ToolButton)`
  &:last-child {
    margin-right : ${3*spacing}rem;
  }
`

const GroupedSlidePlaceholderCard = styled(SlidePlaceholderCard)`
  margin : 0;
  padding : 0;
`

const GroupedInactivePageCard = styled(InactivePageCard)`
  margin : 0;
  padding : 0;
`

const GroupedUploadErrorCard = styled(UploadErrorCard)`
  margin : 0;
  padding : 0;
`

const ListFlatButton = styled(FlatButton)`
  padding-left: 0.4rem;
  padding-right: 0.1rem;
`