import axios from "axios";


import { GetFunction, SetFunction, ActionsFactory, ModelStore, Market, NotebookType, ID, NotebookTemplate, NotebookTemplateContent, Page, Video } from "../store-types";
import configProvider from "../../providers/configprovider";
import { setLoading } from "../helpers/set-loading";
import { setError } from "../helpers/set-error";
import { useUserStore } from "../store";


const videosActions : ActionsFactory<ModelStore['actions']['videos'], ModelStore> =  (set : SetFunction<ModelStore>, get : GetFunction<ModelStore>) => ({
  create : async (file : File, name : string, marketsIds : ID[]) => {
    setLoading(set, 'videos.create', true);

    try {
      const formData = new FormData();
      formData.append("file", file);

      const uploadResponse = await axios.post(`${configProvider('MODEL_API_URL')}/videos`, formData);
      if(uploadResponse.status === 200) {
        const storageName = uploadResponse.data.storageName;

        const newVideo = await axios.post(`${configProvider('MODEL_API_URL')}/public-videos`, {
          name,
          marketsIds,
          storageName
        })

        set(state => {
          state.videos.push(newVideo.data);
        })

        setLoading(set, 'videos.create', false);
        return;
      }
    }
    catch(error) {}

    setError(set, 'videos.create', 'errors.videos.create')
    throw 'errors.videos.create';

  },

  update : async (videoId : number, name : string, marketsIds : ID[]) => {
    setLoading(set, 'videos.update', true);

    try {
      const uploadResponse = await axios.patch(`${configProvider('MODEL_API_URL')}/public-videos/${videoId}`, {
        name, marketsIds
      });
      if(uploadResponse.status === 200) {
        setLoading(set, 'videos.update', false);
        return uploadResponse.data;
      }
    }
    catch(error) {}

    setError(set, 'videos.update', 'errors.videos.update')
    throw 'errors.videos.update';

  },

  load : async () => {
    setLoading(set, 'videos.load', true);

    try {
      const uploadResponse = await axios.get(`${configProvider('MODEL_API_URL')}/public-videos?pageSize=1000`);

      if(uploadResponse.status === 200) {
        setLoading(set, 'videos.load', false);

        set(state => {
          state.videos = uploadResponse.data.data || [];
        })

        return uploadResponse.data.data;
      }
    }
    catch(error) {}

    setError(set, 'videos.load', 'errors.videos.load')
    throw 'errors.videos.load';
  },

  createPrivate : async (file : File, name : string) => {
    setLoading(set, 'videos.createPrivate', true);

    try {
      const formData = new FormData();
      formData.append("file", file);

      const uploadResponse = await axios.post(`${configProvider('MODEL_API_URL')}/videos`, formData);
      if(uploadResponse.status === 200) {
        const storageName = uploadResponse.data.storageName;

        const newVideo = await axios.post(`${configProvider('MODEL_API_URL')}/private-videos`, {
          name,
          storageName
        })

        set(state => {
          state.privateVideos.push(newVideo.data);
        })

        setLoading(set, 'videos.createPrivate', false);
        return;
      }
    }
    catch(error) {}

    setError(set, 'videos.createPrivate', 'errors.videos.createPrivate')
    throw 'errors.videos.createPrivate';

  },

  updatePrivate : async (videoId : number, name : string) => {
    setLoading(set, 'videos.updatePrivate', true);

    try {
      const uploadResponse = await axios.patch(`${configProvider('MODEL_API_URL')}/private-videos/${videoId}`, {
        name
      });
      if(uploadResponse.status === 200) {
        setLoading(set, 'videos.updatePrivate', false);
        return uploadResponse.data;
      }
    }
    catch(error) {}

    setError(set, 'videos.updatePrivate', 'errors.videos.updatePrivate')
    throw 'errors.videos.updatePrivate';

  },

  loadPrivate : async () => {
    setLoading(set, 'videos.loadPrivate', true);

    try {
      const uploadResponse = await axios.get(`${configProvider('MODEL_API_URL')}/private-videos?pageSize=1000`);

      if(uploadResponse.status === 200) {
        setLoading(set, 'videos.loadPrivate', false);

        set(state => {
          state.privateVideos = uploadResponse.data.data || [];
        })

        return uploadResponse.data.data;
      }
    }
    catch(error) {}

    setError(set, 'videos.loadPrivate', 'errors.videos.loadPrivate')
    throw 'errors.videos.loadPrivate';
  },
  share : async (video : Video, userId : ID) => {
    setLoading(set, 'videos.share', true);

    try {
      const newVideo = await axios.post(`${configProvider('MODEL_API_URL')}/private-videos`, {
        userId,
        name : video.name,
        storageName : video.storageName,
        thumbnailStorageName : video.thumbnailStorageName
      })

      setLoading(set, 'videos.share', false);
      return;
    }
    catch(error) {}

    setError(set, 'videos.share', 'errors.videos.share')
    throw 'errors.videos.share';
  },

  publish : async (video : Video) => {
    setLoading(set, 'videos.publish', true);

    const marketsIds = useUserStore.getState().user.marketsIds;

    try {
      const newVideo = await axios.post(`${configProvider('MODEL_API_URL')}/public-videos`, {
        name : video.name,
        marketsIds,
        storageName : video.storageName,
        thumbnailStorageName : video.thumbnailStorageName
      })

      set(state => {
        state.videos.push(newVideo.data);
      })

      setLoading(set, 'videos.publish', false);
      return;
    }
    catch(error) {}

    setError(set, 'videos.publish', 'errors.videos.publish')
    throw 'errors.videos.publish';
  },

  archive : async (id : ID) => {
    setLoading(set, 'videos.archive', true);

    try {
      const response = await axios.patch(`${configProvider('MODEL_API_URL')}/public-videos/${id}`, {
        inactive : true
      })

      if(response.status === 200) {
        set(state => {
          const previous = state.videos.find(a => a.id === id);
          if (previous) {
            previous.inactive = true;
          }
        })

        setLoading(set, 'videos.archive', false);
        return;
      }
    }
    catch(error) {}

    setError(set, 'videos.archive', 'errors.videos.archive')
    throw 'errors.videos.archive';
  },

  unarchive : async (id : ID) => {
    setLoading(set, 'videos.unarchive', true);

    try {
      const response = await axios.patch(`${configProvider('MODEL_API_URL')}/public-videos/${id}`, {
        inactive : false
      })

      if(response.status === 200) {
        set(state => {
          const previous = state.videos.find(a => a.id === id);
          if (previous) {
            previous.inactive = false;
          }
        })

        setLoading(set, 'videos.unarchive', false);
        return;
      }
    }
    catch(error) {}

    setError(set, 'videos.unarchive', 'errors.videos.unarchive')
    throw 'errors.videos.unarchive';
  },

  archivePrivate : async (id : ID) => {
    setLoading(set, 'videos.archivePrivate', true);

    try {
      const response = await axios.patch(`${configProvider('MODEL_API_URL')}/private-videos/${id}`, {
        inactive : true
      })

      if(response.status === 200) {
        set(state => {
          const previous = state.privateVideos.find(a => a.id === id);
          if (previous) {
            previous.inactive = true;
          }
        })

        setLoading(set, 'videos.archivePrivate', false);
        return;
      }
    }
    catch(error) {}

    setError(set, 'videos.archivePrivate', 'errors.videos.archivePrivate')
    throw 'errors.videos.archivePrivate';
  },

  unarchivePrivate : async (id : ID) => {
    setLoading(set, 'videos.unarchivePrivate', true);

    try {
      const response = await axios.patch(`${configProvider('MODEL_API_URL')}/private-videos/${id}`, {
        inactive : false
      })

      if(response.status === 200) {
        set(state => {
          const previous = state.privateVideos.find(a => a.id === id);
          if (previous) {
            previous.inactive = false;
          }
        })

        setLoading(set, 'videos.unarchivePrivate', false);
        return;
      }
    }
    catch(error) {}

    setError(set, 'videos.unarchivePrivate', 'errors.videos.unarchivePrivate')
    throw 'errors.videos.unarchivePrivate';
  }
})

export default videosActions;


