import { colors } from "../../styles";
import IconProps from "./icon-interface";

const CustomersIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.text;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.5799 11.3944C16.3415 10.9608 17.1041 10.5284 17.9087 10.0708C16.5476 8.66121 15.9579 6.96028 16.2059 4.98753C16.3922 3.51026 17.0744 2.2922 18.1688 1.3322C20.3487 -0.579763 23.6164 -0.424925 25.6751 1.66367C27.6292 3.64674 28.1659 7.35139 25.5925 10.088C26.2703 10.4894 26.9613 10.8622 27.6137 11.2969C28.2001 11.6869 28.329 12.3337 27.9984 12.8797C27.6446 13.4635 27.0087 13.5931 26.3695 13.1595C25.2949 12.4301 24.1377 11.9277 22.8637 11.766C20.3487 11.446 18.1136 12.1238 16.2081 13.8867C16.0924 13.9934 15.9657 14.1494 15.9469 14.2973C15.7607 15.7058 15.1788 16.9055 14.1902 17.9056C14.8382 18.2818 15.4664 18.6179 16.0659 19.0032C16.7889 19.4678 16.9851 20.1353 16.6181 20.7225C16.2489 21.3144 15.5998 21.3889 14.8812 20.9198C10.4904 18.0513 4.7463 19.8004 2.5939 24.6233C1.86211 26.2634 2.42087 27.0353 4.03434 26.9917C6.83477 26.9149 9.63851 26.97 12.4411 26.9723C12.6605 26.9723 12.8831 26.9723 13.098 27.009C13.6524 27.1053 14.0348 27.626 13.9907 28.1949C13.9444 28.7879 13.5256 29.1985 12.9338 29.1996C9.61867 29.2031 6.30356 29.218 2.98845 29.195C1.00578 29.1824 -0.450095 26.853 0.21998 24.7506C1.20305 21.6642 3.20005 19.5205 6.01922 18.174C6.18123 18.0972 6.34324 18.0203 6.51296 17.94C3.40394 14.4866 4.95349 10.3151 7.32521 8.74264C9.99559 6.9729 13.9235 7.69777 15.581 11.3955L15.5799 11.3944ZM10.3747 10.1006C8.50225 10.0995 6.97805 11.6651 6.96482 13.6034C6.9516 15.5704 8.48131 17.1807 10.3637 17.183C12.2251 17.1853 13.7604 15.6037 13.7714 13.6711C13.7824 11.696 12.2659 10.1018 10.3747 10.1006ZM21.8233 2.27155C19.9751 2.20962 18.4189 3.74309 18.3429 5.70093C18.2668 7.64845 19.7591 9.28859 21.6635 9.35167C23.515 9.41246 25.0646 7.88587 25.145 5.92344C25.2244 3.98395 23.7244 2.33463 21.8233 2.27155Z" fill={color}/>
      <path d="M25.0013 17.8274L26.2639 20.4851C26.5239 21.0323 27.0389 21.4146 27.638 21.505L30.5177 21.9397C30.5221 21.9404 30.525 21.9411 30.5266 21.9416L30.5289 21.9424C30.5298 21.9434 30.5331 21.9473 30.5356 21.9548C30.5381 21.9623 30.5378 21.9674 30.5376 21.9687L30.5363 21.9707C30.536 21.9711 30.5357 21.9715 30.5353 21.972C30.5342 21.9733 30.5326 21.9752 30.5303 21.9776L28.4026 24.132C27.9926 24.5472 27.8062 25.1337 27.9013 25.7094L28.3974 28.7144C28.3982 28.7191 28.3984 28.7223 28.3984 28.724L28.3983 28.7262C28.398 28.7268 28.3974 28.7277 28.3964 28.729C28.3945 28.7312 28.3918 28.7338 28.3883 28.7363C28.3847 28.7389 28.3813 28.7405 28.3786 28.7415C28.3771 28.7421 28.376 28.7424 28.3754 28.7425L28.3733 28.7418C28.3717 28.7412 28.3687 28.74 28.3646 28.7377L25.8546 27.3669C25.3102 27.0696 24.6519 27.0696 24.1075 27.3669L21.5976 28.7377C21.5934 28.74 21.5905 28.7412 21.5888 28.7418L21.5867 28.7425C21.5861 28.7424 21.585 28.7421 21.5835 28.7415C21.5808 28.7405 21.5774 28.7389 21.5738 28.7363C21.5703 28.7338 21.5676 28.7312 21.5658 28.729C21.5647 28.7277 21.5641 28.7268 21.5638 28.7262L21.5637 28.724C21.5638 28.7223 21.5639 28.7191 21.5647 28.7144L22.0608 25.7094C22.1559 25.1337 21.9695 24.5472 21.5595 24.132L19.4319 21.9776C19.4287 21.9744 19.4268 21.9721 19.4259 21.9707L19.4245 21.9687C19.4244 21.9674 19.424 21.9623 19.4265 21.9548C19.429 21.9473 19.4323 21.9434 19.4333 21.9424L19.4355 21.9416C19.4371 21.9411 19.44 21.9404 19.4445 21.9397L22.3241 21.505C22.9232 21.4146 23.4382 21.0323 23.6982 20.4851L24.9608 17.8274C24.9629 17.8229 24.9646 17.8202 24.9656 17.8187L24.9669 17.817C24.9685 17.8163 24.9733 17.8146 24.9811 17.8146C24.9888 17.8146 24.9936 17.8163 24.9952 17.817L24.9965 17.8187C24.9975 17.8202 24.9992 17.8229 25.0013 17.8274ZM28.3983 28.7267C28.3983 28.7267 28.3983 28.7267 28.3983 28.7267L28.3983 28.7267Z" stroke={color} strokeWidth="1.8"/>
    </svg>
    
  )
}

export default CustomersIcon;

