import { ReactElement, ReactNode, RefObject } from 'react';
import styled from 'styled-components';
import {colors, ColorsNames, shadows, spacing, transitions, typography} from '../../styles';

interface TextButtonProps {
  children : ReactNode;
  color ?: 'light' | 'dark' | 'medium';
  onClick ?: () => void;
  disabled ?: boolean;
  ref ?: RefObject<HTMLButtonElement>
  className ?: string;
  type ?: 'submit'
}

const TextButton : React.FC<TextButtonProps> = (p) => {
  return (
    <Button type={p.type} className={p.className} ref={p.ref} disabled={!!p.disabled} onClick={p.onClick} color={p.color || 'dark'}>
      {p.children}
    </Button>
  );
};

export default TextButton;

const Button = styled.button<{disabled : boolean; color : 'light' | 'dark' | 'medium'}>`
  display: flex;
  align-items: center;
  white-space: nowrap;
  position : relative;
  border : none;
  border-radius : 1.2rem;
  box-shadow: ${shadows.default};
  height : 4rem;
  padding : ${spacing}rem;
  padding-top : 0;
  padding-bottom : 0;
  ${typography.buttonBold.css}
  transition : box-shadow ${transitions.fast}, color ${transitions.fast}, background-color ${transitions.fast};

  ${
    p => p.disabled ?
      `box-shadow: none;` :
      `box-shadow: ${shadows.default};`
  }

  ${p =>
    p.color === 'light' ?
      (
        p.disabled ?
        `background-color: ${colors.background2}; color : ${colors.tertiaryText};` :
        `background-color: ${colors.background2}; color : ${colors.primary};`
      ) :
    p.color === 'medium' ?
      (
        p.disabled ?
        `background-color: ${colors.background2}; color : ${colors.tertiaryText};` :
        `background-color: ${colors.lightPrimary}; color : ${colors.primary};`
      ) :
      (
        p.disabled ?
        `background-color: ${colors.lightSecondary}; color : ${colors.whiteText};` :
        `background-color: ${colors.primary}; color : ${colors.whiteText};`
      )
  }

  &:active, &:focus {
    outline : none;
  }

  &:active {
    box-shadow: none;
  }

  /*
    Create a larger clickable surface on small button
   */
  &::before {
    content : "";
    position : absolute;
    left : -1rem;
    right : -1rem;
    top : -1rem;
    bottom : -1rem;
  }
`