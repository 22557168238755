import styled from "styled-components";
import { colors, shadows, spacing } from "../../styles";
import { useModelStore } from "../../store/store";
import { useModelActions } from "../../store/hooks/use-actions";
import Typography from "../atoms/typography";

interface ContextMenuProps {

}

const ContextMenu : React.FC<ContextMenuProps> = (p) => {
  const {visible, x, y, options} = useModelStore(state => state.process.modals.contextMenu);
  const {hideContextMenu} = useModelActions('modals');

  return (
    <Container
      onClick={e => {hideContextMenu(); e.stopPropagation();}}
      onContextMenu={e => {hideContextMenu(); e.stopPropagation(); e.preventDefault();}}
      className={visible ? 'visible' : 'hidden'}>
      <Frame onClick={e => e.stopPropagation()} style={{top : (y || 0) - window.scrollY, left : x}}>
        {
          options?.map((option, index) => (
            <Line key={option.label} first={index === 0} onClick={() => {!option.disabled && option.callback(); hideContextMenu();}} disabled={!!option.disabled}>
              <Typography variant="smallTextBold" color="text">{option.label}</Typography>
            </Line>
          ))
        }
      </Frame>
    </Container>
  );
}

export default ContextMenu;

const Container = styled.div`
  position : fixed;
  top : 0;
  left : 0;
  width : 100%;
  height : 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 11;

  &.hidden {
    display : none;
  }
`

const Frame = styled.div`
  position : absolute;
  background-color : ${colors.background2};
  border-radius : ${spacing}rem;
  box-shadow: ${shadows.default};
`

const Line = styled.div<{first : boolean, disabled : boolean}>`
  border-top: ${p => p.first ? `none` : `0.1rem solid ${colors.lightPrimary}`};
  padding-top : ${spacing}rem;
  padding-bottom : ${spacing}rem;
  margin-right : ${spacing}rem;
  margin-left : ${spacing}rem;
  cursor : default;

  & span {
    color : ${p => p.disabled ? colors.tertiaryText : colors.text} !important;
  }

  &:hover, &:hover span {
    color : ${p => p.disabled ? colors.tertiaryText : colors.primary} !important;
  }
`

