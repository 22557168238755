import { useTranslation } from "react-i18next";
import { ADMIN, ROLES, SUPERADMIN, USER } from "../../constants/roles";
import { useUserStore } from "../../store/store";
import PageTopSelector from "./pagetop-selector";

interface RoleSelectorProps {
  selected : string;
  onChange : (role : string) => void;
}

const RoleSelector : React.FC<RoleSelectorProps> = (p) => {
  const {t} = useTranslation();
  const currentUser = useUserStore(state => state.user);

  const availableRoles = currentUser.role === SUPERADMIN ? ROLES : [USER, ADMIN];

  return (
    <PageTopSelector
      selected={p.selected}
      onChange={p.onChange}
      options={availableRoles.map(role => ({id : role, label : t(`roles.${role}`)}))}
    />
  )
}

export default RoleSelector;
