import { ReactNode, useEffect, useRef, useState } from "react"
import styled from "styled-components";
import { colors, shadows, spacing, transitions } from "../../styles";
import { transform } from "typescript";
import { DynamicSlideViewMeta } from "../../store/store-types";
import ToolButton from "./tool-button";
import UngroupIcon from "../../assets/icons/ungroup";
import { useModelActions } from "../../store/hooks/use-actions";
import Typography from "./typography";
import HTMLTypography from "./html-typography";
import { useTranslation } from "react-i18next";

interface ContentLevelContainerProps {
  index : number;
  children : ReactNode
  hidden ?: boolean;
  view ?: DynamicSlideViewMeta
  parentLabel : string;
}

const ContentLevelContainer : React.FC<ContentLevelContainerProps> = (p) => {
  const {unGroupSlides} = useModelActions('notebookEditor');
  const {showDialog} = useModelActions('modals');
  const {t} = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<number>(1000);

  const handleUngroup = () => {
    showDialog({
      content : (
        <HTMLTypography variant="textRegular" color="text" html={t('modals.ungroupDialog.content', {name : p.parentLabel})}/>
      ),
      callback : () => unGroupSlides({position : p.index})
    })
  }

  useEffect(() => {
    if(containerRef.current) {
      setContentHeight(containerRef.current.getBoundingClientRect().height * 1.15);
    }
  }, [containerRef.current, p.children, p.view])

  return (
    <Row>
      {
        /*
        Vertical lines used to highlight links between slides
        */
      }
      <VerticalLine $level={1} $visible={!!p.view?.link1}/>
      <VerticalLine $level={2} $visible={!!p.view?.link2}/>
      <VerticalLine $level={3} $visible={!!p.view?.link3}/>
      <SmallVerticalLine $visible={p.index > 0 && !p.view?.hidden}/>
      <Slider $height={contentHeight} className={p.hidden ? 'hidden' : 'visible'}>
        <Container ref={containerRef}>
          {p.children}
        </Container>
      </Slider>
      <ButtonContainer className={p.hidden ? 'hidden' : 'visible'}>
        <ToolButton color="dark" onClick={handleUngroup}><UngroupIcon /></ToolButton>
      </ButtonContainer>
    </Row>
  )
}

export default ContentLevelContainer;

const Row = styled.div`
  position : relative;
`

const Slider = styled.div<{$height : number}>`
  overflow: hidden;

  max-height: calc(30rem + ${p => p.$height}px);
  transition : max-height ${transitions.slow};

  &.hidden {
    max-height: 0rem;
  }
`;

const Container = styled.div`
  display : inline-grid;
  grid-template-columns : repeat(4, 1fr);
  grid-column-gap: 0.8rem;
  grid-row-gap: 0.8rem;
  padding : 0.8rem;
  margin-left : ${spacing}rem;
  border-radius : 2.2rem;
  box-shadow: ${shadows.inset};
  background: rgba(255, 228, 223, 0.30);
  margin-bottom : ${spacing}rem;
  transform : translateX(${spacing * 6}rem);
`

const VerticalLine = styled.div<{$level : number, $visible : boolean}>`
  position : absolute;
  top : -${2*spacing}rem;
  left : ${p => (p.$level) * 2 * spacing}rem;
  height : calc(100% + 3*${spacing}rem);
  width : 0.3rem;
  background-color : ${colors.lightSecondary};
  opacity : ${p => p.$visible ? 1 : 0};
  transition : opacity ${transitions.fast};
`

const SmallVerticalLine = styled.div<{$visible : boolean}>`
  position : absolute;
  top : -${2*spacing}rem;
  left : ${8*spacing}rem;
  height : ${2*spacing}rem;
  width : 0.3rem;
  background-color : ${colors.lightSecondary};
  opacity : ${p => p.$visible ? 1 : 0};
  transition : opacity ${transitions.fast};
`

const ButtonContainer = styled.div`
  position : absolute;
  left: 100rem;
  bottom: 3rem;

  &.hidden {
    display: none;
  }
`