import { colors } from "../../styles";
import IconProps from "./icon-interface";

const DnDHandleIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.primary;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 18 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 1L17 1" stroke={color} strokeWidth="2" strokeLinecap="round"/>
      <path d="M1 7L17 7" stroke={color} strokeWidth="2" strokeLinecap="round"/>
    </svg>
    
  )
}

export default DnDHandleIcon;
