import { useEffect } from "react";

type Callback = (value : boolean) => void;
type Node = {id : number; callback : Callback};

const callbackDictionary : Record<string, Node[]> = {};
let lastCallbackId = 0;

const useBroadcastFlag = <T,>(id : string | undefined, callback : Callback) => {
  const _id = id || 'no-broadcast';

  if(!callbackDictionary[_id]) {
    callbackDictionary[_id] = [];
  }

  useEffect(() => {
    let callbackId = ++lastCallbackId;

    callbackDictionary[_id].push({
      id : callbackId,
      callback
    });

    return () => {
      callbackDictionary[_id] = callbackDictionary[_id].filter(({id}) => id !== callbackId);
    }
  }, []);

  const broadcast = (value : boolean) => {
    if(_id === 'no-broadcast') {return;}
    if(!value) {
      setTimeout(() => {
        callbackDictionary[_id].forEach(({callback}) => callback(value));
      }, 10)
    }
    else {
      callbackDictionary[_id].forEach(({callback}) => callback(value));
    }
  }

  return broadcast;
}

export default useBroadcastFlag;