import { colors } from "../../styles";
import IconProps from "./icon-interface";

const NewUserIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.text;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 31 31" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17.3005 12.0744C18.1129 11.6153 18.9265 11.1574 19.7847 10.6727C18.3328 9.17998 17.7038 7.37871 17.9683 5.28957C18.167 3.72514 18.8948 2.43522 20.0622 1.41858C22.3877 -0.606183 25.8736 -0.442209 28.0698 1.76961C30.1543 3.86968 30.7269 7.79289 27.9816 10.691C28.7047 11.1161 29.4418 11.5108 30.1378 11.9712C30.7633 12.3841 30.9009 13.0692 30.5482 13.6473C30.1708 14.2656 29.4924 14.4028 28.8105 13.9437C27.6642 13.1712 26.4297 12.6392 25.0706 12.468C22.3877 12.1291 20.0034 12.8469 17.9707 14.7138C17.8472 14.8267 17.712 14.9919 17.692 15.1486C17.4933 16.6402 16.8726 17.9106 15.818 18.9698C16.5093 19.3682 17.1794 19.7241 17.819 20.1322C18.5903 20.6241 18.7995 21.331 18.408 21.9529C18.0142 22.5796 17.3217 22.6586 16.5551 22.1618C11.8712 19.124 5.74352 20.9763 3.44741 26.0838C2.66675 27.8207 3.26282 28.6381 4.98403 28.592C7.97145 28.5106 10.9624 28.5689 13.9522 28.5713C14.1861 28.5713 14.4236 28.5713 14.6529 28.6102C15.2442 28.7122 15.6522 29.2637 15.6052 29.8661C15.5558 30.4941 15.109 30.9289 14.4777 30.9301C10.9412 30.9338 7.40477 30.9496 3.8683 30.9253C1.75324 30.9119 0.20016 28.445 0.914977 26.2186C1.96369 22.9501 4.09403 20.68 7.10144 19.254C7.27427 19.1726 7.44709 19.0913 7.62815 19.0062C4.31154 15.349 5.96455 10.9315 8.49463 9.26622C11.3433 7.39207 15.5335 8.1597 17.3017 12.0756L17.3005 12.0744ZM11.7478 10.7043C9.75026 10.7031 8.12429 12.3611 8.11018 14.4138C8.09607 16.4968 9.72792 18.2022 11.736 18.2046C13.7217 18.207 15.3595 16.5321 15.3712 14.4854C15.383 12.3939 13.7652 10.7055 11.7478 10.7043ZM23.9608 2.41335C21.9892 2.34776 20.3291 3.97171 20.248 6.04506C20.1668 8.10748 21.7587 9.84438 23.7903 9.91118C25.7655 9.97556 27.4185 8.3589 27.5043 6.28069C27.589 4.22678 25.9888 2.48016 23.9608 2.41335Z" fill={color}/>
    <path d="M26.3274 26.504C26.3274 27.5814 26.3426 28.6466 26.3227 29.7106C26.3074 30.4879 25.9029 30.9131 25.234 30.9301C24.4933 30.9483 24.0442 30.556 24.0148 29.7908C23.9819 28.9454 24.003 28.0976 24.0007 27.251C24.0007 27.0117 24.0007 26.7736 24.0007 26.4493C23.3576 26.4493 22.7615 26.4493 22.1666 26.4493C21.7375 26.4493 21.3072 26.4591 20.8781 26.4469C20.1268 26.425 19.606 25.9246 19.6024 25.2396C19.5989 24.5533 20.1221 24.0395 20.8686 24.0274C21.6881 24.014 22.5076 24.0238 23.3282 24.0238C23.5222 24.0238 23.7162 24.0238 24.0019 24.0238C24.0019 23.4371 24.0019 22.8808 24.0019 22.3245C24.0019 21.801 23.9889 21.2751 24.0089 20.7528C24.0383 19.9864 24.4933 19.5054 25.1611 19.503C25.8324 19.4993 26.3027 19.9767 26.325 20.7382C26.3485 21.5836 26.3321 22.4314 26.3332 23.278C26.3332 23.4966 26.3332 23.7165 26.3332 24.025C27.2855 24.025 28.1932 24.025 29.0996 24.025C30.1601 24.025 30.7256 24.4392 30.7338 25.2238C30.7421 26.0121 30.1683 26.4493 29.1231 26.4506C28.2649 26.4518 27.4055 26.453 26.5472 26.4566C26.5096 26.4566 26.472 26.4724 26.3297 26.5052L26.3274 26.504Z" fill={color}/>
    </svg>
    
  )
}

export default NewUserIcon;

