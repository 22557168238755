import { ReactElement, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import {colors, ColorsNames, shadows, spacing, transitions, typography} from '../../styles';
import Typography from './typography';
import ArrowDownIcon from '../../assets/icons/arrow-down';
import RemoveCrossIcon from '../../assets/icons/remove-cross';
import DropdownButton from './dropdown-button';
import useEventDelegation from '../../hooks/use-event-delegation';


interface DropdownProps {
  className ?: string;
  open : boolean;
  setOpen : (value : boolean | ((value : boolean) => boolean)) => void;
  value ?: string;
  onChange ?: (value : string) => void;
  button : ReactNode;
  children : ReactNode;
}

const Dropdown : React.FC<DropdownProps> = (p) => {
  const {addEventListener, removeEventListener} = useEventDelegation('page');
  const toggleOpen = () => {p.setOpen(open => !open)};

  useEffect(() => {
    const handler = () => p.setOpen(false);

    addEventListener(handler);
    return removeEventListener;
  }, [])


  return (
    <RelativeContainer className={p.className} onClick={(e => e.stopPropagation())}>
      {p.open ? <Veil onClick={() => p.setOpen(false)}/> : null}
      <DropdownButton onClick={toggleOpen} open={p.open}>{p.button}</DropdownButton>
      <Menu className={p.open ? 'open' : 'closed'}>
        {p.children}
      </Menu>
    </RelativeContainer>
  );
};

export default Dropdown;

const RelativeContainer = styled.div`
  position :relative;
  display : inline-block;
`

const Menu = styled.div`
  position : absolute;
  z-index : 1;
  top : calc(4rem + ${0.5}rem);
  right : 0rem;
  display: flex;
  flex-direction: row;
  border : none;
  border-radius : 1rem;
  box-shadow: ${shadows.default};
  padding-top : 0;
  padding-bottom : 0;
  ${typography.buttonBold.css}
  overflow: scroll;
  cursor : default;

  background-color: ${colors.background2};
  color : ${colors.primary};

  max-height : 0;
  transition : max-height ${transitions.fast}, opacity ${transitions.fast};
  &.open {
    max-height : 30rem;
  }
`

const Veil = styled.div`
  z-index: 1;
  position : fixed;
  top : 0;
  left : 0;
  width : 100%;
  height : 100%;
`