import styled from "styled-components"
import Typography from "../../atoms/typography";
import { useTranslation } from "react-i18next";
import { colors, shadows, spacing } from "../../../styles";
import Calendar from "./calendar";
import { useEffect, useState } from "react";
import ArrowDownIcon from "../../../assets/icons/arrow-down";

interface DatePickerMenuProps {
  title ?: string;
  subtitle ?: string;
  onSelect : (date : Date) => void;
  selected ?: Date;
  isActive ?: (date : Date) => boolean;
}


const DatePickerMenu : React.FC<DatePickerMenuProps> = (p) => {
  const [referenceDate, setReferenceDate] = useState<Date>(p.selected ? p.selected : new Date());

  const nextReference = new Date(referenceDate);
  nextReference.setDate(1);
  nextReference.setMonth(referenceDate.getMonth()+1)

  useEffect(() => {
    if(p.selected) {
      setReferenceDate(p.selected);
    }
  }, [p.selected])


  const handlePreviousReference = () => {
    const newReference = new Date(referenceDate);
    newReference.setDate(1);
    newReference.setMonth(referenceDate.getMonth()-1);
    setReferenceDate(newReference);
  }

  const handleNextReference = () => {
    const newReference = new Date(referenceDate);
    newReference.setDate(1);
    newReference.setMonth(referenceDate.getMonth()+1);
    setReferenceDate(newReference);
  }

  return (
    <Container>
      <Header>
        {
          p.title ?
            <div><Typography variant="textRegular" color="secondaryText">{p.title}</Typography></div> : null
        }
        {
          p.subtitle ?
            <div><Typography variant="smallTextRegular" color="secondaryText">{p.subtitle}</Typography></div> : null
        }
      </Header>
      <Calendars>
        <Calendar
          reference={referenceDate}
          onSelect={p.onSelect}
          selected={p.selected}
          isActive={p.isActive}
        />
        <Calendar
          reference={nextReference}
          onSelect={p.onSelect}
          selected={p.selected}
          isActive={p.isActive}
        />
      </Calendars>
      <Footer>
        <ArrowButton onClick={handlePreviousReference}>
          <ArrowLeftIcon />
        </ArrowButton>
        <ArrowButton onClick={handleNextReference}>
          <ArrowRightIcon/>
        </ArrowButton>
      </Footer>
    </Container>
  )
}

export default DatePickerMenu;

const Container = styled.div`
  border-radius: ${spacing}rem;
  background-color: ${colors.background2};
  box-shadow: ${shadows.default};
  padding : ${spacing}rem;
  user-select: none;
`

const Header = styled.div`
  margin-bottom : ${spacing}rem;
`

const Calendars = styled.div`
  padding : ${spacing}rem;
  display: flex;

  & > div:first-child {
    margin-right : ${4*spacing}rem;
  }
`

const Footer = styled.div`
  display : flex;
  justify-content: space-between;
  margin-bottom : ${0.5*spacing}rem;
  margin-top : ${spacing}rem;
`

const ArrowLeftIcon = styled(ArrowDownIcon)`
  transform : rotate(90deg);
`

const ArrowRightIcon = styled(ArrowDownIcon)`
  transform : rotate(-90deg);
`

const ArrowButton = styled.div`
  width : 1rem !important;
  height : 1rem !important;
  cursor : pointer;
  position: relative;

  /*
    Create a larger clickable surface on small button
   */
  &::before {
    content : "";
    position : absolute;
    left : -1rem;
    right : -1rem;
    top : -1rem;
    bottom : -1rem;
  }
`