import React, { ReactElement, RefObject } from 'react';
import styled from 'styled-components';
import {colors, transitions} from '../../styles';

interface FlatButtonProps {
  children : ReactElement;
  onClick ?: () => void;
  className ?: string;
  disabled ?: boolean;
  ref ?: RefObject<HTMLButtonElement>;
  type ?: "button" | "submit" | "reset";
}

const FlatButton : React.FC<FlatButtonProps> = (p) => {
  return (
    <Button ref={p.ref} type={p.type} disabled={!!p.disabled} onClick={p.onClick} className={p.className}>
      {p.children}
      {/* {React.cloneElement(p.children, {color : p.disabled ? colors.lightPrimary : p.children.props.color})} */}
    </Button>
  );
};

export default FlatButton;

const Button = styled.button<{disabled : boolean}>`
  position : relative;
  border : none;
  width : 4rem;
  height : 4rem;
  background-color: transparent;
  padding : 0.9rem;
  transition : color ${transitions.fast};

  &:active, &:focus {
    outline : none;
  }

  /*
    Create a larger clickable surface on small button
   */
  &::before {
    content : "";
    position : absolute;
    left : -1rem;
    right : -1rem;
    top : -1rem;
    bottom : -1rem;
  }
`