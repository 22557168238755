import { useTranslation } from "react-i18next";
import Modal from "../modal";
import { colors, shadows, spacing, transitions } from "../../../styles";
import styled, { keyframes } from "styled-components";
import TextButton from "../../atoms/text-button";
import { PageSlide, SlideIndex } from "../../../store/store-types";
import TagsIcon from "../../../assets/icons/tags";
import ChoicesIcon from "../../../assets/icons/choices";
import { useModelActions } from "../../../store/hooks/use-actions";
import { useModelStore } from "../../../store/store";
import ContentLevelSlideCard from "../content-level-slide-card";
import ProposalContentLevelSlideCard from "../proposal-content-level-slide-card";

interface ProposalAlternativesModalProps {
  onClose : () => void;
  slide ?: PageSlide;
  onShowCustomTextModal : (slideIndex : SlideIndex) => void;
}


const ProposalAlternativesModal : React.FC<ProposalAlternativesModalProps> = (p) => {
  const {t} = useTranslation();
  const content = useModelStore(state => state.actions.proposalEditor.current());
  const alternativeGroupId = p.slide?.alternativeGroupId || '---';
  const multichoice = !!content.multichoiceSlidesGroups?.[alternativeGroupId];
  const slides = (content.slides || [])
  const choices : {slide : PageSlide, index : number}[] = slides
    .filter((slide) : slide is PageSlide => slide.type === 'page')
    .map((slide, index) => ({slide, index}))
    .filter(({slide}) => slide.alternativeGroupId === p.slide?.alternativeGroupId);

  const handleSubmit = () => {
    p.onClose();
  }


  return (
    <Modal
      icon={<ChoicesIcon />}
      title={t('modals.proposalAlternativesModal.title', {name : p.slide?.label})}
      subtitle={t('modals.proposalAlternativesModal.subtitle')}
      onClose={p.onClose}
    >
      <Container>
        {
          choices.map(({slide, index}) => (
            <ProposalContentLevelSlideCard
              key={slide.pageId}
              index={index}
              slide={slide}
              onShowCustomTextModal={p.onShowCustomTextModal}
              multichoice={multichoice}
            />
          ))
        }
      </Container>
    </Modal>
  );
}

export default ProposalAlternativesModal;


const Container = styled.div`
  max-height: 60vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: ${spacing}rem;
  grid-row-gap: ${spacing}rem;
  overflow: auto;
`