import { useTranslation } from "react-i18next";
import Modal from "../modal";
import UserIcon from "../../../assets/icons/user";
import { colors, spacing, typography } from "../../../styles";
import styled from "styled-components";
import Typography from "../../atoms/typography";
import { useUserStore } from "../../../store/store";
import { useState } from "react";
import TextButton from "../../atoms/text-button";

interface UserProfileModalProps {
  onClose : () => void;
  onSubmit : (firstName : string, lastName : string) => void;
}

const UserProfileModal : React.FC<UserProfileModalProps> = (p) => {
  const {t} = useTranslation();
  const {firstName, lastName, email, role} = useUserStore(state => state.user);
  const loading = useUserStore(state => state.process.loading['user.update']);
  const translatedRoles = role ? t(`roles.${role}`, role) : '-';

  const [editedFirstName, setEditedFirstName] = useState<string | undefined>(firstName);
  const [editedLastName, setEditedLastName] = useState<string | undefined>(lastName);

  const handleSubmit = () => {
    if(editedFirstName && editedLastName) {
      p.onSubmit(editedFirstName, editedLastName)
    }
  }

  return (
    <Modal
      title={t('pages.profile.modalTitle')}
      subtitle={t('pages.profile.modalSubtitle')}
      icon={<UserIcon color={colors.text}/>}
      onClose={p.onClose}
      controls={[
        <TextButton key="validate" disabled={loading || !editedFirstName?.trim() || !editedLastName?.trim()} onClick={handleSubmit}>{t('validate')}</TextButton>
      ]}
    >
      <InputsContainer>
        <FirstNameInput
          placeholder={t('pages.profile.firstNamePlaceholder')}
          value={editedFirstName}
          onChange={e => setEditedFirstName(e.target.value)}
        />
        <LastNameInput
          placeholder={t('pages.profile.lastNamePlaceholder')}
          value={editedLastName}
          onChange={e => setEditedLastName(e.target.value)}
        />
      </InputsContainer>
      <StaticDetailsContainer>
        <Item>
          <Typography variant="smallTextRegular" color="secondaryText">{t('pages.profile.email')}</Typography>
          <Typography variant="textBold" color="text">{email || ''}</Typography>
        </Item>
        <Item>
          <Typography variant="smallTextRegular" color="secondaryText">{t('pages.profile.role')}</Typography>
          <Typography style={{textTransform : 'capitalize'}} variant="textBold" color="text">{translatedRoles}</Typography>
        </Item>
      </StaticDetailsContainer>
    </Modal>
  );
}

export default UserProfileModal;

const InputsContainer = styled.div`
  min-width : 50rem;
  margin-top: ${2*spacing}rem;
  background-color: ${colors.background};
  border-radius: ${spacing}rem;
  padding: ${spacing}rem;
  display: flex;
  flex-direction: column;
`

const FirstNameInput = styled.input`
  color : ${colors.text};
  &::placeholder {
    color : ${colors.tertiaryText};
  }
  ${typography.menuBold.css};
  border : none;
  background-color: transparent;
  outline: none;
`

const LastNameInput = styled.input`
  color : ${colors.text};
  &::placeholder {
    color : ${colors.tertiaryText};
  }
  ${typography.bigTitle.css};
  border : none;
  background-color: transparent;
  outline: none;
`

const StaticDetailsContainer = styled.div`
  margin-top: ${2*spacing}rem;
  display: flex;
  margin-bottom: ${2*spacing}rem;
`;

const Item = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: ${4*spacing}rem;

  &:first-child {
    margin-left: ${spacing}rem;
  }
`