import styled from 'styled-components';
import { useAssertLogged } from '../hooks/use-assert-logged';
import PageLayout from '../components/layout/page-layout';
import { useTranslation } from 'react-i18next';
import Paper from '../components/atoms/paper';
import { colors, spacing, typography } from '../styles';
import ToolButton from '../components/atoms/tool-button';
import EditIcon from '../assets/icons/edit';
import { useUserStore } from '../store/store';
import Typography from '../components/atoms/typography';
import TitleWithIcon from '../components/atoms/title-with-icon';
import CustomersIcon from '../assets/icons/customers';
import { useState } from 'react';
import UserProfileModal from '../components/molecules/modals/user-profile-modal';

interface ProfilePageProps {

}

const ProfilePage : React.FC<ProfilePageProps> = (p) => {
  useAssertLogged();
  const {t} = useTranslation();
  const {firstName, lastName, email, role} = useUserStore(state => state.user);
  const [modalVisible, setModalVisible] = useState<boolean>(!firstName || !lastName);
  const {update} = useUserStore(state => state.actions.user);

  const translatedRoles = role ? t(`roles.${role}`, role) : '-';

  const handleUpdateProfile = async (firstName : string, lastName : string) => {
    await update(firstName, lastName);
    setModalVisible(false);
  }

  return (
    <PageLayout
      title={t('pages.profile.name')}
      subtitle={t('pages.profile.subtitle')}
      modal={modalVisible ? <UserProfileModal onClose={() => setModalVisible(false)} onSubmit={handleUpdateProfile}/> : undefined}
    >
      <Container>
        <Paper style={{flex : 2}}> {/* , marginRight : `${1.5*spacing}rem` */}
          <RightButtonContainer>
            <ToolButton color='dark' onClick={() => setModalVisible(true)}>
              <EditIcon />
            </ToolButton>
          </RightButtonContainer>
          <Content>
            <NameHeader>
              <FirstLetterPreview>
                {(firstName || '-')[0]}
              </FirstLetterPreview>
              <NameContainer>
                <Typography style={{textTransform : 'capitalize'}} variant="menuBold" color="text">{firstName || ''}</Typography>
                <Typography style={{textTransform : 'capitalize'}} variant="bigTitle" color="text">{lastName || ''}</Typography>
              </NameContainer>
            </NameHeader>
            <Item>
              <Typography variant="smallTextRegular" color="secondaryText">{t('pages.profile.email')}</Typography>
              <Typography variant="textBold" color="text">{email || ''}</Typography>
            </Item>
            <Item>
              <Typography variant="smallTextRegular" color="secondaryText">{t('pages.profile.role')}</Typography>
              <Typography style={{textTransform : 'capitalize'}} variant="textBold" color="text">{translatedRoles}</Typography>
            </Item>
          </Content>
        </Paper>
        {/* <CustomersPaper>
          <TitleWithIcon
            title={t('pages.profile.customersTitle')}
            subtitle={t('pages.profile.customersSubtitle')}
            icon={<CustomersIcon />}
          />
          <CustomersContent>
          </CustomersContent>
        </CustomersPaper> */}
      </Container>
    </PageLayout>
  )
}

export default ProfilePage;

const Container = styled.div`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction : row;
  padding-bottom: ${3*spacing}rem;
  padding-top : ${spacing}rem;
  align-items: center;
`
const RightButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`

const Content = styled.div`
  padding: ${spacing}rem;
`

const NameHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-bottom : ${3*spacing}rem;
`

const NameContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding-left: ${2*spacing}rem;
`

const FirstLetterPreview = styled.div`
  width : 9.5rem;
  height : 9.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colors.accent};
  color : ${colors.whiteText};
  text-transform: capitalize;
  ${typography.textBold.css};
  font-size : 5rem;
  border-radius: 5rem;
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom : ${2*spacing}rem;
`

const CustomersPaper = styled(Paper)`
  height: calc(100vh - 25rem);
  flex : 3;
  margin-left: ${1.5*spacing}rem;
  display : flex;
  flex-direction : column;
  padding: ${2*spacing}rem;
  display :flex;
  flex-direction : column;
  overflow: hidden;
`

const CustomersContent = styled.div`
  flex : 1;
  margin-top: ${spacing}rem;
  margin-bottom: ${spacing}rem;
  overflow: auto;
`