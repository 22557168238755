import { colors } from "../../styles";
import IconProps from "./icon-interface";

const UploadIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.text;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.0746579 11.5947C0.0935536 11.557 0.106151 11.5255 0.125046 11.4877C0.269913 11.0909 0.647826 10.8641 1.05723 10.9271C1.47294 10.9901 1.77527 11.3302 1.78157 11.7522C1.78786 12.0293 1.78157 12.3002 1.78157 12.5773C1.78157 13.5851 2.52479 14.196 3.51997 14.322C6.34172 14.6873 9.15717 14.6873 11.9789 14.322C12.9867 14.1897 13.7236 13.5851 13.7236 12.5647C13.7236 12.3002 13.7236 12.0356 13.7236 11.7711C13.7299 11.2861 14.1078 10.9082 14.5802 10.9082C15.0526 10.9082 15.4305 11.2861 15.4305 11.7711C15.4305 12.3128 15.4683 12.8482 15.3486 13.3835C15.0085 14.8826 13.6354 15.8589 12.1112 16.0226C9.20756 16.3439 6.30393 16.3439 3.394 16.0226C1.73118 15.84 0.288809 14.6999 0.0935536 13.0371C0.0935536 13.0056 0.0746579 12.9678 0.0683594 12.9363V11.5884L0.0746579 11.5947Z" fill={color}/>
      <path d="M7.60396 12.6338C7.28273 12.533 7.02449 12.3567 6.93631 12.0102C6.90482 11.8969 6.89852 11.7709 6.89852 11.6512C6.92371 9.04361 6.89852 6.42971 6.89852 3.82211C6.89852 3.75913 6.89852 3.69614 6.89852 3.60166C6.82924 3.67095 6.79144 3.70244 6.75365 3.74023C6.15529 4.3386 5.55063 4.94326 4.95227 5.54162C4.71922 5.77466 4.42949 5.85025 4.10826 5.76207C3.78703 5.67389 3.58548 5.45344 3.5099 5.13221C3.43432 4.81099 3.54139 4.53385 3.77444 4.3008C4.41059 3.67095 5.04045 3.0348 5.6766 2.40494C6.14899 1.93255 6.61509 1.46646 7.08748 0.994065C7.51578 0.565763 8.00077 0.565763 8.42907 0.994065C9.53132 2.09631 10.6273 3.19226 11.7295 4.29451C12.0948 4.65982 12.1263 5.15111 11.8177 5.50383C11.4839 5.88174 10.9296 5.90064 10.5517 5.52902C9.95332 4.93696 9.35496 4.3323 8.75659 3.74023C8.7188 3.70244 8.68101 3.66465 8.60543 3.60166C8.60543 3.70244 8.60543 3.75913 8.60543 3.82211C8.60543 6.44231 8.61802 9.06251 8.60543 11.6827C8.60543 12.1866 8.41017 12.4574 7.92518 12.6149C7.91888 12.6149 7.90629 12.6275 7.89999 12.6338C7.79921 12.6338 7.69844 12.6338 7.59766 12.6338L7.60396 12.6338Z" fill={color}/>
    </svg>
    
  )
}

export default UploadIcon;
