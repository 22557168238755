import axios from "axios";


import { GetFunction, SetFunction, ActionsFactory, ModelStore, Market, ID, Customer } from "../store-types";
import configProvider from "../../providers/configprovider";
import { setLoading } from "../helpers/set-loading";
import { setError } from "../helpers/set-error";


const customersActions : ActionsFactory<ModelStore['actions']['customers'], ModelStore> =  (set : SetFunction<ModelStore>, get : GetFunction<ModelStore>) => ({
  loadOne : async (customerId : ID) => {
    setLoading(set, 'customers.loadOne', true);

    try {
      const customersResponse = await axios.get(`${configProvider('MODEL_API_URL')}/customers/${customerId}`);

      if(customersResponse.status === 200) {
        const {data} : {data : Customer} = customersResponse || {};

        return data;
      }

      setLoading(set, 'customers.loadOne', false);

      return;
    }
    catch(error) {}

    setError(set, 'customers.loadOne', 'errors.customers.loadOne')
    throw "errors.customers.loadOne";
  },

  loadMany : async (customerIds : ID[]) => {
    setLoading(set, 'customers.loadMany', true);

    try {
      const customersResponse = await axios.get(`${configProvider('MODEL_API_URL')}/customers?id=${customerIds.join(',')}`);

      if(customersResponse.status === 200) {
        const {data} : {data : {data : Customer[]}} = customersResponse || {};

        return data.data;
      }

      setLoading(set, 'customers.loadMany', false);

      return;
    }
    catch(error) {}

    setError(set, 'customers.loadMany', 'errors.customers.loadMany')
    throw "errors.customers.loadMany";
  },

  create : async (customer : Partial<Customer>) => {
    setLoading(set, 'customers.create', true);

    try {
      const customersResponse = await axios.post(`${configProvider('MODEL_API_URL')}/customers`, {
        name : customer.name,
        email : customer.email
      });

      if(customersResponse.status === 200) {
        const {data} : {data : Customer} = customersResponse || {};

        return data;
      }

      setLoading(set, 'customers.create', false);
    }
    catch(error) {}

    setError(set, 'customers.create', 'errors.customers.create')
    throw "errors.customers.create";
  },

  update : async (customerId : ID, customer : Partial<Customer>) => {
    setLoading(set, 'customers.update', true);

    try {
      const customersResponse = await axios.patch(`${configProvider('MODEL_API_URL')}/customers/${customerId}`, {
        name : customer.name,
        email : customer.email,
        logoId : customer.logoId
      });

      if(customersResponse.status === 200) {
        const {data} : {data : Customer} = customersResponse || {};

        return data;
      }

      setLoading(set, 'customers.update', false);
    }
    catch(error) {}

    setError(set, 'customers.update', 'errors.customers.update')
    throw 'errors.customers.update';
  },

  uploadLogo : async (customerId : ID, file : File) => {
    setLoading(set, 'customers.uploadLogo', true);

    try {
      const formData = new FormData();
      formData.append("file", file);

      // Upload logo
      const uploadResponse = await axios.post(`${configProvider('MODEL_API_URL')}/logos`, formData);
      if(uploadResponse.status === 200) {
        const uploadId = uploadResponse.data.id;

        const customer = await get().actions.customers.update(customerId, {logoId : uploadId});

        setLoading(set, 'customers.uploadLogo', false);
        return customer;
      }
    }
    catch(error) {}

    setError(set, 'customers.uploadLogo', 'errors.customers.uploadLogo')
    throw 'errors.customers.uploadLogo';
  }
})

export default customersActions;