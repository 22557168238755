import styled from "styled-components";
import { colors, shadows, spacing, typography } from "../../styles";

interface ChipProps {
  className ?: string;
  label : string;
}

const Chip : React.FC<ChipProps> = (p) => {
  return (
    <Chrome className={p.className}>{p.label}</Chrome>
  )
}

export default Chip;

const Chrome = styled.div`
  display : inline-flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.background2};
  box-shadow: ${shadows.heavy};
  border-radius: 3rem;
  height : 3.6rem;
  padding-left : ${spacing}rem;
  padding-right : ${spacing}rem;
  ${typography.textRegular.css};
`