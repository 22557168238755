import { colors } from "../../styles";
import IconProps from "./icon-interface";

const LockedIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.primary;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 20 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.3394 11.478C19.3394 11.2481 19.3218 11.0094 19.2687 10.7883C18.9681 9.52394 17.9159 8.69281 16.5896 8.69281C16.0591 8.71934 15.5286 8.7547 14.9981 8.78123V6.19056C14.9981 5.61584 14.9981 5.04996 14.9981 4.48409C14.9981 4.33377 14.9627 4.18346 14.9362 4.04199C14.3615 1.28333 11.3199 -0.431989 8.63194 0.487565C6.66021 1.15955 5.27203 2.98097 5.31624 5.16491C5.33393 6.2613 5.31624 7.34885 5.31624 8.44524V8.69281C4.72384 8.69281 4.15796 8.67513 3.60092 8.69281C2.66369 8.73702 1.92981 9.17027 1.41698 9.9572C1.1252 10.3993 0.948365 10.9033 1.00142 11.4249C1.32857 14.4489 1.32857 17.4728 1.00142 20.4967C0.895314 21.4428 1.3993 22.2032 2.1597 22.7602C2.52222 23.0255 2.94663 23.1493 3.37988 23.2377C7.90691 23.0785 12.4251 23.0962 16.9521 23.2377C16.9787 23.2288 17.0052 23.2111 17.0317 23.2023C18.4464 22.937 19.3306 21.8849 19.3306 20.4436C18.8973 17.4463 18.8973 14.4577 19.3306 11.4603L19.3394 11.478ZM7.11998 4.9527C7.11998 4.75818 7.14651 4.56366 7.19072 4.37798C7.57092 2.77761 9.18898 1.76963 10.7982 2.11447C12.1068 2.39741 13.044 3.37001 13.2032 4.68745C13.2651 5.18259 13.2386 5.68658 13.2297 6.19056V8.87849C11.2845 8.95807 9.34813 8.95807 7.40292 8.70165H7.12883C7.12883 8.63976 7.12883 8.59555 7.12883 8.55134C7.12883 7.35769 7.12883 6.1552 7.12883 4.96155L7.11998 4.9527ZM17.5445 20.4171C17.5445 21.1156 17.1997 21.487 16.4924 21.4605C12.2836 21.3278 8.0749 21.3278 3.86618 21.4605C3.14999 21.487 2.73442 21.1156 2.80516 20.3994C3.11462 17.4463 3.11462 14.5019 2.80516 11.5487C2.80516 11.1951 2.89357 10.9298 3.06157 10.753C3.32683 10.4789 3.73355 10.4789 3.86618 10.4877C8.0749 10.6911 12.2836 10.6734 16.4924 10.4877C16.6161 10.4877 17.0229 10.4877 17.2793 10.753C17.4561 10.9298 17.5357 11.1862 17.5357 11.531C17.3058 14.4931 17.3146 17.4463 17.5357 20.4083L17.5445 20.4171Z" fill={color}/>
    <path d="M10.4976 13.1403C9.72833 12.9989 9.0033 13.3614 8.66731 14.0687C8.34016 14.7496 8.54352 15.563 9.14477 16.067C9.2155 16.1201 9.25971 16.235 9.26856 16.3234C9.28624 16.624 9.26856 16.9247 9.26856 17.2164C9.26856 17.5082 9.26856 17.8 9.26856 18.0918C9.26856 18.2244 9.29508 18.3659 9.34813 18.4808C9.50729 18.861 9.92285 19.0821 10.3119 19.0025C10.7363 18.9229 11.0458 18.5781 11.0546 18.136C11.0635 17.5436 11.0546 16.9512 11.0546 16.3676C11.0546 16.2262 11.09 16.1289 11.2049 16.0316C11.6205 15.6868 11.8327 15.2359 11.815 14.6965C11.7796 13.9096 11.2226 13.2641 10.4887 13.1315L10.4976 13.1403Z" fill={color}/>
    </svg>
  )
}

export default LockedIcon;
