import React, { ReactElement, RefObject } from 'react';
import styled from 'styled-components';
import {colors, ColorsNames, shadows, spacing, transitions} from '../../styles';

interface ToolButtonProps {
  children : ReactElement;
  color ?: 'light' | 'dark' | 'medium' | 'accent'
  onClick ?: (event : React.MouseEvent<HTMLButtonElement>) => void;
  className ?: string;
  disabled ?: boolean;
  ref ?: RefObject<HTMLButtonElement>;
  type ?: "button" | "submit" | "reset";
}

const ToolButton : React.FC<ToolButtonProps> = (p) => {
  return (
    <Button ref={p.ref} type={p.type} disabled={!!p.disabled} color={p.color ? p.color : 'light'} onClick={p.onClick} className={p.className}>
      {React.cloneElement(p.children, {color : p.disabled ? colors.tertiaryText : p.children.props.color})}
    </Button>
  );
};

export default ToolButton;

const Button = styled.button<{disabled : boolean, color : "light" | "dark" | "medium" | 'accent'}>`
  position : relative;
  border : none;
  border-radius : 1rem;
  box-shadow: ${shadows.default};
  width : 4rem;
  height : 4rem;
  background-color: ${colors.background2};
  padding : 0.9rem;
  transition : box-shadow ${transitions.fast}, color ${transitions.fast}, background-color ${transitions.fast};

  ${
    p => p.disabled ?
      `box-shadow: none;` :
      `box-shadow: ${shadows.default};`
  }

  ${
    p => p.disabled ?
    `background-color: ${colors.background2}; color : ${colors.tertiaryText};` :
    p.color === 'light' ?
      `background-color: ${colors.background2}; color : ${colors.primary};` :
    p.color === 'medium' ?
      `background-color: ${colors.lightPrimary}; color : ${colors.primary};` :
    p.color === 'accent' ?
      `background-color: ${colors.accent}; color : ${colors.whiteText};` :
      `background-color: ${colors.primary}; color : ${colors.whiteText};`
  }

  &:active, &:focus {
    outline : none;
  }

  &:active {
    box-shadow: none;
  }

  /*
    Create a larger clickable surface on small button
   */
  &::before {
    content : "";
    position : absolute;
    left : -1rem;
    right : -1rem;
    top : -1rem;
    bottom : -1rem;
  }
`