import axios from "axios";


import { GetFunction, SetFunction, ActionsFactory, ModelStore, ID, User, Pagination, ListApiResponse, Offer } from "../store-types";
import configProvider from "../../providers/configprovider";
import { setLoading } from "../helpers/set-loading";
import { setError } from "../helpers/set-error";
import { useUserStore } from "../store";
import { ADMIN, SUPERADMIN, USER } from "../../constants/roles";
import { PAGE_SIZE } from "../../constants/api";



const offersActions : ActionsFactory<ModelStore['actions']['offers'], ModelStore> =  (set : SetFunction<ModelStore>, get : GetFunction<ModelStore>) => ({
  load : async (query ?: string) => {
    setLoading(set, 'offers.load', true);

    try {
      let searchParam : string = '';
      if(query?.trim()) {
        searchParam = `&q=${encodeURIComponent(query.trim())};name`
      }

      const offersResponse = await axios.get(`${configProvider('MODEL_API_URL')}/offers?pageSize=${1000}${searchParam}`);

      if(offersResponse.status === 200) {
        const data : ListApiResponse<Offer> = offersResponse.data || {};

        setLoading(set, 'offers.load', false);

        return {
          ...data,
          data : data.data.map(offer => ({
            ...offer,
            notebooksIds : ((offer.notebooks as unknown || '') as string).split(',').map(id => parseInt(id)).filter(id => !!id),
            attachmentsIds : ((offer.attachments as unknown || '') as string).split(',').map(id => parseInt(id)).filter(id => !!id),
            videosIds : ((offer.videos as unknown || '') as string).split(',').map(id => parseInt(id)).filter(id => !!id),
          }))
        };
      }
    }
    catch(error) {}

    setError(set, 'offers.load', 'errors.offers.load')
    throw "error.offers.load";
  },

  loadOne : async(id : ID) => {
    setLoading(set, 'offers.loadOne', true);

    try {
      const offersResponse = await axios.get<Offer>(`${configProvider('MODEL_API_URL')}/offers/${id}`);

      if(offersResponse.status === 200) {
        setLoading(set, 'offers.loadOne', false);

        return {
          ...offersResponse.data,
          notebooksIds : ((offersResponse.data.notebooks as unknown || '') as string).split(',').map(id => parseInt(id)),
          attachmentsIds : ((offersResponse.data.attachments as unknown || '') as string).split(',').map(id => parseInt(id)).filter(id => !!id),
          videosIds : ((offersResponse.data.videos as unknown || '') as string).split(',').map(id => parseInt(id)).filter(id => !!id),
        }
      }
    }
    catch(error) {}

    setError(set, 'offers.loadOne', 'errors.offers.loadOne')
    throw "error.offers.loadOne";
  },

  create : async (offer : Partial<Offer>) => {
    setLoading(set, 'offers.create', true);

    if(!offer.name || !offer.marketId) {
      setError(set, 'offers.create', 'offers.create.missingParams');
      throw 'offers.create.missingParams';
    }

    const currentUserId = useUserStore.getState().user.id;

    try {
      const offersResponse = await axios.post<Offer>(`${configProvider('MODEL_API_URL')}/offers`, {
        name : offer.name,
        marketId : offer.marketId,
        draft : true,
        creatorId : currentUserId,
        proposalsCount : 0,
        notebooks : offer.notebooks,
        attachments : offer.attachments,
        videos : offer.videos,
        thumbnailPageId : offer.thumbnailPageId
      });

      if(offersResponse.status === 200) {
        const newOffer : Offer = offersResponse.data;

        setLoading(set, 'offers.create', false);

        return newOffer;
      }
    }
    catch(error) {}

    setError(set, 'offers.create', 'errors.offers.create')
    throw 'errors.offers.create'
  },

  delete : async (offerId : ID) => {
    setLoading(set, 'offers.delete', true);

    try {
      const offersResponse = await axios.delete(`${configProvider('MODEL_API_URL')}/offers/${offerId}`);

      if(offersResponse.status === 200) {
        setLoading(set, 'offers.delete', false);

        return;
      }

      if(offersResponse.status === 409) {
        setError(set, 'offers.delete', 'offers.deleteConflict')
        return;
      }
    }
    catch(error) {}

    setError(set, 'offers.delete', 'offers.delete')
    throw 'offers.delete'
  },

  archive : async (offerId : number) => {
    setLoading(set, 'offers.archive', true);

    try {
      const offersResponse = await axios.patch(`${configProvider('MODEL_API_URL')}/offers/${offerId}`, {inactive : true});

      if(offersResponse.status === 200) {
        setLoading(set, 'offers.archive', false);

        return;
      }
    }
    catch(error) {}

    setError(set, 'offers.archive', 'offers.archive')
    throw 'offers.archive'
  },

  unarchive : async (offerId : number) => {
    setLoading(set, 'offers.unarchive', true);

    try {
      const offersResponse = await axios.patch(`${configProvider('MODEL_API_URL')}/offers/${offerId}`, {inactive : false});

      if(offersResponse.status === 200) {
        setLoading(set, 'offers.archive', false);

        return;
      }
    }
    catch(error) {}

    setError(set, 'offers.unarchive', 'offers.unarchive')
    throw 'offers.unarchive'
  },


  update : async (offerId : ID, patch : Partial<Offer>) => {
    setLoading(set, 'offers.update', true);

    try {
      const offersResponse = await axios.patch(`${configProvider('MODEL_API_URL')}/offers/${offerId}`, patch);

      if(offersResponse.status === 200) {

        setLoading(set, 'offers.update', false);

        return {
          ...offersResponse.data,
          notebooksIds : ((offersResponse.data.notebooks as unknown || '') as string).split(',').map(id => parseInt(id)),
          attachmentsIds : ((offersResponse.data.attachments as unknown || '') as string).split(',').map(id => parseInt(id)).filter(id => !!id),
          videosIds : ((offersResponse.data.videos as unknown || '') as string).split(',').map(id => parseInt(id)).filter(id => !!id),
        }
      }
    }
    catch(error) {}

    setError(set, 'offers.update', 'offers.update')
    throw 'offers.update'
  }
})

export default offersActions;