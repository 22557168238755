import { useTranslation } from "react-i18next";
import Modal from "../modal";
import { colors, shadows, spacing, transitions } from "../../../styles";
import styled, { keyframes } from "styled-components";
import TextButton from "../../atoms/text-button";
import { ID, PageSlide } from "../../../store/store-types";
import TagsIcon from "../../../assets/icons/tags";
import HTMLTypography from "../../atoms/html-typography";
import ToolButton from "../../atoms/tool-button";
import ArrowDownIcon from "../../../assets/icons/arrow-down";
import MailIcon from "../../../assets/icons/mail";
import DownloadIcon from "../../../assets/icons/mail copy";
import configProvider from "../../../providers/configprovider";
import { CUSTOMER_VIEW } from "../../../constants/routes";
import LogoutIcon from "../../../assets/icons/logout";
import LinkIcon from "../../../assets/icons/link";

interface ShareProposalModalProps {
  onClose : () => void;
  uuid : string;
}


const ShareProposalModal : React.FC<ShareProposalModalProps> = (p) => {
  const {t} = useTranslation();
  const url = `${configProvider('WEB_URL')}${CUSTOMER_VIEW.replace(':id', `${p.uuid}`)}`;

  const handleEmail = () => {
    const subject = t('share.subject');
    const body = encodeURIComponent(t('share.body', {url}));

    window.open(`mailto:?subject=${subject}&body=${body}`);
    p.onClose();
  }

  const handleLink = () => {
    window.open(url, '_blank');
  }

  const downloadProposal = async () => {
    window.open(
      `${configProvider('MODEL_API_URL')}/public/${p.uuid}/proposal.zip?seed=${Date.now()}`,
      '_blank'
    )
  }


  return (
    <Modal
      title={t('modals.share.title')}
      onClose={p.onClose}
      controls={[
        <ToolButton key="link" color="light" onClick={handleLink}><LinkIcon /></ToolButton>,
        <ToolButton key="mail" color="light" onClick={handleEmail}><MailIcon /></ToolButton>,
        <ToolButton key="download" color="light" onClick={downloadProposal}><DownloadIcon /></ToolButton>
      ]}
    >
      <HTMLTypography variant="textRegular" html={t('modals.share.content')} />
    </Modal>
  );
}

export default ShareProposalModal;

const Container = styled.div`
  position : fixed;
  top : 0;
  left : 0;
  width : 100%;
  height : 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const animate = keyframes`
 0% { opacity : 0; }
 100% { opacity : 1; }
`

const Frame = styled.div`
  min-width : 50rem;
  background-color : ${colors.background};
  border-radius : ${spacing}rem;
  box-shadow: ${shadows.inset};
  padding : ${2*spacing}rem;
  animation : ${animate} ${transitions.fast};

`