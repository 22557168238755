import { ReactElement } from 'react';
import styled from 'styled-components';
import {colors, ColorsNames, shadows, spacing, typography} from '../../styles';
import Typography from './typography';
import PlusIcon from '../../assets/icons/plus';
import ClosedFoodDome from '../../assets/images/closed-food-dome';
import Checkbox from './checkbox';

interface NotebookCardProps {
  title : string;
  imgUrl ?: string;
  value ?: boolean;
  onChange ?: (value : boolean) => void;
  radio ?: boolean;
  noCheckbox ?: boolean;
  className ?: string;
}

const NotebookCard : React.FC<NotebookCardProps> = (p) => {
  return (
    <Container onClick={() => p.onChange?.(!p.value)} className={p.className}>
      <Card className={p.value ? 'selected' : ''}>
        {
          p.imgUrl ?
            <Image src={p.imgUrl}/> :
            <ClosedFoodDome />
        }
      </Card>
      <Title onClick={e => e.stopPropagation()}>
        <Typography variant="textRegular" color="secondaryText">{p.title}</Typography>
        {
          !p.noCheckbox ?
          <Checkbox onChange={(v) => p.onChange?.(v)} value={!!p.value} radio={p.radio}/> :
          null
        }
      </Title>
    </Container>
  );
};

export default NotebookCard;


const Container = styled.div`
  position : relative;
  margin-right : 1.5rem;
  margin-bottom : 2.7rem;
  cursor : pointer;
`;

const Card = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width : 20rem;
  height : 11.3rem;
  border-radius: 1rem;
  background-color : ${colors.background2};
  border : 0.3rem solid ${colors.background2} !important;
  overflow : hidden;

  &.selected {
    border : 0.3rem solid ${colors.primary} !important;
  }
`

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  padding-left : 0.5rem;
  & span {
    text-decoration-line: none !important;
  }
`

const IconContainer = styled.div`
  width : 1.2rem;
  height : 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Image = styled.img`
  width : 100%;
  height : 100%;
  object-fit: cover;
  border : none;
`

const Draft = styled.div`
  position : absolute;
  right : 1rem;
  top : 1rem;
  background-color: ${colors.accent};
  color : ${colors.whiteText};
  ${typography.smallTextBold.css};
  padding : 0.4rem 1rem;
  border-radius : 2rem;
`