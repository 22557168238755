import { colors } from "../../styles";
import IconProps from "./icon-interface";

const SearchIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.primary;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.1111 16C14.9944 16.0003 14.8787 15.9774 14.7708 15.9327C14.663 15.888 14.565 15.8223 14.4827 15.7396L11.4604 12.7218C10.22 13.6955 8.68814 14.224 7.11111 14.2222C5.70467 14.2222 4.32981 13.8052 3.16039 13.0238C1.99098 12.2424 1.07953 11.1318 0.541304 9.83242C0.00308114 8.53303 -0.137743 7.10323 0.136641 5.72381C0.411025 4.34439 1.08829 3.07731 2.0828 2.0828C3.07731 1.08829 4.34439 0.411025 5.72381 0.136641C7.10323 -0.137743 8.53303 0.00308108 9.83242 0.541304C11.1318 1.07953 12.2424 1.99098 13.0238 3.16039C13.8052 4.32981 14.2222 5.70467 14.2222 7.11111C14.2255 8.69028 13.6969 10.2245 12.7218 11.4667L15.7396 14.4889C15.8617 14.6135 15.9445 14.7713 15.9776 14.9427C16.0106 15.114 15.9925 15.2913 15.9255 15.4525C15.8585 15.6136 15.7456 15.7514 15.6007 15.8488C15.4559 15.9462 15.2856 15.9988 15.1111 16ZM7.11111 1.77778C6.05628 1.77778 5.02514 2.09058 4.14807 2.67661C3.27101 3.26264 2.58742 4.0956 2.18376 5.07014C1.78009 6.04468 1.67447 7.11703 1.88026 8.1516C2.08605 9.18616 2.594 10.1365 3.33988 10.8823C4.08576 11.6282 5.03607 12.1362 6.07063 12.342C7.1052 12.5478 8.17755 12.4421 9.15209 12.0385C10.1266 11.6348 10.9596 10.9512 11.5456 10.0742C12.1317 9.19709 12.4444 8.16595 12.4444 7.11111C12.4428 5.69713 11.8804 4.34153 10.8805 3.3417C9.8807 2.34186 8.5251 1.77943 7.11111 1.77778Z" fill={color}/>
    </svg>
  )
}

export default SearchIcon;



