import { colors } from "../../styles";
import IconProps from "./icon-interface";

const LogoutIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.tertiaryText;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 17 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.5092 6.80703C16.4805 7.02442 16.4559 7.24592 16.419 7.45921C16.1114 9.24349 15.1844 10.6176 13.6626 11.5856C12.469 12.3444 11.1523 12.6234 9.75769 12.447C7.97752 12.2173 6.57471 11.3436 5.54516 9.87516C5.30316 9.52651 5.37699 9.09172 5.70103 8.85382C6.03327 8.60771 6.48037 8.66924 6.73058 9.02609C7.21049 9.69878 7.80524 10.2279 8.55177 10.593C10.4591 11.52 12.8381 10.9416 14.1138 9.24349C14.8193 8.30008 15.1433 7.24182 15.0203 6.07691C14.8439 4.43209 14.0194 3.17695 12.5264 2.45503C10.7544 1.59366 9.06449 1.83156 7.53863 3.08261C7.231 3.33692 6.98899 3.67326 6.73058 3.985C6.48857 4.27623 6.13992 4.37877 5.81588 4.23111C5.4057 4.03832 5.26624 3.54201 5.52465 3.14824C5.81178 2.71755 6.15223 2.33198 6.5419 1.99153C7.45249 1.19579 8.51075 0.711776 9.70847 0.551807C10.8324 0.404143 11.9193 0.56001 12.9571 1.02761C14.163 1.57315 15.0941 2.43042 15.7463 3.58303C16.1729 4.33775 16.419 5.15401 16.4846 6.01948C16.4887 6.07691 16.501 6.13433 16.5133 6.18766V6.81933L16.5092 6.80703Z" fill={color}/>
      <path d="M2.87478 5.76089C2.97323 5.76089 3.03475 5.75679 3.09218 5.76089C5.81576 6.0152 8.53935 6.0111 11.2588 5.76089C11.5378 5.73628 11.7798 5.82242 11.952 6.05622C12.1202 6.28182 12.1489 6.53202 12.0341 6.78634C11.9192 7.04885 11.7141 7.19651 11.427 7.22112C11.3532 7.22933 11.2793 7.22523 11.2055 7.22523C8.49833 7.53286 5.79115 7.54517 3.08398 7.22523C3.02655 7.21702 2.96913 7.22523 2.87478 7.22523C2.92401 7.28265 2.95272 7.31957 2.98553 7.35238C3.25625 7.6231 3.53517 7.88972 3.79769 8.16864C4.21197 8.60753 4.02328 9.27202 3.44903 9.40738C3.16601 9.473 2.93221 9.37866 2.73532 9.17768C2.40718 8.84953 2.08314 8.52549 1.755 8.19735C1.36943 7.81178 0.979761 7.42621 0.594193 7.03654C0.274254 6.7125 0.266051 6.27771 0.58599 5.95778C1.2997 5.23996 2.01751 4.51805 2.73943 3.80434C3.17422 3.37365 3.8387 3.52952 3.99457 4.09557C4.07251 4.38679 3.98637 4.6329 3.77718 4.84209C3.51466 5.1005 3.25625 5.36302 2.99784 5.62143C2.96092 5.65835 2.93221 5.69526 2.87478 5.75679V5.76089Z" fill={color}/>
    </svg>
  )
}

export default LogoutIcon;
