export const AVAILABLE_FONTS = Object.entries({
  'Carton six' : 'Carton six',
  'Causten Regular' : 'Causten Regular',
  'Caviar Dreams' : 'Caviar Dreams',
  'Elior Bold' : 'Elior Bold',
  'Elior Regular' : 'Elior Regular',
  'KGAlwaysAGoodTime' : 'KG Always A Good Time',
  'Misses' : 'Misses',
  'Montserrat' : 'Montserrat Regular',
  'MyriadPro-Regular' : 'Myriad Pro Regular'
}).map(([value, label]) => ({label, value}));

export const AVAILABLE_SIZES = ['7', '8', '9', '10', '11', '12', '14', '16', '20', '24', '28', '34', '40', '46'].map(
  size => ({
    label : size,
    value : size
  })
);