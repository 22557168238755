import { colors } from "../../styles";
import IconProps from "./icon-interface";

const FirstPageIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.primary;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6.26026 0.993326C6.33629 1.07031 6.3966 1.16171 6.43775 1.26232C6.4789 1.36293 6.50008 1.47076 6.50008 1.57967C6.50008 1.68857 6.4789 1.79641 6.43775 1.89701C6.3966 1.99762 6.33629 2.08903 6.26026 2.16601L2.47589 6.00017L6.26026 9.83543C6.40478 9.99257 6.48346 10.2004 6.47972 10.4152C6.47598 10.6299 6.39011 10.8348 6.24021 10.9867C6.0903 11.1386 5.88806 11.2256 5.6761 11.2294C5.46413 11.2332 5.25899 11.1534 5.10389 11.007L0.740257 6.58596C0.587038 6.43053 0.500977 6.21984 0.500977 6.00017C0.500977 5.7805 0.587038 5.56981 0.740257 5.41438L5.10389 0.993326C5.2573 0.838091 5.46526 0.750898 5.68208 0.750898C5.89889 0.750898 6.10685 0.838091 6.26026 0.993326Z" fill={color}/>
      <path d="M11.2603 0.993326C11.3363 1.07031 11.3966 1.16171 11.4378 1.26232C11.4789 1.36293 11.5001 1.47076 11.5001 1.57967C11.5001 1.68857 11.4789 1.79641 11.4378 1.89701C11.3966 1.99762 11.3363 2.08903 11.2603 2.16601L7.47589 6.00017L11.2603 9.83543C11.4048 9.99257 11.4835 10.2004 11.4797 10.4152C11.476 10.6299 11.3901 10.8348 11.2402 10.9867C11.0903 11.1386 10.8881 11.2256 10.6761 11.2294C10.4641 11.2332 10.259 11.1534 10.1039 11.007L5.74026 6.58596C5.58704 6.43053 5.50098 6.21984 5.50098 6.00017C5.50098 5.7805 5.58704 5.56981 5.74026 5.41438L10.1039 0.993326C10.2573 0.838091 10.4653 0.750898 10.6821 0.750898C10.8989 0.750898 11.1068 0.838091 11.2603 0.993326Z" fill={color}/>
    </svg>
  )
}

export default FirstPageIcon;


