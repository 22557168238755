import axios from "axios";


import { UserStore, GetFunction, SetFunction, ActionsFactory } from "../store-types";
import configProvider from "../../providers/configprovider";
import { setLoading } from "../helpers/set-loading";
import { useResolvedPath } from "react-router-dom";
import { setError } from "../helpers/set-error";
import { msal } from "../../providers/msal";


const userActions : ActionsFactory<UserStore['actions']['user'], UserStore> =  (set : SetFunction<UserStore>, get : GetFunction<UserStore>) => ({
  isConnected : () => {
    return localStorage.getItem('agami-logged') === 'true';
  },

  clearConnection : () => {
    localStorage.setItem('agami-logged', 'false');
  },

  storeLoginRedirect : (path : string) => {
    localStorage.setItem('agami-redirect', path);
  },

  getLoginRedirect : () => {
    return localStorage.getItem('agami-redirect') || null;
  },

  clearLoginRedirect : () => {
    localStorage.setItem('agami-redirect', '');
  },

  login : async (email : string, password ?: string, token ?: string, alternativeEmail ?: string) => {
    set(state => {
      state.process.loading['login'] = true;
      delete state.process.errors['login'];
    })

    try {
      const result = await axios.post(configProvider('MODEL_API_URL') + '/logins', {email, alternativeEmail, password, token});

      if(result.status === 200) {
        set(state => {
          state.user.logged = true;
          state.user.id = result.data.id;
          state.user.token = result.data.token;
          state.user.organisationId = result.data.organisationId;
          state.user.role = result.data.role;
          state.user.firstName = result.data.firstName;
          state.user.lastName = result.data.lastName;
          state.user.email = result.data.email;
          state.user.marketsIds = result.data.marketsIds;
          state.user.regionsIds = result.data.regionsIds;
          state.process.loading['login'] = false;
          delete state.process.errors['login'];
        });

        axios.defaults.headers.common['Authorization'] = `Bearer ${result.data.token}`;

        localStorage.setItem('agami-logged', 'true');

        return true;
      }
    }
    catch(error) {
      console.error(error)
    }

    set(state => {
      state.user.logged = false;
      state.user.id = undefined;
      state.user.token = undefined;
      state.user.organisationId = undefined;
      state.user.role = undefined;
      state.user.firstName = undefined;
      state.user.lastName = undefined;
      state.user.email = undefined;
      state.user.marketsIds = undefined;
      state.user.regionsIds = undefined;
      state.process.loading['login'] = false;
      state.process.errors['login'] = 'errors.unableToLogin';
    })

    axios.defaults.headers.common['Authorization'] = undefined;

    return false;
  },

  logout : () => {
    set(state => {
      state.user.logged = false;
      state.user.id = undefined;
      state.user.token = undefined;
      state.user.organisationId = undefined;
      state.user.role = undefined;
      state.user.firstName = undefined;
      state.user.lastName = undefined;
      state.user.email = undefined;
      state.user.marketsIds = undefined;
      state.user.regionsIds = undefined;
      state.process.loading['login'] = false;
      delete state.process.errors['login'];
    });

    axios.defaults.headers.common['Authorization'] = undefined;

    localStorage.setItem('agami-logged', 'false');
    localStorage.setItem('agami-redirect', '');
  },

  update : async (firstName :string, lastName : string) => {
    setLoading(set, 'user.update', true);

    try {
      const response = await axios.patch(`${configProvider('MODEL_API_URL')}/me`, {
        firstName,
        lastName
      });

      if(response.status === 200) {
        set(state => {
          state.user.firstName = firstName;
          state.user.lastName = lastName;
        })

        setLoading(set, 'user.update', false);
      }
      else {
        throw new Error(`User.update : server responded with status ${response.status}`);
      }
    }
    catch(error) {
      console.error(error);
      setError(set, 'user.update', 'errors.update')
    }
  }
})

export default userActions;