import styled from 'styled-components';
import {colors} from '../../styles';

interface ProgressBarProps {
  total : number;
  progress : number;
  className ?: string;
}

const ProgressBar : React.FC<ProgressBarProps> = (p) => {
  const value = 100 - p.progress/p.total*100;

  return (
    <Container className={p.className}>
      <Slider style={{transform :`translateX(-${value}%)`}}/>
    </Container>
 );
};

export default ProgressBar;

const Container = styled.div`
  border-radius: 1rem;
  height : 0.7rem;
  min-width: 10rem;
  background-color: ${colors.lightPrimary};
  position : relative;
  overflow: hidden;
`

const Slider = styled.div`
  position : absolute;
  background-color : ${colors.primary};
  top : 0;
  left : 0;
  width : 100%;
  height : 0.7rem;
`