import { colors } from "../../styles";
import IconProps from "./icon-interface";

const CalendarIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.primary;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.3794 4.92941C16.37 3.70138 15.7714 2.79194 14.6943 2.20609C14.1569 1.91379 13.5702 1.84994 12.9697 1.86684C12.884 1.86935 12.7977 1.86684 12.702 1.86684C12.702 1.65529 12.712 1.46752 12.6983 1.28162C12.6901 1.17083 12.6645 1.05254 12.6138 0.954896C12.4862 0.710166 12.2016 0.594373 11.937 0.659468C11.6536 0.728943 11.4622 0.961155 11.4541 1.25533C11.4485 1.45625 11.4529 1.65779 11.4529 1.85808H5.32908C5.32908 1.66906 5.33033 1.48817 5.32908 1.30728C5.32533 0.919219 5.05573 0.635683 4.69544 0.64069C4.34014 0.645698 4.08243 0.924227 4.07993 1.30791C4.07868 1.4888 4.07993 1.66968 4.07993 1.86684C3.8929 1.86684 3.72214 1.86559 3.552 1.86684C1.76178 1.88625 0.398785 3.22194 0.396283 4.97072C0.39253 7.83488 0.390028 10.699 0.396909 13.5632C0.400662 15.2519 1.76929 16.6301 3.45442 16.6345C6.74337 16.6427 10.0323 16.6427 13.3213 16.6345C15.0083 16.6308 16.3794 15.2569 16.3838 13.5682C16.3919 10.6884 16.4007 7.80859 16.3788 4.92941H16.3794ZM15.1371 6.98427C15.1371 9.15054 15.1371 11.3168 15.1371 13.4831C15.1371 14.584 14.3402 15.3865 13.2381 15.3871C10.006 15.3896 6.77464 15.3896 3.54262 15.3871C2.44171 15.3865 1.64606 14.5834 1.64543 13.4806C1.64481 11.3143 1.64543 9.14804 1.64543 6.98177V6.81089H15.1365V6.98427H15.1371ZM15.1215 5.54593H1.67045C1.4509 4.1946 2.22153 2.92275 4.07117 3.13306C4.07368 3.18 4.07931 3.22945 4.07931 3.27889C4.07993 3.62752 4.07743 3.97678 4.08056 4.32541C4.08368 4.68656 4.3464 4.9557 4.69356 4.96008C5.04885 4.96446 5.32345 4.69658 5.32783 4.32979C5.33283 3.93422 5.32908 3.53927 5.32908 3.13118H11.4529C11.4529 3.54365 11.4491 3.94423 11.4541 4.34482C11.4572 4.61709 11.623 4.83866 11.8726 4.92691C12.1153 5.01266 12.3993 4.95069 12.5463 4.73914C12.6301 4.61896 12.687 4.45685 12.6945 4.31102C12.7145 3.92233 12.7014 3.53113 12.7014 3.14307C14.172 2.92776 15.336 3.79527 15.1202 5.54656L15.1215 5.54593Z" fill={color}/>
    <path d="M4.3892 13.3C4.83655 13.308 5.2839 13.308 5.73125 13.3C6.10259 13.2934 6.38984 13 6.3932 12.6414C6.39656 12.2769 6.10326 11.9822 5.72318 11.9749C5.50522 11.9703 5.28727 11.9742 5.06864 11.9742C4.8453 11.9742 4.62128 11.9703 4.39794 11.9749C4.01854 11.9829 3.72389 12.2776 3.72658 12.6408C3.72927 12.9973 4.01921 13.2934 4.3892 13.3Z" fill={color}/>
    <path d="M11.0522 13.3003C11.4998 13.3082 11.9473 13.3076 12.3949 13.3003C12.7658 13.2943 13.0552 12.9997 13.0592 12.6426C13.0632 12.2801 12.7678 11.9836 12.3888 11.9749C12.1708 11.9703 11.9527 11.9742 11.7346 11.9742C11.5112 11.9742 11.2871 11.9709 11.0636 11.9749C10.684 11.9822 10.3906 12.2755 10.3926 12.6399C10.3946 12.9983 10.6813 13.2929 11.0528 13.2996L11.0522 13.3003Z" fill={color}/>
    <path d="M7.73857 9.9717C7.95669 9.97636 8.17481 9.97236 8.39294 9.97236C8.61106 9.97236 8.82918 9.97569 9.04798 9.9717C9.43172 9.96371 9.72255 9.67801 9.72524 9.31107C9.72794 8.93814 9.43172 8.64645 9.03923 8.64312C8.60837 8.63979 8.17751 8.63979 7.74664 8.64312C7.3555 8.64645 7.05592 8.94147 7.05861 9.31174C7.0613 9.67602 7.35618 9.96437 7.73857 9.9717Z" fill={color}/>
    <path d="M11.0653 9.96947C11.5073 9.97546 11.9486 9.97546 12.3905 9.96947C12.7693 9.96414 13.0639 9.66259 13.0592 9.29979C13.0545 8.935 12.7592 8.6481 12.3777 8.6421C12.1598 8.63878 11.9418 8.6421 11.7232 8.6421C11.5052 8.6421 11.2873 8.63878 11.0687 8.64277C10.6879 8.64943 10.3946 8.93833 10.3926 9.30445C10.3906 9.66858 10.6839 9.96481 11.0639 9.97013L11.0653 9.96947Z" fill={color}/>
    <path d="M4.39347 9.96922C4.83487 9.97587 5.27694 9.9752 5.71902 9.96922C6.10053 9.96456 6.39323 9.66997 6.39323 9.30555C6.39323 8.94114 6.09851 8.64987 5.71902 8.64256C5.50101 8.63857 5.283 8.64189 5.06432 8.64256C4.84092 8.64256 4.61686 8.63857 4.39347 8.64322C4.02406 8.6512 3.73271 8.93981 3.72665 9.29757C3.7206 9.658 4.01666 9.96257 4.39414 9.96789L4.39347 9.96922Z" fill={color}/>
    <path d="M7.72531 13.3007C8.16705 13.3081 8.60947 13.3074 9.05188 13.3007C9.43436 13.2954 9.72526 13.0021 9.72526 12.637C9.72526 12.2699 9.43436 11.9819 9.05188 11.9746C8.82832 11.9706 8.60408 11.9739 8.38052 11.9746C8.16234 11.9746 7.94416 11.9706 7.72599 11.9759C7.35428 11.9839 7.06473 12.2705 7.05867 12.6297C7.05328 12.9902 7.34889 13.2948 7.72599 13.3014L7.72531 13.3007Z" fill={color}/>
    </svg>
  )
}

export default CalendarIcon;

