import axios from "axios";


import { GetFunction, SetFunction, ActionsFactory, ModelStore, Market, NotebookType, ID, NotebookTemplate, NotebookTemplateContent, Page, Attachment } from "../store-types";
import configProvider from "../../providers/configprovider";
import { setLoading } from "../helpers/set-loading";
import { setError } from "../helpers/set-error";
import { useUserStore } from "../store";


const attachmentsActions : ActionsFactory<ModelStore['actions']['attachments'], ModelStore> =  (set : SetFunction<ModelStore>, get : GetFunction<ModelStore>) => ({
  create : async (file : File, name : string, marketsIds : ID[]) => {
    setLoading(set, 'attachments.create', true);

    try {
      const formData = new FormData();
      formData.append("file", file);

      const uploadResponse = await axios.post(`${configProvider('MODEL_API_URL')}/attachments`, formData);
      if(uploadResponse.status === 200) {
        const storageName = uploadResponse.data.storageName;

        const newAttachment = await axios.post(`${configProvider('MODEL_API_URL')}/attached-notebooks`, {
          name,
          marketsIds,
          storageName
        })

        set(state => {
          state.attachments.push(newAttachment.data);
        })

        setLoading(set, 'attachments.create', false);
        return;
      }
    }
    catch(error) {}

    setError(set, 'attachments.create', 'errors.attachments.create')
    throw 'errors.attachments.create';

  },

  update : async (attachmentId : number, name : string, marketsIds : ID[]) => {
    setLoading(set, 'attachments.update', true);

    try {
      const uploadResponse = await axios.patch(`${configProvider('MODEL_API_URL')}/attached-notebooks/${attachmentId}`, {
        name, marketsIds
      });
      if(uploadResponse.status === 200) {
        setLoading(set, 'attachments.update', false);
        return uploadResponse.data;
      }
    }
    catch(error) {}

    setError(set, 'attachments.update', 'errors.attachments.update')
    throw 'errors.attachments.update';

  },

  load : async () => {
    setLoading(set, 'attachments.update', true);

    try {
      const uploadResponse = await axios.get(`${configProvider('MODEL_API_URL')}/attached-notebooks?pageSize=10000`);

      if(uploadResponse.status === 200) {
        setLoading(set, 'attachments.update', false);

        set(state => {
          state.attachments = uploadResponse.data.data || [];
        })

        return uploadResponse.data.data;
      }
    }
    catch(error) {}

    setError(set, 'attachments.update', 'errors.attachments.update')
    throw 'errors.attachments.update';
  },

  createPrivate : async (file : File, name : string) => {
    setLoading(set, 'attachments.createPrivate', true);

    try {
      const formData = new FormData();
      formData.append("file", file);

      const uploadResponse = await axios.post(`${configProvider('MODEL_API_URL')}/attachments`, formData);
      if(uploadResponse.status === 200) {
        const storageName = uploadResponse.data.storageName;

        const newAttachment = await axios.post(`${configProvider('MODEL_API_URL')}/private-attached-notebooks`, {
          name,
          storageName
        })

        set(state => {
          state.privateAttachments.push(newAttachment.data);
        })

        setLoading(set, 'attachments.createPrivate', false);
        return;
      }
    }
    catch(error) {}

    setError(set, 'attachments.createPrivate', 'errors.attachments.createPrivate')
    throw 'errors.attachments.createPrivate';

  },

  updatePrivate : async (attachmentId : number, name : string) => {
    setLoading(set, 'attachments.updatePrivate', true);

    try {
      const uploadResponse = await axios.patch(`${configProvider('MODEL_API_URL')}/private-attached-notebooks/${attachmentId}`, {
        name
      });
      if(uploadResponse.status === 200) {
        setLoading(set, 'attachments.updatePrivate', false);
        return uploadResponse.data;
      }
    }
    catch(error) {}

    setError(set, 'attachments.updatePrivate', 'errors.attachments.updatePrivate')
    throw 'errors.attachments.updatePrivate';

  },

  loadPrivate : async () => {
    setLoading(set, 'attachments.loadPrivate', true);

    try {
      const uploadResponse = await axios.get(`${configProvider('MODEL_API_URL')}/private-attached-notebooks?pageSize=10000`);

      if(uploadResponse.status === 200) {
        setLoading(set, 'attachments.loadPrivate', false);

        set(state => {
          state.privateAttachments = uploadResponse.data.data || [];
        })

        return uploadResponse.data.data;
      }
    }
    catch(error) {}

    setError(set, 'attachments.loadPrivate', 'errors.attachments.loadPrivate')
    throw 'errors.attachments.loadPrivate';
  },
  share : async (attachment : Attachment, userId : ID) => {
    setLoading(set, 'attachments.share', true);

    try {
      const newAttachment = await axios.post(`${configProvider('MODEL_API_URL')}/private-attached-notebooks`, {
        userId,
        name : attachment.name,
        storageName : attachment.storageName,
        thumbnailStorageName : attachment.thumbnailStorageName
      })

      setLoading(set, 'attachments.share', false);
      return;
    }
    catch(error) {}

    setError(set, 'attachments.share', 'errors.attachments.share')
    throw 'errors.attachments.share';
  },

  publish : async (attachment : Attachment) => {
    setLoading(set, 'attachments.publish', true);

    const marketsIds = useUserStore.getState().user.marketsIds;

    try {
      const newAttachment = await axios.post(`${configProvider('MODEL_API_URL')}/attached-notebooks`, {
        name : attachment.name,
        marketsIds,
        storageName : attachment.storageName,
        thumbnailStorageName : attachment.thumbnailStorageName
      })

      set(state => {
        state.attachments.push(newAttachment.data);
      })

      setLoading(set, 'attachments.publish', false);
      return;
    }
    catch(error) {}

    setError(set, 'attachments.publish', 'errors.attachments.publish')
    throw 'errors.attachments.publish';
  },

  archive : async (id : ID) => {
    setLoading(set, 'attachments.archive', true);

    try {
      const response = await axios.patch(`${configProvider('MODEL_API_URL')}/attached-notebooks/${id}`, {
        inactive : true
      })

      if(response.status === 200) {
        set(state => {
          const previous = state.attachments.find(a => a.id === id);
          if (previous) {
            previous.inactive = true;
          }
        })

        setLoading(set, 'attachments.archive', false);
        return;
      }
    }
    catch(error) {}

    setError(set, 'attachments.archive', 'errors.attachments.archive')
    throw 'errors.attachments.archive';
  },

  unarchive : async (id : ID) => {
    setLoading(set, 'attachments.unarchive', true);

    try {
      const response = await axios.patch(`${configProvider('MODEL_API_URL')}/attached-notebooks/${id}`, {
        inactive : false
      })

      if(response.status === 200) {
        set(state => {
          const previous = state.attachments.find(a => a.id === id);
          if (previous) {
            previous.inactive = false;
          }
        })

        setLoading(set, 'attachments.unarchive', false);
        return;
      }
    }
    catch(error) {}

    setError(set, 'attachments.unarchive', 'errors.attachments.unarchive')
    throw 'errors.attachments.unarchive';
  },

  archivePrivate : async (id : ID) => {
    setLoading(set, 'attachments.archivePrivate', true);

    try {
      const response = await axios.patch(`${configProvider('MODEL_API_URL')}/private-attached-notebooks/${id}`, {
        inactive : true
      })

      if(response.status === 200) {
        set(state => {
          const previous = state.privateAttachments.find(a => a.id === id);
          if (previous) {
            previous.inactive = true;
          }
        })

        setLoading(set, 'attachments.archivePrivate', false);
        return;
      }
    }
    catch(error) {}

    setError(set, 'attachments.archivePrivate', 'errors.attachments.archivePrivate')
    throw 'errors.attachments.archivePrivate';
  },

  unarchivePrivate : async (id : ID) => {
    setLoading(set, 'attachments.unarchivePrivate', true);

    try {
      const response = await axios.patch(`${configProvider('MODEL_API_URL')}/private-attached-notebooks/${id}`, {
        inactive : false
      })

      if(response.status === 200) {
        set(state => {
          const previous = state.privateAttachments.find(a => a.id === id);
          if (previous) {
            previous.inactive = false;
          }
        })

        setLoading(set, 'attachments.unarchivePrivate', false);
        return;
      }
    }
    catch(error) {}

    setError(set, 'attachments.unarchivePrivate', 'errors.attachments.unarchivePrivate')
    throw 'errors.attachments.unarchivePrivate';
  }
})

export default attachmentsActions;


