import { GetFunction, SetFunction, ActionsFactory, ModelStore, SlideIndex, ContextMenuItem } from "../store-types";

const modalsActions : ActionsFactory<ModelStore['actions']['modals'], ModelStore> =  (set : SetFunction<ModelStore>, get : GetFunction<ModelStore>) => ({
  showColorPicker : (x : number, y : number, callback : (color : string) => void) => {
    set(state => {
      state.process.modals.colorPicker = {
        visible : true,
        x,
        y,
        callback
      }
    })
  },

  hideColorPicker : () => {
    set(state => {
      state.process.modals.colorPicker.visible = false;
    })
  },

  showDialog : (options) => {
    set(state => {
      state.process.modals.dialog = {
        visible : true,
        content : options.content,
        callback : options.callback,
        cancelText : options.cancelText,
        validateText : options.validateText
      }
    })
  },

  hideDialog : () => {
    set(state => {
      state.process.modals.dialog.visible = false;
    })
  },

  showContextMenu : (x : number, y : number, options : ContextMenuItem[]) => {
    set(state => {
      state.process.modals.contextMenu = {
        visible : true,
        x,
        y,
        options
      }
    })
  },

  hideContextMenu : () => {
    set(state => {
      state.process.modals.contextMenu = {
        visible : false
      }
    })
  }

})

export default modalsActions;