import { ReactNode, useEffect } from "react";
import styled from "styled-components";
import { ColorsNames, colors, shadows, spacing, transitions } from "../../styles";
import ArrowDownIcon from "../../assets/icons/arrow-down";
import Typography from "./typography";

interface InlineDropdownProps {
  open : boolean;
  setOpen ?: (open : boolean) => void;
  hideChevron ?: boolean;
  label ?: ReactNode;
  color ?: ColorsNames;
  inverted ?: boolean;
  children ?: ReactNode;
}

const InlineDropdown : React.FC<InlineDropdownProps> = (p) => {
  return (
    <Container
      className={[p.open ? 'open' : '', p.inverted ? 'inverted' : ''].join(' ')}
    >
      <Veil onClick={() => p.setOpen?.(false)}/>
      <Chrome
        onClick={() => p.setOpen?.(!p.open)}
      >
        {
          typeof p.label === 'string' ?
            <Typography variant="textRegular" color={p.color || 'text'}>{p.label}</Typography> :
            p.label
        }
        {
          p.hideChevron ? null :
          <ChevronContainer>
            <ArrowDownIcon color={colors.tertiaryText} />
          </ChevronContainer>
        }
      </Chrome>
      <DropdownAligner>
        <Dropdown>
          {p.children}
        </Dropdown>
      </DropdownAligner>
    </Container>
  )
}

export default InlineDropdown;

const Container = styled.div`
  display: inline-flex;
  position : relative;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Chrome = styled.div`
  display: inline-flex;
  position: relative;
  z-index: 8;
  background: ${colors.background2};
  min-height: 2rem;
  border-radius : 1.8rem;
  box-shadow: ${shadows.default};
  align-items: center;
  padding : 0.5rem ${spacing}rem;
  transition : border-radius ${transitions.fast};
  cursor : pointer;

  .open &{
    border-bottom-left-radius: 0;
    border-bottom-right-radius : 0;
  }

  .open.inverted &{
    border-bottom-left-radius: 1.8rem;
    border-bottom-right-radius : 1.8rem;
    border-top-left-radius: 0;
    border-top-right-radius : 0;
  }
`

const ChevronContainer = styled.div`
  height : 1rem;
  width : 1rem;
  margin-left : ${spacing}rem;
  transition: transform ${transitions.fast};

  .inverted &, .open & {
    transform : rotate(180deg);
  }

  .open.inverted & {
    transform : rotate(0deg);
  }
`

const Veil = styled.div`
  position : fixed;
  top : 0;
  left : 0;
  width : 100%;
  height : 100%;
  display: none;
  z-index: 7;

  .open & {
    display: block;
  }
`

const DropdownAligner = styled.div`
  position : absolute;
  width : 0.1px;
  bottom : 0;
  left : 50%;

  .inverted & {
    bottom : auto;
    top : 0;
  }
`

const Dropdown = styled.div`
  display: none;
  position: absolute;
  transform: translateX(-50%);
  top : ${0.5*spacing}rem;
  z-index: 8;

  .open & {
    display: block;
  }

  .inverted & {
    top : auto;
    bottom : ${0.5*spacing}rem;
  }
`
