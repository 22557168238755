/**********************************
 * Typography
 */

type TypographyDef = {
  family     : string;
  weight     : string;
  size       : string;
  style      ?: string;
  decoration ?: string;
  transform  ?: string;
  css        ?: string
}

const EPILOGUE = "'Epilogue', sans-serif";
const LATO     = "'Lato', sans-serif";

const EXTRABOLD = "900";
const BOLD      = "700";
const REGULAR   = "400";
const LIGHT     = "300";

const ITALIC = "italic";

const UNDERLINED = "underline";

const UPPERCASE = "uppercase";

const _typography = {
  /*
  Titles
  */
  bigTitle : {
    family : EPILOGUE,
    weight : EXTRABOLD,
    size   : "2.5rem"
  },
  mediumTitle : {
    family : EPILOGUE,
    weight : BOLD,
    size   : "1.8rem"
  },
  secondaryTitle : {
    family    : LATO,
    weight    : BOLD,
    size      : "1.5rem",
    transform : UPPERCASE
  },
  secondarySubtitle : {
    family : LATO,
    weight : REGULAR,
    size  : "1.2rem"
  },

  /*
  Buttons
  */
  buttonBold : {
    family : LATO,
    weight : BOLD,
    size   : "1.5rem"
  },
  buttonRegular : {
    family : LATO,
    weight : REGULAR,
    size   : "1.5rem"
  },

  /*
    Text
  */
  textBold : {
    family : LATO,
    weight : BOLD,
    size : "1.4rem"
  },
  textRegular : {
    family : LATO,
    weight : REGULAR,
    size : "1.4rem"
  },
  textItalic : {
    family : LATO,
    weight : REGULAR,
    size : "1.4rem",
    style : ITALIC,
  },

  uppercaseTextBold : {
    family : LATO,
    weight : BOLD,
    size : "1.4rem",
    transform : UPPERCASE
  },
  uppercaseUnderlinedTextBold : {
    family : LATO,
    weight : BOLD,
    size : "1.4rem",
    transform : UPPERCASE,
    decoration : UNDERLINED
  },
  uppercaseTextRegular : {
    family : LATO,
    weight : REGULAR,
    size : "1.4rem",
    transform : UPPERCASE
  },
  uppercaseTextItalic : {
    family : LATO,
    weight : REGULAR,
    size : "1.4rem",
    style : ITALIC,
    transform : UPPERCASE
  },

  smallTextBold : {
    family : LATO,
    weight : BOLD,
    size : "1.1rem"
  },
  smallTextRegular : {
    family : LATO,
    weight : REGULAR,
    size : "1.1rem"
  },
  smallTextItalic : {
    family : LATO,
    weight : REGULAR,
    size : "1.1rem",
    style : ITALIC
  },
  smallTextItalicUnderlined : {
    family : LATO,
    weight : REGULAR,
    size : "1.1rem",
    style : ITALIC,
    decoration : UNDERLINED
  },

  /*
    Breadcrumb
  */
  breadcrumbLight : {
    family : EPILOGUE,
    weight : LIGHT,
    size : "1.2rem",
  },
  breadcrumbBold : {
    family : EPILOGUE,
    weight : BOLD,
    size : "1.2rem",
  },


  /*
    Menu
  */
  menuBold : {
    family : EPILOGUE,
    weight : BOLD,
    size : "1.4rem"
  },
  menuRegular : {
    family : EPILOGUE,
    weight : REGULAR,
    size : "1.4rem"
  },
};

Object.entries(_typography).forEach(([style, def] : [string, TypographyDef]) => {
  (_typography[style as keyof typeof _typography] as TypographyDef).css = `
font-family: ${def.family};
font-size: ${def.size};
font-weight: ${def.weight};
${def.style ? `font-style : ${def.style};` : ''}
${def.decoration ? `text-decoration : ${def.decoration};` : ''}
${def.transform ? `text-transform : ${def.transform};` : ''}
  `;
})

export const typography : Record<string, TypographyDef> = _typography;
export type TypographyVariants = keyof typeof _typography;





/**********************************
 * Colors
 */

export const colors = {
  primary : "#FF5C38",
  secondary : "#FF5C38",
  tertiary : "#FB9638",
  lightPrimary : "#FFE4DF",
  lightSecondary : "#FFD2BE",
  lightTertiary : "#FFE4CA",
  accent : "#A4C587",
  lightAccent : "",
  background : "#FFF7F5",
  background2 : "#FFFFFF",
  text : "#1F1F1F",
  secondaryText : "#6A6A6A",
  tertiaryText : "#ACACAC",
  whiteText : "#FFFFFF",
}
export type ColorsNames = keyof typeof colors;




/**********************************
 * Spacing
 */

export const spacing = 1.5;


/**********************************
 * Transitions and animations
 */
export const transitions = {
  flash : "0.1s ease",
  fast : "0.2s ease",
  medium : "0.4s ease",
  slow : "0.7s ease"
};

/**********************************
 * Shadows
 */
export const shadows = {
  default : "0px 4px 8px 0px rgba(0, 0, 0, 0.08)",
  heavy : "0px 4px 8px 0px rgba(0, 0, 0, 0.16)",
  inset : "0px 1px 15px 0px rgba(0, 0, 0, 0.10) inset"
}