import styled from "styled-components";
import SearchIcon from "../../assets/icons/search";
import { colors, spacing, typography } from "../../styles";
import useDebounce from "../../hooks/use-debounce";
import { useEffect } from "react";

interface SearchProps {
  placeholder ?: string;
  onChange : (value ?: string) => void;
  className ?: string;
  debounce ?: number;
}

const Search : React.FC<SearchProps> = (p) => {
  const {debounce, clear} = useDebounce(p.onChange, p.debounce);

  // Just clear debounce timeout on unmount
  useEffect(() => clear, []);

  return (
    <Container className={p.className}>
      <IconContainer><SearchIcon /></IconContainer>
      <Input placeholder={p.placeholder} onChange={e => debounce(e.target.value)}/>
    </Container>
  );
}

export default Search;

const Container = styled.div`
  display: flex;
`

const IconContainer = styled.div`
  width : 1.6rem;
  margin-right : ${spacing}rem;
`

const Input = styled.input`
  flex : 1;
  height : 4rem;
  border-radius : ${spacing}rem;
  background-color: ${colors.background2};
  border : none;
  outline : none;
  padding : 0 ${spacing}rem;
  ${typography.textRegular.css};
  &::placeholder {
    ${typography.textItalic.css};
    color : ${colors.lightPrimary};
  }
`