import { useEffect, useState } from "react";
import SelectDropdown from "../components/atoms/select-dropdown";
import { AVAILABLE_SIZES } from "../constants/fonts";
import { io } from "socket.io-client";

const TestsPage = () => {

  useEffect(() => {
    const socket1 = io('http://localhost:9331', {transports : ['websocket']});
    const socket2 = io('http://localhost:9332', {transports : ['websocket']});

    socket1.onAny((...args) => console.log('socket1', ...args))
    socket2.onAny((...args) => console.log('socket2', ...args))
  }, [])

  const [value, setValue] = useState<string | undefined>('Times');
  const [size, setSize] = useState<string>('10');
  return (
    <div style={{padding : '250px'}}>
      <div>
      <SelectDropdown
        nullable={true}
        value={value}
        onChange={setValue}
        choices={[{value : 'Times', label : 'Times'}, {value : 'Currier new', label : 'Currier new qsdf qdsf'}]}
        placeholder="Typography"
      />

      </div>
      <SelectDropdown
        nullable={false}
        value={size}
        onChange={setSize}
        choices={AVAILABLE_SIZES}
        placeholder="Size"
      />
    </div>
  )
}

export default TestsPage;
