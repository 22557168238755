import styled from 'styled-components';
import PageLayout from '../components/layout/page-layout';
import { useAssertAdmin } from '../hooks/use-assert-admin';
import { useTranslation } from 'react-i18next';
import Table from '../components/atoms/table/table';
import RoleSelector from '../components/atoms/role-selector';
import { ROLES } from '../constants/roles';
import { useEffect, useState } from 'react';
import { colors, spacing } from '../styles';
import MarketSelector from '../components/atoms/market-selector';
import { User } from '../store/store-types';
import { useModelActions } from '../store/hooks/use-actions';
import UserIcon from '../assets/icons/user';
import ToolButton from '../components/atoms/tool-button';
import TrashIcon from '../assets/icons/trash';
import EditIcon from '../assets/icons/edit';
import Typography from '../components/atoms/typography';
import { useModelStore } from '../store/store';
import NewUsersModal from '../components/molecules/modals/users-profile-modal';
import TextButton from '../components/atoms/text-button';
import HTMLTypography from '../components/atoms/html-typography';
import Pagination from '../components/atoms/pagination';
import Search from '../components/atoms/search';
import useLogged from '../hooks/use-logged';

interface UsersPageProps {

}

const UsersPage : React.FC<UsersPageProps> = (p) => {
  useAssertAdmin();
  const logged = useLogged();
  const {t} = useTranslation();
  const [role, setRole] = useState<string>(ROLES[0])
  const [marketId, setMarketId] = useState<number>(0)
  const [page, setPage] = useState<number>(0);
  const [query, setQuery] = useState<string | undefined>('');
  const [pagesCount, setPagesCount] = useState<number>(1);
  const [users, setUsers] = useState<User[]>([])
  const markets = useModelStore(state => state.markets.filter(({inactive}) => !inactive));
  const regions = useModelStore(state => state.regions.filter(({inactive}) => !inactive));
  const {load, delete : deleteUser} = useModelActions('users');
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [editedItem, setEditedItem] = useState<User | undefined>();
  const {showDialog} = useModelActions('modals');

  const handleChange = () => {
    if(logged ) {
      load(page, {role, marketId, query}).then(({data : users, pagination}) => {
        setUsers(users);
        setPagesCount(pagination.pagesCount)
      })
    }
  }

  useEffect(handleChange, [page, role, marketId, query, logged])


  const handleShowCreate = () => {
    setEditedItem(undefined);
    setModalVisible(true)
  }

  const handleShowEdit = (user : User) => {
    setEditedItem(user);
    setModalVisible(true)
  }

  const handleCloseModal = () => {
    setEditedItem(undefined);
    setModalVisible(false)
  }

  const handleDelete = (user : User) => {
    const name = user.firstName || user.lastName ? `${user.firstName} ${user.lastName}` : user.email;

    showDialog({
      content :<HTMLTypography variant="textRegular" color="text" html={t('pages.users.deleteDialogContent', {name})}/>,
      callback : async () => {
        await deleteUser(user.id);
        handleChange();
      }
    })
  }

  return (
    <PageLayout
      title={t('pages.users.name')}
      subtitle={t('pages.users.subtitle')}
      controls={
        <>
          <Search placeholder={t('pages.users.searchPlaceholder')} onChange={setQuery}/>
          <TextButton onClick={handleShowCreate} color="dark">{t('pages.users.createNew')}</TextButton>
        </>
      }
      modal={
        modalVisible ? <NewUsersModal onClose={handleCloseModal} user={editedItem} onChange={handleChange}/> : null
      }
    >
      <Container>
        <SelectorsContainer>
          <RoleSelector selected={role} onChange={setRole}/>
          <MarketSelector selected={marketId} onChange={setMarketId} />
        </SelectorsContainer>
        <Table<User>
          columns={
            [
              {key : '_icon', value : null},
              {key : 'firstName', value : t('pages.users.firstName')},
              {key : 'lastName', value : t('pages.users.lastName')},
              {key : 'email', value : t('pages.users.email')},
              {key : 'marketsIds', value : t('pages.users.marketId')},
              {key : 'regionsIds', value : t('pages.users.regionsIds')},
              {key : '_controls', value : null}
            ]
          }
          records={users}
          renderers={{
            '_icon' : () => <IconContainer><UserIcon color={colors.primary}/></IconContainer>,
            'firstName' : (value) => <Typography variant="textBold">{value || '-'}</Typography>,
            'lastName' : (value) => <Typography variant="textBold">{value || '-'}</Typography>,
            'marketsIds' : (values) => <Typography variant="textRegular">{markets.filter(market => values.includes(market.id)).map(m => m.name).join(', ')}</Typography>,
            'regionsIds' : (values) => <Typography variant="textRegular">{regions.filter(region => (values || []).includes(region.id)).map(m => m.name).join(', ')}</Typography>,
            '_controls' : (value : any, record : User) => (
              <ControlsContainer>
                <ToolButton onClick={() => handleDelete(record)}><TrashIcon color={colors.primary}/></ToolButton>
                <ToolButton onClick={() => handleShowEdit(record)}><EditIcon color={colors.primary}/></ToolButton>
              </ControlsContainer>
            )
          }}
          highlighed={(record : User) => !record.firstName && !record.lastName}
        />
        {
          pagesCount > 1 ?
          <PaginationContainer>
            <Pagination total={pagesCount} current={page+1} onChange={(page) => setPage(page-1)}/>
          </PaginationContainer> :
          null
        }
      </Container>
    </PageLayout>
  )
}

export default UsersPage;

const Container = styled.div`
  padding-top : ${3*spacing}rem;
;`

const SelectorsContainer = styled.div`
  display : flex;
  justify-content: space-between;
  margin-bottom : ${2*spacing}rem;
`

const IconContainer = styled.div`
  margin-left : 1rem;
  width : 1.6rem;
`

const ControlsContainer = styled.div`
  display: flex;
  & button {
    margin-left : ${spacing}rem;
  }
`

const PaginationContainer = styled.div`
  display : flex;
  align-items: center;
  justify-content: center;
  margin-top : ${2*spacing}rem;
`