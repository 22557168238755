import { ChangeEvent, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { colors, typography } from '../../styles';

interface EditableSlideLabelProps {
  initialValue ?: string;
  className ?: string;
  onChange ?: (value : string) => void;
  onFocus ?: () => void;
  onBlur ?: () => void;
  disabled ?: boolean;
}

const EditableSlideLabel : React.FC<EditableSlideLabelProps> = (p) => {
  const [value, setValue] = useState<string>(p.initialValue || '');

  useEffect(() => {
    setValue(p.initialValue || '');
  }, [p.initialValue])

  return (
    <Container data-replicated-value={value} className={p.className}>
      <Input
        rows={1}
        value={value}
        disabled={p.disabled}
        onFocus={p.onFocus}
        onBlur={p.onBlur}
        onChange={(event : ChangeEvent<HTMLTextAreaElement>) => {
          const _value = event.target.value;
          setValue(_value)

          if(p.onChange) {p.onChange(_value)}
        }}
        onKeyDown={(event : React.KeyboardEvent<HTMLTextAreaElement>) => {
          if(event.code === 'Enter') {
            event.currentTarget.blur();
          }
        }}
       />
    </Container>
  );
}

export default EditableSlideLabel;

/*
  Autogrowing Textarea trick source : https://css-tricks.com/the-cleanest-trick-for-autogrowing-textareas/
*/

const Container = styled.div`
  width : 100%;
  display: grid;

  &::after {
    content: attr(data-replicated-value) " ";
    white-space: pre-wrap;
    visibility: hidden;
  }

  & > textarea {
    resize: none;
    overflow: hidden;
  }

  & > textarea, &::after {
    grid-area: 1 / 1 / 2 / 2;
    padding: 0;
    border: none;
    ${typography.textRegular.css}
    outline : none;
  }
`

const Input = styled.textarea`
  width : 100%;
  color: ${colors.secondaryText};
  &:disabled {
    background-color: inherit;
  }
`