export const UpperSerif  : React.FC<{className ?: string}> = (p) => {
  return (
    <svg className={p.className} width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.657471 14H13.2773V0C13.2773 7.28248 7.74668 13.2715 0.657471 14Z" fill="#FFF7F5"/>
    </svg>
  )
}

export const LowerSerif : React.FC<{className ?: string}> = (p) => {
  return (
    <svg className={p.className} width="16" height="15" viewBox="0 0 16 15" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.521049 0.80154C8.28141 0.973992 14.5733 7.09867 15.0044 14.7937H15.0306V0.793701H0.189209C0.301564 0.793701 0.411307 0.798927 0.521049 0.80154Z" fill="#FFF7F5"/>
    </svg>
  )
}
