import styled from "styled-components";
import { DynamicSlideViewMeta, PageSlide } from "../../store/store-types";
import DnDHandleIcon from "../../assets/icons/dnd-handle";
import { colors, shadows, spacing, transitions } from "../../styles";
import Typography from "./typography";
import ToolButton from "./tool-button";
import ArrowDownIcon from "../../assets/icons/arrow-down";
import { useModelActions } from "../../store/hooks/use-actions";
import { useDrag, useDrop } from "react-dnd";
import { SlideDragItem } from "../../types";
import { useModelStore } from "../../store/store";

interface ListViewItemProps {
  index : number;
  slide : PageSlide;
  view ?: DynamicSlideViewMeta;
}

const ListViewItem : React.FC<ListViewItemProps> = (p) => {
  const { toggleCollapse, moveSlides, select } = useModelActions('notebookEditor');
  const collapsed = useModelStore(state => !!state.notebooks.editor.meta[p.slide.pageId]?.collapsed);

  const handleCollapse = () => {
    toggleCollapse(p.slide.pageId);
  }

  const [dragCollectedProps, drag, dragPreview] = useDrag<SlideDragItem>(() => ({
    type : 'slide',
    item: { id : p.slide.pageId, index : p.index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  const [moveDropCollectedProps, moveDropZone] = useDrop<SlideDragItem, unknown, {isOver : boolean}>({
    accept : 'slide',
    drop : () => moveSlides({position : p.index}),
    canDrop : (item) => item.id !== p.slide.pageId,
    collect : (monitor) => ({
      isOver : monitor.canDrop() && monitor.isOver()
    })
  }, [p.index])


  return (
    <Row
      $level={p.slide.level}
      className={[p.view?.hidden ? 'hidden' : 'visible', p.slide.level === 4 ? 'content' : ''].join(' ')}
      ref={moveDropZone}
    >
      <DnDHandleContainer ref={drag} onMouseDown={() => select(p.slide.pageId)}>
        <DnDHandleIcon/>
      </DnDHandleContainer>
      <LabelContainer>
        <Typography variant={p.slide.level === 1 ? "uppercaseTextBold" : p.slide.level === 4 ? "textRegular" : "uppercaseTextRegular"}>
        {p.slide.label}
        </Typography>
        <MoveDropIndicator
          className={moveDropCollectedProps.isOver ? 'dnd-over' : ''}
        />
      </LabelContainer>
      {
        p.view?.hasChildren ?
          <CloseButton onClick={handleCollapse}>
            <StyledArrowDownIcon $rotated={!collapsed} />
          </CloseButton>
          : null
      }
    </Row>
  )
}

export default ListViewItem;

const Row = styled.div<{$level : number}>`
  position : relative;
  padding-left: ${p => p.$level === 4 ? 0 : (p.$level -1 ) * 2 * spacing}rem;
  padding-bottom : ${spacing}rem;
  display: flex;
  align-items: center;
  max-height: 10rem;
  transition: max-height ${transitions.fast}, padding-bottom ${transitions.fast};
  overflow: ${p => p.$level === 4 ? 'visible' : 'hidden'};

  &.hidden {
    max-height : 0rem;
    padding-bottom : 0rem;
  }

  &.content {
    padding-bottom : 0rem;
  }
`

const DnDHandleContainer = styled.div`
  width : 1.6rem;
  height : 1.6rem;
  margin-right : ${spacing}rem;

  .content & {
    position : absolute;
    left : -4rem;
  }

  cursor : grab;

`

const LabelContainer = styled.div`
  position : relative;
  display: flex;
  align-items: center;
  width : 62rem;
  height : 4.8rem;
  border-radius: 1rem;
  box-shadow : ${shadows.default};
  background-color: ${colors.background2};
  padding: 0rem ${spacing}rem;

  .content & {
    box-shadow : none;
    border-radius : none;
    height : 3.5rem;
  }
`

const CloseButton = styled(ToolButton)`
  margin-left : ${spacing}rem;
  height : 4.8rem;
  width : 4.8rem;
  padding : 1.6rem;
`;

const MoveDropIndicator = styled.div`
  position : absolute;
  height : 0.7rem;
  width : 58rem;
  bottom : -1.2rem;
  border-radius: 0.7rem;
  background-color : ${colors.accent};
  display: none;

  &.dnd-over {
    display: block;
  }
  .content & {
    bottom : 0;
  }
`

const StyledArrowDownIcon = styled(ArrowDownIcon)<{$rotated : boolean}>`
  transform : rotate(${p => p.$rotated ? '180deg' : '0deg'});
  transition : transform ${transitions.fast};
`
