import { colors } from "../../styles";
import IconProps from "./icon-interface";

const MosaicViewIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.primary;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 23 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.816406" width="10.2222" height="7.11111" rx="2" fill={color}/>
    <rect x="0.5" y="9.70508" width="10.2222" height="7.11111" rx="2" fill={color}/>
    <rect x="12.5" y="0.816406" width="10.2222" height="7.11111" rx="2" fill={color}/>
    <rect x="12.5" y="9.70508" width="10.2222" height="7.11111" rx="2" fill={color}/>
    </svg>
  )
}

export default MosaicViewIcon;

