import styled from 'styled-components';
import PageLayout from '../components/layout/page-layout';
import { useAssertAdmin } from '../hooks/use-assert-admin';
import { useTranslation } from 'react-i18next';
import TextButton from '../components/atoms/text-button';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { colors, shadows, spacing } from '../styles';
import { useModelActions } from '../store/hooks/use-actions';
import { Filter, ID, NotebookTemplateContent, Page, PageSlide, Proposal, Slide, SlideIndex } from '../store/store-types';
import { useModelStore } from '../store/store';
import Typography from '../components/atoms/typography';
import ToolButton from '../components/atoms/tool-button';
import Checkbox from '../components/atoms/checkbox';
import { SlideCluster, SlideGroup } from '../types';
import ProposalSlideCard from '../components/molecules/proposal-slide-card';
import ProposalContentLevelContainer from '../components/atoms/proposal-content-level-container';
import ProposalSummaryCard from '../components/molecules/proposal-summary-card';
import Select from '../components/atoms/select';
import FlatButton from '../components/atoms/flat-button';
import MosaicViewIcon from '../assets/icons/mosaic-view';
import ListViewIcon from '../assets/icons/list-view';
import EyeIcon from '../assets/icons/eye';
import UndoIcon from '../assets/icons/undo';
import RedoIcon from '../assets/icons/redo';
import CustomTextModal from '../components/molecules/modals/custom-text-modal';
import ProposalListViewContentLevelContainer from '../components/atoms/proposal-list-view-content-level-container';
import ProposalListViewItem from '../components/atoms/proposal-list-view-item';
import ReplaceSlideModal from '../components/molecules/modals/replace-slide-modal';
import { PROPOSAL } from '../constants/routes';
import useLogged from '../hooks/use-logged';
import { useAssertLogged } from '../hooks/use-assert-logged';
import ProposalAlternativeSlideCard from '../components/molecules/proposal-alternative-slide-card';
import ProposalAlternativesModal from '../components/molecules/modals/proposal-alternatives-modal';
import ProposalLinkedSlideCard from '../components/molecules/proposal-linked-slide-card';
import useDialog from '../hooks/use-dialog';
import HTMLTypography from '../components/atoms/html-typography';
import useAutosave from '../hooks/use-autosave';
import useProposalOfferNotebookId from '../hooks/use-proposal-offer-id';

interface ProposalNotebookProps {

}

const ProposalNotebookPage : React.FC<ProposalNotebookProps> = () => {
  useAssertLogged();
  const logged = useLogged();
  const {t} = useTranslation();
  const navigate = useNavigate();
  
  // This page can be used for offer notebook and for simple notebook.
  // The difference is that offer notebook is an assembly of notebooks with ids in nids
  // and other notebooks are simply a notebook
  const { id, nid, nids } = useParams();
  const isOfferNotebook = nids && !nid;
  const offerNotebookId = useProposalOfferNotebookId(parseInt(`${id}`));
  const notebookId = isOfferNotebook ? offerNotebookId : parseInt(`${nid}`);
  // list of source notebooks
  const notebooksIds = isOfferNotebook ? (nids || '')?.split(',').map(nid => parseInt(`${nid}`)) : [parseInt(`${nid}`)];

  const {loadTemplateContent} = useModelActions('notebooks');
  const {loadOne, loadNotebookContent, generateNotebook} = useModelActions('proposals');
  const {selectNotebook, save, prefilter, setTags, setTagsByFilter, isModified, undo, redo, validateNotebook} = useModelActions('proposalEditor');

  // Id of the notebook in editor state. If this is different from the page id then the state should be reset.
  const editedNotebookId = useModelStore(state => state.proposals.editor.notebookId);
  const editedProposalId = useModelStore(state => state.proposals.editor.proposalId);

  const canUndo = useModelStore(state => state.proposals.editor.historyPosition < state.proposals.editor.history.length - 1 );
  const canRedo = useModelStore(state => !!state.proposals.editor.historyPosition);
  const readonly = useModelStore(state => state.proposals.editor.readonly);


  const content = useModelStore(state => parseInt(id || '-1') !== editedProposalId || notebookId !== editedNotebookId ? {} : state.actions.proposalEditor.current() || {});
  const selectedNumber  = useModelStore(state => 
      Object.entries(state.actions.proposalEditor.current()?.selection || {})
      .filter(([id, selected]) => selected && !!(content.slides || []).find(slide => slide.type === 'page' && `${slide.pageId}` === id))
      .length
    );

  const isTagsPage = !content.prefiltered;

  const [proposal, setProposal] = useState<Proposal | null>(null);
  const [usedFilters, setUsedFilters] = useState<Filter[]>([]);

  const filters = useModelStore(state => state.filters)

  const tags = useModelStore(state => state.proposals.editor.tags);
  const tagsByFilter = useModelStore(state => state.proposals.editor.tagsByFilter);

  const [viewStyle, setViewStyle] = useState<"list" | "mosaic">("mosaic");

  const [customTextVisibility, setCustomTextVisibilty] = useState<SlideIndex | false>(false);
  const [replaceSlideVisibility, setReplaceSlideVisibility] = useState<SlideIndex | false>(false);
  const [insertSlideVisibility, setInsertSlideVisibility] = useState<SlideIndex | false>(false);

  const [alternativesModalVisibility, setAlternativesModalVisibility] = useState<PageSlide | false>(false);

  const showDialog = useDialog({
    validateText : t('pages.proposal-notebook.solveError'),
    cancelText : false
  });



  useEffect(() => {
    (async () => {
      if(id && filters && logged) {
        const proposal = await loadOne(parseInt(id));
        setProposal(proposal);

        // This is the content of the proposal notebook, it is empty before prefilter is called
        const notebookContent = await loadNotebookContent(parseInt(id), notebookId);
        // This is teh content of the notebook template, which should be loaded to compute tags list for prefilter
        const templateContents = await Promise.all(notebooksIds.map(nid => loadTemplateContent(nid)));
        const templateSlides = templateContents.reduce((slides, content) => [...slides, ...(content.slides || [])], [] as Slide[]);

        if(parseInt(id) !== editedProposalId || notebookId !== editedNotebookId) {
          // Reset editor state if the id in state is different from the url id
          selectNotebook(parseInt(id), notebookId, notebookContent);
        }


        // Map containing reference the filter associated to each tag
        const tagsFiltersMap : Record<ID, ID> = {};
        filters.forEach(filter => {
          filter.values.forEach(tag => {
            tagsFiltersMap[tag.id] = filter.id
          })
        });

        // Determine which tags are used in the template, so that only used
        // filters are shown
        const usedFiltersSet = new Set<ID>();
        templateSlides.forEach((slide) => {
          if(slide.type === 'page') {
            (slide.tags || []).forEach(tagId => {
              if(tagsFiltersMap[tagId]) {
                usedFiltersSet.add(tagsFiltersMap[tagId])
              }
            })
          }
        });

        const usedFilters : Filter[] = [...usedFiltersSet.values()].map(filterId => filters.find(filter => filter.id === filterId)).filter(f => !!f) as Filter[];
        setUsedFilters(usedFilters);

        // If current tempate uses no filter, then just skip questions page
        if(!usedFilters.length) {
          await prefilter([], notebooksIds);
        }
      }
    })();
  }, [id, nid, nids, filters, logged]);



  const setFilterValue = (id : ID, value : boolean) => {
    const values = new Set<ID>(tags);

    if(value) {
      values.add(id);
    }
    else {
      values.delete(id);
    }

    setTags([...values.values()])
  }

  const handeContinue = async () => {
    if(!content.prefiltered) {
      // Set a preliminary selection of slides based on filter and save the template
      // into this notebook object
      await prefilter(tags, notebooksIds);

      // Clear selected tags. This is necessary because in filter preselection page tags
      // are ORed, but in usual filtering tags are ANDed. If prefiltering is left active
      // when switching page, preselected pages will not be displayed since ORed tags are
      // ANDed.
      setTags([]);
      setTagsByFilter({});
    }
    else {
      const validationResult = validateNotebook();

      if(validationResult.error) {
        switch(validationResult.type) {
          case 'empty':
            showDialog(undefined, {
              content : <HTMLTypography variant='textRegular' html={t('pages.proposal-notebook.errors.empty')} />
            });
            break;
          case 'missing-text':
            showDialog(undefined, {
              content : <HTMLTypography variant='textRegular' html={t('pages.proposal-notebook.errors.missing-text', {label : validationResult.slide.label})} />
            });
          break;
          default:
            showDialog(undefined, {
              content : <HTMLTypography variant='textRegular' html={t('pages.proposal-notebook.errors.unknown')} />
            });
        }
      }
      else {
        generateNotebook(parseInt(`${id}`), notebookId);
        navigate(PROPOSAL.replace(':id', `${id}`));
      }
    }
  }

  const handleSelectTags = (filterId : ID, values ?: ID[]) => {
    const filter = filters.find(filter => filter.id === filterId);
    const filterValues = (filter?.values || []).map(({id}) => id);
    const {[filterId] : removed, ...restOfTagsByFilter} = tagsByFilter;

    setTags([...tags.filter(id => !filterValues.includes(id)), ...(values || [])]);
    setTagsByFilter({
      ...restOfTagsByFilter,
      ...(values?.length ? {[filterId] : values || []} : {})
    })
  }

  const handleResetTags = () => {
    setTags([]);
    setTagsByFilter({});
  }

  const handleSave = () => {
    if(id) {
      save();
    }
  }


  // TODO remplace with actual proposal notebook template
  const viewMeta = useModelStore(state => state.actions.proposalEditor.viewMeta());

  // Group slides together when they are at level 4
  const slideClusters : SlideCluster[] = [];
  let lastGroup : SlideGroup | null;
  let lastLabel : string;
  (content.slides || []).forEach((slide, index) => {
    const meta = viewMeta[index];

    if(meta?.hiddenAlternative) {
      // Do not render alternative slides that come after the first one
      return;
    }

    if(slide.level < 4) {
      slideClusters.push({type : 'slide', slide, meta, index});
      lastGroup = null;
      lastLabel = slide.label;
    }
    else {
      if(!lastGroup) {
        lastGroup = {type : 'group', slides : [], parentLabel : lastLabel || '---'};
        slideClusters.push(lastGroup);
      }
      lastGroup.slides.push({slide, meta, index});
    }
  })

  useAutosave(isModified(), handleSave);

  return (
    <PageLayout
      breadcrumbs={[t('pages.proposal-notebook.breadcrumb1')]}
      title={
        <TitleContainer>
          <TitleTypography variant="bigTitle" color="text">{proposal?.name || ''}</TitleTypography>
        </TitleContainer>
      }
      subtitle={isTagsPage ? t('pages.proposal-notebook.subtitle_filters') : t('pages.proposal-notebook.subtitle_edit')}
      controls={
        <>
          <div>
            <HistoryToolButton disabled={readonly || !canUndo} onClick={undo}><UndoIcon color={colors.text}/></HistoryToolButton>
            <HistoryToolButton disabled={readonly || !canRedo} onClick={redo}><RedoIcon color={colors.text}/></HistoryToolButton>
          </div>
          <TextButton disabled={!isModified()} color="light" onClick={handleSave}>{t('pages.proposal-notebook.saveDraft')}</TextButton>
          {/* <TextButton disabled={!content.slides?.length || isModified()} color="dark" onClick={() => navigate(NOTEBOOK_TEMPLATE_TAGS.replace(':id', id || ''))}>{t('pages.notebook.next')}</TextButton> */}
          <TextButton disabled={!isTagsPage && isModified()} color="dark" onClick={handeContinue}>{
            isTagsPage ? t('pages.proposal-notebook.continue') : t('pages.proposal-notebook.generate')
          }</TextButton>
        </>
      }
      viewControls={
        isTagsPage ?
          null :
          <div style={{textAlign:'right'}}>
            <Typography variant="textRegular" color="secondaryText">{t('pages.proposal-notebook.totalSlides', {total : content.slides?.length || 0, selected : selectedNumber})}</Typography>
            {
              tags.length ?
              <ShowAllButton onClick={handleResetTags}>
              <EyeIcon /> <Typography variant="textRegular" color="primary">
                  {t('pages.proposal-notebook.showAll')}
                </Typography>
              </ShowAllButton> : null
            }
          </div>
      }
      showBackArrow
      modal={
        !!customTextVisibility ?
          <CustomTextModal slideIndex={customTextVisibility} onClose={() => setCustomTextVisibilty(false)} /> :
        !!replaceSlideVisibility ?
          <ReplaceSlideModal slideIndex={replaceSlideVisibility} onClose={() => setReplaceSlideVisibility(false)} marketId={proposal?.marketId}/> :
        !!insertSlideVisibility ?
          <ReplaceSlideModal slideIndex={insertSlideVisibility} onClose={() => setInsertSlideVisibility(false)} marketId={proposal?.marketId} insert='after'/> :
        !!alternativesModalVisibility ?
          <ProposalAlternativesModal slide={alternativesModalVisibility} onClose={() => setAlternativesModalVisibility(false)} onShowCustomTextModal={setCustomTextVisibilty}/> :
          null
      }
      confirmNavigation={isModified()}
    >
      {
        isTagsPage ?
          <Container>
            {
              usedFilters.map(filter => (
                <FilterContainer key={filter.id}>
                  <Typography variant="textRegular" color="text">{filter.question}</Typography>
                  <CheckboxContainer>
                  {
                    filter.values.map(choice => (
                      <Checkbox key={choice.id} variant="smallTextItalic" label={choice.value} onChange={(checked) => setFilterValue(choice.id, checked)} value={!!tags.includes(choice.id)}/>
                    ))
                  }
                  </CheckboxContainer>
                </FilterContainer>
              ))
            }
        </Container> : null
      }
      {
        isTagsPage ? null :
        <SlidesContainer>
          <Typography variant="smallTextRegular" color="secondaryText">{t('pages.proposal-notebook.filterBy')}</Typography>
          <FiltersBar>
            <FiltersDropdownContainer>
              {
                usedFilters.map(filter => (
                  <Select<ID>
                    key={filter.id}
                    multiselect
                    placeholder={filter.name}
                    choices={filter.values.map(value => ({label : value.value, value : value.id}))}
                    value={filter.values.filter(filter => tags.includes(filter.id)).map(filter => filter.id)}
                    onChange={value => handleSelectTags(filter.id, value)}
                  />
                ))
              }
            </FiltersDropdownContainer>
            <div style={{textAlign:'right'}}>
              <FlatButton onClick={() => setViewStyle("mosaic")} disabled={viewStyle === "mosaic"}>
                <MosaicViewIcon color={viewStyle === "mosaic" ? colors.primary : colors.lightPrimary}/>
              </FlatButton>
              <ListFlatButton onClick={() => setViewStyle("list")} disabled={viewStyle === "list"}>
                <ListViewIcon color={viewStyle === "list" ? colors.primary : colors.lightPrimary}/>
              </ListFlatButton>
            </div>
          </FiltersBar>
          {
            viewStyle === 'mosaic' ?
              (slideClusters).map(cluster => (
                cluster.type === 'slide' ? (
                  cluster.slide.type === 'page' && cluster.slide.isSummary ?
                  <ProposalSummaryCard
                    key={'mosaic' + cluster.slide.pageId}
                    index={cluster.index}
                    slide={cluster.slide}
                    view={cluster.meta}
                    onInsertSlide={setInsertSlideVisibility}
                  /> :
                  // ...or insert a single slide
                  cluster.slide.type === 'page' && cluster.slide.alternativeGroupId ?
                  <ProposalAlternativeSlideCard
                    key={'mosaic' + cluster.slide.pageId}
                    index={cluster.index}
                    slide={cluster.slide}
                    view={cluster.meta}
                    onShowAlternativesModal={setAlternativesModalVisibility}
                    onInsertSlide={setInsertSlideVisibility}
                  /> :
                  cluster.slide.type === 'page' && !cluster.slide.isSummary ?
                  <ProposalSlideCard
                    key={'mosaic' + cluster.slide.pageId}
                    index={cluster.index}
                    slide={cluster.slide}
                    view={cluster.meta}
                    onShowCustomTextModal={setCustomTextVisibilty}
                    onReplaceSlide={setReplaceSlideVisibility}
                    onInsertSlide={setInsertSlideVisibility}
                  /> : null
                ) : (
                  <ProposalContentLevelContainer
                    key={'mosaic-container' + (cluster.slides?.[0]?.index || 0)}
                    index={cluster.slides?.[0]?.index || 0}
                    hidden={cluster.slides?.every(slide => slide?.meta?.hidden)}
                    view={cluster.slides?.[0]?.meta}
                    parentLabel={cluster.parentLabel}
                    slides={cluster.slides}
                    onShowCustomTextModal={setCustomTextVisibilty}
                    onReplaceSlide={setReplaceSlideVisibility}
                    onInsertSlide={setInsertSlideVisibility}
                    onShowAlternativesModal={setAlternativesModalVisibility}
                    />
                )
              )) :
              (slideClusters).map(cluster => (
                cluster.type === 'slide' ? (
                  cluster.slide.type === 'page' ?
                  <ProposalListViewItem
                    key={'list-main' + cluster.index}
                    slide={cluster.slide}
                    view={cluster.meta}
                    index={cluster.index}
                  />
                  : null
                ) : (
                  <ProposalListViewContentLevelContainer
                    key={'list-container' + (cluster.slides?.[0]?.index || 0)}
                    index={cluster.slides?.[0]?.index || 0}
                    hidden={cluster.slides?.[0]?.meta?.hidden}
                    view={cluster.slides?.[0]?.meta}
                  >
                    {
                      cluster.slides.map(slide => (
                        slide.slide.type === 'page' ?
                          <ProposalListViewItem
                            key={'list-content' + slide.index}
                            slide={slide.slide}
                            view={slide.meta}
                            index={slide.index}
                          /> : null
                      ))
                    }
                  </ProposalListViewContentLevelContainer>
                )
              ))
          }
        </SlidesContainer>
      }
    </PageLayout>
  )
}

export default ProposalNotebookPage;

const Container = styled.div`
  border-radius: 1.5rem;
  box-shadow: ${shadows.default};
  padding : ${2*spacing}rem;
  padding-top : ${spacing}rem;
  background-color: ${colors.background2};
  columns: 2;
  column-gap: ${spacing}rem;
;`

const TitleContainer = styled.div`
  display: flex;
  align-items: baseline;
`

const TitleTypography = styled(Typography)`
  margin-right : ${spacing}rem;
`

const FilterContainer = styled.div`
  padding-top: ${2*spacing}rem;
  break-inside: avoid;
`

const CheckboxContainer = styled.div`
  margin-top : ${spacing}rem;

  & > div {
    margin-right : ${2*spacing}rem;
    margin-bottom : 1rem;
  }
`



const SlidesContainer = styled.div`
`
const FiltersBar = styled.div`
  display: flex;
`

const FiltersDropdownContainer = styled.div`
  flex : 1;
  display: flex;
  flex-wrap: wrap;
  margin-bottom : ${spacing}rem;

  & > div {
    margin-top : ${spacing}rem;
    margin-right : ${spacing}rem;
  }
`

const ListFlatButton = styled(FlatButton)`
  padding-left: 0.4rem;
  padding-right: 0.1rem;
`

const ShowAllButton = styled.div`
  margin-top : 0.5rem;
  cursor : pointer;
  height : 1.3rem;
  display : flex;
  flex-wrap: nowrap;
  white-space: pre;
  color : ${colors.primary};
  text-decoration:  underline;
  align-items: end;

  & > svg {
    margin-right : 1rem;
  }
`

const HistoryToolButton = styled(ToolButton)`
  &:last-child {
    margin-right : ${3*spacing}rem;
  }
`
