import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserStore } from "../store/store"
import { HOME, PROFILE } from "../constants/routes";
import { useUserActions } from "../store/hooks/use-actions";

export const useAssertLogged = () => {
  const {logged, firstName, lastName} = useUserStore(state => state.user);
  const navigate = useNavigate();
  const {storeLoginRedirect} = useUserActions('user');
  const location = useLocation();

  useEffect(() => {
    if(!logged) {
      storeLoginRedirect(location.pathname);
      navigate(HOME);
    }
    else if(!firstName || !lastName) {
      navigate(PROFILE);
    }
  }, [logged])
}