import axios from "axios";


import { GetFunction, SetFunction, ActionsFactory, ModelStore, Market, NotebookType, ID, NotebookTemplate, NotebookTemplateContent, Page, PageSlide } from "../store-types";
import configProvider from "../../providers/configprovider";
import { setLoading } from "../helpers/set-loading";
import { setError } from "../helpers/set-error";


const notebooksActions : ActionsFactory<ModelStore['actions']['notebooks'], ModelStore> =  (set : SetFunction<ModelStore>, get : GetFunction<ModelStore>) => ({
  createTemplate : async (name : string, marketsIds : ID[], type : NotebookType) => {
    setLoading(set, 'createNotebookTemplate', true);

    try {
      const response = await axios.post(`${configProvider('MODEL_API_URL')}/notebook-templates`, {
        name,
        marketsIds,
        type,
        draft : true
      });

      if(response.status === 200) {
        const {id} : {id : ID} = response.data || {};

        setLoading(set, 'createNotebookTemplate', false);

        return id;
      }
    }
    catch(error) {}

    setError(set, 'createNotebookTemplate', 'errors.templateCreationError')
    throw 'errors.templateCreationError';
  },

  loadTemplates : async () => {
    setLoading(set, 'loadNotebookTemplates', true);

    try {
      const response = await axios.get(`${configProvider('MODEL_API_URL')}/notebook-templates?pageSize=10000`);

      if(response.status === 200) {
        const {data} : {data : NotebookTemplate[]} = response.data || {};

        set(state => {
          data?.forEach(template => {
            state.notebooks.templates[template.id] = template;
          })
        })

        setLoading(set, 'loadNotebookTemplates', false);
        return;
      }
    }
    catch(error) {}

    setError(set, 'loadNotebookTemplates', 'errors.loadNotebookTemplates')
    throw 'errors.loadNotebookTemplates';
  },

  loadTemplate : async (id : ID) => {
    setLoading(set, 'loadNotebookTemplate', true);

    try {
      const response = await axios.get(`${configProvider('MODEL_API_URL')}/notebook-templates/${id}`);

      if(response.status === 200) {
        const template : NotebookTemplate = response.data;

        set(state => {
          state.notebooks.templates[template.id] = template;
        })

        setLoading(set, 'loadNotebookTemplate', false);
        return template;
      }
    }
    catch(error) {}

    setError(set, 'loadNotebookTemplate', 'errors.loadNotebookTemplate')
    throw 'errors.loadNotebookTemplate';
  },

  loadTemplateContent : async (id : ID) => {
    setLoading(set, 'loadTemplateContent', true);

    try {
      const response = await axios.get(`${configProvider('MODEL_API_URL')}/notebook-templates/${id}/content`);

      if(response.status === 200) {
        const content : NotebookTemplateContent = response.data;

        set(state => {
          state.notebooks.templatesContent[id] = content;
        })

        setLoading(set, 'loadTemplateContent', false);
        return content;
      }
    }
    catch(error) {
      console.error(error)
    }

    setError(set, 'loadTemplateContent', 'errors.loadTemplateContent')
    throw 'errors.loadTemplateContent';
  },

  saveTemplateContent : async (id : ID, content : NotebookTemplateContent) => {
    setLoading(set, 'saveTemplateContent', true);

    try {
      const response = await axios.put(`${configProvider('MODEL_API_URL')}/notebook-templates/${id}/content`, content);

      if(response.status === 200) {
        set(state => {
          state.notebooks.templatesContent[id] = content;
        })

        const firstPage : PageSlide = content.slides?.find(slide => slide.type === 'page') as PageSlide;

        if(firstPage) {
          const thumbnailPageId = firstPage.pageId;
          await axios.patch(`${configProvider('MODEL_API_URL')}/notebook-templates/${id}`, {thumbnailPageId});
        }

        setLoading(set, 'saveTemplateContent', false);
        return;
      }
    }
    catch(error) {}

    setError(set, 'saveTemplateContent', 'errors.saveTemplateContent')
    throw 'errors.saveTemplateContent';
  },

  publish : async (id : ID) => {
    setLoading(set, 'publishNotebookTemplate', true);

    try {
      const response = await axios.patch(`${configProvider('MODEL_API_URL')}/notebook-templates/${id}`, {
        draft : false
      })

      if(response.status === 200) {
        set(state => {
          state.notebooks.templates[id].draft = false;
        })

        setLoading(set, 'publishNotebookTemplate', false);
        return;
      }
    }
    catch(error) {}

    setError(set, 'publishNotebookTemplate', 'errors.publishNotebookTemplate')
    throw 'errors.publishNotebookTemplate';
  },

  update : async (id : ID, name ?: string, marketsIds ?: ID[]) => {
    setLoading(set, 'publishNotebookTemplate', true);

    if(!name && !marketsIds) {
      return;
    }

    try {
      const response = await axios.patch(`${configProvider('MODEL_API_URL')}/notebook-templates/${id}`, {
        ...(name ? {name} : {}),
        ...(marketsIds ? {marketsIds} : {})
      })

      if(response.status === 200) {
        set(state => {
          state.notebooks.templates[id].name = response.data.name;
          state.notebooks.templates[id].marketsIds = response.data.marketsIds;
        })

        setLoading(set, 'publishNotebookTemplate', false);
        return;
      }
    }
    catch(error) {}

    setError(set, 'publishNotebookTemplate', 'errors.publishNotebookTemplate')
    throw 'errors.publishNotebookTemplate';
  },

  archive : async (id : ID) => {
    setLoading(set, 'notebooks.archive', true);

    try {
      const response = await axios.patch(`${configProvider('MODEL_API_URL')}/notebook-templates/${id}`, {
        inactive : true
      })

      if(response.status === 200) {
        set(state => {
          state.notebooks.templates[id].inactive = true;
        })

        setLoading(set, 'notebooks.archive', false);
        return;
      }
    }
    catch(error) {}

    setError(set, 'notebooks.archive', 'errors.notebooks.archive')
    throw 'errors.notebooks.archive';
  },

  unarchive : async (id : ID) => {
    setLoading(set, 'notebooks.unarchive', true);

    try {
      const response = await axios.patch(`${configProvider('MODEL_API_URL')}/notebook-templates/${id}`, {
        inactive : false
      })

      if(response.status === 200) {
        set(state => {
          state.notebooks.templates[id].inactive = false;
        })

        setLoading(set, 'notebooks.unarchive', false);
        return;
      }
    }
    catch(error) {}

    setError(set, 'notebooks.unarchive', 'errors.notebooks.unarchive')
    throw 'errors.notebooks.unarchive';
  },
})

export default notebooksActions;