import { ReactElement } from 'react';
import styled from 'styled-components';
import {colors, ColorsNames, shadows, spacing, transitions} from '../../styles';
import { Link } from 'react-router-dom';
import Typography from './typography';
import PlusIcon from '../../assets/icons/plus';

interface EmptySlideImportProps {
  onSelect : (file : File) => void;
}

const EmptySlideImport : React.FC<EmptySlideImportProps> = (p) => {
  return (
    <Card htmlFor="file_input">
      <IconContainer><PlusIcon color={colors.primary}/></IconContainer>
      <FileInput id="file_input" type="file" accept="application/pdf" onChange={e => e.target.files?.[0] && p.onSelect(e.target.files?.[0])}/>
    </Card>
  );
};

export default EmptySlideImport;

const Card = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  width : 20rem;
  height : 11.3rem;
  border-radius: 1.5rem;
  box-shadow: ${shadows.default};
  background-color : ${colors.lightPrimary};
  overflow : hidden;
  transition : box-shadow ${transitions.fast};

  &:active {
    box-shadow: none;
  }  
`


const IconContainer = styled.div`
  width : 1.2rem;
  height : 1.2rem;
`

const FileInput = styled.input`
  position : absolute;
  visibility: hidden;
`