import { colors } from "../../styles";
import IconProps from "./icon-interface";

const DeindentIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.text;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.52936 1.88718C8.64139 1.78687 8.73067 1.66577 8.7917 1.53135C8.85273 1.39693 8.88421 1.25203 8.88421 1.10558C8.88421 0.959135 8.85273 0.814237 8.7917 0.679816C8.73067 0.545395 8.64139 0.424298 8.52936 0.323983C8.29758 0.115895 7.99109 0 7.67256 0C7.35403 0 7.04754 0.115895 6.81576 0.323983L0.354482 6.21838C0.242793 6.31893 0.153766 6.44008 0.0928232 6.57446C0.0318808 6.70883 0.000299076 6.85361 1.67866e-06 6.99998C-0.000263543 7.14554 0.0309019 7.28959 0.0915617 7.42318C0.153159 7.55819 0.242562 7.68006 0.354482 7.78158L6.8166 13.68C7.04838 13.8881 7.35487 14.004 7.6734 14.004C7.99193 14.004 8.29842 13.8881 8.5302 13.68C8.64223 13.5797 8.73151 13.4586 8.79254 13.3242C8.85357 13.1897 8.88505 13.0448 8.88505 12.8984C8.88505 12.7519 8.85357 12.607 8.79254 12.4726C8.73151 12.3382 8.64223 12.2171 8.5302 12.1168L4.137 8.10878C9.58074 7.2571 13.0373 7.29063 19.7887 8.10878C19.9457 8.11575 20.1025 8.09233 20.2496 8.03993C20.3968 7.98753 20.5313 7.90725 20.6449 7.80393C20.7586 7.70062 20.849 7.57642 20.9109 7.43886C20.9727 7.30129 21.0045 7.15321 21.0045 7.00358C21.0045 6.85395 20.9727 6.70587 20.9109 6.56831C20.849 6.43074 20.7586 6.30655 20.6449 6.20323C20.5313 6.09992 20.3968 6.01964 20.2496 5.96724C20.1025 5.91484 19.9457 5.89142 19.7887 5.89838C12.9358 5.1095 9.51377 5.11491 4.13616 5.89838L8.52936 1.88718Z" fill={color}/>
    </svg>
  )
}

export default DeindentIcon;
