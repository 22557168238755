import styled from 'styled-components';
import PageLayout from '../components/layout/page-layout';
import { useTranslation } from 'react-i18next';
import { useAssertLogged } from '../hooks/use-assert-logged';
import Typography from '../components/atoms/typography';
import { colors, shadows, spacing } from '../styles';
import { useModelStore, useUserStore } from '../store/store';
import { useModelActions } from '../store/hooks/use-actions';
import { useEffect, useState } from 'react';
import { ID, Proposal, User } from '../store/store-types';
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import MarketIcon from '../assets/icons/markets';
import RegionIcon from '../assets/icons/region';
import UserIcon from '../assets/icons/user';
import { USER } from '../constants/roles';
import MarketSelector from '../components/atoms/market-selector';
import Select from '../components/atoms/select';

interface DashboardPageProps {

}

const CustomTooltip = ({ active, payload, label } : any) => {
  if (active && payload && payload.length) {
    return (
      <TooltipContainer>
        <Typography variant='smallTextItalic' color={'primary'}>{payload[0]?.payload?.longLabel}</Typography><br /><br />
        <Typography variant='mediumTitle' color={'primary'}>{payload[0].value}</Typography>
      </TooltipContainer>
    );
  }

  return null;
};

const TooltipContainer = styled.div`
  background-color: ${colors.background};
  border-radius: ${spacing}rem;
  padding : ${0.9*spacing}rem;
  box-shadow: ${shadows.default};
`


const DashboardPage : React.FC<DashboardPageProps> = (p) => {
  useAssertLogged();
  const role = useUserStore(state => state.user.role);
  const user = useUserStore(state => state.user);
  const {load} = useModelActions('proposals');
  const {load : loadMarkets} = useModelActions('markets');
  const {load : loadRegions} = useModelActions('regions');
  const {loadMany : loadUsers} = useModelActions('users');
  const { t } = useTranslation();
  const _markets = useModelStore(state => state.markets);
  const markets = _markets.filter(({inactive}) => !inactive)
  const _regions = useModelStore(state => state.regions);
  const regions = _regions.filter(({inactive}) => !inactive);
  const [proposals, setProposals] = useState<Proposal[]>([])
  const [data, setData] = useState<{start : Date, end : Date, label : string, value : number}[]>([])
  const [yTicks, setYTicks] = useState<number[]>([0, 1]);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [marketsCount, setMarketsCounts] = useState<Record<ID, number>>({});
  const [regionsCount, setRegionsCounts] = useState<Record<ID, number>>({});
  const [usersCount, setUsersCount] = useState<Record<ID, number>>({});
  const [users, setUsers] = useState<User[]>([]);
  const [selectedMarket, setSelectedMarket] = useState<ID>(0);
  const [selectedWeeks, setSelectedWeeks] = useState<number>(12);

  const durationChoices = [
    {label : t('pages.dashboard.three-months'), value : 12},
    {label : t('pages.dashboard.six-months'), value : 26},
    {label : t('pages.dashboard.one-year'), value : 52}
  ]

  useEffect(() => {
    if(role) {
      const today = new Date();
      const startDate = new Date();
      startDate.setDate(today.getDate() - selectedWeeks * 7 - today.getDay() + 1); // 12 weeks from now, - the day of the week (starts from sunday) + 1 to start from monday

      (async () => {
        await loadMarkets();
        await loadRegions();
        const {data} = await load(0, {after : startDate, creatorId : role === USER ? user.id : undefined});
        setProposals(data);
      })()
    }
  }, [role, selectedWeeks])

  useEffect(() => {
    if(proposals && markets && regions) {
      const today = new Date();
      const startDate = new Date();
      startDate.setDate(today.getDate() - selectedWeeks * 7 - today.getDay() + 1); // 12 weeks from now, - the day of the week (starts from sunday) + 1 to start from monday

      const data = [];
      let start = startDate;
      let end = new Date(startDate);
      end.setDate(start.getDate() + 6);

      while(start.getTime() < Date.now()) {
        data.push({
          start : new Date(start),
          end : new Date(end),
          value : proposals
            .filter(p => selectedMarket === 0 || p.marketId === selectedMarket)
            .filter(p => new Date(p.sentAt || '0-0-0').getTime() < end.getTime() && new Date(p.sentAt || '0-0-0').getTime() >= start.getTime()).length,
          label : `${start.getDate()}`.padStart(2, '0') + '/' + `${start.getMonth() + 1}`.padStart(2, '0'), // + ' - ' +`${end.getDate()}`.padStart(2, '0') + '/' + `${end.getMonth() + 1}`.padStart(2, '0')
          longLabel : `Semaine du ${start.getDate()}`.padStart(2, '0') + '/' + `${start.getMonth() + 1}`.padStart(2, '0') + ' au ' +`${end.getDate()}`.padStart(2, '0') + '/' + `${end.getMonth() + 1}`.padStart(2, '0')
        })

        start.setDate(start.getDate()+7);
        end.setDate(end.getDate()+7);
      }

      setData(data);

      const maxVal = Math.max(...data.map(({value}) => value));
      const pow = Math.pow(10,Math.floor(Math.log10(maxVal)));
      const yTicks = maxVal <=1 ? [0, 1] : [0, Math.ceil(maxVal/pow)*pow/2, Math.ceil(maxVal/pow)*pow]
      setYTicks(yTicks);

      const marketsIds : Record<ID, number> = {};
      const regionsIds : Record<ID, number> = {};
      const usersIds   : Record<ID, number> = {};

      proposals
        .filter(p => selectedMarket === 0 || p.marketId === selectedMarket)
        .forEach(proposal => {
          marketsIds[proposal.marketId] = (marketsIds[proposal.marketId] || 0) + 1;
          (proposal.regionsIds || []).forEach(r => {
            regionsIds[r] = (regionsIds[r] || 0) + 1;
          });
          usersIds[proposal.creatorId] = (usersIds[proposal.creatorId] || 0) + 1;
        });

      setUsersCount(usersIds);
      setMarketsCounts(marketsIds);
      setRegionsCounts(regionsIds);

      if(Object.keys(usersIds).length) {
        loadUsers(Object.keys(usersIds).map(i => parseInt(i))).then(setUsers)
      }
    }
  }, [proposals, _markets, _regions, selectedMarket, selectedWeeks])

  return (
    <PageLayout
    title={t('pages.dashboard.title')}
    subtitle={t('pages.dashboard.subtitle')}
    >
      <Container>
        <TitleContainer>
          <Typography variant='mediumTitle' color="primary" style={{marginBottom : `${0.5*spacing}rem`}}>{t('pages.dashboard.proposals')}</Typography>
          <RightTitleContainer>
            <DurationSelect<number>
              choices={durationChoices}
              placeholder=""
              value={selectedWeeks}
              onChange={v => v && setSelectedWeeks(v)}
            />
            <MarketSelector onChange={setSelectedMarket} selected={selectedMarket}/>
          </RightTitleContainer>
        </TitleContainer>
        <Frame>
          <FrameHeader>
            {/* <Typography variant="textRegular" color="secondaryText">{t('pages.dashboard.frameTitle')}</Typography> */}
            <CounterFrame>
              <Typography variant="textBold" color="primary">{t('pages.dashboard.createdProposals')}</Typography>&nbsp;
              <Typography variant="mediumTitle" color="primary">{proposals.length}</Typography>
            </CounterFrame>
          </FrameHeader>

          <ChartContainer>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={data} margin={{left : -20, top : 5, right : 5, bottom : 5}}>
                <CartesianGrid vertical={false} stroke={colors.lightPrimary}/>
                <XAxis dataKey="label" tickLine={false} stroke={colors.primary}/>
                <YAxis ticks={yTicks} tickLine={false} stroke={colors.primary}/>
                <Tooltip cursor={false} content={<CustomTooltip />}/>
                <Line isAnimationActive={false} type="monotone" dataKey="value" stroke={colors.primary} strokeWidth={2} />
              </LineChart>
            </ResponsiveContainer>
          </ChartContainer>

          <Tabs>
            <Tab onClick={() => setSelectedTab(0)} className={selectedTab === 0 ? 'selected' : ''}>
              <MarketIcon width="2rem" height="2rem" color={selectedTab === 0 ? colors.primary : colors.tertiaryText}/>&nbsp;&nbsp;
              <Typography variant='textBold' color={selectedTab === 0 ? 'primary' : 'tertiaryText'}>{t('pages.dashboard.markets')}</Typography>
            </Tab>
            <Tab onClick={() => setSelectedTab(1)} className={selectedTab === 1 ? 'selected' : ''}>
              <RegionIcon width="2rem" height="2rem" color={selectedTab === 1 ? colors.primary : colors.tertiaryText}/>&nbsp;&nbsp;
              <Typography variant='textBold' color={selectedTab === 1 ? 'primary' : 'tertiaryText'}>{t('pages.dashboard.regions')}</Typography>
            </Tab>
            {
              role === USER ? null :
              <Tab onClick={() => setSelectedTab(2)} className={selectedTab === 2 ? 'selected' : ''}>
                <UserIcon width="2rem" height="2rem" color={selectedTab === 2 ? colors.primary : colors.tertiaryText}/>&nbsp;&nbsp;
                <Typography variant='textBold' color={selectedTab === 2 ? 'primary' : 'tertiaryText'}>{t('pages.dashboard.users')}</Typography>
              </Tab>
            }
          </Tabs>
          {
            selectedTab !== 0 ? null :
            <TabCard>
              {
                Object.entries(marketsCount).map(([id, value]) => (
                  <CountItem>
                    <Typography variant='textItalic' color="text">{markets.find(m => m.id === parseInt(id))?.name || '-'}</Typography><br/><br/>
                    <Typography variant='mediumTitle' color="primary">{value}</Typography><br/><br/>
                  </CountItem>
                ))
              }
            </TabCard>
          }
          {
            selectedTab !== 1 ? null :
            <TabCard>
              {
                Object.entries(regionsCount).map(([id, value]) => (
                  <CountItem>
                    <Typography variant='textItalic' color="text">{regions.find(m => m.id === parseInt(id))?.name || '-'}</Typography><br/><br/>
                    <Typography variant='mediumTitle' color="primary">{value}</Typography><br/><br/>
                  </CountItem>
                ))
              }
            </TabCard>
          }
          {
            selectedTab !== 2 ? null :
            <TabCard>
              {
                Object.entries(usersCount).map(([id, value]) => (
                  <CountItem>
                    <Typography variant='textItalic' color="text">{users.find(m => m.id === parseInt(id))?.firstName || ''} {users.find(m => m.id === parseInt(id))?.lastName || ''}</Typography><br/><br/>
                    <Typography variant='mediumTitle' color="primary">{value}</Typography><br/><br/>
                  </CountItem>
                ))
              }
            </TabCard>
          }
        </Frame>
      </Container>
    </PageLayout>
  )
}

export default DashboardPage;

const Container = styled.div`
  padding-top : ${spacing}rem;
`

const Frame = styled.div`
  margin-top : ${2*spacing}rem;
  background-color: ${colors.background2};
  box-shadow: ${shadows.default};
  border-radius: ${spacing}rem;
  padding : ${2*spacing}rem;
  padding-left : 0;
`

const FrameHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding-left: ${2*spacing}rem;
`

const CounterFrame = styled.div`
  border-radius: ${spacing}rem;
  padding : ${spacing}rem;
  background-color: ${colors.background};
`

const ChartContainer = styled.div`
  height : 15rem;
  margin-top : ${2*spacing}rem;
`;

const Tabs = styled.div`
  margin-top : ${spacing}rem;
  margin-left : ${2*spacing}rem;
  display: flex;
`

const Tab = styled.div`
  flex : 1;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top-left-radius: ${spacing}rem;
  border-top-right-radius: ${spacing}rem;
  padding : ${spacing}rem;
  cursor : default;

  &.selected {
    background-color: ${colors.background};
  }
`

const TabCard = styled.div`
  border-bottom-left-radius: ${spacing}rem;
  border-bottom-right-radius: ${spacing}rem;
  padding : ${spacing}rem;
  background-color: ${colors.background};
  margin-left : ${2*spacing}rem;
`

const CountItem = styled.div`
  display: inline-block;
  margin-right : ${2*spacing}rem;
  margin-bottom : ${2*spacing}rem;
`

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
`

const RightTitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`

const DurationSelect = styled(Select)`
  margin-right : -${spacing}rem;
  margin-bottom : ${spacing}rem;
`