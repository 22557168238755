import { ReactNode, CSSProperties } from "react";
import styled from "styled-components";
import { spacing } from "../../../styles";

interface TableHeaderProps {
  className ?: string;
  style ?: CSSProperties;
  children ?: ReactNode;
}

const TableHeader : React.FC<TableHeaderProps> = (p) => {
  return (
    <TH {...p} />
  );
}

export default TableHeader;

const TH = styled.th`
  padding-left: ${spacing}rem;
  padding-right: ${spacing}rem;
  text-align: left;
`