import styled from 'styled-components';
import {colors, ColorsNames, typography, TypographyVariants} from '../../styles';

interface HTMLTypographyProps {
  variant : TypographyVariants;
  color ?: ColorsNames;
  html : string;
  className ?: string;
}

const HTMLTypography : React.FC<HTMLTypographyProps> = (p) => {
  const t = typography[p.variant].css as string;
  const color = colors[p.color || 'text'];

  return <Container className={p.className} typography={t} color={color} dangerouslySetInnerHTML={{__html : p.html}}/>;
};

export default HTMLTypography;

const Container = styled.span<{typography : string, color : string}>`
  ${p => p.typography}
  color : ${p => p.color};
`