import { ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import {colors, ColorsNames, shadows, spacing, transitions, typography} from '../../styles';
import Typography from './typography';
import ArrowDownIcon from '../../assets/icons/arrow-down';
import RemoveCrossIcon from '../../assets/icons/remove-cross';
import useEventDelegation from '../../hooks/use-event-delegation';

type SelectProps<Value> = {
  className ?: string;
  choices : {label : string; value : Value}[];
  placeholder : string;
  color ?: 'light' | 'accent';
} & (
  {
    multiselect ?: false;
    value ?: Value;
    onChange ?: (value ?: Value) => void;
  } | {
    multiselect : true;
    value ?: Value[];
    onChange ?: (value ?: Value[]) => void;
  }
)

const Select = <Value = string | number>(p : SelectProps<Value>) : ReactElement => {
  const [open, setOpen] = useState<boolean>(false);
  const values = (!p.value ? [] : (p.multiselect ? p.value : [p.value])).filter(v => !!v);
  const selected = p.choices.filter(choice => values.includes(choice.value));
  const {addEventListener : addFrameEventHandler, removeEventListener : removeFrameEventHandler} = useEventDelegation('modal-frame');
  const {addEventListener : addPageEventHandler, removeEventListener : removePageEventHandler} = useEventDelegation('page');

  const toggleOpen = () => {setOpen(open => !open)};
  const handleSelect = (value : Value) => {
    if(p.multiselect) {
      if(values.includes(value)) {
        p.onChange && p.onChange(values.filter(v => v !== value));
      }
      else {
        p.onChange && p.onChange([value, ...values]);
      }
    }
    else {
      p.onChange && p.onChange(value);
    }
    setOpen(false);
  }
  const handleReset = (e : React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if(p.multiselect) {
      p.onChange && p.onChange([]);
    }
    else {
      p.onChange && p.onChange();
    }
    setOpen(false);
  }

  useEffect(() => {
    const handler = () => setOpen(false);

    addPageEventHandler(handler);
    addFrameEventHandler(handler);
    return () => {
      removePageEventHandler();
      removeFrameEventHandler();
    }
  }, [])


  return (
    <RelativeContainer onClick={(e => e.stopPropagation())} className={p.className}>
      {open ? <Veil onClick={() => setOpen(false)}/> : null}
      <Chrome $color={p.color} $dropDownZIndex={open ? 3 : 1}>
        <div>
          <Selected  role="menu" onClick={toggleOpen}>
            <FadingTypography $fade={!selected.length} variant="buttonBold" color={p.color === 'accent' ? 'whiteText' : "primary"}>{((selected[0]?.label || '') + (selected.length > 1 ? ` +${selected.length-1}` : '')) || ''}</FadingTypography>
            <FadingTypography $fade={!!selected.length} variant="buttonRegular" color={p.color === 'accent' ? 'whiteText' : "tertiaryText"}>{p.placeholder}</FadingTypography>
          </Selected>
          <DropDown $visible={open} $choices={p.choices.length}>
            <MaskedPlaceholder>
              <Typography variant="buttonRegular">{p.placeholder}</Typography>
            </MaskedPlaceholder>
            {
              p.choices.map(choice => (
                <Option
                  key={choice.label}
                  className={values.includes(choice.value) ? 'selected' : ''}
                  onClick={() => handleSelect(choice.value)}
                  $color={p.color}
                  $multiselect={p.multiselect}
                >
                  <Typography variant="buttonRegular" color={p.color === "accent" ? "whiteText" : "secondaryText"}>{choice.label}</Typography>
                </Option>
              ))
            }
          </DropDown>
        </div>
        <IconContainer onClick={selected.length ? handleReset : toggleOpen}>
            {
              selected.length ?
                <RemoveCrossIcon color={p.color === 'accent' ? colors.whiteText : colors.primary} /> :
                <StyledArrowDownIcon className={open ? 'open' : ''} color={p.color === 'accent' ? colors.whiteText : colors.secondaryText}/>
            }
        </IconContainer>
      </Chrome>
      <HiddenContent>
        <HiddenOption>
          <Typography variant="buttonRegular" color="secondaryText">{p.placeholder}</Typography>
        </HiddenOption>
      {
        p.choices.map(choice => (
          <HiddenOption key={choice.label}><Typography variant="buttonRegular" color="secondaryText">{choice.label}</Typography></HiddenOption>
        ))
      }
      </HiddenContent>
    </RelativeContainer>
  );
};

export default Select;

const RelativeContainer = styled.div`
  position :relative;

  & span {
    white-space: nowrap;
  }
`

const Chrome = styled.div<{$color ?: 'light' | 'accent'; $dropDownZIndex ?: number;}>`
  display: flex;
  flex-direction: row;
  position : absolute;
  z-index: ${p => p.$dropDownZIndex ? p.$dropDownZIndex : 1};
  border : none;
  border-radius : 2rem;
  box-shadow: ${shadows.default};
  padding-top : 0;
  padding-bottom : 0;
  ${typography.buttonBold.css}
  margin-right : ${spacing}rem;
  overflow: hidden;
  cursor : default;

  background-color: ${p => p.$color === 'accent' ? colors.accent : colors.background2};
  color : ${p => p.$color === 'accent' ? colors.whiteText : colors.primary};

  &:active, &:focus {
    outline : none;
  }

  &:active {
    box-shadow: none;
  }

  transition: box-shadow ${transitions.fast};
`;

const Selected = styled.div`
  display: flex;
  align-items: center;
  height : 3.6rem;
  padding-left : 1.8rem;
  padding-right : 1.8rem;
`

const IconContainer = styled.div`
  width : 1rem;
  height : 3.6rem;
  /* margin-left : -1rem; */
  transform: translateX(-1.2rem);
  position : absolute;
  right : 0;
`

const DropDown = styled.div<{$visible : boolean, $choices : number}>`
  overflow: auto;
  max-height: ${p => p.$visible ? ((p.$choices > 6 ? 6 : p.$choices) * 3.6) + 'rem' : 0};
  min-width: 10rem;
  transition: max-height ${transitions.fast};
`

const Option = styled.div<{$color ?: 'light' | 'accent'; $multiselect ?: boolean}>`
  height : 3.6rem;
  display: flex;
  align-items: center;
  padding-left : 1.8rem;
  padding-right : ${p => p.$multiselect ? '6rem' : '3.2rem'};

  &.selected {
    background-color: ${p => p.$color === 'accent' ?  colors.background2 : colors.lightPrimary};
  }

  &:hover {
    background-color: ${p => p.$color === 'accent' ?  colors.background2 : colors.primary};
  }

  &.selected span {
    color : ${p => p.$color === 'accent' ? colors.accent : colors.primary} !important;
  }

  &:hover span {
    color : ${p => p.$color === 'accent' ? colors.accent : colors.whiteText} !important;
  }

`

const MaskedPlaceholder = styled(Option)`
  height : 0.01px;
  visibility: hidden;
  pointer-events: none;
`

const FadingTypography = styled(Typography)<{$fade : boolean}>`
  position : absolute;
  transition: opacity ${transitions.fast};
  opacity : ${p => p.$fade ? '0' : '1'};
`

const HiddenContent = styled.div`
  height : 3.6rem;
  padding-right : 2.5rem;
`

const HiddenOption = styled(Option)`
  visibility: hidden;
  position : relative;
  height : 0.1px;
  pointer-events: none;
`

const StyledArrowDownIcon = styled(ArrowDownIcon)`
  transition : transform ${transitions.fast};

  &.open {
    transform: rotate(180deg);
  }
`

const Veil = styled.div`
  z-index: 2;
  position : fixed;
  top : 0;
  left : 0;
  width : 100%;
  height : 100%;
`