import { colors } from "../../styles";
import IconProps from "./icon-interface";

const ShareIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.text;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 19 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.8583 14.1332C13.413 14.1332 12.1045 14.9593 11.5433 16.206C10.4413 15.8911 9.42834 15.4681 8.52955 14.9462C7.71012 14.4711 6.95547 13.8957 6.28259 13.2336C6.88016 12.6068 7.20648 11.8037 7.20648 10.9524C7.20648 10.2451 6.98381 9.57458 6.56032 9.00293C8.05263 7.75772 9.78381 6.75772 11.7134 6.02745C12.3514 7.10791 13.5401 7.77228 14.8591 7.77228C16.8462 7.77228 18.4623 6.24278 18.4623 4.36232C18.4623 2.48186 16.8453 0.953125 14.8583 0.953125C12.8713 0.953125 11.2551 2.48263 11.2551 4.36309C11.2551 4.45274 11.2583 4.54163 11.2656 4.62899C9.07126 5.44355 7.10688 6.57995 5.42348 8.0106C4.87287 7.70485 4.24696 7.54316 3.60324 7.54316C1.61619 7.54316 0 9.07267 0 10.9531C0 12.8336 1.61619 14.3631 3.60324 14.3631C4.09717 14.3631 4.57247 14.2711 5.02024 14.0888C6.66235 15.7577 8.75951 16.9531 11.2567 17.6435C11.3134 19.4773 12.9069 20.9524 14.8583 20.9524C16.8097 20.9524 18.4615 19.4229 18.4615 17.5424C18.4615 15.6619 16.8453 14.1324 14.8583 14.1324V14.1332ZM14.8583 19.4972C13.7992 19.4972 12.9174 18.7478 12.8049 17.7531V17.7462C12.7968 17.6765 12.7935 17.6098 12.7935 17.5432C12.7935 17.278 12.8494 17.0206 12.9587 16.7776C13.2842 16.0558 14.03 15.5891 14.8583 15.5891C15.9968 15.5891 16.9231 16.4658 16.9231 17.5432C16.9231 18.6206 15.9968 19.4972 14.8583 19.4972ZM4.24615 12.8098C4.03887 12.8742 3.82267 12.9071 3.60324 12.9071C2.46478 12.9071 1.53846 12.0305 1.53846 10.9531C1.53846 9.87573 2.46478 8.9991 3.60324 8.9991C3.92227 8.9991 4.22834 9.06654 4.51255 9.1991C4.82672 9.34546 5.09798 9.56615 5.29717 9.83742C5.54008 10.1654 5.66883 10.5516 5.66883 10.9531C5.66883 11.4458 5.47368 11.9171 5.11822 12.2796C4.88016 12.524 4.57895 12.7071 4.24696 12.8106L4.24615 12.8098ZM14.8583 2.40906C15.9968 2.40906 16.9231 3.28569 16.9231 4.36309C16.9231 5.44048 15.9968 6.31711 14.8583 6.31711C14.119 6.31711 13.4308 5.9378 13.0632 5.32707C12.8899 5.04125 12.7968 4.71481 12.7943 4.38378V4.36309C12.7943 3.28569 13.7198 2.40906 14.8583 2.40906Z" fill={color}/>
    </svg>
    
  )
}

export default ShareIcon;
