import { useEffect, useState } from "react";
import styled from "styled-components"
import Typography from "../../atoms/typography";
import { useTranslation } from "react-i18next";
import { colors, shadows, spacing, typography } from "../../../styles";
import ArrowDownIcon from "../../../assets/icons/arrow-down";
import { useModelActions } from "../../../store/hooks/use-actions";
import { ID, User } from "../../../store/store-types";
import useDebounce from "../../../hooks/use-debounce";
import PlusInCircleIcon from "../../../assets/icons/plus-in-circle";
import { getColor, getFirstLetter } from "./tools";

interface SharePickerMenuProps {
  selectedUsers : User[];
  onRemoveUser : (user : User) => void;
  onAddUser : (user : User) => void;
}


const SharePickerMenu : React.FC<SharePickerMenuProps> = (p) => {
  const {t} = useTranslation();
  const [editedQuery, setEditedQuery] = useState<string>('');
  const {search} = useModelActions('users');
  const [users, setUsers] = useState<User[] | null>(null);

  const {debounce} = useDebounce(async (value ?: string) => {
    if(value) {
      search(value).then(setUsers);
    }
    else {
      setUsers([]);
    }
  }, 400)


  const handleChangeQuery = (query : string) => {
    setEditedQuery(query);
    setUsers(null);
    debounce(query);
  }


  const handleSelectUser = (user : User) => {
    p.onAddUser(user);
    setEditedQuery('');
    setUsers(null);
  }

  return (
    <Container>
      <Header>
        <div><Typography variant="textRegular" color="secondaryText">{t('pages.proposal.shareDialogTitle')}</Typography></div>
      </Header>
      <Input
        placeholder={t('pages.proposal.shareDialogPlaceholder')}
        value={editedQuery}
        onChange={e => handleChangeQuery(e.target.value)}
      />
      {
        users ?
          <SelectionDropdown>
            {
              editedQuery && users && users.length === 0 ?
                <NoUserFoundContainer>
                  <Typography variant="textBold">{t('pages.users.noUserFound')}</Typography>
                </NoUserFoundContainer> :
              users && users.length ?
              users.map(user => (
                <SelectionItem key={user.id} onClick={() => handleSelectUser(user)}>
                  <Typography variant="textBold">{user.firstName} {user.lastName}</Typography> <Typography variant="textRegular">&lt;{user.email}&gt;</Typography>
                </SelectionItem>
              )) : null
            }
          </SelectionDropdown> : null
      }
      {
        p.selectedUsers.map(user => (
          <UserChip>
            <UserLetter style={{background : getColor(user)}}>
              <Typography variant="smallTextRegular" color="whiteText">{getFirstLetter(user)}</Typography>
            </UserLetter>
            <Typography variant="smallTextRegular" color="text">
              {(user.firstName || user.lastName) ? `${user.firstName} ${user.lastName}` : user.email}
            </Typography>
            <span onClick={() => p.onRemoveUser(user)}>
              <DeleteUserIcon width="1.8rem" height="1.8rem" />
            </span>
          </UserChip>
        ))
      }
    </Container>
  )
}

export default SharePickerMenu;

const Container = styled.div`
  border-radius: ${spacing}rem;
  background-color: ${colors.background2};
  box-shadow: ${shadows.default};
  padding : ${spacing}rem;
  user-select: none;
`

const Header = styled.div`
  margin-bottom : ${spacing}rem;
`






const Input = styled.input`
  background-color : ${colors.background};
  min-width : 35rem;
  border-radius : ${spacing}rem;
  border : none;
  outline: none;
  padding: ${spacing}rem;
  ${typography.textRegular.css};
  &::placeholder {
    color : ${colors.secondaryText}
  }
  margin-bottom : ${spacing}rem;
`

const SelectionDropdown = styled.div`
  position : absolute;
  border-radius : 1.5rem;
  box-shadow: ${shadows.heavy};
  background-color: ${colors.background2};
  padding : ${spacing}rem;
  padding-left : 0;
  padding-right : 0;
  z-index: 10;
  bottom: 0;
  transform: translateY(100%);
`

const SelectionItem = styled.div`
  padding : 0.7rem;
  padding-left : ${spacing}rem;
  padding-right : ${spacing}rem;
  cursor : pointer;

  &:hover {
    background-color: ${colors.primary};
  }

  &:hover span{
    color: ${colors.whiteText};
  }
`

const NoUserFoundContainer = styled.div`
  padding : 0.7rem;
  padding-left : ${spacing}rem;
  padding-right : ${spacing}rem;
`
const UserChip = styled.div`
  display: inline-flex;
  align-items: center;
  padding : 0.7rem;
  border-radius : 2rem;
  box-shadow: ${shadows.default};
  margin-right : ${spacing}rem;
  margin-bottom : ${spacing}rem;
`

const DeleteUserIcon = styled(PlusInCircleIcon)`
  transform: rotate(45deg);
  margin-left : 0.7rem;
`

const UserLetter = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width : 1.8rem;
  height : 1.8rem;
  border-radius : 1rem;
  margin-right : 0.7rem;
`