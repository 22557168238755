import { colors } from "../../styles";
import IconProps from "./icon-interface";

const TextIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.text;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M0.537468 8.7198C0.24062 8.7198 0 8.48041 0 8.18509C0 6.10386 0.111628 4.26859 0.205065 3.09553C0.324961 1.59424 1.50574 0.40144 3.01313 0.259949C4.28072 0.141491 6.1999 0 8.1447 0C8.44155 0 8.68217 0.239383 8.68217 0.534704C8.68217 0.830026 8.44155 1.06941 8.1447 1.06941C6.24124 1.06941 4.35845 1.20843 3.11401 1.32524C2.12755 1.41738 1.35525 2.19805 1.27669 3.18026C1.18491 4.3344 1.07494 6.13841 1.07494 8.18509C1.07494 8.48041 0.834315 8.7198 0.537468 8.7198Z" fill={color}/>
      <path d="M7.8553 16C7.55845 16 7.31783 15.7606 7.31783 15.4653C7.31783 15.17 7.55845 14.9306 7.8553 14.9306C9.75876 14.9306 11.6416 14.7916 12.886 14.6748C13.8725 14.5826 14.6448 13.802 14.7233 12.8197C14.8151 11.6656 14.9251 9.86159 14.9251 7.81491C14.9251 7.51959 15.1657 7.28021 15.4625 7.28021C15.7594 7.28021 16 7.51959 16 7.81491C16 9.89614 15.8884 11.7314 15.7949 12.9045C15.675 14.4058 14.4943 15.5986 12.9869 15.7401C11.7193 15.8585 9.8001 16 7.8553 16Z" fill={color}/>
      <path d="M7.99752 4.0909C9.45695 4.0909 10.9164 4.08596 12.3766 4.09666C12.5594 4.0983 12.8041 4.12463 12.9091 4.23979C13.0464 4.39033 13.1481 4.64699 13.1308 4.84607C13.1043 5.1455 12.8554 5.31414 12.5462 5.32072C12.0368 5.33224 11.5274 5.32483 11.0181 5.32483C8.60858 5.32483 6.19824 5.32483 3.78873 5.32483C3.69199 5.32483 3.59607 5.32895 3.49933 5.32401C3.09499 5.3018 2.82212 5.04021 2.8337 4.68895C2.84444 4.35249 3.10822 4.09748 3.49519 4.09337C4.38987 4.0835 5.28537 4.09008 6.18005 4.09008C6.78615 4.09008 7.39142 4.0909 7.99752 4.0909Z" fill={color}/>
      <path d="M8.04382 7.38139C9.50326 7.38139 10.9627 7.37892 12.4229 7.38303C12.8744 7.38386 13.1547 7.6183 13.1671 7.98108C13.1787 8.3455 12.8951 8.61039 12.4602 8.61532C11.7027 8.62273 10.9453 8.61779 10.1879 8.61779C8.0124 8.61779 5.8369 8.62108 3.66057 8.61203C3.4646 8.61121 3.19917 8.58653 3.09003 8.46396C2.94946 8.30602 2.85354 8.0255 2.88496 7.81985C2.93292 7.50149 3.21819 7.38139 3.5415 7.38221C4.65695 7.38468 5.7724 7.38221 6.88786 7.38221C7.27318 7.38221 7.65933 7.38221 8.04465 7.38221L8.04382 7.38139Z" fill={color}/>
      <path d="M4.9414 10.6719C5.42346 10.6719 5.90553 10.6645 6.3876 10.6735C6.80682 10.6817 7.06563 10.9236 7.06646 11.2864C7.06811 11.6492 6.81013 11.9001 6.39256 11.9042C5.42842 11.9124 4.46429 11.9132 3.50016 11.9042C3.09581 11.9001 2.82212 11.6228 2.8337 11.2716C2.84444 10.9351 3.10822 10.685 3.49519 10.6752C3.97726 10.6637 4.45933 10.6727 4.9414 10.6719Z" fill={color}/>
    </svg>
  )
}

export default TextIcon;
