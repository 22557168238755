import { useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useUserStore } from "../store/store"
import { ADMIN, SUPERADMIN } from "../constants/roles";
import { DASHBOARD, HOME } from "../constants/routes";
import { useUserActions } from "../store/hooks/use-actions";

export const useAssertAdmin = () => {
  const logged = useUserStore(state => state.user.logged);
  const role = useUserStore(state => state.user.role)
  const {storeLoginRedirect} = useUserActions('user');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if(!logged) {
      storeLoginRedirect(location.pathname);
      navigate(HOME);
    }
    else if (role !== ADMIN && role !== SUPERADMIN) {
      navigate(DASHBOARD);
    }
  }, [logged, role])
}