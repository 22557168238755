import { useRef, useState } from 'react';
import styled from 'styled-components';

import {colors, shadows, spacing, transitions} from '../../styles';
import { PageSlide } from '../../store/store-types';
import { useModelActions } from '../../store/hooks/use-actions';
import { useModelStore } from '../../store/store';
import configProvider from '../../providers/configprovider';
import ToolButton from '../atoms/tool-button';
import EditableSlideLabel from '../atoms/editable-slide-label';
import { useTranslation } from 'react-i18next';
import Typography from '../atoms/typography';
import { t } from 'i18next';
import ChoicesIcon from '../../assets/icons/choices';
import LinkIcon from '../../assets/icons/link';

interface TagsReviewSlideCardProps {
  index : number;
  slide : PageSlide;
  selected : boolean;
  onSelect : () => void;
  onCtrlSelect : () => void;
  onShiftSelect : () => void;
}

const TagsReviewSlideCard : React.FC<TagsReviewSlideCardProps> = (p) => {
  const imgUrl = `${configProvider('PDF_SERVICE_URL')}/pages/${p.slide.pageId}/thumbnail`;
  const label = p.slide.label || ''; //`Page ${p.index+1}`;

  const handleSelectClick = (e : React.MouseEvent<HTMLDivElement>) => {
    if(e.ctrlKey || e.metaKey) {
      p.onCtrlSelect()
    }
    else if(e.shiftKey) {
      p.onShiftSelect();
    }
    else {
      p.onSelect();
    }
  }

  return (
    <Row id={'tags-review-slide-card-' + p.slide.pageId}>
      <CardContainer className={p.slide.mandatory ? 'mandatory' : ''}>
        <PageCard
          className={p.selected ? 'selected' : ''}
          >
          <PageThumbnail
            src={imgUrl}
            onClick={handleSelectClick}
          />
        </PageCard>
        <Label>
          <Typography variant="textBold" color="text">{label}</Typography>
          <IconsContainer>
            {
              p.slide.alternativeGroupId ? <ChoicesIcon width={'1.5rem'} height={'1.5rem'} color={colors.accent}/> : null
            }
            {
              p.slide.linkedGroupdId ? <LinkIcon width={'1.5rem'} height={'1.5rem'} color={colors.accent}/> : null
            }
          </IconsContainer>
        </Label>
        {
          p.slide.mandatory ?
          <Mandatory>
            <Typography variant='smallTextItalic' color='primary'>{t('pages.notebook.mandatory')}</Typography>
          </Mandatory> : null
        }
      </CardContainer>
    </Row>
  );
};

export default TagsReviewSlideCard;

const Row = styled.div`
  position : relative;
`

const CardContainer = styled.div`
  position :relative;
  margin : ${spacing}rem;
  transition : transform ${transitions.fast}, max-height ${transitions.slow}, margin-top ${transitions.fast}, margin-bottom ${transitions.fast};
  max-height : 25rem;
  overflow: hidden;
  width : 21rem;
  border-radius: 1.5rem;

  &.hidden {
    max-height : 0rem;
    margin-top : 0rem;
    margin-bottom : 0rem;
  }

  &.mandatory {
    background-color: ${colors.lightPrimary};
  }
`

const PageCard = styled.div`
  width : 21rem;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  border-radius: 1.5rem;
  padding : 0.3rem;
  padding-bottom : 0;
  border : 0.3rem solid ${colors.background2};
  background-color: ${colors.background2};
  z-index : 0;
  box-shadow: ${shadows.default};

  &.selected {
    border : 0.3rem solid ${colors.primary} !important;
  }

  &.dnd-over {
    border : 0.3rem solid ${colors.accent} !important;
  }

  &.mandatory {
    background-color: ${colors.lightPrimary};
    border : 0.3rem solid ${colors.lightPrimary};
  }
  &.mandatory textarea {
    background-color: ${colors.lightPrimary};
  }
`


const PageThumbnail = styled.img`
  max-width: 20rem;
  border-radius: 1rem;
`

const Label = styled.div`
  margin-top : 1rem;
  padding-left : 1rem;
  padding-right : 1rem;
  display: flex;
  justify-content: space-between;
`

const Mandatory = styled.div`
  margin-bottom : 1rem;
  padding-left : 1rem;
  padding-right : 1rem;
`

const IconsContainer = styled.div`
  height : 1.5rem;
`






