import { InteractionRequiredAuthError, InteractionType, PublicClientApplication, AuthenticationResult, AccountInfo } from "@azure/msal-browser";
import { AuthCodeMSALBrowserAuthenticationProvider } from "@microsoft/microsoft-graph-client/authProviders/authCodeMsalBrowser";
import { Client } from "@microsoft/microsoft-graph-client";

import configProvider from "./configprovider";
import { useUserStore } from "../store/store";



class MSAL {
  private msalInstance : PublicClientApplication;
  // private loginResponse : AuthenticationResult | undefined;
  private account : AccountInfo | undefined;

  constructor() {
    const msalConfig = {
      auth: {
        clientId: configProvider('MSAL_CLIENT_ID'),
        authority: configProvider('MSAL_AUTHORITY'),
        redirectUri : configProvider('MSAL_REDIRECT_URL'),
        postLogoutRedirectUri : configProvider('WEB_URL')
      },
      cache : {
        storeAuthStateInCookie : false
      }
    };

    this.msalInstance = new PublicClientApplication(msalConfig);

    this.init();
  }

  private async init() {
    await this.msalInstance.initialize();
    const redirectResponse = await this.msalInstance.handleRedirectPromise();

    if (redirectResponse !== null) {
      // Acquire token silent success
      const accessToken = redirectResponse.accessToken;
      const username = redirectResponse.account.username;
      this.account = redirectResponse.account;
      useUserStore.getState().actions.user.login(username, undefined, accessToken);
    }
  }

  logout() {
    this.msalInstance?.logoutRedirect();
    this.account = undefined;
  }

  async login() {
    const accounts = await this.msalInstance.getAllAccounts();

    const accessTokenRequest = {
      scopes: ["user.read"],
      account: accounts[0]
    }

    if(accounts.length) {
      this.msalInstance.acquireTokenSilent(accessTokenRequest)
      .then(
        (accessTokenResponse) => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          const {username} = accounts[0];
          this.account = accounts[0];
          useUserStore.getState().actions.user.login(username, undefined, accessToken);
        }
      )
      .catch((error) => {
        //Acquire token silent failure, and send an interactive request
        console.log(error);
        if (error instanceof InteractionRequiredAuthError) {
          this.msalInstance.acquireTokenRedirect(accessTokenRequest);
        }
      });
    }
    else {
      this.msalInstance.acquireTokenRedirect(accessTokenRequest);
    }

    // const silentRequest = {
    //   scopes: ["User.Read"],
    // };

    // try {
    //     this.loginResponse = await this.msalInstance.ssoSilent(silentRequest);
    //     useUserStore.getState().actions.user.login(this.loginResponse.account.username, undefined, this.loginResponse.accessToken);
    //   } catch (err) {
    //     // Clear connection flag into localStore
    //     useUserStore.getState().actions.user.clearConnection();

    //     if (err instanceof InteractionRequiredAuthError) {
    //       console.log('msal4')

    //       await this.msalInstance
    //         .loginRedirect(silentRequest)
    //         .catch((error) => {
    //             // handle error
    //             console.error(error)
    //         });
    //     } else {
    //       console.error(err)
    //       // handle error
    //     }
    // }
  }

  async searchUser(query ?: string) {
    if(query && this.msalInstance && this.account) {
      const authProvider = new AuthCodeMSALBrowserAuthenticationProvider(this.msalInstance, {
        account: this.account,
        interactionType: InteractionType.Popup,
        scopes: ['User.Read'],
      });

      const graphClient = Client.initWithMiddleware({ authProvider: authProvider });

      const request = graphClient.api('/users')
        .header('ConsistencyLevel', 'eventual')
        .search(`"displayName:${query}"`)
        .orderby('displayName')
        .top(5)

      const users : {value : MSALUser[]} = await request.get();

      return users.value;
    }
    else {
      return null;
    }
  }
}

export const msal = new MSAL();


export type MSALUser = {
  displayName : string;
  userPrincipalName : string;
  mail : string | null;
  givenName : string;
  surname ?: string;
}