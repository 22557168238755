import { colors } from "../../styles";
import IconProps from "./icon-interface";

const ListViewIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.primary;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 35 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.58008 2.33984H28.1372" stroke={color} strokeWidth="2.7" strokeLinecap="round"/>
    <circle cx="2.24647" cy="2.34022" r="1.52381" fill={color}/>
    <path d="M7.58008 7.95898H32.7229" stroke={color} strokeWidth="2.7" strokeLinecap="round"/>
    <circle cx="2.24647" cy="7.95936" r="1.52381" fill={color}/>
    <path d="M7.58008 13.5781H23.7135" stroke={color} strokeWidth="2.7" strokeLinecap="round"/>
    <circle cx="2.24647" cy="13.5785" r="1.52381" fill={color}/>
    </svg>
    
  )
}

export default ListViewIcon;
