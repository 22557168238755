export default {
  'cancel' : 'Annuler',
  'validate' : 'Valider',
  'logout' : 'Déconnexion',
  'delete' : 'Supprimer',


  'show-archived' : 'Afficher les éléments archivés',
  'hide-archived' : 'Cacher les éléments archivés',

  'navigation-blocker' : 'Les modifications que vous avez apportées ne seront peut-être pas enregistrées. Quitter cette page?',

  roles : {
    superadmin : 'SuperAdmin',
    admin : 'Admin',
    supervisor : 'Superviseur',
    user : 'Contributeur'
  },

  errors : {
    continue : 'Continuer',
    unableToLogin : "Email ou password erronés",
    unknown :{
      title : 'Erreur',
      content : ['Une erreur inconue est survenue pendant le traitement de votre page.', 'Vérifiez que vous êtes bien connectés à Internet et réessayez.']
    },
    markets : {
      deleteConflict : {
        content : ['Impossible de supprimer le marché.', `Veuillez vérifier qu’aucune ressource n’est associé à ce marché et essayez à nouveau.`]
      }
    },
    users : {
      create : {
        title : 'Erreur',
        content : ['Une erreur est survenue lors de l\'habilitation de l\'utilisateur.', 'Veuillez vérifier que l\'utilisateur ne soit pas habilité dans aucune entité Elior.']
      }
    }
  },
  pages : {
    home : {
      loading : 'Chargement...',
      welcome : 'Bienvenue',
      welcomeText : 'Connectez vous à votre compte Agami en utilisant les identifiants de votre compte Elior',
      loginButton : 'Se connecter'
    },
    profile : {
      name : "Espace personnel",
      subtitle : "Modifiez vos informations personnelles et consultez vos différents clients.",
      email : "Adresse mail",
      role : "Rôle",
      customersTitle : "Clients",
      customersSubtitle : "Retrouvez vos différents clients et leurs informations principales.",
      modalTitle : "Profil utilisateur",
      modalSubtitle : "Veuillez compléter votre profil pour accéder à Agami.",
      firstNamePlaceholder : "Entrer le prénom",
      lastNamePlaceholder : "Entrer le nom"
    },
    /*
    Admin pages
    */
    users : {
      name : `Licences`,
      subtitle : 'Créer ou modifier un profil d’utilisateur.',
      firstName : 'prénom',
      lastName : 'nom',
      email : 'mail',
      marketId : 'marché',
      regionsIds : 'Périmètres',
      createNew : 'Créer une licence',
      modalTitle : 'Nouvel utilisateur',
      modalSubtitle : 'Ajoutez un utilisateur sur la plateforme Agami, attribuez lui un rôle et un marché.',
      updateModalTitle : 'Paramètres du compte utilisateur',
      updateModalSubtitle : 'Modifiez le rôle, marché et perimètre du compte utilisateur.',
      emailPlaceholder : 'Email du collaborateur',
      rolePlaceholder : 'Rôle',
      marketPlaceholder : 'Marché',
      regionPlaceholder : 'Périmètres',
      deleteDialogContent : `
        <p>Vous êtes sur le point de supprimer l'utilisateur <i>{{ name }}</i>.</p>
        <p><strong>Voulez-vous continuer ?</strong></p>
      `,
      validateCreationButton : `Créer l'utilisateur`,
      searchPlaceholder : 'Rechercher',
      noUserFound : 'Aucun collaborateur trouvé'
    },
    markets : {
      name : 'Marchés',
      subtitle : "Administrer et consulter vos marchés.",
      columns : {
        name : 'Marché'
      },
      createNew : 'Créer un marché',
      modalTitle : 'Marché',
      modalSubtitle : 'Veuillez saisir le nom du marché.',
      namePlaceholder : 'Nom du marché',
      deleteDialogContent : `
        <p>Vous êtes sur le point d'archiver le marché <i>{{ name }}</i>.</p>
        <p><strong>Voulez-vous continuer ?</strong></p>
      `,
      all : 'Tous les marchés'
    },
    regions : {
      name : 'Périmètres',
      subtitle : "Administrer et consulter vos périmètres.",
      columns : {
        name : 'Périmètre'
      },
      createNew : 'Créer un périmètre',
      modalTitle : 'Périmètre',
      modalSubtitle : 'Veuillez saisir le nom du périmètre.',
      namePlaceholder : 'Nom du périmètre',
      deleteDialogContent : `
        <p>Vous êtes sur le point d'archiver le périmètre <i>{{ name }}</i>.</p>
        <p><strong>Voulez-vous continuer ?</strong></p>
      `,
      all : 'Tous les périmètres'
    },


    library : {
      name : "Bibliothèque",
      subtitle : "Administrer et consulter le contenu disponible pour vos offres commerciales.",
      'notebook-offres' : 'Cahiers offre',
      'new-notebook-offres' : 'Créer un nouveau cahier offre',
      'new-notebook-annexes' : 'Créer un nouveau cahier annexe',
      'new-notebook-generiques' : 'Créer un nouveau cahier générique',
      'new-notebook-videos' : 'Créer une nouvelle vidéo',
      'notebook-annexes' : 'Cahiers Annexes',
      'notebook-generiques' : 'Cahiers génériques',
      'videos' : 'Vidéos',
      'privateVideos' : 'Mes vidéos',
      'private-attachments' : 'Mes cahiers génériques',

      archive : 'Archiver',
      unarchive : 'Publier',
      "show-all" : 'Afficher les archives',
      "hide-archived" : 'Cacher les cahiers archivés'
    },
    'new-notebook' : {
      namePlaceholder : 'Entrer le nom du cahier',
      subtitle : 'Choisir le nom du cahier ainsi qu’un marché.',
      'breadcrumb-offers' : 'Nouveau cahier offre',
      'breadcrumb-annexes' : 'Nouveau cahier annexe',
      'breadcrumb-generic' : 'Nouveau cahier générique',

      'add-content-cta' : 'Ajouter du contenu'
    },
    filters : {
      name : "Filtres",
      subtitle : "Administrer et consulter les filtres.",
      createNew : "Créer un filtre",
      modalTitle : "Nouveau filtre",
      modalSubtitle : "Créez un nouveau filtre, définissez son contenu et liez le à un marché.",
      questionPlaceholder : "Entrer la question qui sera posée au contributeur",
      namePlaceholder : "Titre du filtre",
      newValuePlaceholder : "Ajouter une réponse",
      marketIdLabel : "Marché.s d’action du filtre (plusieurs choix possible)",
      validate : "Sauvegarder le filtre",
      deleteDialogContent : `
        <p>Vous êtes sur le point d'archiver le filtre <i>{{ name }}</i>.</p>
        <p><strong>Voulez-vous continuer ?</strong></p>
      `,
      expandMarketLabel : 'Marchés concernés'
    },
    dashboard : {
      name : "Dashboard",
      subtitle : "Suivre les propositions commerciales de votre périmètre.",
      title : "Bienvenue sur Agami !",
      frameTitle : 'Suivre les propositions commerciales de votre périmètre',
      createdProposals : "Propositions créées ",
      markets : "Marchés",
      regions : "Périmètres",
      users : "Contributeurs",
      proposals : "Propositions commerciales",
      "three-months" : 'Les derniers 3 mois',
      "six-months" : 'Les derniers 6 mois',
      "one-year" : 'Les derniers 12 mois'
    },
    notebook : {
      breadcrumb1 : "Nouveau template de cahier",
      breadcrumb2 : "Organisation du cahier",
      subtitle : "Importer du contenu et hiérarchiser les slides.",

      mandatory : 'Obligatoire',

      saveDraft : "Enregistrer", // en tant que brouillon
      save : "Enregistrer",
      next : "Suivant",
      updateDone : "Terminer",
      slideContextMenu : {
        insertBefore : 'Insérer une slide au-dessus',
        insertAfter : 'Insérer une slide en-dessous',
        linkSlides : 'Lier les slides',
        unlinkSlides : 'Séparer les slides',
        alternatives : 'Créer des slides alternatives',
        compulsory : 'Rendre obligatoire',
        notCompulsory : 'Rendre non obligatoire',
        summary : 'Insérer le sommaire en-dessous',
        insertLeft : 'Insérer une slide à gauche',
        insertRight : 'Insérer une slide à droite',
      },
      tagsModalTitle : "Edition des tags",
      tagsModalSubtitle : "Éditer les tags permettant au contributeur de filtrer le contenu à ajouter à son offre commerciale.",
      tagsModalValidate : "Enregistrer les tags",

      summary : "Sommaire",
      summaryLabel : "Généré en fin d’édition de l’offre",
      erreurDeChargement : "Oups! Le chargement n'a pas fonctionné."
    },
    'tags-review' : {
      breadcrumb1 : "Nouveau template de cahier",
      breadcrumb2 : "Organisation du cahier",
      breadcrumb3 : "Édition des tags",
      subtitle : "Éditer les tags de chaque slide afin de permettre de filtrer le contenu lors de la création d’offre.",
      frameSubtitle : 'Éditer les tags permettant au contributeur de filtrer le contenu à ajouter à son offre commerciale.',
      frameTitle : 'Édition des tags',
      frameTitleMultiple : '{{number}} slides sélectionnées',
      next : 'Slide suivante',
      confirmationMessage : 'Vous êtes sur le point de publier un cahier d’offre.<br/><br/><strong>Êtes vous sûr.e</strong> ?',
      publishButton : 'Publier'
    },
    offers : {
      name : 'Offres',
      subtitle : 'Retrouver ici les offres commerciales.',
      searchPlaceholder : 'Rechercher',
      createNew : 'Créer une offre',
      published : 'Offres publiées',
      drafts : 'Brouillons ({{count}})'
    },
    'new-offer' : {
      breadcrumb1 : 'Offres commerciales',
      breadcrumb2 : 'Nouvelle offre',
      subtitle : 'Choisir le nom de l\'offre ainsi qu’un marché.',
      create : 'Ajouter des cahiers',
      namePlaceholder : 'Entrer le nom de l’offre'
    },
    'offer' : {
      subtitle : 'Choisir le contenu que vous souhaitez attribuer à cette nouvelle offre commerciale.'
    },
    'offer-review' : {
      publish : 'Publier',
      offerNotebook : 'Cahier offre',
      genericNotebooks_one : 'Cahier générique',
      genericNotebooks_other : 'Cahiers génériques',
      annexesNotebooks_one : 'Cahier annexe',
      annexesNotebooks_other : 'Cahiers annexes',
      videos_one : 'Video annexe',
      videos_other : 'Videos annexes',
      offerName : 'Offre'
    },
    proposals : {
      name : 'Propositions commerciales',
      name_user : 'Mes propositions commerciales',
      subtitle : 'Retrouver ici vos propositions commerciales.',
      createNew : 'Créer une proposition',
      published : 'Propositions envoyées',
      drafts : 'Brouillons',
      fields : {
        name : 'nom de la proposition',
        customers : 'Client',
        creator : 'Contributeur',
        createdAt : 'date de création',
        sentAt : 'date de remise',
        validUntil : 'fin de validité',
        market : 'marché',
        regionsIds : 'Périmètres'
      },
      deleteDialogContent: `
        <p>Vous êtes sur le point de supprimer la proposition <i>{{ name }}</i>.</p>
        <p><strong>Voulez-vous continuer ?</strong></p>
      `,
      searchPlaceholder : 'Rechercher',
    },
    'new-proposal' : {
      breadcrumb1 : 'Nouvelle proposition commerciale',
      subtitle : 'Choisir le nom de votre prochaine offre ainsi que les différents collaborateurs intégrés.',
      createNew : 'Créer une proposition',
      namePlaceholder : 'Entrer le nom de la proposition'
    },
    proposal : {
      breadcrumb1 : 'Nouvelle proposition commerciale',
      subtitle_offer : 'Choisir le type d’offre que vous souhaitez utiliser.',
      subtitle_edit : 'Éditer le contenu de votre proposition en personnalisant vos cahiers et en ajoutant un cahier personnalisé (facultatif).',
      subtitle_view : 'Contenu de votre proposition commerciale.',
      continue : 'Valider l\'offre',
      share : 'Partager l\'offre',
      editOfferLabel : `Éditer l'offre`,
      validFrom : 'Offre valide du',
      validUntil : 'au',
      customer : 'Client',
      regions : 'Périmètre.s.',
      regionsPlaceholder : 'Choisir',
      addCustomer : 'Ajouter un client',
      addLogo : 'Ajouter un logo',
      validUntilTitle : 'Déterminez la fin de validité de votre proposition commerciale',
      validUntilSubtitle : 'Durée par défaut : 3 mois',
      saveDraft : 'Enregistrer en tant que brouillon',
      updatedAt : 'Dernière édition le {{date}}',
      notUpdated : 'Pas encore d\'édition',
      validateDialogButton : 'Valider mon offre',
      confirmationMessage : `
        <p>Vous êtes sur le point de verrouiller votre offre.<br/>Une fois verrouillée, vous ne pourrez plus la modifier.</p>
        <p><strong>Êtes vous sûr(e) ? </strong></p>
      `,
      customContent : 'Contenu personnalisé',
      shareDialogTitle : 'Ajouter des collaborateurs pour co-créer cette offre.',
      shareDialogPlaceholder : 'Nom...',
      offerNotebookName : 'Offre'
    },
    'proposal-notebook' : {
      breadcrumb1 : 'Nouvelle proposition commerciale',
      subtitle_filters : 'Répondez à ces quelques questions avant de composer votre proposition commerciale.',
      subtitle_edit : 'Choisir le contenu de votre proposition commerciale.',
      continue : 'Suivant',
      filterBy : 'Filtrer par :',
      totalSlides : 'Nombre de slides : {{ selected }} sur {{ total }}',
      showAll : "Afficher les slides masquées",
      saveDraft : "Enregistrer en tant que brouillon",
      slideContextMenu : {
        insertText : "Encart personnalisé",
        insertSlideAfter : "Insérer une slide après"
      },
      mandatory : 'Obligatoire',
      showOthers_one : '+{{count}} Slide masquée',
      showOthers_other : '+{{count}} Slides masquées',
      hideOthers_one : 'Masquer la slide',
      hideOthers_other : 'Masquer les slides',
      generate : 'Générer le cahier',
      solveError : 'Corriger l\'erreur',
      errors : {
        'empty' : `
          Aucune slide n’a été sélectionnée.<br/>
          Veuillez sélectionner au moins une slide.
        `,
        'missing-text': `
          Veuillez saisir le texte de la slide "<strong>{{label}}</strong>".
        `,
        'unknown' : `
          Une erreur de validation inconnue est apparue.
        `
      },
    },
    public : {
      title : 'Votre proposition commerciale Elior',
      subtitle : 'Vous trouverez ci-dessous le contenu de votre offre commerciale proposée par Elior.',
      validUntil : 'Date de fin de validité de la proposition : ',
      download : 'Télécharger la proposition commerciale'
    },
    summaryPdf : {
      title : 'SOMMAIRE'
    },
    overlaySettings : {
      name : 'Réglages PDF',
      subtitle : 'Réglages pour la création des fichiers PDF',
      save : 'Enregistrer'
    }
  },
  modals : {
    cancel : 'Annuler',
    validate : 'Valider',
    textArea : {
      title : "Encart personnalisable",
      subtitle : "Indiquer dans quelle zone le contributeur pourra ajouter du contenu personnalisable.",
      saveButton : "Enregistrer l’encart personnalisable",
      fontPlaceholder : "Typographie"
    },
    attachment : {
      title : 'Cahier générique',
      subtitle : 'Choisir le titre du cahier génériqué.',
      namePlaceholder : 'Nom du cahier',
      filePlaceholder : 'Choisir le fichier'
    },
    video : {
      title : 'Video',
      subtitle : 'Choisir le titre et le fichier vidéo.',
      namePlaceholder : 'Nom de la vidéo',
      filePlaceholder : 'Choisir le fichier'
    },
    ungroupDialog : {
      content : `
      Une fois le lien rompu, vos concepts ne seront plus rattaché à la rubrique <i>{{name}}</i>.<br/><br/>
      Les liens des slides liées et alternatives seront perdus.<br/><br/>
      <strong>Êtes vous sûr(e) ?</strong>
      `
    },
    customText : {
      title : "Encart personnalisable",
      subtitle : "Complétez le contenu de votre encart personnalisé.",
      placeholder : "Entrer votre texte ici",
      save : "Sauvegarder le texte"
    },
    replaceSlide : {
      title : 'Importer une slide',
      subtitle : 'Choisir une slide figurant dans un cahier de la bibliothèque ou depuis votre ordinateur',
      searchPlaceholder : 'Rechercher',
      validate : 'Remplacer',
      insert   : 'Insérer',
      loadFromDisk : 'Importer depuis mon ordinateur'
    },
    notebookPreview : {
      subtitle : 'Consultez librement le contenu de ce cahier.'
    },
    share : {
      title : 'Partager l\'offre',
      content : '<p>Votre offre a été validée.</p><p>Cliquer ci-dessous pour partager ou télécharger votre offre.</p>'
    },
    customer : {
      title : 'Ajouter un client',
      subtitle : 'Indiquez quel est le client concerné par cette proposition commerciale.',
      namePlaceholder : 'Entrer le nom du client',
      emailPlaceholder : 'Adresse email'
    },
    addContent : {
      title : 'Contenu personnalisé',
      subtitle : 'Choisir le contenu personnalisé à rajouter à la proposition commerciale.',
      validate : 'Selectionner'
    },
    publishVideoDialog : {
      content : `
      <p>Vous êtes sur le point de changer le statut de votre vidéo <i>{{ name }}</i>.</p>
      <p><strong>Rendre la vidéo publique ?</strong></p>
    `
    },
    publishAttachmentDialog : {
      content : `
      <p>Vous êtes sur le point de changer le statut de votre cahier <i>{{ name }}</i>.</p>
      <p><strong>Rendre le cahier publique ?</strong></p>
    `
    },
    shareVideoModal : {
      title : 'Partager votre contenu personnalisé',
      subtitle : 'Renseigner le destinataire.',
      placeholder : 'Rechercher',
      validate : 'Partager',
    },
    alternativesModal : {
      title : 'Slides alternatives - {{name}}',
      subtitle : 'Visionner ou modifier les slides alternatives.',
      multichoice : 'Choix multiples'
    },
    proposalAlternativesModal : {
      title : 'Slides alternatives - {{name}}',
      subtitle : 'Choisir la slide adaptée à votre proposition commerciale.'
    }
  },
  components : {
    'offer-card' : {
      createdBy : 'Créé par',
      createdDate : 'le',
      createdProposals : 'Propositions créées',
      annexes : 'Cahier.s Annexe.s',
      generics : 'Cahier.s Générique.s',
      deleteDialog : `
        <p>Vous êtes sur le point d'archiver l'offre <i>{{ name }}</i>.</p>
        <p><strong>Voulez-vous continuer ?</strong></p>
      `
    },
    datePicker : {
      months : ['Janvier', 'Février', 'Mars', 'Avril', 'Mai', 'Juin', 'Juillet', 'Août', 'Septembre', 'Octobre', 'Novembre', 'Décembre']
    }
  },

  share : {
    subject : 'Votre proposition commerciale',
    body : `
Bonjour,

voici le lien vers la proposition commerciale Elior :

{{ url }}

Bien cordialement,
    `,
  }
};
