import { colors } from "../../styles";
import IconProps from "./icon-interface";

const PlusInCircleIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.secondaryText;

  return (
  <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="9.51758" cy="9.46094" r="8.6" stroke={color} stroke-width="0.8"/>
    <path d="M9.51562 4.66016L9.51562 14.2656" stroke={color} stroke-width="0.8" stroke-linecap="round"/>
    <path d="M14.3203 9.44141L4.71484 9.44141" stroke={color} stroke-width="0.8" stroke-linecap="round"/>
  </svg>
  )
}

export default PlusInCircleIcon;

