import { colors } from "../../styles";
import IconProps from "./icon-interface";

const UngroupIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.whiteText;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 28 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M27.5548 2.48446C27.4227 1.7401 26.7428 1.16991 25.9011 1.09754C18.2659 0.439069 10.2936 0.441454 2.20595 1.10469C1.37628 1.17309 0.713191 1.70511 0.554625 2.43038C0.111763 4.46066 -0.0323877 6.58478 0.12778 8.74548C0.150203 9.05244 0.421686 9.28148 0.727606 9.26C1.03673 9.23774 1.26817 8.97053 1.24575 8.66436C1.09359 6.61022 1.22973 4.59188 1.64937 2.66657C1.70062 2.43118 1.97291 2.24191 2.29724 2.21487C10.3224 1.5564 18.2315 1.55401 25.8034 2.20691C26.1349 2.23554 26.4064 2.43356 26.4505 2.6777C27.0151 5.85792 27.0215 8.89101 26.4697 11.6927C26.4232 11.9305 26.1518 12.1237 25.825 12.1515C21.442 12.5301 16.9982 12.6987 12.6144 12.6518H12.608C12.3013 12.6518 12.0506 12.8967 12.0474 13.2021C12.0442 13.5098 12.2925 13.7611 12.6016 13.7651C13.0837 13.7699 13.5666 13.7731 14.0503 13.7731C17.9984 13.7731 21.985 13.6013 25.9219 13.2609C26.7492 13.1893 27.4275 12.6327 27.57 11.9066C28.1482 8.97053 28.1434 5.79986 27.5548 2.48446Z" fill={color}/>
      <path d="M9.58356 16.7949H3.73746C2.52259 16.7949 1.53516 17.7763 1.53516 18.9819V21.2086C1.53516 22.415 2.52339 23.3955 3.73746 23.3955H9.58356C10.7984 23.3955 11.7859 22.4142 11.7859 21.2086V18.9819C11.7859 17.7755 10.7976 16.7949 9.58356 16.7949ZM10.6647 21.2086C10.6647 21.801 10.1794 22.2822 9.58356 22.2822H3.73746C3.14083 22.2822 2.65633 21.8002 2.65633 21.2086V18.9819C2.65633 18.3894 3.14163 17.9083 3.73746 17.9083H9.58356C10.1802 17.9083 10.6647 18.3902 10.6647 18.9819V21.2086Z" fill={color}/>
      <path d="M9.58356 9.63672H3.73746C2.52259 9.63672 1.53516 10.6181 1.53516 11.8237V14.0504C1.53516 15.2568 2.52339 16.2373 3.73746 16.2373H9.58356C10.7984 16.2373 11.7859 15.256 11.7859 14.0504V11.8237C11.7859 10.6173 10.7976 9.63672 9.58356 9.63672ZM10.6647 14.0504C10.6647 14.6428 10.1794 15.124 9.58356 15.124H3.73746C3.14083 15.124 2.65633 14.642 2.65633 14.0504V11.8237C2.65633 11.2312 3.14163 10.7501 3.73746 10.7501H9.58356C10.1802 10.7501 10.6647 11.232 10.6647 11.8237V14.0504Z" fill={color}/>
      <path d="M9.58356 2.47949H3.73746C2.52259 2.47949 1.53516 3.46083 1.53516 4.66644V6.89314C1.53516 8.09954 2.52339 9.08008 3.73746 9.08008H9.58356C10.7984 9.08008 11.7859 8.09874 11.7859 6.89314V4.66644C11.7859 3.46004 10.7976 2.47949 9.58356 2.47949ZM10.6647 6.89314C10.6647 7.4856 10.1794 7.96673 9.58356 7.96673H3.73746C3.14083 7.96673 2.65633 7.48481 2.65633 6.89314V4.66644C2.65633 4.07397 3.14163 3.59285 3.73746 3.59285H9.58356C10.1802 3.59285 10.6647 4.07477 10.6647 4.66644V6.89314Z" fill={color}/>
      <path d="M24.1672 14.2493C23.9013 14.091 23.557 14.1769 23.3984 14.4402C21.3258 17.8701 19.4783 18.7814 15.2787 19.4057L16.9084 17.3603C16.9517 17.307 16.9837 17.2458 17.0013 17.1806C17.0189 17.1154 17.023 17.0478 17.0117 16.9818C17.0005 16.9158 16.9757 16.853 16.9381 16.7973C16.9004 16.7416 16.8508 16.6931 16.7931 16.6565C16.673 16.5802 16.5248 16.55 16.3807 16.5738C16.2365 16.5969 16.106 16.6716 16.0163 16.783L13.5233 19.9123C13.4801 19.9664 13.4488 20.0276 13.4304 20.0928C13.4128 20.1572 13.4096 20.2248 13.42 20.29C13.4312 20.356 13.456 20.4189 13.4937 20.4745C13.5313 20.5302 13.5802 20.5787 13.6386 20.6153L17.0029 22.7935C17.1231 22.8698 17.2712 22.9 17.4154 22.8762C17.5595 22.8531 17.69 22.7784 17.7797 22.667C17.823 22.6138 17.8542 22.5525 17.8726 22.4873C17.8903 22.4221 17.8943 22.3545 17.883 22.2885C17.8718 22.2225 17.847 22.1597 17.8094 22.104C17.7717 22.0483 17.7221 21.9998 17.6644 21.9632L15.4205 20.5095C19.761 19.8646 22.041 18.8467 24.3586 15.0119C24.518 14.7479 24.4323 14.406 24.1664 14.2485L24.1672 14.2493Z" fill={color}/>
    </svg>
  )
}

export default UngroupIcon;

