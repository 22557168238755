import { useTranslation } from "react-i18next";
import Modal from "../modal";
import { colors, shadows, spacing, typography } from "../../../styles";
import styled from "styled-components";
import { useModelStore, useUserStore } from "../../../store/store";
import { useState } from "react";
import TextButton from "../../atoms/text-button";
import { ID, User } from "../../../store/store-types";
import Select from "../../atoms/select";
import { ADMIN, ROLES, SUPERADMIN, USER } from "../../../constants/roles";
import NewUserIcon from "../../../assets/icons/new-user";
import { useModelActions } from "../../../store/hooks/use-actions";
import { MSALUser, msal } from "../../../providers/msal";
import useDebounce from "../../../hooks/use-debounce";
import Typography from "../../atoms/typography";

interface UsersModalProps {
  onClose : () => void;
  user ?: User;
  onChange : () => void;
}

const UsersModal : React.FC<UsersModalProps> = (p) => {
  const {t} = useTranslation();
  const currentUser = useUserStore(state => state.user);
  const loading = useModelStore(state => state.process.loading['users.create'] || state.process.loading['users.update']);
  const {create, update} = useModelActions('users');
  const markets = useModelStore(state => state.markets.filter(({inactive}) => !inactive));
  const regions = useModelStore(state => state.regions.filter(({inactive}) => !inactive));
  const [editedQuery, setEditedQuery] = useState<string | undefined>(p.user ? `${p.user?.firstName || ''} ${p.user?.lastName || ''} <${p.user?.email}>` : undefined);
  const [editedRole, setEditedRole] = useState<string | undefined>(p.user?.role);
  const [editedMarkets, setEditedMarkets] = useState<ID[] | undefined>(p.user?.marketsIds);
  const [editedRegions, setEditedRegions] = useState<ID[] | undefined>(p.user?.regionsIds);
  const [users, setUsers] = useState<MSALUser[] | null>(null);
  const [selectedUser, setSelectedUser] = useState<MSALUser | null>(null);

  const {debounce} = useDebounce(async (value ?: string) => {
    setUsers(await msal.searchUser(value));
  }, 400)

  const handleSubmit = async () => {
    if((selectedUser || p.user) && editedRole) {
      if(p.user) {
        await update(p.user.id, {
          role : editedRole,
          marketsIds : editedMarkets,
          regionsIds : editedRegions
        })
      }
      else if(selectedUser) {
        await create({
          email : selectedUser.mail || selectedUser.userPrincipalName,
          alternativeEmail : selectedUser.userPrincipalName || selectedUser.mail || "",
          role : editedRole,
          marketsIds : editedMarkets,
          regionsIds : editedRegions,
          firstName : selectedUser.givenName  || selectedUser.displayName,
          lastName : selectedUser.surname || ' '
        });
      }

      p.onClose();
      p.onChange();
    }
  }

  const availableRoles = currentUser.role === SUPERADMIN ? ROLES : [USER, ADMIN];

  const handleChangeQuery = (query : string) => {
    setEditedQuery(query);
    setSelectedUser(null);
    if(!p.user) {
      debounce(query);
    }
  }

  const handleUserVeilClick = () => {
    setEditedQuery('');
    setUsers(null);
    setSelectedUser(null);
  }

  const handleSelectUser = (user : MSALUser) => {
    setSelectedUser(user);
    setEditedQuery(`${user.displayName} <${user.mail || user.userPrincipalName}>`);
    setUsers(null);
  }

  return (
    <Modal
      title={p.user ? t('pages.users.updateModalTitle') : t('pages.users.modalTitle')}
      subtitle={p.user ? t('pages.users.updateModalSubtitle') : t('pages.users.modalSubtitle')}
      icon={<NewUserIcon color={colors.text}/>}
      onClose={p.onClose}
      controls={[
        <TextButton key="validate" disabled={loading || !editedRole || !(selectedUser || p.user)} onClick={handleSubmit}>{p.user ? t('validate') : t('pages.users.validateCreationButton')}</TextButton>
      ]}
    >
      {
        Array.isArray(users) ? <UsersVeil onClick={handleUserVeilClick}/> : null
      }
      <InputsContainer>
        <EmailInput
          disabled={!!p.user}
          placeholder={t('pages.users.emailPlaceholder')}
          value={editedQuery}
          onChange={e => handleChangeQuery(e.target.value)}
        />
        <Select<string>
          color="accent"
          choices={availableRoles.map(role => ({label : t(`roles.${role}`), value : role}))}
          placeholder={t('pages.users.rolePlaceholder')}
          value={editedRole}
          onChange={(value) => setEditedRole(value)}
        />
        {
          users ?
            <SelectionDropdown>
              {
                users.length === 0 ?
                  <NoUserFoundContainer>
                    <Typography variant="textBold">{t('pages.users.noUserFound')}</Typography>
                  </NoUserFoundContainer> :
                users.map(user => (
                  <SelectionItem key={user.userPrincipalName} onClick={() => handleSelectUser(user)}>
                    <Typography variant="textBold">{user.displayName}</Typography> <Typography variant="textRegular">&lt;{user.mail || user.userPrincipalName}&gt;</Typography>
                  </SelectionItem>
                ))
              }
            </SelectionDropdown> : null
        }
      </InputsContainer>
      <DropDownContainer>
        <Select<ID>
          choices={markets.map(market => ({label : market.name, value : market.id}))}
          placeholder={t('pages.users.marketPlaceholder')}
          value={editedMarkets}
          onChange={value => setEditedMarkets(value)}
          multiselect
        />
        <RegionsSelect<ID>
          choices={regions.map(region => ({label : region.name, value : region.id}))}
          placeholder={t('pages.users.regionPlaceholder')}
          value={editedRegions}
          onChange={value => setEditedRegions(value)}
          multiselect
        />
      </DropDownContainer>
    </Modal>
  );
}

export default UsersModal;

const InputsContainer = styled.div`
  min-width : 50rem;
  margin-top: ${2*spacing}rem;
  background-color: ${colors.background};
  border-radius: ${spacing}rem;
  padding: ${spacing}rem;
  display: flex;
  flex-direction: row;
  position : relative;
`

const EmailInput = styled.input`
  flex : 1;
  z-index: 10;
  color : ${colors.text};
  &::placeholder {
    color : ${colors.tertiaryText};
  }
  ${typography.textBold.css};
  border : none;
  background-color: transparent;
  outline: none;
`

const DropDownContainer = styled.div`
  margin-top: ${spacing}rem;
  display: flex;
`

const RegionsSelect = styled(Select)`
  margin-left: ${spacing}rem;
`

const SelectionDropdown = styled.div`
  position : absolute;
  border-radius : 1.5rem;
  box-shadow: ${shadows.heavy};
  background-color: ${colors.background2};
  padding : ${spacing}rem;
  padding-left : 0;
  padding-right : 0;
  z-index: 10;
  bottom: 0;
  transform: translateY(100%);
`

const SelectionItem = styled.div`
  padding : 0.7rem;
  padding-left : ${spacing}rem;
  padding-right : ${spacing}rem;
  cursor : pointer;

  &:hover {
    background-color: ${colors.primary};
  }

  &:hover span{
    color: ${colors.whiteText};
  }
`

const NoUserFoundContainer = styled.div`
  padding : 0.7rem;
  padding-left : ${spacing}rem;
  padding-right : ${spacing}rem;
`

const UsersVeil = styled.div`
  position: absolute;
  width : 100%;
  height : 100%;
  top: 0;
  left: 0;
  z-index: 10;
`