const Logo : React.FC<{width ?: string, height ?: string}> = (p) => {
  const width = p.width || "15.1rem";
  const height = p.height || "2.9rem";


  return (
    <svg width={width} height={height} viewBox="0 0 151 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_657_324)">
      <path d="M12.9613 0.259521C10.6357 0.259521 6.40845 0.340037 1.79093 1.33191L2.55927 7.60652C2.56534 7.60524 2.57142 7.60353 2.57749 7.60224C8.126 6.30929 11.7415 6.56411 11.7415 6.56411C13.5245 6.56411 14.9696 7.88876 14.9696 9.52261V11.2528H10.5212C10.0623 11.2528 9.61034 11.2712 9.16681 11.3076C6.62717 11.5171 4.37122 12.3252 2.73312 13.7295C1.03474 15.1848 0 17.2799 0 20.0118C0 25.8411 4.71053 28.7709 10.5212 28.7709C10.9339 28.7709 13.7778 28.7709 16.3787 28.3987C19.0085 28.0222 21.4215 27.4848 23.4825 26.7409V9.01854C23.4825 3.18933 18.772 0.259521 12.9613 0.259521ZM10.9465 16.4675H14.9696V22.9078C14.9696 22.9078 13.1735 23.5566 10.9465 23.5566C8.71955 23.5566 7.99794 22.1001 7.99794 20.0118C7.99794 17.9236 8.71955 16.4675 10.9465 16.4675Z" fill="black"/>
      <path d="M68.4314 0.259521C66.1058 0.259521 61.8785 0.340037 57.261 1.33191L58.0294 7.60652C58.0354 7.60524 58.0415 7.60353 58.0476 7.60224C63.5961 6.30929 67.2116 6.56411 67.2116 6.56411C68.9946 6.56411 70.4397 7.88876 70.4397 9.52261V11.2528H65.9913C65.5324 11.2528 65.0804 11.2712 64.6369 11.3076C62.0973 11.5171 59.8413 12.3252 58.2032 13.7295C56.5048 15.1848 55.4701 17.2799 55.4701 20.0118C55.4701 25.8411 60.1806 28.7709 65.9913 28.7709C66.404 28.7709 69.2479 28.7709 71.8487 28.3987C74.4786 28.0222 76.8916 27.4848 78.9526 26.7409V9.01854C78.9526 3.18933 74.2421 0.259521 68.4314 0.259521ZM66.4166 16.4675H70.4397V22.9078C70.4397 22.9078 68.6436 23.5566 66.4166 23.5566C64.1896 23.5566 63.468 22.1001 63.468 20.0118C63.468 17.9236 64.1896 16.4675 66.4166 16.4675Z" fill="black"/>
      <path d="M51.2176 12.3256V26.7408C50.9722 26.8295 46.973 28.3447 41.074 28.6929C40.3052 28.7383 38.0291 28.8612 36.6495 28.5327C31.573 27.3233 27.735 21.4988 27.735 14.5154C27.735 7.53196 31.552 1.6308 36.6495 0.497596C40.35 -0.324686 43.3131 -0.182499 50.4492 1.33187L49.8716 6.04714L49.6804 7.60648C49.6243 7.59192 44.8465 6.38805 40.4155 6.56492C37.9043 6.66514 35.7148 8.3915 35.7148 14.5154C35.7148 20.6392 38.4703 22.4662 40.4987 22.4662C41.2806 22.4662 42.4205 22.438 43.7267 22.3412V17.4666H40.2V12.3256H51.2176Z" fill="black"/>
      <path d="M113.754 0.259521C112.355 0.259521 110.129 0.288644 107.683 0.52762C106.146 0.677943 104.607 0.910923 102.868 1.27196C101.29 0.597856 99.4441 0.259521 97.4713 0.259521C94.5363 0.259521 91.8527 0.461666 89.7599 0.733618C85.4644 1.29166 83.2051 2.14392 83.2051 2.14392V28.7537H91.7181V7.72473C91.7181 7.72473 93.0403 6.56454 96.252 6.56454C98.035 6.56454 99.48 7.88918 99.48 9.52304V28.7542H107.993V7.64036C108.286 7.37354 109.429 6.56454 112.534 6.56454C114.317 6.56454 115.762 7.88918 115.762 9.52304V28.7542H124.275V9.01854C124.275 3.18933 119.565 0.259521 113.754 0.259521Z" fill="black"/>
      <path d="M137.041 0.528076H128.528V28.7538H137.041V0.528076Z" fill="black"/>
      <path d="M146.146 29.0004C148.827 29.0004 151 27.0092 151 24.5528C151 22.0965 148.827 20.1052 146.146 20.1052C143.466 20.1052 141.293 22.0965 141.293 24.5528C141.293 27.0092 143.466 29.0004 146.146 29.0004Z" fill="#FF5C38"/>
      </g>
      <defs>
      <clipPath id="clip0_657_324">
      <rect width="151" height="29" fill="white"/>
      </clipPath>
      </defs>
    </svg>
  );
};

export default Logo;

