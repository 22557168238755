import { useRef, useState } from 'react';
import styled from 'styled-components';
import { useDrag, useDrop } from 'react-dnd'

import {colors, shadows, spacing, transitions} from '../../styles';
import { DynamicSlideViewMeta, PageSlide } from '../../store/store-types';
import { useModelActions } from '../../store/hooks/use-actions';
import { useModelStore } from '../../store/store';
import configProvider from '../../providers/configprovider';
import ToolButton from '../atoms/tool-button';
import TrashIcon from '../../assets/icons/trash';
import ChoicesIcon from '../../assets/icons/choices';
import EditableSlideLabel from '../atoms/editable-slide-label';
import { SlideDragItem } from '../../types';
import useContextMenu from '../../hooks/use-context-menu';
import { useTranslation } from 'react-i18next';
import Typography from '../atoms/typography';
import ConvergeIcon from '../../assets/icons/converge';

interface ContentLevelAlternativeSlideCardProps {
  index : number;
  slide : PageSlide;
  view ?: DynamicSlideViewMeta;
  readonly ?: boolean;
  onShowAlternativesModal : (slide : PageSlide) => void;
}

const ContentLevelAlternativeSlideCard : React.FC<ContentLevelAlternativeSlideCardProps> = (p) => {
  const {t} = useTranslation();
  const imgUrl = `${configProvider('PDF_SERVICE_URL')}/pages/${p.slide.pageId}/thumbnail`;
  const label = p.slide.label || ''; //`Page ${p.index+1}`;

  const {setMandatory, save, select, addToSelection, addRangeToSelection, deselect, appendContentSlidesAfterAlternatives, moveSlidesAfterAlternatives, ungroupAlternatives, removeAllAlternativeSlides, addUploadAfterAlternatives, addUpload} = useModelActions('notebookEditor');
  const {start} = useModelActions('uploads');

  const selectionLength = useModelStore(state => Object.values(state.notebooks.editor.selection || {}).filter((v : boolean)=>!!v).length);
  const multiSelection  = selectionLength > 1;
  const selected        = useModelStore(state => state.notebooks.editor.selection[p.slide.pageId]);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const fileInputBeforeRef = useRef<HTMLInputElement>(null);
  const fileInputAfterRef = useRef<HTMLInputElement>(null);

  const selectionOngoing = useRef<boolean>(false);

  /*
    Handle Drag and drop
  */
  const [dragCollectedProps, drag, dragPreview] = useDrag<SlideDragItem>(() => ({
    type : 'slide',
    item: { id : p.slide.pageId, index : p.index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  const [appendDropCollectedProps, appendDropZone] = useDrop<SlideDragItem, unknown, {isOver : boolean}>({
    accept : 'slide',
    drop : () => appendContentSlidesAfterAlternatives({position : p.index}),
    canDrop : (item) => item.id !== p.slide.pageId && !selected,
    collect : (monitor) => ({
      isOver : monitor.canDrop() && monitor.isOver()
    })
  }, [selected, p.index])

  const [moveDropCollectedProps, moveDropZone] = useDrop<SlideDragItem, unknown, {isOver : boolean}>({
    accept : 'slide',
    drop : () => moveSlidesAfterAlternatives({position : p.index}),
    canDrop : (item) => item.id !== p.slide.pageId && !selected,
    collect : (monitor) => ({
      isOver : monitor.canDrop() && monitor.isOver()
    })
  }, [selected, p.index])


  /*
    Context menu callbacks
   */
  const contextMenu = useContextMenu({
    options : [
      {label : t('pages.notebook.slideContextMenu.insertLeft'), callback : () => fileInputBeforeRef.current?.click(), disabled : multiSelection},
      {label : t('pages.notebook.slideContextMenu.insertRight'), callback : () => fileInputAfterRef.current?.click(), disabled : multiSelection},
      {label : t(!p.slide.mandatory ? 'pages.notebook.slideContextMenu.compulsory' : 'pages.notebook.slideContextMenu.notCompulsory'), callback : () => setMandatory(!p.slide.mandatory)}
    ],
  });

  const handleUngroupAlternative = () => {
    if(p.slide.alternativeGroupId) {
      ungroupAlternatives(p.slide.alternativeGroupId)
    }
  }

  const handleRemove = () => {
    removeAllAlternativeSlides({position : p.index});
  }

  const handleSelectFileBefore = async(file : File) => {
    const uploadId = await start(file);
    addUpload({position : p.index}, uploadId, p.slide.level);
    save();
  }

  const handleSelectFileAfter = async(file : File) => {
    const uploadId = await start(file);
    addUploadAfterAlternatives({position : p.index}, uploadId, p.slide.level);
    save();
  }

  const handleSelectMouseDown = (e : React.MouseEvent<HTMLDivElement>) => {
    if(!selected) {
      selectionOngoing.current = true
      if(e.ctrlKey || e.metaKey) {
        addToSelection(p.slide.pageId);
      }
      else if (e.shiftKey) {
        addRangeToSelection(p.slide.pageId);
      }
      else {
        select(p.slide.pageId);
      }
    }
  }

  const handleSelectClick = (e : React.MouseEvent<HTMLDivElement>) => {
    if(selectionOngoing.current) {
      selectionOngoing.current = false;
    }
    else {
      if(e.ctrlKey || e.metaKey) {
        deselect(p.slide.pageId)
      }
      else if(multiSelection) {
        select(p.slide.pageId);
      }
      else {
        deselect(p.slide.pageId)
      }
    }
  }

  return (
    <CardContainer>
      <FileInput
        ref={fileInputBeforeRef}
        type="file"
        accept="application/pdf"
        onChange={e => e.target.files?.[0] && handleSelectFileBefore(e.target.files?.[0])}
        />
      <FileInput
        ref={fileInputAfterRef}
        type="file"
        accept="application/pdf"
        onChange={e => e.target.files?.[0] && handleSelectFileAfter(e.target.files?.[0])}
        />
      <PageCard
        ref={appendDropZone}
        className={[selected ? 'selected' : '', appendDropCollectedProps.isOver ? 'dnd-over' : '', p.slide.mandatory ? 'mandatory' : ''].join(' ')}
        {...contextMenu}
      >
        <div style={{position :'relative'}}>
        <PageThumbnail
          ref={drag}
          src={imgUrl}
          onMouseDown={handleSelectMouseDown}
          onClick={handleSelectClick}
        />
        {
          !p.slide.textArea ? null :
          <TextAreaPreview
            style={{
              left : `${p.slide.textArea.x}%`,
              top : `${p.slide.textArea.y}%`,
              width : `${p.slide.textArea.width}%`,
              height : `${p.slide.textArea.height}%`,
            }}
          />
        }

        </div>
        <AlternativeButton onClick={() => p.onShowAlternativesModal(p.slide)}>
          <Typography variant='buttonBold' color="whiteText">{p.view?.alternativesCount}</Typography>
          &nbsp;&nbsp;&nbsp;
          <ChoicesIcon width='1.7rem' height="1.7rem" color={colors.whiteText}/>
        </AlternativeButton>
        <Label>
          <EditableSlideLabel disabled initialValue={label}/>
        </Label>
        {
          !p.slide.mandatory ? null :
          <MandatoryContainer>
            <Typography variant="smallTextItalic" color="primary">{t('pages.notebook.mandatory')}</Typography>
          </MandatoryContainer>
        }
      </PageCard>
      <ToolBox>
        <StyledToolButton disabled={!!p.readonly} onClick={handleRemove}><TrashIcon color={colors.primary}/></StyledToolButton>
        <StyledToolButton disabled={!!p.readonly || !p.slide.alternativeGroupId} onClick={handleUngroupAlternative}>
          <ConvergeIcon />
        </StyledToolButton>
      </ToolBox>
      <MoveDropZone
        ref={moveDropZone}
      >
        <MoveDropIndicator
          className={moveDropCollectedProps.isOver ? 'dnd-over' : ''}
        />
      </MoveDropZone>
    </CardContainer>
  );
};

export default ContentLevelAlternativeSlideCard;


const CardContainer = styled.div`
  display: inline-block;
  position :relative;
  transition : transform ${transitions.fast}, max-height ${transitions.slow}, margin-top ${transitions.fast}, margin-bottom ${transitions.fast};

  &.hidden {
    max-height : 0rem;
    margin-top : 0rem;
    margin-bottom : 0rem;
  }
`

const PageCard = styled.div`
  width : 21rem;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  border-radius: 1.5rem;
  padding : 0.3rem;
  padding-bottom : 0;
  border : 0.3rem solid ${colors.background2};
  background-color: ${colors.background2};
  z-index : 0;
  box-shadow: ${shadows.default};

  &.selected {
    border : 0.3rem solid ${colors.primary} !important;
  }

  &.dnd-over {
    border : 0.3rem solid ${colors.accent} !important;
  }

  &.mandatory {
    background-color: ${colors.lightPrimary};
    border : 0.3rem solid ${colors.lightPrimary};
  }
  &.mandatory textarea {
    background-color: ${colors.lightPrimary};
  }
`

const MandatoryContainer = styled.div`
  margin-left : 1rem;
  margin-top : -0.8rem;
  margin-bottom : 1rem;
`


const PageThumbnail = styled.img`
  max-width: 20rem;
  border-radius: 1rem;
`

const Label = styled.div`
  margin-top : 1rem;
  margin-bottom : 1rem;
  padding-left : 1rem;
  padding-right : 1rem;
`

const ToolBox = styled.div`
  background-color: ${colors.lightPrimary};
  border-bottom-left-radius: ${spacing}rem;
  border-bottom-right-radius: ${spacing}rem;
  padding-top : 2rem;
  padding-left : 1rem;
  margin-top : -1rem;
  width : fit-content;
`


const StyledToolButton = styled(ToolButton)`
  margin-right: 1rem;
  margin-bottom : 1rem;
`

const ReplaceSlideButton = styled(ToolButton)`
  position : absolute;
  top : 0.8rem;
  right : 0.8rem;
`

const FileInput = styled.input`
  position : absolute;
  visibility: hidden;
`

const TextAreaPreview = styled.div`
  position : absolute;
  border : 0.2rem dashed ${colors.primary};
  pointer-events: none;
`

const MoveDropZone = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -1.5rem;
  height: 100%;
  width: 2rem;
  z-index: 1;
`

const MoveDropIndicator = styled.div`
  height: 14rem;
  width: 0.7rem;
  margin-top: 1rem;
  border-radius: 0.7rem;
  background-color: ${colors.accent};
  display: none;

  &.dnd-over {
    display: block;
  }
`

const AlternativeButton = styled.div`
  position: absolute;
  left : 50%;
  top  : 50%;
  display: flex;
  align-items: center;
  transform: translateX(-50%) translateY(-100%);
  background-color: ${colors.accent};
  padding : 0.5rem ${spacing}rem;
  border-radius: ${spacing}rem;
  cursor : pointer;
`