import styled from 'styled-components';
import { useAssertAdmin } from '../hooks/use-assert-admin';
import PageLayout from '../components/layout/page-layout';
import { useTranslation } from 'react-i18next';
import Typography from '../components/atoms/typography';
import { useModelStore } from '../store/store';
import { Market } from '../store/store-types';
import List from '../components/atoms/list';
import TextButton from '../components/atoms/text-button';
import TrashIcon from '../assets/icons/trash';
import { colors } from '../styles';
import EditIcon from '../assets/icons/edit';
import MarketModal from '../components/molecules/modals/market-modal';
import { useState } from 'react';
import { useModelActions } from '../store/hooks/use-actions';
import HTMLTypography from '../components/atoms/html-typography';
import UnarchiveIcon from '../assets/icons/unarchive';
import ArchiveIcon from '../assets/icons/archive';

interface MarketsPageProps {

}

const MarketsPage : React.FC<MarketsPageProps> = (p) => {
  useAssertAdmin();
  const {t} = useTranslation();
  const _markets = useModelStore(state => state.markets);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [editedItem, setEditedItem] = useState<Market | undefined>();
  const {archive, unarchive} = useModelActions('markets');
  const {showDialog} = useModelActions('modals');
  const [showArchived, setShowArchived] = useState<boolean>(false);

  const markets = showArchived ? _markets : _markets.filter(({inactive}) => !inactive);

  const handleShowCreate = () => {
    setModalVisible(true)
  }
  const handleShowEdit = (market : Market) => {
    setModalVisible(true)
    setEditedItem(market);
  }
  const handleHideModal = () => {
    setModalVisible(false);
    setEditedItem(undefined);
  }

  const handleDelete = (item : Market) => {
    if(item.inactive) {
      unarchive(item.id);
    }
    else {
      showDialog({
        content :<HTMLTypography variant="textRegular" color="text" html={t('pages.markets.deleteDialogContent', {name : item.name})}/>,
        callback : () => archive(item.id)
      })
    }
  }

  return (
    <PageLayout
      title={t('pages.markets.name')}
      subtitle={t('pages.markets.subtitle')}
      controls={<TextButton onClick={handleShowCreate} color="dark">{t('pages.markets.createNew')}</TextButton>}
      modal={
        modalVisible ? <MarketModal onClose={handleHideModal} market={editedItem}/> : null
      }
    >
      <Container>
        <div style={{textAlign : 'right'}}>
          <Typography variant="smallTextBold" color="primary" onClick={() => setShowArchived(s => !s)} style={{cursor : 'pointer'}}>
              {!showArchived ? t('show-archived') : t('hide-archived')}
          </Typography>
        </div>

        <List<Market>
          records={markets}
          renderer={(value) => (
            <Typography variant="textBold" color="text">{value.name}</Typography>
          )}
          controls={[
            {icon : (value) => value.inactive ? <UnarchiveIcon color={colors.accent}/> : <ArchiveIcon color={colors.primary}/>, callback : handleDelete, color : 'light'},
            {icon : <EditIcon color={colors.primary}/>, callback : handleShowEdit, color : 'light'}
          ]}
        />
      </Container>
    </PageLayout>
  )
}

export default MarketsPage;

const Container = styled.div`
`
