import axios from "axios";


import { GetFunction, SetFunction, ActionsFactory, ModelStore, Market, NotebookType, ID, NotebookTemplate, NotebookTemplateContent, Page } from "../store-types";
import configProvider from "../../providers/configprovider";
import { setLoading } from "../helpers/set-loading";
import { setError } from "../helpers/set-error";


const uploadActions : ActionsFactory<ModelStore['actions']['uploads'], ModelStore> =  (set : SetFunction<ModelStore>, get : GetFunction<ModelStore>) => ({
  load : async (uploadId : ID) => {
    const loadingId = `uploads.load.${uploadId}`;

    setLoading(set, loadingId, true);

    try {
      const pagesResponse = await axios.get(`${configProvider('PDF_SERVICE_URL')}/pages?uploadId=${uploadId}&pageSize=1000`);
      const taskResponse = await axios.get(`${configProvider('PDF_SERVICE_URL')}/tasks?uploadId=${uploadId}`);

      if(pagesResponse.status === 200 && taskResponse.status === 200) {
        const pages : Page[] = pagesResponse.data.data || [];
        const done = taskResponse.data.data?.[0]?.status === 'done';
        const error = taskResponse.data.data?.[0]?.status === 'error';

        set(state => {
          state.uploads[uploadId] = {
            id : uploadId,
            status : done ? 'done' : 'running',
            error : error,
            pages : pages.sort((a, b) => a.page < b.page ? -1 : 1)
          }
        })

        setLoading(set, uploadId, false);
        return;
      }
    }
    catch(error) {
      console.error(error)
    }

    setError(set, uploadId, 'errors.uploads.load')
    throw 'errors.uploads.load';

  },

  start : async (file : File) => {
    setLoading(set, 'uploads.start', true);

    try {
      const formData = new FormData();
      formData.append("file", file);

      // Upload file into PDF service
      const uploadResponse = await axios.post(`${configProvider('PDF_SERVICE_URL')}/uploads`, formData);
      if(uploadResponse.status === 200) {
        const uploadId = uploadResponse.data.id;

        setLoading(set, 'uploads.start', false);
        return uploadId;
      }
    }
    catch(error) {}

    setError(set, 'uploads.start', 'errors.uploads.start')
    throw 'errors.uploads.start';
  }
})

export default uploadActions;


