import axios from "axios";


import { GetFunction, SetFunction, ActionsFactory, ModelStore, Market } from "../store-types";
import configProvider from "../../providers/configprovider";
import { setLoading } from "../helpers/set-loading";
import { setError } from "../helpers/set-error";


const marketsActions : ActionsFactory<ModelStore['actions']['markets'], ModelStore> =  (set : SetFunction<ModelStore>, get : GetFunction<ModelStore>) => ({
  load : async () => {
    setLoading(set, 'markets.load', true);

    try {
      const marketsResponse = await axios.get(`${configProvider('MODEL_API_URL')}/markets?pageSize=1000`);

      if(marketsResponse.status === 200) {
        const {data} : {data : Market[]} = marketsResponse.data || {};

        set(state => {
          state.markets = (data || []).map(market => ({name : market.name, id : market.id, inactive : market.inactive}));
        })
      }

      setLoading(set, 'markets.load', false);

      return;
    }
    catch(error) {}

    setError(set, 'markets.load', 'errors.markets.load')
  },

  create : async (name : string) => {
    setLoading(set, 'markets.create', true);

    try {
      const marketsResponse = await axios.post(`${configProvider('MODEL_API_URL')}/markets`, {name});

      if(marketsResponse.status === 200) {
        const newMarket : Market = marketsResponse.data;

        set(state => {
          state.markets.push(newMarket);
        })
        setLoading(set, 'markets.create', false);

        return newMarket;
      }
    }
    catch(error) {}

    setError(set, 'markets.create', 'errors.markets.create')
    throw 'errors.markets.create'
  },

  archive : async (marketId : number) => {
    setLoading(set, 'markets.archive', true);

    try {
      const marketsResponse = await axios.patch(`${configProvider('MODEL_API_URL')}/markets/${marketId}`, {inactive : true});

      if(marketsResponse.status === 200) {
        set(state => {
          state.markets = state.markets.map(market => market.id !== marketId ? market : {...market, inactive : true});
        })

        setLoading(set, 'markets.archive', false);

        return;
      }

      if(marketsResponse.status === 409) {
        setError(set, 'markets.archive', 'markets.deleteConflict')
        return;
      }
    }
    catch(error) {}

    setError(set, 'markets.archive', 'markets.archive')
    throw 'markets.archive'
  },

  unarchive : async (marketId : number) => {
    setLoading(set, 'markets.unarchive', true);

    try {
      const marketsResponse = await axios.patch(`${configProvider('MODEL_API_URL')}/markets/${marketId}`, {inactive : false});

      if(marketsResponse.status === 200) {
        set(state => {
          state.markets = state.markets.map(market => market.id !== marketId ? market : {...market, inactive : false});
        })

        setLoading(set, 'markets.unarchive', false);

        return;
      }
    }
    catch(error) {}

    setError(set, 'markets.unarchive', 'markets.unarchive')
    throw 'markets.unarchive'
  },

  rename : async (marketId : number, name : string) => {
    setLoading(set, 'markets.rename', true);

    try {
      const marketsResponse = await axios.patch(`${configProvider('MODEL_API_URL')}/markets/${marketId}`, {name});

      if(marketsResponse.status === 200) {
        set(state => {
          const market = state.markets.find(market => market.id === marketId);
          if(market) {
            market.name = name;
          }
        })

        setLoading(set, 'markets.rename', false);

        return;
      }

      if(marketsResponse.status === 409) {
        setError(set, 'markets.rename', 'markets.rename')
        return;
      }
    }
    catch(error) {}

    setError(set, 'markets.rename', 'markets.rename')
    throw 'markets.rename'
  }
})

export default marketsActions;