import styled from "styled-components";
import Typography from "./typography";
import { useModelStore } from "../../store/store";
import { ID, Offer, User } from "../../store/store-types";
import DocumentsIcon from "../../assets/icons/documents";
import { colors, shadows, spacing } from "../../styles";
import DocumentIcon from "../../assets/icons/document";
import ClosedFoodDome from "../../assets/images/closed-food-dome";
import ToolButton from "./tool-button";
import EditIcon from "../../assets/icons/edit";
import CopyIcon from "../../assets/icons/copy";
import TrashIcon from "../../assets/icons/trash";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { OFFER, OFFER_REVIEW } from "../../constants/routes";
import configProvider from "../../providers/configprovider";
import { useModelActions } from "../../store/hooks/use-actions";
import useDialog from "../../hooks/use-dialog";
import HTMLTypography from "./html-typography";
import EyeIcon from "../../assets/icons/eye";
import UnarchiveIcon from "../../assets/icons/unarchive";
import ArchiveIcon from "../../assets/icons/archive";

interface OfferCardProps {
  offer : Offer;
  user ?: User;
  onRefresh : () => void;
  noActions ?: boolean;
  selected ?: boolean;
  onSelectionChange ?: (value : boolean) => void
}

const OfferCard : React.FC<OfferCardProps> = (p) => {
  const {t} = useTranslation();
  const navigate = useNavigate();

  const {create, delete : deleteOffer, archive, unarchive} = useModelActions('offers');

  const market = useModelStore(state => state.markets.find(market => market.id === p.offer.marketId));

  const notebookTemplates = useModelStore(state => state.notebooks.templates);
  const attachments = useModelStore(state => state.attachments);

  const offerNotebook = Object.values(notebookTemplates).find(notebook => notebook.type === 'offers' && notebook.draft === false && p.offer.notebooksIds.includes(notebook.id));
  const annexesNotebooks = Object.values(notebookTemplates).filter(notebook => notebook.type === 'annexes' && notebook.draft === false && p.offer.notebooksIds.includes(notebook.id));
  const genericNotebooks = Object.values(attachments).filter(attachment => p.offer.attachmentsIds.includes(attachment.id));

  const showDialog = useDialog({
    content : <HTMLTypography variant="textRegular" color="text" html={t('components.offer-card.deleteDialog', {name : p.offer.name})}/>,
    validateText : t('delete')
  })

  const userName = p.user ?
    (p.user.firstName ? `${p.user.firstName[0].toUpperCase()}. ` : '') + (p.user.lastName || '') :
    '-'

  const handleCopy = async () => {
    const newOffer = await create({
      name : p.offer.name + '*',
      marketId : p.offer.marketId,
      notebooks : p.offer.notebooks,
      thumbnailPageId : p.offer.thumbnailPageId
    })
    p.onRefresh();
    navigate(OFFER.replace(':id', `${newOffer.id}`))
  }

  const handleDelete = () => {
    showDialog(async () => {
      await deleteOffer(p.offer.id);
      p.onRefresh();
    })
  }

  const handleArchive = async () => {
    await archive(p.offer.id);
    p.onRefresh();
  }

  const handleUnarchive = async () => {
    await unarchive(p.offer.id);
    p.onRefresh();
  }

  return (
    <Container>
      <Card className={p.selected ? 'selected' : ''} onClick={() => p.onSelectionChange?.(!p.selected)}>
        <Title>
          <div>
            <Typography variant="mediumTitle" color="text">{p.offer.name}</Typography>
            <Typography variant="mediumTitle" color="secondaryText">&nbsp;&nbsp;&nbsp;|&nbsp;&nbsp;&nbsp;</Typography>
            <Typography variant="mediumTitle" color="text">{p.offer.notebooksIds.length + p.offer.attachmentsIds.length} <IconContainer><DocumentIcon color={colors.secondaryText}/></IconContainer></Typography>
          </div>
          <Typography variant="uppercaseTextRegular" color="secondaryText">{market?.name || ''}</Typography>
        </Title>
        <Content>
          <ThumbnailColumn>
            {
              p.offer.thumbnailPageId ?
                <div><Thumbnail src={`${configProvider('PDF_SERVICE_URL')}/pages/${p.offer.thumbnailPageId}/thumbnail`}/></div> :
                <PlaceholderContainer><ClosedFoodDome /></PlaceholderContainer>
            }
            <Typography variant="smallTextBold" color="secondaryText">{offerNotebook?.name || ''}</Typography>
          </ThumbnailColumn>
          <NotebooksColumn>
            {
              annexesNotebooks.length ? <Typography variant="smallTextBold" color="primary">{t('components.offer-card.annexes')}</Typography> : null
            }
            <ul>
              {
                annexesNotebooks.map(notebook => (
                  <li key={notebook.id}><Typography variant="smallTextRegular" color="secondaryText">{notebook.name}</Typography></li>
                ))
              }
            </ul>
          </NotebooksColumn>
          <NotebooksColumn>
            {
              genericNotebooks.length ? <Typography variant="smallTextBold" color="primary">{t('components.offer-card.generics')}</Typography> : null
            }
            <ul>
              {
                genericNotebooks.map(attachment => (
                  <li key={attachment.id}><Typography variant="smallTextRegular" color="secondaryText">{attachment.name}</Typography></li>
                ))
              }
            </ul>
          </NotebooksColumn>
        </Content>

      </Card>
      <Toolbar className={p.noActions ? 'hidden' : ''}>
        <BottomInfoContainer>
          <BottomInfo>
            <BottomLabel>
              <Typography variant="smallTextItalic" color="primary">{t('components.offer-card.createdBy')}</Typography>
            </BottomLabel>
            <BottomContent>
              <Typography variant="smallTextRegular" color="text">{userName}</Typography>
            </BottomContent>
          </BottomInfo>
          <BottomInfo>
            <BottomLabel>
              <Typography variant="smallTextItalic" color="primary">{t('components.offer-card.createdDate')}</Typography>
            </BottomLabel>
            <BottomContent>
              <Typography variant="smallTextRegular" color="text">{new Date(p.offer.createdAt).toLocaleDateString('fr')}</Typography>
            </BottomContent>
          </BottomInfo>
          <BottomInfo>
            <BottomLabel>
              <Typography variant="smallTextItalic" color="primary">{t('components.offer-card.createdProposals')}</Typography>
            </BottomLabel>
            <BottomContent>
              <Typography variant="smallTextRegular" color="text">{p.offer.proposalsCount}</Typography>
            </BottomContent>
          </BottomInfo>
        </BottomInfoContainer>
        <div>
          {
            p.offer.draft ?
              <ToolButton onClick={() => navigate(OFFER.replace(':id', `${p.offer.id}`))}><EditIcon color={colors.primary}/></ToolButton> :
              <ToolButton onClick={() => navigate(OFFER_REVIEW.replace(':id', `${p.offer.id}`))}><EyeIcon color={colors.primary}/></ToolButton>
          }
          <ToolButton onClick={handleCopy}><CopyIcon color={colors.primary}/></ToolButton>
          {
            !p.offer.proposalsCount ?
              <ToolButton onClick={handleDelete} ><TrashIcon color={colors.primary}/></ToolButton> :
            p.offer.inactive ?
              <ToolButton onClick={handleUnarchive} ><UnarchiveIcon color={colors.accent}/></ToolButton> :
              <ToolButton onClick={handleArchive} ><ArchiveIcon color={colors.primary}/></ToolButton>
          }
        </div>
      </Toolbar>
    </Container>
  )
}

export default OfferCard;

const Container = styled.div`
  width : 51rem;
  margin-right : ${2*spacing}rem;
  margin-top : ${2*spacing}rem;

  /* &:nth-child(2n) {
    margin-right : 0rem;
  } */
`

const Card = styled.div`
  background-color: ${colors.background2};
  border-radius: 1.5rem;
  box-shadow: ${shadows.default};
  padding : ${2*spacing}rem;
  z-index: 1;
  position: relative;
  border : 0.3rem solid ${colors.background2};

  &.selected {
    border : 0.3rem solid ${colors.primary};
  }
`

const Title = styled.div`
  display: flex;
  justify-content: space-between;
`

const Content = styled.div`
  display: flex;
  margin-top : ${spacing}rem;
`

const ThumbnailColumn = styled.div`

`
const NotebooksColumn = styled.div`
  margin-left : ${2*spacing}rem;

  ul {
    padding-inline-start: 1rem;
  }

  li {
    padding-left : 0.5rem;
  }

  li::marker {
    color : ${colors.secondaryText};
    content : "●";
    font-size : 0.6rem;
  }
`

const Thumbnail = styled.img`
  width : 14.4rem;
  height : 8.1rem;
  border-radius : 0.5rem;
  margin-bottom : 0.5rem;
`

const IconContainer = styled.div`
  position: relative;
  display: inline-block;
  height: 2rem;
  top: 0.4rem;
`

const PlaceholderContainer = styled.div`
  width : 14.4rem;
  height : 8.1rem;
  background-color: ${colors.lightPrimary};
  border-radius : 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  padding : 4.5rem;
  margin-bottom : 0.5rem;
`

const Toolbar = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${colors.lightPrimary};
  border-bottom-left-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
  padding : ${spacing}rem;
  padding-top : ${2*spacing}rem;
  top : -${spacing}rem;
  z-index: 0;
  position: relative;

  & button {
    margin-left : ${spacing}rem;
  }

  &.hidden {
    display: none;
  }
`

const BottomInfoContainer = styled.div`
  display: flex;
  align-items: center;
`

const BottomInfo = styled.div`
  margin-left : ${spacing}rem;
`
const BottomLabel = styled.div`

`
const BottomContent = styled.div``