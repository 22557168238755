import { useRef, useState } from 'react';
import styled from 'styled-components';
import { useDrag, useDrop } from 'react-dnd'

import {colors, shadows, spacing, transitions} from '../../styles';
import { DynamicSlideViewMeta, PageSlide, SlideIndex } from '../../store/store-types';
import { useModelActions } from '../../store/hooks/use-actions';
import { useModelStore } from '../../store/store';
import configProvider from '../../providers/configprovider';
import ToolButton from '../atoms/tool-button';
import TextIcon from '../../assets/icons/text';
import TrashIcon from '../../assets/icons/trash';
import ChoicesIcon from '../../assets/icons/choices';
import TagsIcon from '../../assets/icons/tags';
import EditableSlideLabel from '../atoms/editable-slide-label';
import ReplaceSlideIcon from '../../assets/icons/replace-slide';
import { ProposalSlideDragItem, SlideDragItem } from '../../types';
import useContextMenu from '../../hooks/use-context-menu';
import { useTranslation } from 'react-i18next';
import Typography from '../atoms/typography';
import Checkbox from '../atoms/checkbox';

interface ProposalContentLevelAlternativeSlideCardProps {
  index : number;
  slide : PageSlide;
  view ?: DynamicSlideViewMeta;
  readonly ?: boolean;
  onShowAlternativesModal : (slide : PageSlide) => void;
  onInsertSlide ?: (slideIndex : SlideIndex | false) => void;
}

const ProposalContentLevelAlternativeSlideCard : React.FC<ProposalContentLevelAlternativeSlideCardProps> = (p) => {
  const {t} = useTranslation();
  const [modifiedLabel, setModifiedLabel] = useState<string>(p.slide.label);

  const {setLabel, moveSlides} = useModelActions('proposalEditor');
  const {start} = useModelActions('uploads');

  const selected = useModelStore(state => {
    if(!p.slide.alternativeGroupId) {
      return false;
    }

    const selection = state.actions.proposalEditor.current().selection || {};
    const alternatives = (state.actions.proposalEditor.current().slides || []).filter(slide => slide.alternativeGroupId === p.slide.alternativeGroupId);

    return alternatives.some(slide => slide.type === 'page' && selection[slide.pageId])
  })

  const firstSelected : PageSlide = useModelStore(state => {
    if(!p.slide.alternativeGroupId) {
      return p.slide;
    }

    const selection = state.actions.proposalEditor.current().selection || {};
    const alternatives = (state.actions.proposalEditor.current().slides || []).filter(slide => slide.alternativeGroupId === p.slide.alternativeGroupId);

    return alternatives.find((slide) : slide is PageSlide => slide.type === 'page' && selection[slide.pageId]) || p.slide
  });

  const imgUrl = `${configProvider('PDF_SERVICE_URL')}/pages/${firstSelected.pageId}/thumbnail`;
  const label = firstSelected.label || '';// `Page ${p.index+1}`;

  const fileInputRef = useRef<HTMLInputElement>(null);

  /*
    Handle Drag and drop
  */
    const [dragCollectedProps, drag, dragPreview] = useDrag<ProposalSlideDragItem>(() => ({
      type : 'slide',
      item: { id : p.slide.pageId, index : p.index, level : p.slide.level },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }), [selected, p.index])

    const [appendDropCollectedProps, appendDropZone] = useDrop<ProposalSlideDragItem, unknown, {isOver : boolean}>({
      accept : 'slide',
      drop : (item) => moveSlides({position : item.index}, {position : p.index}, true),
      canDrop : (item) => item.id !== p.slide.pageId && p.slide.level === item.level,
      collect : (monitor) => ({
        isOver : monitor.canDrop() && monitor.isOver()
      })
    }, [selected, p.index])

    const [moveDropCollectedProps, moveDropZone] = useDrop<ProposalSlideDragItem, unknown, {isOver : boolean}>({
      accept : 'slide',
      drop : (item) => moveSlides({position : item.index}, {position : p.index}),
      canDrop : (item) => item.id !== p.slide.pageId && p.slide.level === item.level,
      collect : (monitor) => ({
        isOver : monitor.canDrop() && monitor.isOver()
      })
    }, [selected, p.index])
  

  /*
    Context menu callbacks
   */
    const contextMenu = useContextMenu({
      options : [
        ...(p.onInsertSlide ? [{label : t('pages.proposal-notebook.slideContextMenu.insertSlideAfter'), callback : () => p.onInsertSlide?.({position : p.index})}] : []),
      ],
    });
  

  const handleLabelBlur = () => {
    if(p.slide.label !== modifiedLabel) {
      setLabel({position : p.index}, modifiedLabel);
    }
  }

  // const handleRemove = () => {
  //   removeSlide({position : p.index});
  // }


  // const handleSelectFileReplacement = async(file : File) => {
  //   const uploadId = await start(file);
  //   replaceSlide({position : p.index}, uploadId);
  //   save();
  // }

  // const handleSelectFileBefore = async(file : File) => {
  //   const uploadId = await start(file);
  //   addUpload({position : p.index}, uploadId, p.slide.level);
  //   save();
  // }

  // const handleSelectFileAfter = async(file : File) => {
  //   const uploadId = await start(file);
  //   addUpload({position : p.index + 1}, uploadId, p.slide.level);
  //   save();
  // }


  const handleSelectMouseDown = () => {
    // if(!selected) {
    //   selectionOngoing.current = true
    //   if(e.ctrlKey || e.metaKey) {
    //     addToSelection(p.slide.pageId);
    //   }
    //   else if (e.shiftKey) {
    //     addRangeToSelection(p.slide.pageId);
    //   }
    //   else {
    //     select(p.slide.pageId);
    //   }
    // }
  }

  const handleSelectClick = () => {
    p.onShowAlternativesModal(p.slide);

    // toggleSelection(p.slide.pageId)

    // if(selectionOngoing.current) {
    //   selectionOngoing.current = false;
    // }
    // else {
    //   if(e.ctrlKey || e.metaKey) {
    //     deselect(p.slide.pageId)
    //   }
    //   else if(multiSelection) {
    //     select(p.slide.pageId);
    //   }
    //   else {
    //     deselect(p.slide.pageId)
    //   }
    // }
  }

  return (
    <CardContainer
      className={p.view?.hidden ? 'hidden' : 'visible'}
    >
      {/* <FileInput
        ref={fileInputBeforeRef}
        type="file"
        accept="application/pdf"
        onChange={e => e.target.files?.[0] && handleSelectFileBefore(e.target.files?.[0])}
        />
      <FileInput
        ref={fileInputAfterRef}
        type="file"
        accept="application/pdf"
        onChange={e => e.target.files?.[0] && handleSelectFileAfter(e.target.files?.[0])}
        /> */}
      <PageCard
        ref={appendDropZone}
        className={[selected ? 'selected' : '', p.slide.mandatory ? 'mandatory' : '', appendDropCollectedProps.isOver ? 'dnd-over' : ''].join(' ')}
        {...contextMenu}
      >
        <div style={{position :'relative'}}>
        <PageThumbnail
          ref={drag}
          src={imgUrl}
          onMouseDown={handleSelectMouseDown}
          onClick={handleSelectClick}
          draggable={false}
        />
        {
          !p.slide.textArea ? null :
          <TextAreaPreview
            style={{
              left : `${p.slide.textArea.x}%`,
              top : `${p.slide.textArea.y}%`,
              width : `${p.slide.textArea.width}%`,
              height : `${p.slide.textArea.height}%`,
            }}
          />
        }

        </div>
        <AlternativeButton onClick={() => p.onShowAlternativesModal(p.slide)}>
          <Typography variant='buttonBold' color="whiteText">{p.view?.alternativesCount}</Typography>
          &nbsp;&nbsp;&nbsp;
          <ChoicesIcon width='1.7rem' height="1.7rem" color={colors.whiteText}/>
        </AlternativeButton>
        <Label>
          <EditableSlideLabel initialValue={label} onChange={setModifiedLabel} onBlur={handleLabelBlur}/>
          <Checkbox value={!!selected} onChange={() => p.onShowAlternativesModal(p.slide) }/>
        </Label>
        {
          !p.slide.mandatory ? null :
          <MandatoryContainer>
            <Typography variant="smallTextItalic" color="primary">{t('pages.notebook.mandatory')}</Typography>
          </MandatoryContainer>
        }
      </PageCard>
      <MoveDropZone
        ref={moveDropZone}
      >
        <MoveDropIndicator
          className={moveDropCollectedProps.isOver ? 'dnd-over' : ''}
        />
      </MoveDropZone>
    </CardContainer>
  );
};

export default ProposalContentLevelAlternativeSlideCard;


const CardContainer = styled.div`
  display: inline-block;
  position :relative;
  transition : transform ${transitions.fast}, max-width ${transitions.slow}, margin-top ${transitions.fast}, margin-bottom ${transitions.fast};
  /* overflow: hidden; */

  &.hidden {
    display: none;
  }
`

const PageCard = styled.div`
  width : 21rem;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  border-radius: 1.5rem;
  padding : 0.3rem;
  padding-bottom : 0;
  border : 0.3rem solid ${colors.background2};
  background-color: ${colors.background2};
  z-index : 0;
  box-shadow: ${shadows.default};

  &.selected {
    border : 0.3rem solid ${colors.primary} !important;
  }

  &.dnd-over {
    border : 0.3rem solid ${colors.accent} !important;
  }

  &.mandatory {
    background-color: ${colors.lightPrimary};
    border : 0.3rem solid ${colors.lightPrimary};
  }
  &.mandatory textarea {
    background-color: ${colors.lightPrimary};
  }
`

const MandatoryContainer = styled.div`
  margin-left : 1rem;
  margin-top : -0.8rem;
  margin-bottom : 1rem;
`


const PageThumbnail = styled.img`
  max-width: 20rem;
  border-radius: 1rem;
`

const Label = styled.div`
  display: flex;
  margin-top : 1rem;
  margin-bottom : 1rem;
  padding-left : 1rem;
`






const TextAreaPreview = styled.div`
  position : absolute;
  border : 0.2rem dashed ${colors.primary};
  pointer-events: none;
`

const MoveDropZone = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -1.5rem;
  height: 100%;
  width: 2rem;
  z-index: 1;
`

const MoveDropIndicator = styled.div`
  height: 14rem;
  width: 0.7rem;
  margin-top: 1rem;
  border-radius: 0.7rem;
  background-color: ${colors.accent};
  display: none;

  &.dnd-over {
    display: block;
  }
`

const AlternativeButton = styled.div`
  position: absolute;
  left : 50%;
  top  : 50%;
  display: flex;
  align-items: center;
  transform: translateX(-50%) translateY(-100%);
  background-color: ${colors.accent};
  padding : 0.5rem ${spacing}rem;
  border-radius: ${spacing}rem;
  cursor : pointer;
`