import { useRef } from 'react';
import styled from 'styled-components';
import { useDrag, useDrop } from 'react-dnd'

import {colors, shadows, spacing, transitions} from '../../styles';
import { DynamicSlideViewMeta, PageSlide, SlideIndex } from '../../store/store-types';
import { useModelActions } from '../../store/hooks/use-actions';
import { useModelStore } from '../../store/store';
import ToolButton from '../atoms/tool-button';
import TrashIcon from '../../assets/icons/trash';
import IndentIcon from '../../assets/icons/indent';
import DeindentIcon from '../../assets/icons/deindent';
import { ProposalSlideDragItem, SlideDragItem } from '../../types';
import useContextMenu from '../../hooks/use-context-menu';
import { useTranslation } from 'react-i18next';
import Typography from '../atoms/typography';
import ClosedFoodDome from '../../assets/images/closed-food-dome';
import Checkbox from '../atoms/checkbox';

interface ProposalSummaryCardProps {
  index : number;
  slide : PageSlide;
  view ?: DynamicSlideViewMeta;
  readonly ?: boolean;
  onInsertSlide ?: (slideIndex : SlideIndex | false) => void;
}

const ProposalSummaryCard : React.FC<ProposalSummaryCardProps> = (p) => {
  const {t} = useTranslation();

  const {toggleSelection, moveSlides} = useModelActions('proposalEditor');

  const selected  = useModelStore(state => state.actions.proposalEditor.current().selection?.[p.slide.pageId]);


  /*
    Handle Drag and drop
  */
    const [dragCollectedProps, drag, dragPreview] = useDrag<ProposalSlideDragItem>(() => ({
      type : 'slide',
      item: { id : p.slide.pageId, index : p.index, level : p.slide.level },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }), [selected, p.index])

    const [appendDropCollectedProps, appendDropZone] = useDrop<ProposalSlideDragItem, unknown, {isOver : boolean}>({
      accept : 'slide',
      drop : (item) => moveSlides({position : item.index}, {position : p.index}, true),
      canDrop : (item) => item.id !== p.slide.pageId && p.slide.level === item.level,
      collect : (monitor) => ({
        isOver : monitor.canDrop() && monitor.isOver()
      })
    }, [selected, p.index])

    const [moveDropCollectedProps, moveDropZone] = useDrop<ProposalSlideDragItem, unknown, {isOver : boolean}>({
      accept : 'slide',
      drop : (item) => moveSlides({position : item.index}, {position : p.index}),
      canDrop : (item) => item.id !== p.slide.pageId && p.slide.level === item.level,
      collect : (monitor) => ({
        isOver : monitor.canDrop() && monitor.isOver()
      })
    }, [selected, p.index])


  /*
    Context menu callbacks
   */
    const contextMenu = useContextMenu({
      options : [
        ...(p.onInsertSlide ? [{label : t('pages.proposal-notebook.slideContextMenu.insertSlideAfter'), callback : () => p.onInsertSlide?.({position : p.index})}] : []),
      ],
    });

  // const handleRemove = () => {
  //   removeSlide({position : p.index});
  // }

  // const handleIndent = () => {
  //   indent({position : p.index});
  // }

  // const handleDeindent = () => {
  //   deindent({position : p.index});
  // }



  const handleSelectMouseDown = () => {
    // if(!selected) {
    //   selectionOngoing.current = true
    //   if(e.ctrlKey || e.metaKey) {
    //     addToSelection(p.slide.pageId);
    //   }
    //   else if (e.shiftKey) {
    //     addRangeToSelection(p.slide.pageId);
    //   }
    //   else {
    //     select(p.slide.pageId);
    //   }
    // }
  }

  const handleSelectClick = () => {
    toggleSelection(p.slide.pageId)
    // if(selectionOngoing.current) {
    //   selectionOngoing.current = false;
    // }
    // else {
    //   if(e.ctrlKey || e.metaKey) {
    //     deselect(p.slide.pageId)
    //   }
    //   else if(multiSelection) {
    //     select(p.slide.pageId);
    //   }
    //   else {
    //     deselect(p.slide.pageId)
    //   }
    // }
  }


  // const handleSelectFileBefore = async(file : File) => {
  //   const uploadId = await start(file);
  //   addUpload({position : p.index}, uploadId, p.slide.level);
  //   save();
  // }

  // const handleSelectFileAfter = async(file : File) => {
  //   const uploadId = await start(file);
  //   addUpload({position : p.index + 1}, uploadId, p.slide.level);
  //   save();
  // }


  return (
    <Row
    >
      {
        /*
         Vertical lines used to highlight links between slides
        */
      }
      <VerticalLine $level={1} $visible={!!p.view?.link1}/>
      <VerticalLine $level={2} $visible={!!p.view?.link2}/>
      <VerticalLine $level={3} $visible={!!p.view?.link3}/>
      <SmallVerticalLine $level={p.slide.level} $visible={p.index > 0 && !p.view?.hidden}/>
      {
        /*
         Actual card
        */
      }
      <CardContainer
        style={{transform : `translateX(${spacing * 2 * (p.slide.level - 1)}rem)`}}
        className={p.view?.hidden ? 'hidden' : 'visible'}
        >
        {/* <FileInput
          ref={fileInputBeforeRef}
          type="file"
          accept="application/pdf"
          onChange={e => e.target.files?.[0] && handleSelectFileBefore(e.target.files?.[0])}
          />
        <FileInput
          ref={fileInputAfterRef}
          type="file"
          accept="application/pdf"
          onChange={e => e.target.files?.[0] && handleSelectFileAfter(e.target.files?.[0])}
          /> */}
        <PageCard
          ref={appendDropZone}
          className={[selected ? 'selected' : '', appendDropCollectedProps.isOver ? 'dnd-over' : ''].join(' ')}
          {...contextMenu}
          >
          <div style={{position :'relative'}}>
            <ImageContainer
            ref={drag}
            onMouseDown={handleSelectMouseDown}
            onClick={handleSelectClick}
            >
              <ClosedFoodDome />
            </ImageContainer>
          </div>
          <Label>
            <Typography style={{flex : 1}} variant="textRegular" color="secondaryText">{t('pages.notebook.summary')}</Typography>
            <Checkbox value={!!selected} onChange={() => toggleSelection(p.slide.pageId) }/>
          </Label>
          <MandatoryContainer>
            <Typography variant="smallTextItalicUnderlined" color="secondaryText">{t('pages.notebook.summaryLabel')}</Typography>
          </MandatoryContainer>
        </PageCard>
      </CardContainer>
      <MoveDropZone
        ref={moveDropZone}
      >
        <MoveDropIndicator
          style={{transform : `translateX(${spacing * 2 * (p.slide.level - 1)}rem)`}}
          className={moveDropCollectedProps.isOver ? 'dnd-over' : ''}
        />
      </MoveDropZone>
    </Row>
  );
};

export default ProposalSummaryCard;

const Row = styled.div`
  position : relative;
`

const CardContainer = styled.div`
  position :relative;
  margin : ${spacing}rem;
  margin-bottom : ${2*spacing}rem;
  transition : transform ${transitions.fast}, max-height ${transitions.slow}, margin-top ${transitions.fast}, margin-bottom ${transitions.fast};
  max-height : 25rem;
  overflow : hidden;

  &.hidden {
    max-height : 0rem;
    margin-top : 0rem;
    margin-bottom : 0rem;
  }
`

const PageCard = styled.div`
  width : 21rem;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  border-radius: 1.5rem;
  padding : 0.3rem;
  padding-bottom : 0;
  border : 0.3rem solid ${colors.lightPrimary};
  background-color: ${colors.lightPrimary};
  z-index : 0;
  box-shadow: ${shadows.default};

  &.selected {
    border : 0.3rem solid ${colors.primary} !important;
  }

  &.dnd-over {
    border : 0.3rem solid ${colors.accent} !important;
  }

  &.mandatory {
    background-color: ${colors.lightPrimary};
    border : 0.3rem solid ${colors.lightPrimary};
  }
  &.mandatory textarea {
    background-color: ${colors.lightPrimary};
  }
`

const MandatoryContainer = styled.div`
  margin-left : 1rem;
  margin-top : -0.8rem;
  margin-bottom : 1rem;
`


const Label = styled.div`
  display : flex;
  margin-top : 1rem;
  margin-bottom : 1rem;
  padding-left : 1rem;
`








const VerticalLine = styled.div<{$level : number, $visible : boolean}>`
  position : absolute;
  top : -${2*spacing}rem;
  left : ${p => (p.$level) * 2 * spacing}rem;
  height : calc(100% + 3*${spacing}rem);
  width : 0.3rem;
  background-color : ${colors.lightSecondary};
  opacity : ${p => p.$visible ? 1 : 0};
  transition : opacity ${transitions.fast};
`

const SmallVerticalLine = styled.div<{$level : number, $visible : boolean}>`
  position : absolute;
  top : -${2*spacing}rem;
  left : ${p => (p.$level) * 2 * spacing}rem;
  height : ${2*spacing}rem;
  width : 0.3rem;
  background-color : ${colors.lightSecondary};
  opacity : ${p => p.$visible ? 1 : 0};
  transition : opacity ${transitions.fast};
`


const MoveDropZone = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position : absolute;
  bottom : 0;
  margin : ${spacing}rem;
  margin-bottom : -${2*spacing}rem;
  height : ${2*spacing}rem;
  width : 100%;
  z-index: 1;
  transition : transform ${transitions.fast};
`

const MoveDropIndicator = styled.div`
  height : 0.7rem;
  width : 17rem;
  margin-left : 2rem;
  border-radius: 0.7rem;
  background-color : ${colors.accent};
  display: none;

  &.dnd-over {
    display: block;
  }
`

const ImageContainer = styled.div`
  display: flex;
  align-items : center;
  justify-content: center;
  width : 20rem;
  height : 11.3rem;
  background-color: ${colors.background2};
  border-radius: 1rem;
`

