import { useTranslation } from "react-i18next";
import Modal from "../modal";
import { colors, spacing, typography } from "../../../styles";
import styled from "styled-components";
import { useModelStore, useUserStore } from "../../../store/store";
import { useState } from "react";
import TextButton from "../../atoms/text-button";
import MarketIcon from "../../../assets/icons/markets";
import { Filter, ID } from "../../../store/store-types";
import { useModelActions } from "../../../store/hooks/use-actions";
import TagsIcon from "../../../assets/icons/tags";
import IconChip from "../../atoms/icon-chip";
import AddIcon from "../../../assets/icons/add";
import RemoveCrossIcon from "../../../assets/icons/remove-cross";
import Typography from "../../atoms/typography";
import Checkbox from "../../atoms/checkbox";

interface FilterModalProps {
  onClose : () => void;
  filter ?: Filter;
}

let lastValueIndex = -1;

const FilterModal : React.FC<FilterModalProps> = (p) => {
  const {t} = useTranslation();
  const loading = useUserStore(state => state.process.loading['filter.create'] || state.process.loading['filter.update']);

  const markets = useModelStore(state => state.markets.filter(({inactive}) => !inactive));
  const [editedName, setEditedName] = useState<string | undefined>(p.filter ? p.filter.name : '');
  const [editedQuestion, setEditedQuestion] = useState<string | undefined>(p.filter? p.filter.question : '');
  const [editedValues, setEditedValues] = useState<{id : ID, value : string}[]>(p.filter?.values || []);
  const [editedMarkets, setEditedMarkets] = useState<ID[]>(p.filter?.marketsIds || []);
  const {create, update} = useModelActions('filters');
  const [newValue, setNewValue] = useState<string>('');
  const [editingValueId, setEditingValueId] = useState<ID | undefined>()

  const disabled = loading || !editedName?.trim() || !editedValues.length || !editedQuestion?.trim();

  const handleSubmit = async () => {
    if(!disabled) {
      if(p.filter) {
        await update(p.filter.id, {
          name : editedName?.trim(),
          question : editedQuestion?.trim(),
          marketsIds : editedMarkets,
          values : editedValues
        });
      }
      else {
        await create({
          name : editedName?.trim(),
          question : editedQuestion?.trim(),
          marketsIds : editedMarkets,
          values : editedValues
        });
      }
      p.onClose();
    }
  }

  const handleAddValue = () => {
    if(newValue.trim()) {
      setEditedValues(values => [...values, {id : --lastValueIndex, value : newValue.trim()}])
      setNewValue('');
    }
  }

  const handleRemoveValue = (id : ID) => {
    setEditedValues(values => [...values].filter(value => value.id !== id))
  }

  const handleEditValue = (id : ID, value : string) => {
    setEditedValues(values => values.map(_v => _v.id !== id ? _v : {..._v, value}))
  }

  const handleChangeMarkets = (marketId : ID, value : boolean) => {
    setEditedMarkets(markets => {
      const set = new Set(markets);
      if(value) {
        set.add(marketId);
      }
      else {
        set.delete(marketId);
      }
      return [...set.values()];
    })
  }

  return (
    <Modal
      title={t('pages.filters.modalTitle')}
      subtitle={t('pages.filters.modalSubtitle')}
      icon={<TagsIcon color={colors.text}/>}
      onClose={p.onClose}
      onSubmit={handleSubmit}
      controls={[
        <TextButton key="validate" type="submit" disabled={disabled}>{t('pages.filters.validate')}</TextButton>
      ]}
    >
      <Container>
        <ValuesContainer>
          <NameInput
            placeholder={t('pages.filters.namePlaceholder')}
            value={editedName}
            onChange={e => setEditedName(e.target.value)}
          />
          <ValuesScroller>
          {
            editedValues.map(({id, value}) => (
              <ChipContainer key={id}>
                <IconChip
                  onClick={() => handleRemoveValue(id)}
                  content={
                    editingValueId === id ?
                    <NewValueInput autoFocus value={value} onChange={e => handleEditValue(id, e.target.value)} onBlur={() => setEditingValueId(undefined)}/> :
                    <Typography onClick={() => setEditingValueId(id)} variant="textRegular" color="text">{value}</Typography>
                  }
                  icon={<RemoveCrossIcon color={colors.text} />}
                />
              </ChipContainer>
            ))
          }
          <ChipContainer>
            <IconChip
              onClick={handleAddValue}
              content={<NewValueInput value={newValue} onChange={e => setNewValue(e.target.value)} onBlur={handleAddValue} placeholder={t('pages.filters.newValuePlaceholder')}/>}
              icon={<AddIcon />}
            />
          </ChipContainer>
          </ValuesScroller>
        </ValuesContainer>
        <DetailsContainer>
          <Question value={editedQuestion} onChange={e => setEditedQuestion(e.target.value)} placeholder={t('pages.filters.questionPlaceholder')}/>
          <Typography variant="textItalic" color="secondaryText">{t('pages.filters.marketIdLabel')}</Typography>
          <CheckboxContainer>
          {
            markets.map(market =>
              <Checkbox key={market.id} value={editedMarkets.includes(market.id)} label={market.name} onChange={(value) => handleChangeMarkets(market.id, value)}/>
            )
          }
          </CheckboxContainer>
        </DetailsContainer>
      </Container>
    </Modal>
  );
}

export default FilterModal;

const Container = styled.div`
  display : flex;
`

const ValuesContainer = styled.div`
  background-color : ${colors.background};
  border-radius: ${spacing}rem;
  padding : ${spacing}rem;
  width : 39rem;
  height : 31rem;
  margin-right : ${spacing}rem;
  display : flex;
  flex-direction : column;
`

const DetailsContainer = styled.div`
  width : 39rem;
  margin-left : ${spacing}rem;
`

const Question = styled.textarea`
  width : 100%;
  height : 9rem;
  background-color: ${colors.background};
  border-radius: ${spacing}rem;
  padding : ${spacing}rem;
  margin-bottom : ${spacing}rem;
  border : none;
  outline : none;
  resize: none;
  ${typography.textRegular.css};
  color : ${colors.text};

  &::placeholder {
    ${typography.textRegular.css};
    color : ${colors.secondaryText};
  }
`

const NameInput = styled.input`
  color : ${colors.text};
  width : 100%;
  &::placeholder {
    color : ${colors.tertiaryText};
  }
  ${typography.mediumTitle.css};
  border : none;
  background-color: ${colors.background2};
  border-radius: 1rem;
  padding : 1rem;
  outline: none;
`

const NewValueInput = styled.input`
  border : none;
  outline : none;
  padding-left : 0;
  ${typography.textRegular.css};
  color : ${colors.text};
  &::placeholder {
    color : ${colors.tertiaryText};
  }
`
const ValuesScroller = styled.div`
  flex : 1;
  overflow: auto;
  padding : ${spacing}rem 0 ${2*spacing}rem 0;
`

const ChipContainer = styled.div`
  margin-top : ${spacing}rem;
  &:first-child {
    margin-top : 0;
  }
`

const CheckboxContainer = styled.div`
  margin-top : ${spacing}rem;

  & > div {
    margin-right : ${2*spacing}rem;
  }
`