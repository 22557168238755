import { ReactElement, cloneElement} from "react";
import styled from "styled-components";
import { transitions, colors, spacing, typography } from "../../styles";
import Typography from "./typography";
import { Link, NavLink } from "react-router-dom";
import { LowerSerif, UpperSerif } from "../../assets/images/menu-item-serif";

interface MenuItemProps {
  label : string;
  icon : ReactElement;
  linkTo : string;
  selected ?: boolean;
  onClick ?: () => void;
  activeColor ?: string;
}

const MenuItem : React.FC<MenuItemProps> = (p) => {
  const activeColor = p.activeColor || colors.text;

  return (
    <Item role="button" tabIndex={0} onClick={p.onClick}>
      <StyledLink $activeColor={activeColor} to={p.linkTo} className={({isActive}) => isActive ? 'active'  : ''}>
        {
          ({isActive}) => (
            <>
              <IconContainer>
                {cloneElement(p.icon, {color : isActive ? activeColor : colors.secondaryText})}
               </IconContainer>
              <Typography variant={p.selected ? 'menuBold' : 'menuRegular'} color="secondaryText">{p.label}</Typography>
            </>
          )
        }
      </StyledLink>
      <StyledUpperSerif />
      <StyledLowerSerif />
    </Item>
  );
}

export default MenuItem;

const Item = styled.li`
  position: relative;
  width: 19.4rem;
  height: 5.4rem;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding : ${spacing}rem;
  padding-left : ${spacing}rem;
  cursor : default;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
  transition: background-color ${transitions.fast};

  &:active, &:focus, &:hover {
    outline : none;
    background-color : ${colors.background};
  }
`;

const IconContainer = styled.div`
  width : 1.7rem;
  height : 1.8rem;
  margin-right : ${spacing}rem;
`

const StyledLink = styled(NavLink)<{$activeColor : string}>`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  cursor : default;
  text-decoration-line: none;

  &.active * {
    ${typography.menuBold.css};
    ${p => `color : ${p.$activeColor}`};
  }
`

const StyledUpperSerif = styled(UpperSerif)`
  position : absolute;
  right : 0;
  top : 0;
  transform: translateY(-100%);
  display : none;

  li.selected &, li:active &, li:focus &, li:hover & {
    display : block;
  }
`

const StyledLowerSerif = styled(LowerSerif)`
  position : absolute;
  right : 0;
  bottom : 0;
  transform: translateY(100%);
  display : none;

  li.selected &, li:active &, li:focus &, li:hover & {
    display : block;
  }
`