import styled from "styled-components";
import { colors, spacing, transitions } from "../../styles";
import Logo from "../../assets/images/logo";
import Typography from "../atoms/typography";
import { useUserActions } from "../../store/hooks/use-actions";
import MenuItem from "../atoms/menu-item";
import { useTranslation } from "react-i18next";
import LibraryIcon from "../../assets/icons/library";
import MarketIcon from "../../assets/icons/markets";
import { DASHBOARD, FILTERS, HOME, LIBRARY, MARKETS, OFFERS, PROFILE, PROPOSALS, REGIONS, USERS } from "../../constants/routes";
import DocumentsIcon from "../../assets/icons/documents";
import UserIcon from "../../assets/icons/user";
import { useUserStore } from "../../store/store";
import SettingsIcon from "../../assets/icons/settings";
import DashboardIcon from "../../assets/icons/dashboard";
import LogoutIcon from "../../assets/icons/logout";
import StyledLink from "../atoms/styled-link";
import UsersSettingsIcon from "../../assets/icons/users-settings";
import { ADMIN, SUPERADMIN, SUPERVISOR } from "../../constants/roles";
import TagsIcon from "../../assets/icons/tags";
import OffersIcon from "../../assets/icons/offers";

import packageJson from '../../../package.json';
import { useNavigate } from "react-router-dom";
import RegionIcon from "../../assets/icons/region";

interface NavBarProps {

}

const NavBar : React.FC<NavBarProps> = (p) => {
  const {t} = useTranslation();
  const {logout} = useUserActions('user');
  const {firstName, lastName, role} = useUserStore(state => state.user);
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate(HOME);
    logout();
  }

  return (
    <Container>
      <LogoContainer>
        <Logo />
      </LogoContainer>

      <LinksBlock>
        <MenuItem label={t('pages.dashboard.name')} icon={<DashboardIcon color={colors.secondaryText}/>} linkTo={DASHBOARD} />
        <MenuItem label={t('pages.library.name')} icon={<LibraryIcon color={colors.secondaryText}/>} linkTo={LIBRARY} />
        {
          role === ADMIN || role === SUPERADMIN ?
            <MenuItem label={t('pages.offers.name')} icon={<OffersIcon color={colors.secondaryText}/>} linkTo={OFFERS} /> :
            null
        }
        <MenuItem label={t('pages.proposals.name')} icon={<DocumentsIcon width="1.6rem" color={colors.secondaryText}/>} linkTo={PROPOSALS} />
      </LinksBlock>

      <div style={{width : '100%'}}>
        {
          role === ADMIN ?
            <AdminLinkBlock>
              <MenuItem activeColor={colors.primary} label={t('pages.filters.name')} icon={<TagsIcon color={colors.secondaryText}/>} linkTo={FILTERS} />
            </AdminLinkBlock> :
          role === SUPERADMIN ?
            <AdminLinkBlock>
              <MenuItem activeColor={colors.primary} label={t('pages.users.name')} icon={<UsersSettingsIcon color={colors.secondaryText}/>} linkTo={USERS} />
              <MenuItem activeColor={colors.primary} label={t('pages.filters.name')} icon={<TagsIcon color={colors.secondaryText}/>} linkTo={FILTERS} />
              <MenuItem activeColor={colors.primary} label={t('pages.markets.name')} icon={<MarketIcon color={colors.secondaryText}/>} linkTo={MARKETS} />
              <MenuItem activeColor={colors.primary} label={t('pages.regions.name')} icon={<RegionIcon color={colors.secondaryText}/>} linkTo={REGIONS} />
            </AdminLinkBlock> :
          null
        }
        <Footer >
          <FooterContent>
            <StyledLink to={PROFILE}>
              <FooterItem>
                  <FooterIconContainer>
                    <UserIcon />
                  </FooterIconContainer>
                  <Typography variant="menuRegular" color="secondaryText">{firstName || ''} {(!firstName && lastName) || ''}</Typography>
              </FooterItem>
            </StyledLink>
            <FooterItem onClick={handleLogout}>
              <FooterIconContainer>
                <LogoutIcon />
              </FooterIconContainer>
              <Typography variant="menuRegular" color="tertiaryText">{t('logout')}</Typography>
            </FooterItem>
          </FooterContent>
        </Footer>
      </div>
      <Version>
        v{packageJson.version}
      </Version>
    </Container>
  )
};

export default NavBar;

const Container = styled.div`
  display : flex;
  flex-direction: column;
  align-items: center;
  position : fixed;
  z-index : 5;
  width : 21.9rem;
  height : 100vh;
  top : 0;
  left : 0;
  background-color: ${colors.background2};

  padding-top: 7.1rem;
  padding-bottom: ${spacing}rem;
`;

const LogoContainer = styled.div`
  width: 15.1rem;
  height: 2.9rem;
`

const LinksBlock = styled.div`
  width : 100%;
  flex : 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top : 5rem;
`

const AdminLinkBlock = styled(LinksBlock)`
  margin-top : 1rem;
`

const Footer = styled.div`
  margin-top : ${2*spacing}rem;
  width : 100%;
  padding-left : ${2*spacing}rem;
  padding-right : ${2*spacing}rem;
`

const FooterContent = styled.div`
  border-top : 0.05rem solid ${colors.secondaryText};
  padding : ${spacing}rem;
`;

const FooterIconContainer = styled.div`
  width : 1.6rem;
  height : 1.6rem;
  margin-right : ${spacing}rem;
`

const FooterItem = styled.div`
  white-space: nowrap;
  cursor : default;
  display: flex;
  align-items: center;
  margin-bottom: ${spacing}rem;
`

const Version = styled.div`
  position : fixed;
  bottom : 0.4rem;
  right : 0.5rem;
  font-size: 1rem;
  color : #AAA;
`