import axios from "axios";


import { GetFunction, SetFunction, ActionsFactory, ModelStore, Market, Filter, ID } from "../store-types";
import configProvider from "../../providers/configprovider";
import { setLoading } from "../helpers/set-loading";
import { setError } from "../helpers/set-error";


const filtersActions : ActionsFactory<ModelStore['actions']['filters'], ModelStore> =  (set : SetFunction<ModelStore>, get : GetFunction<ModelStore>) => ({
  load : async () => {
    setLoading(set, 'filters.load', true);

    try {
      const filtersResponse = await axios.get(`${configProvider('MODEL_API_URL')}/filters?pageSize=1000`);
      const filtersValuesResponse = await axios.get(`${configProvider('MODEL_API_URL')}/filters-values?pageSize=1000`);

      if(filtersResponse.status === 200 && filtersValuesResponse.status === 200) {
        const {data : filters} : {data : Filter[]} = filtersResponse.data || {};
        const {data : values} : {data : {id : ID, filterId : ID, value : string}[]} = filtersValuesResponse.data || {};

        values.forEach(value => {
          const filter = filters.find(filter => filter.id === value.filterId);
          if(filter) {
            if(!filter?.values) {
              filter.values = [];
            }
            filter.values.push(value)
          }
        })

        set(state => {
          state.filters = filters;
        })
      }

      setLoading(set, 'filters.load', false);

      return;
    }
    catch(error) {}

    setError(set, 'filters.load', 'errors.filters.load')
  },

  create : async (filter : Partial<Filter>) => {
    setLoading(set, 'filters.create', true);

    if(!filter.name || !filter.question || !filter.values?.length) {
      setError(set, 'filters.create', 'filter.create.missingProp');
      throw 'filter.create.missingProp';
    }

    try {
      const filtersResponse = await axios.post(`${configProvider('MODEL_API_URL')}/filters`, {
        name : filter.name,
        question : filter.question,
        marketsIds : filter.marketsIds
      });

      if(filtersResponse.status === 200) {
        const newFilter : Filter = filtersResponse.data;

        set(state => {
          state.filters.push(newFilter);
        })

        for(let value of filter.values) {
          await get().actions.filters.addValue(newFilter.id, value.value);
        }

        setLoading(set, 'filters.create', false);

        return;
      }
    }
    catch(error) {}

    setError(set, 'filters.create', 'errors.filters.create')
    throw 'errors.filters.create'
  },

  archive : async (filterId : number) => {
    setLoading(set, 'filters.archive', true);

    try {
      const filtersResponse = await axios.patch(`${configProvider('MODEL_API_URL')}/filters/${filterId}`, {inactive : true});

      if(filtersResponse.status === 200) {
        set(state => {
          state.filters = state.filters.map(filter => filter.id !== filterId ? filter : {...filter, inactive : true});
        })

        setLoading(set, 'filters.archive', false);

        return;
      }
    }
    catch(error) {}

    setError(set, 'filters.archive', 'filters.archive')
    throw 'filters.archive'
  },

  unarchive : async (filterId : number) => {
    setLoading(set, 'filters.unarchive', true);

    try {
      const filtersResponse = await axios.patch(`${configProvider('MODEL_API_URL')}/filters/${filterId}`, {inactive : false});

      if(filtersResponse.status === 200) {
        set(state => {
          state.filters = state.filters.map(filter => filter.id !== filterId ? filter : {...filter, inactive : false});
        })

        setLoading(set, 'filters.unarchive', false);

        return;
      }
    }
    catch(error) {}

    setError(set, 'filters.unarchive', 'filters.unarchive')
    throw 'filters.unarchive'
  },

  update : async (filterId : number, patch : Partial<Filter>) => {
    setLoading(set, 'filters.update', true);

    const sanitizedPatch : Partial<Filter> = {};
    if(patch.name) {
      sanitizedPatch.name = patch.name;
    }
    if(patch.question) {
      sanitizedPatch.question = patch.question;
    }
    if(patch.marketsIds) {
      sanitizedPatch.marketsIds = patch.marketsIds;
    }

    try {
      const filtersResponse = await axios.patch(`${configProvider('MODEL_API_URL')}/filters/${filterId}`, sanitizedPatch);

      if(filtersResponse.status === 200) {
        set(state => {
          const filter = state.filters.find(filter => filter.id === filterId);
          if(filter) {
            Object.assign(filter, sanitizedPatch);
          }
        })

        if(patch.values) {
          const filter = get().filters.find(filter => filter.id === filterId);
          const currentValues = filter?.values || [];
          const newValues = patch.values || [];
          const toBeRemoved = currentValues.filter(value => newValues.every(v => v.id !== value.id));
          const toBeAdded   = newValues.filter(value => currentValues.every(v => v.id != value.id));
          const toBeUpdated = newValues.filter(value => {
            const found = currentValues.find(v => v.id === value.id);
            return found && found.value !== value.value;
          })

          for(let rem of toBeRemoved) {
            await get().actions.filters.deleteValue(rem.id);
          }
          for(let add of toBeAdded) {
            await get().actions.filters.addValue(filterId, add.value);
          }
          for(let upd of toBeUpdated) {
            await get().actions.filters.updateValue(upd.id, upd.value);
          }
        }

        setLoading(set, 'filters.update', false);

        return;
      }
    }
    catch(error) {}

    setError(set, 'filters.update', 'filters.update')
    throw 'filters.update'
  },

  addValue : async (filterId : number, value : string) => {
    setLoading(set, 'filters.addValue', true);

    try {
      const filtersResponse = await axios.post(`${configProvider('MODEL_API_URL')}/filters-values`, {
        filterId,
        value
      });

      if(filtersResponse.status === 200) {
        const newFilterValue : Filter['values'][0] = filtersResponse.data;

        set(state => {
          const filter = state.filters.find(filter => filter.id === filterId);
          if(filter) {
            if(!filter.values) {
              filter.values = [];
            }
            filter.values.push(newFilterValue)
          }
        })
        setLoading(set, 'filters.addValue', false);

        return;
      }
    }
    catch(error) {}

    setError(set, 'filters.addValue', 'errors.filters.addValue')
    throw 'errors.filters.addValue'
  },

  deleteValue : async (valueId: ID) => {
    setLoading(set, 'filters.deleteValue', true);

    try {
      const filtersResponse = await axios.delete(`${configProvider('MODEL_API_URL')}/filters-values/${valueId}`);

      if(filtersResponse.status === 200) {
        set(state => {
          const filter = state.filters.find(filter => (filter.values || []).some(value => value.id === valueId));

          if(filter) {
            filter.values = (filter.values || []).filter(value => value.id !== valueId);
          }
        })
        setLoading(set, 'filters.deleteValue', false);

        return;
      }
    }
    catch(error) {}

    setError(set, 'filters.deleteValue', 'errors.filters.deleteValue')
    throw 'errors.filters.deleteValue'
  },

  updateValue : async(valueId : ID, value : string) => {
    setLoading(set, 'filters.updateValue', true);

    try {
      const filtersResponse = await axios.patch(`${configProvider('MODEL_API_URL')}/filters-values/${valueId}`, {value});

      if(filtersResponse.status === 200) {
        set(state => {
          const filter = state.filters.find(filter => (filter.values || []).some(value => value.id === valueId));
          const _value = (filter?.values || []).find(value => value.id === valueId);

          if(_value) {
            _value.value = value;
          }
        })
        setLoading(set, 'filters.updateValue', false);

        return;
      }
    }
    catch(error) {}

    setError(set, 'filters.updateValue', 'errors.filters.updateValue')
    throw 'errors.filters.updateValue'
  }
})

export default filtersActions;