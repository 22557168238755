import { colors } from "../../styles";
import IconProps from "./icon-interface";

const ViewIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.primary;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 32 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="path-1-outside-1_2394_92993" maskUnits="userSpaceOnUse" x="23.7136" y="17.8928" width="8" height="8" fill="black">
    <rect fill="white" x="23.7136" y="17.8928" width="8" height="8"/>
    <path d="M26.7229 24.8734C26.3034 24.8734 25.8839 24.8757 25.4636 24.8734C24.9971 24.8704 24.7052 24.6324 24.7138 24.2683C24.7216 23.9102 25.0073 23.682 25.4565 23.6805C26.2181 23.6782 26.9788 23.682 27.7403 23.6797C28.9096 23.676 29.7056 22.9192 29.7165 21.7946C29.7236 21.0641 29.7165 20.3344 29.7189 19.6039C29.7205 19.1437 29.9521 18.8869 30.3505 18.8929C30.7309 18.8989 30.961 19.1609 30.9625 19.5986C30.9649 20.3479 30.9688 21.0964 30.9625 21.8456C30.9477 23.5438 29.6344 24.8261 27.8656 24.8712C27.4852 24.8809 27.104 24.8727 26.7237 24.8727V24.8749L26.7229 24.8734Z"/>
    </mask>
    <path d="M26.7229 24.8734C26.3034 24.8734 25.8839 24.8757 25.4636 24.8734C24.9971 24.8704 24.7052 24.6324 24.7138 24.2683C24.7216 23.9102 25.0073 23.682 25.4565 23.6805C26.2181 23.6782 26.9788 23.682 27.7403 23.6797C28.9096 23.676 29.7056 22.9192 29.7165 21.7946C29.7236 21.0641 29.7165 20.3344 29.7189 19.6039C29.7205 19.1437 29.9521 18.8869 30.3505 18.8929C30.7309 18.8989 30.961 19.1609 30.9625 19.5986C30.9649 20.3479 30.9688 21.0964 30.9625 21.8456C30.9477 23.5438 29.6344 24.8261 27.8656 24.8712C27.4852 24.8809 27.104 24.8727 26.7237 24.8727V24.8749L26.7229 24.8734Z" fill={color}/>
    <path d="M26.7229 24.8734C26.3034 24.8734 25.8839 24.8757 25.4636 24.8734C24.9971 24.8704 24.7052 24.6324 24.7138 24.2683C24.7216 23.9102 25.0073 23.682 25.4565 23.6805C26.2181 23.6782 26.9788 23.682 27.7403 23.6797C28.9096 23.676 29.7056 22.9192 29.7165 21.7946C29.7236 21.0641 29.7165 20.3344 29.7189 19.6039C29.7205 19.1437 29.9521 18.8869 30.3505 18.8929C30.7309 18.8989 30.961 19.1609 30.9625 19.5986C30.9649 20.3479 30.9688 21.0964 30.9625 21.8456C30.9477 23.5438 29.6344 24.8261 27.8656 24.8712C27.4852 24.8809 27.104 24.8727 26.7237 24.8727V24.8749L26.7229 24.8734Z" stroke={color} strokeWidth="0.4" mask="url(#path-1-outside-1_2394_92993)"/>
    <mask id="path-2-outside-2_2394_92993" maskUnits="userSpaceOnUse" x="23.9841" y="0.155517" width="8" height="8" fill="black">
    <rect fill="white" x="23.9841" y="0.155517" width="8" height="8"/>
    <path d="M30.9647 5.14625C30.9647 5.56576 30.967 5.98526 30.9647 6.40555C30.9617 6.87201 30.7237 7.16394 30.3596 7.15533C30.0015 7.14751 29.7733 6.86184 29.7718 6.41259C29.7695 5.65107 29.7733 4.89033 29.771 4.1288C29.7673 2.95951 29.0105 2.16355 27.8859 2.15259C27.1554 2.14555 26.4257 2.15259 25.6952 2.15025C25.235 2.14868 24.9782 1.91701 24.9842 1.51864C24.9902 1.13827 25.2523 0.90817 25.6899 0.906604C26.4392 0.904256 27.1877 0.900343 27.9369 0.906604C29.6351 0.921475 30.9174 2.23477 30.9625 4.00358C30.9722 4.38395 30.964 4.7651 30.964 5.14547L30.9662 5.14547L30.9647 5.14625Z"/>
    </mask>
    <path d="M30.9647 5.14625C30.9647 5.56576 30.967 5.98526 30.9647 6.40555C30.9617 6.87201 30.7237 7.16394 30.3596 7.15533C30.0015 7.14751 29.7733 6.86184 29.7718 6.41259C29.7695 5.65107 29.7733 4.89033 29.771 4.1288C29.7673 2.95951 29.0105 2.16355 27.8859 2.15259C27.1554 2.14555 26.4257 2.15259 25.6952 2.15025C25.235 2.14868 24.9782 1.91701 24.9842 1.51864C24.9902 1.13827 25.2523 0.90817 25.6899 0.906604C26.4392 0.904256 27.1877 0.900343 27.9369 0.906604C29.6351 0.921475 30.9174 2.23477 30.9625 4.00358C30.9722 4.38395 30.964 4.7651 30.964 5.14547L30.9662 5.14547L30.9647 5.14625Z" fill={color}/>
    <path d="M30.9647 5.14625C30.9647 5.56576 30.967 5.98526 30.9647 6.40555C30.9617 6.87201 30.7237 7.16394 30.3596 7.15533C30.0015 7.14751 29.7733 6.86184 29.7718 6.41259C29.7695 5.65107 29.7733 4.89033 29.771 4.1288C29.7673 2.95951 29.0105 2.16355 27.8859 2.15259C27.1554 2.14555 26.4257 2.15259 25.6952 2.15025C25.235 2.14868 24.9782 1.91701 24.9842 1.51864C24.9902 1.13827 25.2523 0.90817 25.6899 0.906604C26.4392 0.904256 27.1877 0.900343 27.9369 0.906604C29.6351 0.921475 30.9174 2.23477 30.9625 4.00358C30.9722 4.38395 30.964 4.7651 30.964 5.14547L30.9662 5.14547L30.9647 5.14625Z" stroke={color} strokeWidth="0.4" mask="url(#path-2-outside-2_2394_92993)"/>
    <mask id="path-3-outside-3_2394_92993" maskUnits="userSpaceOnUse" x="0.219726" y="-0.11377" width="8" height="8" fill="black">
    <rect fill="white" x="0.219726" y="-0.11377" width="8" height="8"/>
    <path d="M5.21046 0.905647C5.62997 0.905647 6.04947 0.903395 6.46976 0.905647C6.93622 0.90865 7.22815 1.14664 7.21954 1.51075C7.21172 1.86886 6.92605 2.09709 6.4768 2.09859C5.71528 2.10084 4.95454 2.09709 4.19301 2.09934C3.02372 2.10309 2.22776 2.85985 2.2168 3.98448C2.20976 4.71496 2.2168 5.44469 2.21446 6.17517C2.21289 6.63538 1.98122 6.89213 1.58285 6.88613C1.20248 6.88012 0.972379 6.61811 0.970813 6.18042C0.968465 5.43117 0.964552 4.68267 0.970814 3.93342C0.985684 2.23523 2.29898 0.952944 4.06779 0.907899C4.44816 0.898139 4.82931 0.906399 5.20968 0.906399L5.20968 0.904144L5.21046 0.905647Z"/>
    </mask>
    <path d="M5.21046 0.905647C5.62997 0.905647 6.04947 0.903395 6.46976 0.905647C6.93622 0.90865 7.22815 1.14664 7.21954 1.51075C7.21172 1.86886 6.92605 2.09709 6.4768 2.09859C5.71528 2.10084 4.95454 2.09709 4.19301 2.09934C3.02372 2.10309 2.22776 2.85985 2.2168 3.98448C2.20976 4.71496 2.2168 5.44469 2.21446 6.17517C2.21289 6.63538 1.98122 6.89213 1.58285 6.88613C1.20248 6.88012 0.972379 6.61811 0.970813 6.18042C0.968465 5.43117 0.964552 4.68267 0.970814 3.93342C0.985684 2.23523 2.29898 0.952944 4.06779 0.907899C4.44816 0.898139 4.82931 0.906399 5.20968 0.906399L5.20968 0.904144L5.21046 0.905647Z" fill={color}/>
    <path d="M5.21046 0.905647C5.62997 0.905647 6.04947 0.903395 6.46976 0.905647C6.93622 0.90865 7.22815 1.14664 7.21954 1.51075C7.21172 1.86886 6.92605 2.09709 6.4768 2.09859C5.71528 2.10084 4.95454 2.09709 4.19301 2.09934C3.02372 2.10309 2.22776 2.85985 2.2168 3.98448C2.20976 4.71496 2.2168 5.44469 2.21446 6.17517C2.21289 6.63538 1.98122 6.89213 1.58285 6.88613C1.20248 6.88012 0.972379 6.61811 0.970813 6.18042C0.968465 5.43117 0.964552 4.68267 0.970814 3.93342C0.985684 2.23523 2.29898 0.952944 4.06779 0.907899C4.44816 0.898139 4.82931 0.906399 5.20968 0.906399L5.20968 0.904144L5.21046 0.905647Z" stroke={color} strokeWidth="0.4" mask="url(#path-3-outside-3_2394_92993)"/>
    <mask id="path-4-outside-4_2394_92993" maskUnits="userSpaceOnUse" x="-0.0495609" y="17.6235" width="8" height="8" fill="black">
    <rect fill="white" x="-0.0495609" y="17.6235" width="8" height="8"/>
    <path d="M0.969856 20.6328C0.969856 20.2133 0.967604 19.7938 0.969856 19.3735C0.972859 18.907 1.21085 18.6151 1.57496 18.6237C1.93307 18.6315 2.1613 18.9172 2.1628 19.3665C2.16505 20.128 2.1613 20.8887 2.16355 21.6503C2.1673 22.8195 2.92406 23.6155 4.04869 23.6265C4.77917 23.6335 5.5089 23.6265 6.23938 23.6288C6.69959 23.6304 6.95634 23.862 6.95034 24.2604C6.94433 24.6408 6.68232 24.8709 6.24463 24.8724C5.49538 24.8748 4.74688 24.8787 3.99763 24.8724C2.29943 24.8576 1.01715 23.5443 0.972108 21.7755C0.962348 21.3951 0.970608 21.014 0.970608 20.6336L0.968353 20.6336L0.969856 20.6328Z"/>
    </mask>
    <path d="M0.969856 20.6328C0.969856 20.2133 0.967604 19.7938 0.969856 19.3735C0.972859 18.907 1.21085 18.6151 1.57496 18.6237C1.93307 18.6315 2.1613 18.9172 2.1628 19.3665C2.16505 20.128 2.1613 20.8887 2.16355 21.6503C2.1673 22.8195 2.92406 23.6155 4.04869 23.6265C4.77917 23.6335 5.5089 23.6265 6.23938 23.6288C6.69959 23.6304 6.95634 23.862 6.95034 24.2604C6.94433 24.6408 6.68232 24.8709 6.24463 24.8724C5.49538 24.8748 4.74688 24.8787 3.99763 24.8724C2.29943 24.8576 1.01715 23.5443 0.972108 21.7755C0.962348 21.3951 0.970608 21.014 0.970608 20.6336L0.968353 20.6336L0.969856 20.6328Z" fill={color}/>
    <path d="M0.969856 20.6328C0.969856 20.2133 0.967604 19.7938 0.969856 19.3735C0.972859 18.907 1.21085 18.6151 1.57496 18.6237C1.93307 18.6315 2.1613 18.9172 2.1628 19.3665C2.16505 20.128 2.1613 20.8887 2.16355 21.6503C2.1673 22.8195 2.92406 23.6155 4.04869 23.6265C4.77917 23.6335 5.5089 23.6265 6.23938 23.6288C6.69959 23.6304 6.95634 23.862 6.95034 24.2604C6.94433 24.6408 6.68232 24.8709 6.24463 24.8724C5.49538 24.8748 4.74688 24.8787 3.99763 24.8724C2.29943 24.8576 1.01715 23.5443 0.972108 21.7755C0.962348 21.3951 0.970608 21.014 0.970608 20.6336L0.968353 20.6336L0.969856 20.6328Z" stroke={color} strokeWidth="0.4" mask="url(#path-4-outside-4_2394_92993)"/>
    <mask id="path-5-outside-5_2394_92993" maskUnits="userSpaceOnUse" x="6.96045" y="6.26587" width="18" height="13" fill="black">
    <rect fill="white" x="6.96045" y="6.26587" width="18" height="13"/>
    <path d="M16.7426 7.26587C19.2953 7.34127 21.752 8.29282 23.5319 10.4437C24.0758 11.1013 24.4566 11.8348 24.6803 12.6268C24.7237 12.7805 24.727 12.9635 24.6777 13.1149C23.887 15.5545 22.0894 17.1841 19.4229 17.9889C16.1947 18.9633 13.175 18.5255 10.5 16.5978C9.23648 15.6872 8.41035 14.4995 7.99269 13.0985C7.94927 12.9524 7.94927 12.7764 7.99532 12.6309C8.71949 10.3496 10.381 8.80717 12.8021 7.88601C13.9308 7.45641 15.1213 7.26938 16.7413 7.26645L16.7426 7.26587ZM16.1 17.5389C17.1261 17.5295 17.9167 17.4506 18.6797 17.2367C21.0726 16.5674 22.784 15.2506 23.5911 13.0962C23.643 12.9576 23.6397 12.7782 23.5911 12.6373C23.1688 11.4152 22.3867 10.3946 21.2245 9.65059C18.5028 7.90822 15.5909 7.7206 12.5923 9.00999C10.8572 9.75638 9.67585 10.9885 9.08585 12.6367C9.03587 12.777 9.03981 12.9535 9.08585 13.0956C9.28975 13.7239 9.61731 14.3031 10.0613 14.8239C11.6333 16.6656 13.7795 17.4681 16.0987 17.5383L16.1 17.5389Z"/>
    </mask>
    <path d="M16.7426 7.26587C19.2953 7.34127 21.752 8.29282 23.5319 10.4437C24.0758 11.1013 24.4566 11.8348 24.6803 12.6268C24.7237 12.7805 24.727 12.9635 24.6777 13.1149C23.887 15.5545 22.0894 17.1841 19.4229 17.9889C16.1947 18.9633 13.175 18.5255 10.5 16.5978C9.23648 15.6872 8.41035 14.4995 7.99269 13.0985C7.94927 12.9524 7.94927 12.7764 7.99532 12.6309C8.71949 10.3496 10.381 8.80717 12.8021 7.88601C13.9308 7.45641 15.1213 7.26938 16.7413 7.26645L16.7426 7.26587ZM16.1 17.5389C17.1261 17.5295 17.9167 17.4506 18.6797 17.2367C21.0726 16.5674 22.784 15.2506 23.5911 13.0962C23.643 12.9576 23.6397 12.7782 23.5911 12.6373C23.1688 11.4152 22.3867 10.3946 21.2245 9.65059C18.5028 7.90822 15.5909 7.7206 12.5923 9.00999C10.8572 9.75638 9.67585 10.9885 9.08585 12.6367C9.03587 12.777 9.03981 12.9535 9.08585 13.0956C9.28975 13.7239 9.61731 14.3031 10.0613 14.8239C11.6333 16.6656 13.7795 17.4681 16.0987 17.5383L16.1 17.5389Z" fill={color}/>
    <path d="M16.7426 7.26587C19.2953 7.34127 21.752 8.29282 23.5319 10.4437C24.0758 11.1013 24.4566 11.8348 24.6803 12.6268C24.7237 12.7805 24.727 12.9635 24.6777 13.1149C23.887 15.5545 22.0894 17.1841 19.4229 17.9889C16.1947 18.9633 13.175 18.5255 10.5 16.5978C9.23648 15.6872 8.41035 14.4995 7.99269 13.0985C7.94927 12.9524 7.94927 12.7764 7.99532 12.6309C8.71949 10.3496 10.381 8.80717 12.8021 7.88601C13.9308 7.45641 15.1213 7.26938 16.7413 7.26645L16.7426 7.26587ZM16.1 17.5389C17.1261 17.5295 17.9167 17.4506 18.6797 17.2367C21.0726 16.5674 22.784 15.2506 23.5911 13.0962C23.643 12.9576 23.6397 12.7782 23.5911 12.6373C23.1688 11.4152 22.3867 10.3946 21.2245 9.65059C18.5028 7.90822 15.5909 7.7206 12.5923 9.00999C10.8572 9.75638 9.67585 10.9885 9.08585 12.6367C9.03587 12.777 9.03981 12.9535 9.08585 13.0956C9.28975 13.7239 9.61731 14.3031 10.0613 14.8239C11.6333 16.6656 13.7795 17.4681 16.0987 17.5383L16.1 17.5389Z" stroke={color} strokeWidth="0.4" mask="url(#path-5-outside-5_2394_92993)"/>
    <mask id="path-6-outside-6_2394_92993" maskUnits="userSpaceOnUse" x="12.2373" y="8.90479" width="8" height="8" fill="black">
    <rect fill="white" x="12.2373" y="8.90479" width="8" height="8"/>
    <path d="M19.4366 12.8722C19.4379 14.507 18.0494 15.8464 16.3483 15.8508C14.6343 15.8545 13.2354 14.5145 13.2373 12.871C13.2399 11.2392 14.6349 9.90416 16.3379 9.90479C18.041 9.90479 19.4354 11.2405 19.4366 12.8728V12.8722ZM18.4053 12.8765C18.4046 11.7922 17.4748 10.8979 16.3425 10.8929C15.2147 10.8879 14.279 11.7786 14.2687 12.866C14.2583 13.9528 15.2095 14.8676 16.3438 14.8614C17.4761 14.8552 18.4059 13.9596 18.4053 12.8765Z"/>
    </mask>
    <path d="M19.4366 12.8722C19.4379 14.507 18.0494 15.8464 16.3483 15.8508C14.6343 15.8545 13.2354 14.5145 13.2373 12.871C13.2399 11.2392 14.6349 9.90416 16.3379 9.90479C18.041 9.90479 19.4354 11.2405 19.4366 12.8728V12.8722ZM18.4053 12.8765C18.4046 11.7922 17.4748 10.8979 16.3425 10.8929C15.2147 10.8879 14.279 11.7786 14.2687 12.866C14.2583 13.9528 15.2095 14.8676 16.3438 14.8614C17.4761 14.8552 18.4059 13.9596 18.4053 12.8765Z" fill={color}/>
    <path d="M19.4366 12.8722C19.4379 14.507 18.0494 15.8464 16.3483 15.8508C14.6343 15.8545 13.2354 14.5145 13.2373 12.871C13.2399 11.2392 14.6349 9.90416 16.3379 9.90479C18.041 9.90479 19.4354 11.2405 19.4366 12.8728V12.8722ZM18.4053 12.8765C18.4046 11.7922 17.4748 10.8979 16.3425 10.8929C15.2147 10.8879 14.279 11.7786 14.2687 12.866C14.2583 13.9528 15.2095 14.8676 16.3438 14.8614C17.4761 14.8552 18.4059 13.9596 18.4053 12.8765Z" stroke={color} strokeWidth="0.4" mask="url(#path-6-outside-6_2394_92993)"/>
    </svg>
    
    
  )
}

export default ViewIcon;


