import { useCallback, useEffect, useRef } from "react";

const AUTOSAVE_INTERVAL = 10000;

const useAutosave = (modified : boolean, callback : () => void) => {
  const timerRef = useRef<NodeJS.Timer>();
  const modifiedRef = useRef<boolean>(false);
  const callbackRef = useRef<()=>void>(() => {});

  modifiedRef.current = modified;
  callbackRef.current = callback;

  const handler = () => {
    if(modifiedRef.current) {
      callbackRef.current();
    }
  };

  useEffect(() => {
    timerRef.current = setInterval(handler, AUTOSAVE_INTERVAL);

    return () => {
      clearInterval(timerRef.current);
    }
  }, [])
}

export default useAutosave;