import styled from "styled-components";
import ArrowDownIcon from "../../assets/icons/arrow-down";
import { colors } from "../../styles";
import Typography from "./typography";
import FirstPageIcon from "../../assets/icons/first-page";
import LastPageIcon from "../../assets/icons/last-page";
import PreviousPageIcon from "../../assets/icons/previous-page";
import NextPageIcon from "../../assets/icons/next-page";

interface PaginationProps {
  total : number;
  current : number;
  onChange : (page : number) => void;
}

const Pagination : React.FC<PaginationProps> = (p) => {
  const handleFirstPage = () => {
    if(p.current > 1) {
      p.onChange(1)
    }
  }

  const handlePreviousPage = () => {
    if(p.current > 1) {
      p.onChange(p.current - 1);
    }
  }

  const handleNextPage = () => {
    if(p.current < p.total) {
      p.onChange(p.current + 1);
    }
  }

  const handleLastPage = () => {
    if(p.current < p.total) {
      p.onChange(p.total);
    }
  }

  return (
    <Container>
      <Button onClick={handleFirstPage}>
        <FirstPageIcon color={p.current === 1 ? colors.tertiaryText : colors.primary}/>
      </Button>
      <Button onClick={handlePreviousPage}>
        <PreviousPageIcon color={p.current === 1 ? colors.tertiaryText : colors.primary}/>
      </Button>
      <Typography style={{margin : "0 1.2rem"}} variant="textBold" color="primary">{p.current} de {p.total}</Typography>
      <Button onClick={handleNextPage}>
        <NextPageIcon color={p.current === p.total ? colors.tertiaryText : colors.primary}/>
      </Button>
      <Button onClick={handleLastPage}>
        <LastPageIcon color={p.current === p.total ? colors.tertiaryText : colors.primary}/>
      </Button>

    </Container>
  );
}

export default Pagination;

const Container = styled.div`
  display: flex;
`
const Button = styled.button`
  height : 1.8rem;
  padding : 0 0.6rem;
  border : none;
  background-color : transparent;
`
