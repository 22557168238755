import { useRef } from "react";

const DEFAULT_TIMEOUT=1000;

const useDebounce = <Value,>(callback : (value ?: Value) => void, timeout ?: number) => {
  let timer = useRef<NodeJS.Timeout | undefined>();

  const debounce = (value ?: Value) => {
    if(timer.current) {
      clearTimeout(timer.current);
    }

    timer.current = setTimeout(() => callback(value), timeout !== undefined ? timeout : DEFAULT_TIMEOUT);
  }

  const clear = () => {
    if(timer.current) {
      clearTimeout(timer.current);
    }
  };

  return {debounce, clear};
}

export default useDebounce;