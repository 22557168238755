import styled from "styled-components";
import NavBar from "./navbar";
import React, { ReactElement, ReactNode, useEffect, useRef } from "react";
import { useNavigate, unstable_useBlocker as useBlocker } from 'react-router-dom';
import { colors, spacing, transitions } from "../../styles";
import Typography from "../atoms/typography";
import ColorPicker from "../molecules/color-picker";
import { useModelStore, useUserStore } from "../../store/store";
import Modal from "../molecules/modal";
import TextAreaModal from "../molecules/modals/text-area-modal";
import Dialog from "../molecules/dialog";
import ContextMenu from "../molecules/context-menu";
import ErrorDialog from "../molecules/error-dialog";
import getErrorMessage from "../molecules/errors/errors-map";
import { useTranslation } from "react-i18next";
import useEventDelegation from "../../hooks/use-event-delegation";
import DeindentIcon from "../../assets/icons/deindent";
import { useDrop } from "react-dnd";
import { ProposalSlideDragItem, SlideDragItem } from "../../types";

interface PageLayoutProps {
  children : ReactNode;
  title ?: ReactNode;
  subtitle ?: ReactNode;
  controls ?: ReactNode;
  viewControls ?: ReactNode;
  breadcrumbs ?: string[];
  modal ?: ReactNode;
  showBackArrow ?: boolean;
  confirmNavigation ?: boolean;
}

const PageLayout : React.FC<PageLayoutProps> = (p) => {
  const navigate = useNavigate();
  const breadCrumbs = p.breadcrumbs || [];
  const lastBreadcrumb = breadCrumbs.slice(-1)[0];
  const otherBreadCrumbs = breadCrumbs.slice(0, -1);

  const {delegatedHandler} = useEventDelegation('page');
  const {delegatedHandler : delegateKeyboardHandler} = useEventDelegation('page-keyboard');

  const {t} = useTranslation();

  /*
    Error Handlings
  */
  const clearUserError = useUserStore(state => state.process.clearError);
  const clearModelError = useModelStore(state => state.process.clearError);
  const modelErrors = useModelStore(state => state.process.errors);
  const userErrors = useUserStore(state => state.process.errors);
  const errors = Object.entries({...modelErrors, ...userErrors});
  const [currentErrorKey, currentErrorValue] = errors.length ? errors[0] : [];
  const errorMessage = currentErrorKey && currentErrorValue ? getErrorMessage(t, currentErrorKey, currentErrorValue) : null;

  const handleCloseErrorDialog = () => {
    if(currentErrorKey) {
      clearModelError(currentErrorKey);
      clearUserError(currentErrorKey);
    }
  }

  /*
    Dialog props
  */
  const {visible : dialogVisibile, ...dialogProps} = useModelStore(state => state.process.modals.dialog);

  /*
    Blur background when errors, dialog or modals are visible
  */
  const blurred = !!errors.length || dialogVisibile || !!p.modal;

  useEffect(() => {
    const handler = (event : BeforeUnloadEvent) => {
      if(p.confirmNavigation) {
        event.preventDefault();
      }
    };

    window.addEventListener('beforeunload', handler);

    return () => {
      window.removeEventListener('beforeunload', handler);
    }
  }, [p.confirmNavigation])

  useBlocker(() => {
    if(!!p.confirmNavigation) {
      return !window.confirm(t('navigation-blocker'))
    }
    return false;
  })


  // Simulate automatic scroll when user moves a slide over the header
  const scrollTimeoutRef = useRef<any>();
  const [_, scrollDropZone] = useDrop<SlideDragItem | ProposalSlideDragItem, unknown, unknown>({
    accept : 'slide',
    collect : (monitor) => {
      const handle = () => {
        scrollTimeoutRef.current = undefined;
        if(monitor.isOver()) {
          window.scrollBy({top : -3})
          scrollTimeoutRef.current = setTimeout(handle, 10);
        }
      }
      if(!scrollTimeoutRef.current) {
        handle();
      }
    }
  }, [])



  return (
    <>
      <Container $blurred={blurred} onClick={delegatedHandler} onKeyDown={delegateKeyboardHandler}>
        <PageContent>
          <ChildrenContainer>
            {p.children}
          </ChildrenContainer>
        </PageContent>
        <ColorPicker />
        <Header ref={scrollDropZone}>
          <LeftHeaderButtons>
            {
              p.showBackArrow ? <BackIconContainer onClick={() => navigate(-1)}><DeindentIcon color={colors.text}/></BackIconContainer> : null
            }
          </LeftHeaderButtons>
          <MiddleHeader>
            <BreadCrumbContainer>
            {
              otherBreadCrumbs.map(breadCrumb => (
                <span key={breadCrumb}>
                  <Typography variant="breadcrumbLight" color="secondaryText">{breadCrumb}</Typography>
                  <BreadcrumbSeparator variant="breadcrumbBold" color="secondaryText">&gt;</BreadcrumbSeparator>
                </span>
              ))
            }
            {
              lastBreadcrumb ?
                <Typography variant="breadcrumbBold" color="text">{lastBreadcrumb}</Typography> :
                null
            }
            </BreadCrumbContainer>
            <MainTitleContainer>{typeof p.title === 'string' ? <Typography variant="bigTitle">{p.title}</Typography> : p.title}</MainTitleContainer>
            <SubtitleContainer>{typeof p.subtitle === 'string' ? <Typography variant="textRegular" color="secondaryText">{p.subtitle}</Typography> : p.subtitle}</SubtitleContainer>
          </MiddleHeader>
          <RightHeader>
            <BreadCrumbContainer>
            </BreadCrumbContainer>
            <ControlsContainer>
              {p.controls}
            </ControlsContainer>
            <ViewControlsContainer>
              {p.viewControls}
            </ViewControlsContainer>
          </RightHeader>
        </Header>
        <NavBar/>
        <ContextMenu />
      </Container>
      {
        errorMessage ?
          <ErrorDialog {...errorMessage} onClose={handleCloseErrorDialog}/> :
        dialogVisibile ?
          <Dialog {...dialogProps} /> :
        p.modal ?
          p.modal :
          null
      }
    </>
    )
};

export default PageLayout;

const Container = styled.div<{$blurred: boolean}>`
  min-height: 100vh;
  transition : filter ${transitions.flash};
  /* ${p => p.$blurred ? 'filter : blur(0.8rem) brightness(0.8)' : ''}; */
`;

const PageContent = styled.main`
  min-height : 100vh;
  margin-left: 21.9rem;
  background-color: ${colors.background};
`;

const Header = styled.div`
  top : 0;
  left : 0;
  position: fixed;
  z-index : 4;
  display : flex;
  width : 100%;
  height : 16rem;
  flex-direction : row;
  padding-top : 4rem;
  padding-right : 4.5rem;
  padding-left : 22rem;
  background-color: ${colors.background};
`;

const LeftHeaderButtons = styled.div`
  width : 4.5rem;
  padding-top : 2rem;
  display: flex;
  justify-content: center;
`;

const BackIconContainer = styled.div`
  width : 2rem;
  height : 5rem;
`

const MiddleHeader = styled.div`
  flex : 1;
`;

const BreadCrumbContainer = styled.div`
  min-height : 2rem;
`

const MainTitleContainer = styled.div`
  margin-top : 1rem;
  min-height : 5rem;
`

const SubtitleContainer = styled.div`
  min-height : 2rem;
`

const RightHeader = styled.div`

`

const ControlsContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top : 1rem;
  & button {
    margin-left : ${spacing}rem;
  }
`;

const ViewControlsContainer = styled.div`
  position: absolute;
  right: 0;
  margin-right: ${3*spacing}rem;
  margin-top: ${spacing}rem;
`

const ChildrenContainer = styled.div`
  margin-left: 4.5rem;
  margin-right : 4.5rem;
  padding-top : 16rem;
  min-height : 100vh;
  display: flex;
  flex-direction: column;
`

const BreadcrumbSeparator = styled(Typography)`
  margin-right : ${spacing}rem;
  margin-left :  ${spacing}rem;
`