import { colors } from "../../styles";
import IconProps from "./icon-interface";

const VideoIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.primary;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.7455 25.0853C12.1871 25.0825 11.6487 24.8796 11.23 24.5143C10.8113 24.1489 10.5408 23.6458 10.4685 23.0982C9.94732 19.1058 9.94732 15.0637 10.4685 11.0713C10.5412 10.524 10.812 10.0214 11.2306 9.65624C11.6492 9.29113 12.1874 9.08832 12.7455 9.08533C13.0067 9.08498 13.266 9.1292 13.5119 9.21604L14.2373 9.47088C18.2049 10.8629 21.9229 12.8711 25.2515 15.4201C25.5086 15.6169 25.7168 15.8693 25.86 16.1579C26.0032 16.4466 26.0776 16.7638 26.0776 17.0853C26.0776 17.4068 26.0032 17.7241 25.86 18.0127C25.7168 18.3014 25.5086 18.5538 25.2515 18.7506C21.9227 21.2992 18.2047 23.3074 14.2373 24.6998L13.5119 24.9535C13.266 25.0407 13.0068 25.0853 12.7455 25.0853ZM12.7455 10.7352C12.5912 10.736 12.4423 10.7921 12.3265 10.8931C12.2107 10.994 12.1359 11.1331 12.1157 11.2844C11.6123 15.1342 11.6123 19.0321 12.1157 22.8818C12.1359 23.0332 12.2107 23.1722 12.3265 23.2732C12.4423 23.3742 12.5912 23.4302 12.7455 23.4311C12.8133 23.431 12.8805 23.4195 12.9444 23.397L13.6786 23.1455C17.4796 21.8115 21.0416 19.8875 24.2308 17.4456C24.2868 17.4028 24.3321 17.3479 24.3633 17.2851C24.3945 17.2222 24.4108 17.1531 24.4108 17.0831C24.4108 17.0131 24.3945 16.944 24.3633 16.8812C24.3321 16.8184 24.2868 16.7634 24.2308 16.7206C21.0414 14.2792 17.4794 12.3552 13.6786 11.0208L12.9543 10.7671C12.8902 10.7445 12.8225 10.7329 12.7544 10.733L12.7455 10.7352Z" fill={color}/>
      <circle cx="16.6899" cy="17.0842" r="15.7813" stroke={color} stroke-width="1.5"/>
    </svg>
  )
}

export default VideoIcon;

