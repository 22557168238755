import { GetFunction, SetFunction, ActionsFactory, ModelStore, Page, Upload, ID, NotebookGenerationProgress, Attachment } from "../store-types";

const rtActions : ActionsFactory<ModelStore['actions']['rt'], ModelStore> =  (set : SetFunction<ModelStore>, get : GetFunction<ModelStore>) => ({
  newPage : (page : Page) => {
    set(state => {
      state.uploads[page.uploadId] = {
        id : page.uploadId,
        status : 'running',
        pages : [...(state.uploads[page.uploadId]?.pages || []), page].sort((a, b) => a.page < b.page ? -1 : 1)
      }
    });
  },

  importProgress : (uploadId : ID, page : number, total : number) => {
    set(state => {
      state.process.progress[`uploads-${uploadId}`] = {
        progress : page,
        total
      }
    })
  },

  importEnd : (uploadId : ID) => {
    setTimeout(async () => {
      // Load state and pages of current upload
      await get().actions.uploads.load(uploadId);

      // Test if currently edited notebook hand uloads that needs updates
      get().actions.notebookEditor.updateUploads();

      /*
      Waits two seconds before loading pages and status because
      'import-end' event is emitted before database status is
      switched to 'done'
      */
    }, 2000)
  },

  generationProgress : ({proposalId, notebookId, progress, total} : NotebookGenerationProgress) => {
    set(state => {
      state.process.progress[`generation-${proposalId}-${notebookId}`] = {
        progress : progress,
        total : total
      }
    })
  },

  notebookGenerated  : ({proposalId, notebookId, progress, total} : NotebookGenerationProgress) => {
    set(state => {
      state.process.progress[`generation-${proposalId}-${notebookId}`] = {
        progress : progress,
        total : total
      }
      state.proposals.generated[`${proposalId}-${notebookId}`] = true;
    })
  },

  attachmentThumbnailCreated : ({type, attachment}) => {
    set(state => {
      if(type === 'public') {
        state.attachments = state.attachments.map(_a => _a.id === attachment.id ? {..._a, thumbnailStorageName : attachment.thumbnailStorageName} : _a);
      }
      else if (type === 'private') {
        state.privateAttachments = state.privateAttachments.map(_a => _a.id === attachment.id ? {..._a, thumbnailStorageName : attachment.thumbnailStorageName} : _a);
      }
    })
  },

  videoScreenshotCreated : ({type, video}) => {
    set(state => {
      if(type === 'public') {
        state.videos = state.videos.map(_v => _v.id === video.id ? {..._v, thumbnailStorageName : video.thumbnailStorageName} : _v);
      }
      else if(type === 'private') {
        state.privateVideos = state.privateVideos.map(_v => _v.id === video.id ? {..._v, thumbnailStorageName : video.thumbnailStorageName} : _v);
      }
    })
  }
})

export default rtActions;