import styled from 'styled-components';
import { useAssertAdmin } from '../hooks/use-assert-admin';
import PageLayout from '../components/layout/page-layout';
import { useTranslation } from 'react-i18next';
import Typography from '../components/atoms/typography';
import { useModelStore } from '../store/store';
import { Market } from '../store/store-types';
import List from '../components/atoms/list';
import TextButton from '../components/atoms/text-button';
import TrashIcon from '../assets/icons/trash';
import { colors, typography } from '../styles';
import EditIcon from '../assets/icons/edit';
import MarketModal from '../components/molecules/modals/market-modal';
import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useModelActions } from '../store/hooks/use-actions';
import HTMLTypography from '../components/atoms/html-typography';
import UnarchiveIcon from '../assets/icons/unarchive';
import ArchiveIcon from '../assets/icons/archive';
import { useAssertSuperAdmin } from '../hooks/use-assert-superadmin';
import { AVAILABLE_FONTS } from '../constants/fonts';
import SelectDropdown from '../components/atoms/select-dropdown';
import {set, get, unset, cloneDeep} from 'lodash';
import axios from 'axios';
import configProvider from '../providers/configprovider';
import ToolButton from '../components/atoms/tool-button';

interface OverlaySettingsPage {

}

const OverlaySettingsPage : React.FC<OverlaySettingsPage> = (p) => {
  useAssertSuperAdmin();
  const {t} = useTranslation();
  const [values, setValues] = useState<any>({});

  useEffect(() => {
    axios.get(`${configProvider('PDF_SERVICE_URL')}/config`).then(({data}) => {
      setValues(data);
    })
  }, [])

  const update = (path : string) => (event : ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;

    setValues((v : any) => {
      const copy = cloneDeep(v);
      set(copy, path, value);

      return copy;
    })
  }

  const updateFont = (path : string) => (value : string) => {
    setValues((v : any) => {
      const copy = cloneDeep(v);
      set(copy, path, value);

      return copy;
    })
  }

  const clear = (path : string) => () => {
    setValues((v : any) => {
      const copy = cloneDeep(v);
      unset(copy, path);

      return copy;
    })
  }


  console.log(values);

  const isValidNumber = useCallback((path : string, name : string) => {
    const value = get(values, path);
    if(value === undefined || value === '') {return true;}
    const invalid = isNaN(parseFloat(value));
    if(invalid) {
      alert('Le format du champ "' + name  + '" est invalide');
    }
    return !invalid
  }, [values]);

  const isValidColor = useCallback((path : string, name : string) => {
    const value = get(values, path);
    if(value === undefined || value === '') {return true;}
    const invalid = typeof value !== "string" || !value.match(/#[a-fA-F0-9]{6,6}/);
    if(invalid) {
      alert('Le format du champ "' + name  + '" est invalide');
    }
    return !invalid

  }, [values]);

  const handleSave = () => {
    if(
      isValidNumber('firstPage.logo.x', 'Première page - Logo x') &&
      isValidNumber('firstPage.logo.y', 'Première page - Logo y') &&
      isValidNumber('firstPage.logo.width', 'Première page - Largeur logo') &&
      isValidNumber('firstPage.logo.height', 'Première page - Hauteur logo') &&
      isValidNumber('firstPage.customerName.x', 'Première page - Nom du client x') &&
      isValidNumber('firstPage.customerName.y', 'Première page - Nom du client y') &&
      isValidNumber('firstPage.customerName.fontSize', 'Première page - Nom du client : taille de police') &&
      isValidColor('firstPage.customerName.fontColor', 'Première page - Nom du client : couleur de police') &&
      isValidNumber('firstPage.propositionName.x', 'Première page - Nom de la proposition x') &&
      isValidNumber('firstPage.propositionName.y', 'Première page - Nom de la proposition y') &&
      isValidNumber('firstPage.propositionName.fontSize', 'Première page - Nom de la proposition : taille de police') &&
      isValidColor('firstPage.propositionName.fontColor', 'Première page - Nom de la proposition : couleur de police') &&
      isValidNumber('firstPage.validity.x', 'Première page - Dates de validité x') &&
      isValidNumber('firstPage.validity.y', 'Première page - Dates de validité y') &&
      isValidNumber('firstPage.validity.fontSize', 'Première page - Dates de validité : taille de police') &&
      isValidColor('firstPage.validity.fontColor', 'Première page - Dates de validité : couleur de police') &&
      isValidNumber('lastPage.logo.x', 'Dernière page - Logo x') &&
      isValidNumber('lastPage.logo.y', 'Dernière page - Logo y') &&
      isValidNumber('lastPage.logo.width', 'Dernière page - Largeur logo') &&
      isValidNumber('lastPage.logo.height', 'Dernière page - Hauteur logo') &&
      isValidNumber('lastPage.customerName.x', 'Dernière page - Nom du client x') &&
      isValidNumber('lastPage.customerName.y', 'Dernière page - Nom du client y') &&
      isValidNumber('lastPage.customerName.fontSize', 'Dernière page - Nom du client : taille de police') &&
      isValidColor('lastPage.customerName.fontColor', 'Dernière page - Nom du client : couleur de police') &&
      isValidNumber('lastPage.propositionName.x', 'Dernière page - Nom de la proposition x') &&
      isValidNumber('lastPage.propositionName.y', 'Dernière page - Nom de la proposition y') &&
      isValidNumber('lastPage.propositionName.fontSize', 'Dernière page - Nom de la proposition : taille de police') &&
      isValidColor('lastPage.propositionName.fontColor', 'Dernière page - Nom de la proposition : couleur de police') &&
      isValidNumber('lastPage.validity.x', 'Dernière page - Dates de validité x') &&
      isValidNumber('lastPage.validity.y', 'Dernière page - Dates de validité y') &&
      isValidNumber('lastPage.validity.fontSize', 'Dernière page - Dates de validité : taille de police') &&
      isValidColor('lastPage.validity.fontColor', 'Dernière page - Dates de validité : couleur de police') &&
      isValidColor('summary.color', 'Sommaire : couleur de police')
    ) {
      axios.post(`${configProvider('PDF_SERVICE_URL')}/config`, values)
    }
  }

  return (
    <PageLayout
      title={t('pages.overlaySettings.name')}
      subtitle={t('pages.overlaySettings.subtitle')}
      controls={<TextButton onClick={handleSave} color="dark">{t('pages.overlaySettings.save')}</TextButton>}
    >
      <Container>
        <PageTitle><Typography variant='bigTitle' color="text">Sommaire</Typography></PageTitle>
        <Row>
          <div style={{marginRight : "2rem"}}>
            <Typography variant='smallTextRegular' color="secondaryText" > </Typography><br/>
            <ToolButton onClick={clear('summary')} color="light"><TrashIcon color="black"/></ToolButton>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText" >Couleur du sommaire (format #XXXXXX)</Typography><br/>
            <Input placeholder="Couleur" value={values?.summary?.color || ''} onChange={update('summary.color')}/>
          </div>
        </Row>

        <PageTitle><Typography variant='bigTitle' color="text">Première page</Typography></PageTitle>

        <SectionTitle><Typography variant='mediumTitle' color="primary">Logo</Typography></SectionTitle>
        <Row>
          <div style={{marginRight : "2rem"}}>
            <Typography variant='smallTextRegular' color="secondaryText" > </Typography><br/>
            <ToolButton onClick={clear('firstPage.logo')} color="light"><TrashIcon color="black"/></ToolButton>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText" >Position : x</Typography><br/>
            <Input placeholder="Position : x" value={values?.firstPage?.logo?.x || ''} onChange={update('firstPage.logo.x')}/>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText">Position : y</Typography><br/>
            <Input placeholder="Position : y" value={values?.firstPage?.logo?.y || ''} onChange={update('firstPage.logo.y')}/>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText">Largeur</Typography><br/>
            <Input placeholder="Largeur" value={values?.firstPage?.logo?.width || ''} onChange={update('firstPage.logo.width')}/>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText">Hauteur</Typography><br/>
            <Input placeholder="Hauteur" value={values?.firstPage?.logo?.height || ''} onChange={update('firstPage.logo.height')}/>
          </div>
        </Row>

        <SectionTitle><Typography variant='mediumTitle' color="primary">Nom du client</Typography></SectionTitle>
        <Row>
          <div style={{marginRight : "2rem"}}>
            <Typography variant='smallTextRegular' color="secondaryText" > </Typography><br/>
            <ToolButton onClick={clear('firstPage.customerName')} color="light"><TrashIcon color="black"/></ToolButton>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText" >Position : x</Typography><br/>
            <Input placeholder="Position : x" value={values?.firstPage?.customerName?.x || ''} onChange={update('firstPage.customerName.x')}/>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText">Position : y</Typography><br/>
            <Input placeholder="Position : y" value={values?.firstPage?.customerName?.y || ''} onChange={update('firstPage.customerName.y')}/>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText">Taille de la police</Typography><br/>
            <Input placeholder="Taille de la police" value={values?.firstPage?.customerName?.fontSize || ''} onChange={update('firstPage.customerName.fontSize')}/>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText">Couleur (format #XXXXXX)</Typography><br/>
            <Input placeholder="Couleur" value={values?.firstPage?.customerName?.fontColor || ''} onChange={update('firstPage.customerName.fontColor')}/>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText">Police</Typography><br/>
            <SelectDropdown
              nullable={false}
              choices={AVAILABLE_FONTS}
              value={values?.firstPage?.customerName?.fontFamily}
              onChange={updateFont('firstPage.customerName.fontFamily')}
              placeholder={t('modals.textArea.fontPlaceholder')}
            />
          </div>
        </Row>


        <SectionTitle><Typography variant='mediumTitle' color="primary">Nom de la proposition</Typography></SectionTitle>
        <Row>
          <div style={{marginRight : "2rem"}}>
            <Typography variant='smallTextRegular' color="secondaryText" > </Typography><br/>
            <ToolButton onClick={clear('firstPage.propositionName')} color="light"><TrashIcon color="black"/></ToolButton>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText" >Position : x</Typography><br/>
            <Input placeholder="Position : x" value={values?.firstPage?.propositionName?.x || ''} onChange={update('firstPage.propositionName.x')}/>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText">Position : y</Typography><br/>
            <Input placeholder="Position : y" value={values?.firstPage?.propositionName?.y || ''} onChange={update('firstPage.propositionName.y')}/>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText">Taille de la police</Typography><br/>
            <Input placeholder="Taille de la police" value={values?.firstPage?.propositionName?.fontSize || ''} onChange={update('firstPage.propositionName.fontSize')}/>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText">Couleur (format #XXXXXX)</Typography><br/>
            <Input placeholder="Couleur" value={values?.firstPage?.propositionName?.fontColor || ''} onChange={update('firstPage.propositionName.fontColor')}/>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText">Police</Typography><br/>
            <SelectDropdown
              nullable={false}
              choices={AVAILABLE_FONTS}
              value={values?.firstPage?.propositionName?.fontFamily}
              onChange={updateFont('firstPage.propositionName.fontFamily')}
              placeholder={t('modals.textArea.fontPlaceholder')}
            />
          </div>
        </Row>


        <SectionTitle><Typography variant='mediumTitle' color="primary">Dates de validité</Typography></SectionTitle>
        <Row>
          <div style={{marginRight : "2rem"}}>
            <Typography variant='smallTextRegular' color="secondaryText" > </Typography><br/>
            <ToolButton onClick={clear('firstPage.validity')} color="light"><TrashIcon color="black"/></ToolButton>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText" >Position : x</Typography><br/>
            <Input placeholder="Position : x" value={values?.firstPage?.validity?.x || ''} onChange={update('firstPage.validity.x')}/>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText">Position : y</Typography><br/>
            <Input placeholder="Position : y" value={values?.firstPage?.validity?.y || ''} onChange={update('firstPage.validity.y')}/>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText">Taille de la police</Typography><br/>
            <Input placeholder="Taille de la police" value={values?.firstPage?.validity?.fontSize || ''} onChange={update('firstPage.validity.fontSize')}/>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText">Couleur (format #XXXXXX)</Typography><br/>
            <Input placeholder="Couleur" value={values?.firstPage?.validity?.fontColor || ''} onChange={update('firstPage.validity.fontColor')}/>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText">Police</Typography><br/>
            <SelectDropdown
              nullable={false}
              choices={AVAILABLE_FONTS}
              value={values?.firstPage?.validity?.fontFamily}
              onChange={updateFont('firstPage.validity.fontFamily')}
              placeholder={t('modals.textArea.fontPlaceholder')}
            />
          </div>
        </Row>

        <PageTitle><Typography variant='bigTitle' color="text">Dernière page</Typography></PageTitle>

        <SectionTitle><Typography variant='mediumTitle' color="primary">Logo</Typography></SectionTitle>
        <Row>
          <div style={{marginRight : "2rem"}}>
            <Typography variant='smallTextRegular' color="secondaryText" > </Typography><br/>
            <ToolButton onClick={clear('lastPage.logo')} color="light"><TrashIcon color="black"/></ToolButton>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText" >Position : x</Typography><br/>
            <Input placeholder="Position : x" value={values?.lastPage?.logo?.x || ''} onChange={update('lastPage.logo.x')}/>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText">Position : y</Typography><br/>
            <Input placeholder="Position : y" value={values?.lastPage?.logo?.y || ''} onChange={update('lastPage.logo.y')}/>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText">Largeur</Typography><br/>
            <Input placeholder="Largeur" value={values?.lastPage?.logo?.width || ''} onChange={update('lastPage.logo.width')}/>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText">Hauteur</Typography><br/>
            <Input placeholder="Hauteur" value={values?.lastPage?.logo?.height || ''} onChange={update('lastPage.logo.height')}/>
          </div>
        </Row>

        <SectionTitle><Typography variant='mediumTitle' color="primary">Nom du client</Typography></SectionTitle>
        <Row>
          <div style={{marginRight : "2rem"}}>
            <Typography variant='smallTextRegular' color="secondaryText" > </Typography><br/>
            <ToolButton onClick={clear('lastPage.customerName')} color="light"><TrashIcon color="black"/></ToolButton>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText" >Position : x</Typography><br/>
            <Input placeholder="Position : x" value={values?.lastPage?.customerName?.x || ''} onChange={update('lastPage.customerName.x')}/>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText">Position : y</Typography><br/>
            <Input placeholder="Position : y" value={values?.lastPage?.customerName?.y || ''} onChange={update('lastPage.customerName.y')}/>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText">Taille de la police</Typography><br/>
            <Input placeholder="Taille de la police" value={values?.lastPage?.customerName?.fontSize || ''} onChange={update('lastPage.customerName.fontSize')}/>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText">Couleur (format #XXXXXX)</Typography><br/>
            <Input placeholder="Couleur" value={values?.lastPage?.customerName?.fontColor || ''} onChange={update('lastPage.customerName.fontColor')}/>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText">Police</Typography><br/>
            <SelectDropdown
              nullable={false}
              choices={AVAILABLE_FONTS}
              value={values?.lastPage?.customerName?.fontFamily}
              onChange={updateFont('lastPage.customerName.fontFamily')}
              placeholder={t('modals.textArea.fontPlaceholder')}
            />
          </div>
        </Row>


        <SectionTitle><Typography variant='mediumTitle' color="primary">Nom de la proposition</Typography></SectionTitle>
        <Row>
          <div style={{marginRight : "2rem"}}>
            <Typography variant='smallTextRegular' color="secondaryText" > </Typography><br/>
            <ToolButton onClick={clear('lastPage.propositionName')} color="light"><TrashIcon color="black"/></ToolButton>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText" >Position : x</Typography><br/>
            <Input placeholder="Position : x" value={values?.lastPage?.propositionName?.x || ''} onChange={update('lastPage.propositionName.x')}/>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText">Position : y</Typography><br/>
            <Input placeholder="Position : y" value={values?.lastPage?.propositionName?.y || ''} onChange={update('lastPage.propositionName.y')}/>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText">Taille de la police</Typography><br/>
            <Input placeholder="Taille de la police" value={values?.lastPage?.propositionName?.fontSize || ''} onChange={update('lastPage.propositionName.fontSize')}/>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText">Couleur (format #XXXXXX)</Typography><br/>
            <Input placeholder="Couleur" value={values?.lastPage?.propositionName?.fontColor || ''} onChange={update('lastPage.propositionName.fontColor')}/>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText">Police</Typography><br/>
            <SelectDropdown
              nullable={false}
              choices={AVAILABLE_FONTS}
              value={values?.lastPage?.propositionName?.fontFamily}
              onChange={updateFont('lastPage.propositionName.fontFamily')}
              placeholder={t('modals.textArea.fontPlaceholder')}
            />
          </div>
        </Row>


        <SectionTitle><Typography variant='mediumTitle' color="primary">Dates de validité</Typography></SectionTitle>
        <Row>
          <div style={{marginRight : "2rem"}}>
            <Typography variant='smallTextRegular' color="secondaryText" > </Typography><br/>
            <ToolButton onClick={clear('lastPage.validity')} color="light"><TrashIcon color="black"/></ToolButton>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText" >Position : x</Typography><br/>
            <Input placeholder="Position : x" value={values?.lastPage?.validity?.x || ''} onChange={update('lastPage.validity.x')}/>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText">Position : y</Typography><br/>
            <Input placeholder="Position : y" value={values?.lastPage?.validity?.y || ''} onChange={update('lastPage.validity.y')}/>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText">Taille de la police</Typography><br/>
            <Input placeholder="Taille de la police" value={values?.lastPage?.validity?.fontSize || ''} onChange={update('lastPage.validity.fontSize')}/>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText">Couleur (format #XXXXXX)</Typography><br/>
            <Input placeholder="Couleur" value={values?.lastPage?.validity?.fontColor || ''} onChange={update('lastPage.validity.fontColor')}/>
          </div>
          <div>
            <Typography variant='smallTextRegular' color="secondaryText">Police</Typography><br/>
            <SelectDropdown
              nullable={false}
              choices={AVAILABLE_FONTS}
              value={values?.lastPage?.validity?.fontFamily}
              onChange={updateFont('lastPage.validity.fontFamily')}
              placeholder={t('modals.textArea.fontPlaceholder')}
            />
          </div>
        </Row>


      </Container>
    </PageLayout>
  )
}

export default OverlaySettingsPage;

const Container = styled.div`
`

const Row = styled.div`
  display: flex;
`

const PageTitle = styled.div`
  margin-top : 4rem;
`

const SectionTitle = styled.div`
  margin-top : 3rem;
  margin-bottom : 1rem;
`

const Input = styled.input`
  border : none;
  padding : 1rem;
  border-radius : 1rem;
  margin-right : 2rem;
  ${typography.textRegular.css}

  &::placeholder {
    color : ${colors.secondaryText}
  }
`