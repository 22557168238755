import { colors } from "../../styles";
import IconProps from "./icon-interface";

const SelectAllIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.primary;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="15.5" y="9.11536" width="6.38462" height="6.38462" rx="0.5" transform="rotate(90 15.5 9.11536)" stroke={color}/>
    <rect x="6.88464" y="9.11536" width="6.38462" height="6.38462" rx="0.5" transform="rotate(90 6.88464 9.11536)" stroke={color}/>
    <rect x="6.88464" y="0.5" width="6.38462" height="6.38462" rx="0.5" transform="rotate(90 6.88464 0.5)" stroke={color}/>
    <rect x="5.14703" y="2.38232" width="2.76471" height="2.76471" transform="rotate(90 5.14703 2.38232)" fill={color} stroke={color}/>
    <rect x="13.6177" y="2.38232" width="2.76471" height="2.76471" transform="rotate(90 13.6177 2.38232)" fill={color} stroke={color}/>
    <rect x="13.6177" y="10.8529" width="2.76471" height="2.76471" transform="rotate(90 13.6177 10.8529)" fill={color} stroke={color}/>
    <rect x="5.14703" y="10.8529" width="2.76471" height="2.76471" transform="rotate(90 5.14703 10.8529)" fill={color} stroke={color}/>
    <rect x="15.5" y="0.5" width="6.38462" height="6.38462" rx="0.5" transform="rotate(90 15.5 0.5)" stroke={color}/>
    </svg>
    
  )
}

export default SelectAllIcon;
