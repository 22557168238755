import { colors } from "../../styles";
import IconProps from "./icon-interface";

const DashboardIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.text;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="mask0_657_286" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="16">
      <path d="M17.8151 0H0V16H17.8151V0Z" fill="white"/>
      </mask>
      <g mask="url(#mask0_657_286)">
      <path d="M7.0141 0.16626H1.34394C1.03656 0.16626 0.741772 0.275925 0.524422 0.471129C0.307073 0.666334 0.184967 0.931088 0.184967 1.20715L0.184967 4.36004C0.184967 4.6361 0.307073 4.90085 0.524422 5.09606C0.741772 5.29126 1.03656 5.40093 1.34394 5.40093H7.0141C7.32096 5.40022 7.61499 5.29024 7.8317 5.09512C8.04841 4.89999 8.1701 4.63564 8.1701 4.36004V1.20715C8.1701 0.931549 8.04841 0.667198 7.8317 0.47207C7.61499 0.276942 7.32096 0.166966 7.0141 0.16626ZM7.13485 4.17159C7.13485 4.2058 7.12735 4.23966 7.11277 4.27126C7.0982 4.30286 7.07684 4.33157 7.04991 4.35576C7.02298 4.37994 6.99101 4.39912 6.95583 4.41221C6.92065 4.4253 6.88294 4.43204 6.84486 4.43204H1.52506C1.48698 4.43204 1.44927 4.4253 1.41409 4.41221C1.3789 4.39912 1.34693 4.37994 1.32001 4.35576C1.29308 4.33157 1.27172 4.30286 1.25714 4.27126C1.24257 4.23966 1.23507 4.2058 1.23507 4.17159V1.37248C1.23507 1.30341 1.26562 1.23716 1.32001 1.18832C1.37439 1.13948 1.44815 1.11204 1.52506 1.11204H6.84189C6.9188 1.11204 6.99256 1.13948 7.04694 1.18832C7.10132 1.23716 7.13188 1.30341 7.13188 1.37248L7.13485 4.17159Z" fill={color}/>
      <path d="M16.4719 10.6H10.8047C10.4974 10.6 10.2026 10.7096 9.98525 10.9049C9.7679 11.1001 9.64578 11.3648 9.64578 11.6409V14.7938C9.64578 15.0698 9.7679 15.3346 9.98525 15.5298C10.2026 15.725 10.4974 15.8346 10.8047 15.8346H16.4719C16.7793 15.8346 17.0741 15.725 17.2915 15.5298C17.5088 15.3346 17.6309 15.0698 17.6309 14.7938V11.6409C17.6309 11.3648 17.5088 11.1001 17.2915 10.9049C17.0741 10.7096 16.7793 10.6 16.4719 10.6ZM16.5868 14.6169C16.5868 14.6859 16.5562 14.7522 16.5018 14.801C16.4474 14.8499 16.3737 14.8773 16.2968 14.8773H10.977C10.9001 14.8773 10.8263 14.8499 10.7719 14.801C10.7175 14.7522 10.687 14.6859 10.687 14.6169V11.8177C10.687 11.7487 10.7175 11.6824 10.7719 11.6336C10.8263 11.5847 10.9001 11.5573 10.977 11.5573H16.2938C16.3707 11.5573 16.4445 11.5847 16.4989 11.6336C16.5532 11.6824 16.5838 11.7487 16.5838 11.8177L16.5868 14.6169Z" fill={color}/>
      <path d="M16.4719 0.165283H10.8047C10.4974 0.165283 10.2026 0.274948 9.98525 0.470153C9.7679 0.665357 9.64578 0.930111 9.64578 1.20617V8.21328C9.64578 8.48934 9.7679 8.7541 9.98525 8.9493C10.2026 9.14451 10.4974 9.25417 10.8047 9.25417H16.4719C16.7793 9.25417 17.0741 9.14451 17.2915 8.9493C17.5088 8.7541 17.6309 8.48934 17.6309 8.21328V1.20706C17.631 1.07029 17.6012 0.934846 17.543 0.808457C17.4848 0.682068 17.3994 0.567216 17.2918 0.470467C17.1842 0.373717 17.0564 0.296966 16.9157 0.244601C16.775 0.192236 16.6242 0.165283 16.4719 0.165283ZM16.5868 8.08351C16.5868 8.15258 16.5562 8.21882 16.5018 8.26767C16.4474 8.31651 16.3737 8.34395 16.2968 8.34395H10.977C10.9001 8.34395 10.8263 8.31651 10.7719 8.26767C10.7175 8.21882 10.687 8.15258 10.687 8.08351V1.37239C10.687 1.30332 10.7175 1.23708 10.7719 1.18823C10.8263 1.13939 10.9001 1.11195 10.977 1.11195H16.2938C16.3707 1.11195 16.4445 1.13939 16.4989 1.18823C16.5532 1.23708 16.5838 1.30332 16.5838 1.37239L16.5868 8.08351Z" fill={color}/>
      <path d="M7.01714 6.71545H1.34995C1.04257 6.71545 0.747784 6.82511 0.530434 7.02032C0.313085 7.21552 0.190979 7.48027 0.190979 7.75633V14.7626C0.190979 15.0386 0.313085 15.3034 0.530434 15.4986C0.747784 15.6938 1.04257 15.8035 1.34995 15.8035H7.01714C7.32452 15.8035 7.61931 15.6938 7.83666 15.4986C8.05401 15.3034 8.17612 15.0386 8.17612 14.7626V7.75633C8.17612 7.48027 8.05401 7.21552 7.83666 7.02032C7.61931 6.82511 7.32452 6.71545 7.01714 6.71545ZM7.13195 14.6337C7.13195 14.7027 7.1014 14.769 7.04701 14.8178C6.99263 14.8667 6.91887 14.8941 6.84196 14.8941H1.52513C1.44822 14.8941 1.37446 14.8667 1.32008 14.8178C1.2657 14.769 1.23514 14.7027 1.23514 14.6337V7.92167C1.23514 7.8526 1.2657 7.78635 1.32008 7.73751C1.37446 7.68867 1.44822 7.66123 1.52513 7.66123H6.84592C6.92283 7.66123 6.99659 7.68867 7.05097 7.73751C7.10536 7.78635 7.13591 7.8526 7.13591 7.92167L7.13195 14.6337Z" fill={color}/>
      </g>
    </svg>
  )
}

export default DashboardIcon;
