import { useTranslation } from "react-i18next";
import Modal from "../modal";
import { colors, shadows, spacing, typography } from "../../../styles";
import styled from "styled-components";
import { useModelStore, useUserStore } from "../../../store/store";
import { useRef, useState } from "react";
import TextButton from "../../atoms/text-button";
import { Attachment, ID, User } from "../../../store/store-types";
import Select from "../../atoms/select";
import { ADMIN, ROLES, SUPERADMIN, USER } from "../../../constants/roles";
import NewUserIcon from "../../../assets/icons/new-user";
import { useModelActions } from "../../../store/hooks/use-actions";
import { msal } from "../../../providers/msal";
import useDebounce from "../../../hooks/use-debounce";
import Typography from "../../atoms/typography";
import DocumentIcon from "../../../assets/icons/document";
import UploadIcon from "../../../assets/icons/upload";

interface AttachmentModalProps {
  onClose : () => void;
  attachment ?: Attachment;
  private ?: boolean;
}

const AttachmentModal : React.FC<AttachmentModalProps> = (p) => {
  const {t} = useTranslation();
  const markets = useModelStore(state => state.markets.filter(({inactive}) => !inactive));
  const loading = useModelStore(state => state.process.loading['attachments.create'] || state.process.loading['attachments.update']);
  const { create, createPrivate } = useModelActions('attachments');
  const [editedName, setEditedName] = useState<string | undefined>(p.attachment?.name);
  const [editedMarketsIds, setEditedMarketsIds] = useState<ID[] | undefined>(p.attachment?.marketsIds);
  const [selectedFile, setSelectedFile] = useState<File>();

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleSubmit = async () => {
    if(selectedFile && editedName?.trim()) {
      if(p.private) {
        await createPrivate(selectedFile, editedName);
      }
      else if(editedMarketsIds) {
        await create(selectedFile, editedName, editedMarketsIds);
      }

      p.onClose();
    }
  }

  const handleChangeName = (name : string) => {
    setEditedName(name)
  }

  const handleFileClick = () => {
    fileInputRef.current?.click();
  }

  const handleSelectFile = async(file : File) => {
    setSelectedFile(file);
  }

  return (
    <Modal
      title={t('modals.attachment.title')}
      subtitle={t('modals.attachment.subtitle')}
      icon={<DocumentIcon color={colors.text}/>}
      onClose={p.onClose}
      controls={[
        <TextButton key="validate" disabled={loading || !selectedFile || (!p.private && (editedMarketsIds === p.attachment?.marketsIds || !editedMarketsIds?.length)) || editedName === p.attachment?.name} onClick={handleSubmit}>{t('validate')}</TextButton>
      ]}
    >
      <Container>
        <NameInput
          placeholder={t('modals.attachment.namePlaceholder')}
          value={editedName}
          onChange={e => handleChangeName(e.target.value)}
        />
        <SecondRow>
        <InputsContainer onClick={handleFileClick}>
          {
            selectedFile ?
              <Typography variant="textBold">{selectedFile.name}</Typography> :
              <Typography variant="textBold" color="secondaryText">{t('modals.attachment.filePlaceholder')}</Typography>

          }
          <div>
            <UploadIcon width="1.5rem" color={colors.secondaryText}/>
          </div>
          <FileInput
            ref={fileInputRef}
            type="file"
            accept={'application/pdf'}
            placeholder={t('modals.attachment.filePlaceholder')}
            onChange={e => e.target.files?.[0] && handleSelectFile(e.target.files?.[0])}
            />
        </InputsContainer>
        {
          p.private ? null :
          <DropDownContainer>
            <Select<ID>
              choices={markets.map(market => ({label : market.name, value : market.id}))}
              placeholder={t('pages.users.marketPlaceholder')}
              value={editedMarketsIds}
              onChange={value => setEditedMarketsIds(value)}
              multiselect
              color="accent"
            />
          </DropDownContainer>
        }
        </SecondRow>
      </Container>
    </Modal>
  );
}

export default AttachmentModal;

const Container = styled.div`
  background-color: ${colors.background};
  border-radius: 1rem;
  padding : ${spacing}rem;
`;

const SecondRow = styled.div`
  display: flex;
  align-items: center;
`


const InputsContainer = styled.div`
  margin-top: ${spacing}rem;
  flex : 1;
  background-color: ${colors.background2};
  border-radius: 1rem;
  padding: 1rem;
  position : relative;
  align-items: center;
  display: flex;
  justify-content: space-between;
`

const NameInput = styled.input`
  margin-top: ${spacing}rem;
  min-width: 60rem;
  padding : 1rem;
  border-radius: 1rem;
  flex : 1;
  z-index: 10;
  color : ${colors.text};
  &::placeholder {
    color : ${colors.tertiaryText};
  }
  ${typography.bigTitle.css};
  border : none;
  background-color: ${colors.background2};
  outline: none;
  width : 100%;
`

const FileInput = styled.input`
  position: absolute;
  visibility: hidden;
`


const DropDownContainer = styled.div`
  margin-top: ${spacing}rem;
  margin-left : ${spacing}rem;
  display: flex;
`





