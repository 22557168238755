import { useEffect, useState } from "react";
import InlineDropdown from "../../atoms/inline-dropdown";
import DatePickerMenu from "./date-picker-menu";
import useToggler from "../../../hooks/use-toggler";

interface DatePickerProps {
  value ?: Date;
  onChange ?: (date : Date) => void;
  inverted ?: boolean;
  title ?: string;
  subtitle ?: string;
  isActive ?: (date : Date) => boolean;
  disabled ?: boolean;
}

const DatePicker : React.FC<DatePickerProps> = (p) => {
  const [open, setOpen] = useState<boolean>(false);
  const selectedDate = p.value ? new Date(p.value) : undefined;

  const {toggle, addCallback, removeCallback} = useToggler('dropdown');

  useEffect(() => {
    addCallback(() => setOpen(false));

    return removeCallback;
  }, [])


  const handleSelect = (date : Date) => {
    p.onChange?.(date);
    setOpen(false);
  }

  const handleOpen = (open : boolean) => {
    if(p.disabled) {return;}
    if(open) {toggle();}
    setOpen(open);
  }

  return (
    <InlineDropdown
      open={!p.disabled ? open : false}
      setOpen={handleOpen}
      inverted={p.inverted}
      label={selectedDate ? selectedDate.toLocaleDateString() : 'JJ/MM/AAAA'}
      color={selectedDate ? 'text' : 'tertiaryText'}
      hideChevron={p.disabled}
    >
      <DatePickerMenu
        selected={p.value}
        onSelect={handleSelect}
        title={p.title}
        subtitle={p.subtitle}
        isActive={p.isActive}
      />
    </InlineDropdown>
  );
}

export default DatePicker;

