import { useTranslation } from "react-i18next";
import Modal from "../modal";
import { colors, shadows, spacing, transitions } from "../../../styles";
import styled, { keyframes } from "styled-components";
import TextButton from "../../atoms/text-button";
import { PageSlide, SlideIndex } from "../../../store/store-types";
import TagsIcon from "../../../assets/icons/tags";
import ChoicesIcon from "../../../assets/icons/choices";
import { useModelActions } from "../../../store/hooks/use-actions";
import { useModelStore } from "../../../store/store";
import ContentLevelSlideCard from "../content-level-slide-card";
import Checkbox from "../../atoms/checkbox";

interface AlternativesModalProps {
  onClose : () => void;
  slide ?: PageSlide;
  onShowTagsModal : (slideIndex  : SlideIndex) => void;
  onShowTextAreaModal : (slideIndex  : SlideIndex) => void;
  multichoice ?: boolean;
  onMultichoiceChange ?: (value : boolean) => void;
}


const AlternativesModal : React.FC<AlternativesModalProps> = (p) => {
  const {t} = useTranslation();
  const content = useModelStore(state => state.actions.notebookEditor.current());
  const slides = (content.slides || [])
  const choices : {slide : PageSlide, index : number}[] = slides
    .filter((slide) : slide is PageSlide => slide.type === 'page')
    .map((slide, index) => ({slide, index}))
    .filter(({slide}) => slide.alternativeGroupId === p.slide?.alternativeGroupId);

  const handleSubmit = () => {
    p.onClose();
  }


  return (
    <Modal
      icon={<ChoicesIcon />}
      title={t('modals.alternativesModal.title', {name : p.slide?.label})}
      subtitle={t('modals.alternativesModal.subtitle')}
      onClose={p.onClose}
    >
      <Container>
        {
          choices.map(({slide, index}) => (
            <ContentLevelSlideCard
              key={slide.pageId}
              index={index}
              slide={slide}
              onShowTagsModal={p.onShowTagsModal}
              onShowTextAreaModal={p.onShowTextAreaModal}
            />
          ))
        }
      </Container>
        <FooterCheckbuttonContainer>
          <Checkbox
            value={!!p.multichoice}
            onChange={v => p.onMultichoiceChange?.(v)}
            label={t('modals.alternativesModal.multichoice')}
          />
        </FooterCheckbuttonContainer>
    </Modal>
  );
}

export default AlternativesModal;


const Container = styled.div`
  max-height: 60vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: ${spacing}rem;
  grid-row-gap: ${spacing}rem;
  overflow: auto;
`

const FooterCheckbuttonContainer = styled.div`
  margin-top : ${spacing}rem;
  display: flex;
  justify-content: flex-end;
`