import axios from "axios";


import { GetFunction, SetFunction, ActionsFactory, ModelStore, Market, Filter, ID } from "../store-types";
import configProvider from "../../providers/configprovider";
import { setLoading } from "../helpers/set-loading";
import { setError } from "../helpers/set-error";


const pagesActions : ActionsFactory<ModelStore['actions']['pages'], ModelStore> =  (set : SetFunction<ModelStore>, get : GetFunction<ModelStore>) => ({
  clone : async (pagesIds : ID[]) => {
    setLoading(set, 'pages.clone', true);

    try {
      const newPages = await Promise.all(pagesIds.map(id => axios.post<{id : number}>(`${configProvider('PDF_SERVICE_URL')}/pages/${id}/clone`)));

      return newPages.map(page => page.data.id);
    }
    catch(error) {}
    setError(set, 'pages.clone', 'errors.pages.clone')
    throw "error.pages.clone";
  }
})

export default pagesActions;