import { useTranslation } from "react-i18next";
import Modal from "../modal";
import { colors, spacing } from "../../../styles";
import styled from "styled-components";
import { useModelStore } from "../../../store/store";
import { useEffect, useRef, useState } from "react";
import TextButton from "../../atoms/text-button";
import { ID, NotebookTemplateContent, PageSlide, SlideIndex } from "../../../store/store-types";
import { useModelActions } from "../../../store/hooks/use-actions";
import Typography from "../../atoms/typography";
import ReplaceSlideIcon from "../../../assets/icons/replace-slide";
import NotebookCard from "../../atoms/notebook-card";
import configProvider from "../../../providers/configprovider";
import Search from "../../atoms/search";
import normalize from "../../../store/helpers/normalize-search-string";
import ToolButton from "../../atoms/tool-button";
import DeindentIcon from "../../../assets/icons/deindent";
import { HalfFoodDomeAnimation } from "../../../assets/animations/food-dome";
import DocumentIcon from "../../../assets/icons/document";

interface NotebookPreviewModalProps {
  title : string;
  pages : ID[];
  onClose : () => void;
}

const NotebookPreviewModal : React.FC<NotebookPreviewModalProps> = (p) => {
  const {t} = useTranslation();

  return (
    <Modal
      title={p.title}
      subtitle={t('modals.notebookPreview.subtitle')}
      icon={<DocumentIcon color={colors.text}/>}
      onClose={p.onClose}
    >
      <Container>
        {
          p.pages.map(pageId => (
            <SlideImage
              src={`${configProvider('PDF_SERVICE_URL')}/pages/${pageId}/preview`}
            />
          ))
        }

      </Container>
    </Modal>
  );
}

export default NotebookPreviewModal;


const Container = styled.div`
  height : 70vh;
  width : 100rem;
  overflow: auto;
`

const SlideImage = styled.img`
  width : 100rem;
  margin-bottom : ${spacing}rem;
  border-radius: 1rem;
  border : 0.1rem solid ${colors.tertiaryText}
`








