import styled from 'styled-components';
import { useAssertLogged } from '../hooks/use-assert-logged';
import PageLayout from '../components/layout/page-layout';
import { useAssertAdmin } from '../hooks/use-assert-admin';
import { useTranslation } from 'react-i18next';
import Search from '../components/atoms/search';
import TextButton from '../components/atoms/text-button';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { NEW_OFFER, NOTEBOOK_TEMPLATE, OFFERS } from '../constants/routes';
import PageTopSelector from '../components/atoms/pagetop-selector';
import { colors, shadows, spacing } from '../styles';
import { useModelActions } from '../store/hooks/use-actions';
import { Attachment, ID, Market, NotebookContent, NotebookTemplate, Offer, PageSlide, Slide, Video } from '../store/store-types';
import { useModelStore } from '../store/store';
import Typography from '../components/atoms/typography';
import configProvider from '../providers/configprovider';
import NotebookCard from '../components/atoms/notebook-card';
import DocumentIcon from '../assets/icons/document';
import ViewIcon from '../assets/icons/view';
import ToolButton from '../components/atoms/tool-button';
import NotebookPreviewModal from '../components/molecules/modals/notebook-preview-modal';
import useLogged from '../hooks/use-logged';
import ClosedFoodDome from '../assets/images/closed-food-dome';
import VideoIcon from '../assets/icons/video';

interface OfferReviewPageProps {

}

const OfferReviewPage : React.FC<OfferReviewPageProps> = (p) => {
  useAssertAdmin();
  const logged = useLogged();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const {loadOne, update} = useModelActions('offers');
  const {loadTemplates, loadTemplateContent} = useModelActions('notebooks');
  const {load : loadAttachments} = useModelActions('attachments');
  const {load : loadVideos} = useModelActions('videos');

  const markets     = useModelStore(state => state.markets.filter(({inactive}) => !inactive));
  const attachments = useModelStore(state => state.attachments);
  const videos = useModelStore(state => state.videos);

  const [offer, setOffer] = useState<Offer | null>(null);
  const [market, setMarket] = useState<Market | null>(null);

  const notebookTemplates = useModelStore(state => state.notebooks.templates);
  const offerNotebooks = offer?.notebooksIds?.map(nid => notebookTemplates[nid])?.filter(n => !!n && n.type === 'offers');
  const annexesNotebooks = Object.values(notebookTemplates).filter(notebook => notebook.type === 'annexes' && notebook.draft === false && offer?.notebooksIds.includes(notebook.id));
  const genericNotebooks = Object.values(attachments).filter(attachment => offer?.attachmentsIds.includes(attachment.id));
  const offerVideos = Object.values(videos).filter(video => offer?.videosIds.includes(video.id));

  const [previewPages, setPreviewPages] = useState<ID[]>();
  const [previewTitle, setPreviewTitle] = useState<string>();

  useEffect(() => {
    (async () => {
      if(id && logged) {
        await loadTemplates();
        await loadAttachments();
        await loadVideos();

        const offer = await loadOne(parseInt(id));
        setOffer(offer);

        const market = markets.find(market => market.id === offer.marketId);
        setMarket(market || null);
      }
    })();
  }, [id, logged]);


  const handlePublish = async () => {
    if(id) {
      await update(parseInt(id), {draft : false});
      navigate(OFFERS);
    }
  }

  const handleView = async (notebook : NotebookTemplate) => {
    const content = await loadTemplateContent(notebook.id);
    const pagesIds = (content?.slides || []).map(slide => slide.type === 'page' && !slide.isSummary ? slide.pageId : null).filter(p => !!p) as ID[];

    if(pagesIds.length) {
      setPreviewTitle(notebook.name);
      setPreviewPages(pagesIds);
    }
    else {
      setPreviewTitle(undefined);
      setPreviewPages(undefined);
    }
  }

  const handleViewMany = async(notebooks : NotebookTemplate[]) => {
    const contents = await Promise.all(notebooks.map(n => loadTemplateContent(n.id)));
    const slides = contents.reduce((slides, content) => [...slides, ...(content.slides || [])], [] as Slide[]);
    const pagesIds = slides.map(slide => slide.type === 'page' && !slide.isSummary ? slide.pageId : null).filter(p => !!p) as ID[];

    if(pagesIds.length) {
      setPreviewTitle(t('pages.offer-review.offerName'));
      setPreviewPages(pagesIds);
    }
    else {
      setPreviewTitle(undefined);
      setPreviewPages(undefined);
    }
  }

  const handleViewAttachment = async (attachment : Attachment) => {
    window.open(`${configProvider('MODEL_API_URL')}/attached-notebooks/${attachment.id}/content.pdf`, '_blank')
  }

  const handleViewVideo = async (video : Video) => {
    window.open(`${configProvider('MODEL_API_URL')}/public-videos/${video.id}/video`, '_blank')
  }

  return (
    <PageLayout
      breadcrumbs={[t('pages.new-offer.breadcrumb1'), t('pages.new-offer.breadcrumb2')]}
      title={
        <TitleContainer>
          <TitleTypography variant="bigTitle" color="text">{offer?.name || ''}</TitleTypography>
          <Typography variant="textItalic" color="secondaryText">{market?.name || ''}</Typography>
        </TitleContainer>
      }
      controls={
        offer?.draft ?
          <TextButton color="dark" onClick={handlePublish}>{t('pages.offer-review.publish')}</TextButton> :
          null
      }
      showBackArrow
      modal={
        previewPages && previewPages.length && previewTitle ?
          <NotebookPreviewModal title={previewTitle} pages={previewPages} onClose={() => setPreviewPages(undefined)}/> : null
      }
    >
      <Container>
        {
          offerNotebooks ?
            <NotebookCardContainer>
              <NotebookTypeTitle>
                <IconContainer><DocumentIcon color={colors.secondaryText}/></IconContainer>&nbsp;
                <Typography variant="mediumTitle" color="text">{t('pages.offer-review.offerNotebook')}</Typography>
              </NotebookTypeTitle>
              <ThumbnailContainer>
                <Thumbnail src={`${configProvider('PDF_SERVICE_URL')}/pages/${offerNotebooks[0].thumbnailPageId}/thumbnail`}/>
                <StyledToolButton onClick={() => handleViewMany(offerNotebooks)}><ViewIcon /></StyledToolButton>
              </ThumbnailContainer>
              <Typography variant="uppercaseTextBold" color="secondaryText">{t('pages.offer-review.offerName')}</Typography>
            </NotebookCardContainer> :
            null
        }
        {
          annexesNotebooks.length ?
            annexesNotebooks.map((notebook, index) => (
              <NotebookCardContainer key={notebook.id}>
                <NotebookTypeTitle>
                  {
                    index === 0 ?
                    <>
                      <IconContainer><DocumentIcon color={colors.secondaryText}/></IconContainer>&nbsp;
                      <Typography variant="mediumTitle" color="text">{t('pages.offer-review.annexesNotebooks', {count : annexesNotebooks.length})}</Typography>
                    </> : null
                  }
                </NotebookTypeTitle>
                <ThumbnailContainer>
                  <Thumbnail src={`${configProvider('PDF_SERVICE_URL')}/pages/${notebook.thumbnailPageId}/thumbnail`}/>
                  <StyledToolButton onClick={() => handleView(notebook)}><ViewIcon /></StyledToolButton>
                </ThumbnailContainer>
                <Typography variant="uppercaseTextBold" color="secondaryText">{notebook.name}</Typography>
              </NotebookCardContainer>
            )) :
            null
        }
        {
          genericNotebooks.length ?
            genericNotebooks.map((attachment, index) => (
              <NotebookCardContainer key={attachment.id}>
                <NotebookTypeTitle>
                  {
                    index === 0 ?
                    <>
                      <IconContainer><DocumentIcon color={colors.secondaryText}/></IconContainer>&nbsp;
                      <Typography variant="mediumTitle" color="text">{t('pages.offer-review.genericNotebooks', {count : genericNotebooks.length})}</Typography>
                    </> : null
                  }
                </NotebookTypeTitle>
                <ThumbnailContainer>
                  {
                    attachment.thumbnailStorageName ?
                      <Thumbnail src={`${configProvider('MODEL_API_URL')}/attached-notebooks/${attachment.id}/thumbnail.png`}/> :
                      <AttachmentCard><ClosedFoodDome /></AttachmentCard>
                  }
                  <StyledToolButton onClick={() => handleViewAttachment(attachment)}><ViewIcon /></StyledToolButton>
                </ThumbnailContainer>
                <Typography variant="uppercaseTextBold" color="secondaryText">{attachment.name}</Typography>
              </NotebookCardContainer>
            )) :
            null
        }
        {
          offerVideos.length ?
            offerVideos.map((video, index) => (
              <NotebookCardContainer key={video.id}>
                <NotebookTypeTitle>
                  {
                    index === 0 ?
                    <>
                      <IconContainer><DocumentIcon color={colors.secondaryText}/></IconContainer>&nbsp;
                      <Typography variant="mediumTitle" color="text">{t('pages.offer-review.videos', {count : genericNotebooks.length})}</Typography>
                    </> : null
                  }
                </NotebookTypeTitle>
                <ThumbnailContainer>
                  {
                    video.thumbnailStorageName ?
                      <Thumbnail src={`${configProvider('MODEL_API_URL')}/public-videos/${video.id}/thumbnail.png`}/> :
                      <AttachmentCard><ClosedFoodDome /></AttachmentCard>
                  }
                  <StyledToolButton onClick={() => handleViewVideo(video)}><VideoIcon /></StyledToolButton>
                </ThumbnailContainer>
                <Typography variant="uppercaseTextBold" color="secondaryText">{video.name}</Typography>
              </NotebookCardContainer>
            )) :
            null
        }
      </Container>
    </PageLayout>
  )
}

export default OfferReviewPage;

const Container = styled.div`
  border-radius: 1.5rem;
  box-shadow: ${shadows.default};
  padding : ${2*spacing}rem;
  background-color: ${colors.background2};
  display: flex;
  justify-content: flex-start;
  flex-wrap : wrap;
;`

const TitleContainer = styled.div`
  display: flex;
  align-items: baseline;
`

const TitleTypography = styled(Typography)`
  margin-right : ${spacing}rem;
`

const NotebookCardContainer = styled.div`
  display: inline-block;
  margin-right : 1rem;
  margin-top : ${2*spacing}rem;
`

const Thumbnail = styled.img`
  width : 23.4rem;
  height : 13.18rem;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
`

const AttachmentCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width : 23.4rem;
  height : 13.18rem;
  border-radius: 1rem;
  background-color : ${colors.background2};
  border : 0.3rem solid ${colors.background2} !important;
  overflow : hidden;
`


const IconContainer = styled.div`
  position: relative;
  display: inline-block;
  height: 2rem;
  top: 0.4rem;
`

const NotebookTypeTitle = styled.div`
  height : 3.5rem;
`

const ThumbnailContainer = styled.div`
  position : relative;
`

const StyledToolButton = styled(ToolButton)`
  position : absolute;
  bottom : 1.5rem;
  right : 1rem;
  padding : 0.3rem;
`