
import { colors } from "../../styles";
import IconProps from "./icon-interface";

const DownloadIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.primary;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M10.018 0.0703125C10.4284 0.199069 10.7583 0.424394 10.871 0.866995C10.9112 1.01185 10.9193 1.17279 10.9193 1.32569C10.8871 4.65727 10.9193 7.9969 10.9193 11.3285C10.9193 11.409 10.9193 11.4894 10.9193 11.6101C11.0078 11.5216 11.0561 11.4814 11.1044 11.4331C11.8688 10.6686 12.6414 9.89606 13.4059 9.13157C13.7036 8.83382 14.0738 8.73725 14.4842 8.84991C14.8946 8.96258 15.1521 9.24423 15.2487 9.65464C15.3453 10.0651 15.2085 10.4191 14.9107 10.7169C14.0979 11.5216 13.2932 12.3344 12.4804 13.1391C11.8769 13.7427 11.2814 14.3382 10.6778 14.9417C10.1306 15.4889 9.51099 15.4889 8.96378 14.9417C7.55551 13.5334 6.15528 12.1332 4.74701 10.7249C4.28026 10.2582 4.24003 9.6305 4.63434 9.17985C5.06085 8.69702 5.76901 8.67287 6.25185 9.14766C7.01634 9.90411 7.78083 10.6767 8.54532 11.4331C8.5936 11.4814 8.64189 11.5297 8.73845 11.6101C8.73845 11.4814 8.73845 11.409 8.73845 11.3285C8.73845 7.98081 8.72236 4.63313 8.73845 1.28545C8.73845 0.641671 8.98792 0.295637 9.60756 0.0944544C9.61561 0.0944544 9.6317 0.0783598 9.63975 0.0703125C9.76851 0.0703125 9.89726 0.0703125 10.026 0.0703125L10.018 0.0703125Z" fill={color}/>
      <path d="M0.00804727 14.0256C0.0321891 13.9773 0.0482836 13.9371 0.0724255 13.8888C0.257513 13.3818 0.740349 13.0921 1.26342 13.1726C1.79454 13.2531 2.18081 13.6876 2.18886 14.2268C2.19691 14.5809 2.18886 14.9269 2.18886 15.281C2.18886 16.5685 3.13844 17.3491 4.40991 17.5101C8.01509 17.9768 11.6122 17.9768 15.2174 17.5101C16.505 17.3411 17.4465 16.5685 17.4465 15.2649C17.4465 14.9269 17.4465 14.5889 17.4465 14.2509C17.4545 13.6313 17.9374 13.1484 18.5409 13.1484C19.1445 13.1484 19.6273 13.6313 19.6273 14.2509C19.6273 14.943 19.6756 15.627 19.5227 16.311C19.0881 18.2263 17.3338 19.4736 15.3864 19.6828C11.6766 20.0933 7.9668 20.0933 4.24896 19.6828C2.12448 19.4495 0.281655 17.9929 0.0321891 15.8684C0.0321891 15.8282 0.00804727 15.7799 0 15.7397V14.0175L0.00804727 14.0256Z" fill={color}/>
    </svg>
    
  )
}

export default DownloadIcon;

