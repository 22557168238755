import styled from 'styled-components';
import PageLayout from '../components/layout/page-layout';
import { useTranslation } from 'react-i18next';
import { spacing } from '../styles';
import TextButton from '../components/atoms/text-button';
import { useAssertLogged } from '../hooks/use-assert-logged';
import NotebookNameInput from '../components/atoms/notebook-name-input';
import { useState } from 'react';
import { useModelActions } from '../store/hooks/use-actions';
import { useNavigate } from 'react-router-dom';
import { PROPOSAL, PROPOSAL_OFFER } from '../constants/routes';

interface NewProposalPageProps {

}

const NewProposalPage : React.FC<NewProposalPageProps> = () => {
  useAssertLogged();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const [name, setName] = useState<string>('');
  const {create} = useModelActions('proposals');

  const handleShowCreate = async () => {
    const newProposal = await create({
      name
    })
    navigate(PROPOSAL_OFFER.replace(':id', `${newProposal.id}`));
  }

  return (
    <PageLayout
      breadcrumbs={[t('pages.new-proposal.breadcrumb1')]}
      title={
        <TitleContainer>
          <ProposalNameInput value={name} onChange={setName} placeholder={t('pages.new-proposal.namePlaceholder')}/>
        </TitleContainer>
      }
      subtitle={t('pages.new-proposal.subtitle')}
      controls={
        <>
          <TextButton disabled={!name.trim()} onClick={handleShowCreate} color="dark">{t('pages.new-proposal.createNew')}</TextButton>
        </>
      }
      showBackArrow
    >
      <Container>
     </Container>
    </PageLayout>
  )
}

export default NewProposalPage;

const Container = styled.div`
  padding-top : ${3*spacing}rem;
;`

const TitleContainer = styled.div`
  display: flex;
`

const ProposalNameInput = styled(NotebookNameInput)`
  width : 42rem;
`




