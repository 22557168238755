import { colors } from "../../styles";
import IconProps from "./icon-interface";

const LibraryIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.text;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.9679 14.3508L12.87 2.49596C12.8361 2.36654 12.785 2.22479 12.6635 2.1533C12.5414 2.08243 12.3922 2.10831 12.2627 2.14282L11.519 2.34065C10.5442 2.59949 9.56991 2.85895 8.59627 3.12025C8.52536 3.13936 8.46 3.16524 8.39279 3.19359V2.42693C8.39279 1.78168 8.39279 1.13581 8.39279 0.490563C8.39279 0.138048 8.25466 0.00123257 7.90134 0.00123257C6.83274 0 5.76599 0 4.69863 0C4.33852 0 4.2041 0.135583 4.20348 0.500424C4.20348 0.862183 4.20348 1.22456 4.20348 1.58632V2.09784H0.51241C0.134423 2.09784 0 2.23342 0 2.6149V15.4885C0 15.8706 0.130106 16 0.515492 16H7.90196C7.91306 16 7.92416 16 7.93464 16C7.9519 16 7.96855 16 7.98582 15.9988C8.22753 15.9858 8.36689 15.8521 8.38847 15.6105C8.3934 15.5581 8.39279 15.5076 8.39217 15.4583V15.4151V4.54449L11.2767 15.5945C11.3112 15.727 11.3637 15.8724 11.4888 15.9452C11.6134 16.0179 11.7638 15.992 11.8946 15.9575L12.1295 15.8946C13.2881 15.5859 14.4467 15.2771 15.6054 14.9677C15.9624 14.8722 16.0604 14.7052 15.9679 14.3508ZM12.4181 3.81604L9.2246 4.66651L9.00139 3.81172L12.1936 2.96002L12.4181 3.81604ZM12.6727 4.79716L9.48297 5.64887L9.41945 5.4153L12.6117 4.56421L12.6727 4.79716ZM14.3302 11.1474L11.138 11.9978L9.67782 6.39704L12.87 5.54534L14.3302 11.1474ZM14.7329 12.6856L11.5419 13.5361L11.3347 12.7466L14.5257 11.8949L14.7329 12.6856ZM15.1522 14.288L11.9593 15.1397L11.7367 14.2836L14.9277 13.4332L15.1522 14.288ZM7.60783 13.3906V13.6452H4.99152V13.3906H7.60783ZM4.98597 2.88113V2.35791H7.60536V2.88113H4.98597ZM7.60475 3.67306V12.5944H4.98721V3.67306H7.60475ZM7.60598 14.434V15.2198H4.98597V14.434H7.60598ZM7.60783 0.779601V1.56105H4.98967V0.779601H7.60783ZM4.19423 4.19752V11.808H0.780638V4.19752H4.19423ZM0.782488 3.40744V2.87929H4.193V3.40744H0.782488ZM4.18992 12.5938V13.1158H0.778788V12.5938H4.18992ZM4.18992 13.9108V15.2216H0.778788V13.9108H4.18992Z" fill={color}/>
      <path d="M1.51627 7.59946C1.64576 7.59946 1.77525 7.60008 1.90474 7.60008C2.00155 7.60008 2.09897 7.60008 2.19578 7.60008H2.48621H2.77355C3.00109 7.60008 3.22862 7.60008 3.45615 7.60008C3.77371 7.59884 3.92231 7.45032 3.92355 7.13355C3.9254 6.50309 3.9254 5.85044 3.92355 5.19472C3.92231 4.8687 3.78357 4.72942 3.458 4.7288C2.82967 4.72695 2.17729 4.72695 1.51812 4.7288C1.1981 4.72942 1.04887 4.87733 1.04826 5.19287C1.04702 5.83873 1.04702 6.48522 1.04826 7.13108C1.04826 7.4497 1.19748 7.59823 1.51565 7.60008L1.51627 7.59946ZM3.13736 5.50902V6.81863H1.83013V5.50902H3.13798H3.13736Z" fill={color}/>
    </svg>
  )
}

export default LibraryIcon;
