
import { colors } from "../../styles";
import IconProps from "./icon-interface";

const MailIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.primary;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M14.9849 22.0703C11.6373 22.0703 8.28979 22.0703 4.94225 22.0703C2.5653 22.0703 0.716558 20.5055 0.320399 18.1615C0.280783 17.9106 0.274181 17.6597 0.274181 17.4088C0.267578 13.1766 0.267578 8.95747 0.267578 4.73178C0.267578 2.36143 1.8324 0.519292 4.15653 0.123134C4.41404 0.0769151 4.68475 0.0703125 4.94225 0.0703125C11.6439 0.0703125 18.3522 0.0703125 25.0539 0.0703125C27.7148 0.0703125 29.7154 2.0445 29.722 4.70537C29.7352 8.95086 29.7352 13.1898 29.722 17.4353C29.7154 20.0895 27.7082 22.0703 25.0473 22.0703C21.6931 22.0703 18.3324 22.0703 14.9783 22.0703H14.9849ZM27.6025 4.94306C27.4639 5.05531 27.3582 5.13454 27.2592 5.21377C24.0569 7.7954 20.8546 10.3836 17.6523 12.9653C16.0347 14.2726 13.9879 14.2858 12.3636 12.9917C9.13493 10.4233 5.91284 7.84822 2.68414 5.2798C2.60491 5.21377 2.51908 5.16095 2.40683 5.07511C2.40683 5.17415 2.39363 5.23358 2.39363 5.2864C2.39363 9.33382 2.39363 13.3812 2.39363 17.4353C2.39363 18.901 3.46986 19.9443 4.96866 19.9443C11.6505 19.9443 18.339 19.9443 25.0209 19.9443C25.2586 19.9443 25.5095 19.9179 25.7406 19.8584C26.8828 19.5745 27.5893 18.6039 27.5959 17.3428C27.5959 13.3152 27.5959 9.2876 27.5959 5.25339C27.5959 5.17415 27.5959 5.10153 27.5959 4.94966L27.6025 4.94306ZM26.797 2.84342C26.2556 2.35483 25.6481 2.18976 24.9747 2.18976C18.3258 2.18976 11.6835 2.18976 5.03469 2.18976C4.93565 2.18976 4.83661 2.18976 4.74417 2.18976C4.13673 2.23598 3.59531 2.44066 3.14633 2.88964C3.19915 2.93586 3.23216 2.96887 3.27178 2.99528C6.77118 5.7882 10.2706 8.57451 13.77 11.3674C14.5227 11.9683 15.5197 11.9287 16.2988 11.3014C18.273 9.70357 20.2538 8.11233 22.228 6.52109C23.74 5.2996 25.2586 4.07812 26.8036 2.83022L26.797 2.84342Z" fill={color}/>
    </svg>
  )
}

export default MailIcon;

