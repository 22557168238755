

type EventCallback<Event> = (e : Event) => void;

const callbacks : Record<string, Record<string, ((e : any) => void)>> = {};
let lastId : number = 0;

/**
 * Implement event delegation inside React event model. Tipically used for
 * closing dropdown when user clicks anywere else outside of the dropdown.
 * Old style solution is to use document.addEventListener('click', ...).
 * But this is outside of React event model and messes up when trying to
 * use stopPropagation.
 *
 * To use useEventDelegation, call the hook with a key such as'page-click',
 * 'modal-frame-click', etc..
 * The hook returns three function:
 * - delegateHandler is the handled function to provide to target React
 *   element. <Page onClick={delegatedHandler} />
 * - addEventListener is used inside the requiring component (ie: dropdown).
 *   it requires a callback, that will be called when delegatedHandler is
 *   executed
 * - removeEventListener, with no arguments, removes the callback provided
 *   by addEventListener.
 *
 * @param key Unique key for this event delegation
 * @returns {delegateHandler, addEventListener, removeEventListener}
 */
const useEventDelegation = <Event,>(key : string) => {
  if(!callbacks[key]) {
    callbacks[key] = {}
  }

  const id = `handler_${++lastId}`;

  const delegatedHandler = (event : Event) => {
    Object.values(callbacks[key] || {}).forEach(handler => handler(event));
  }

  const addEventListener = (callback : EventCallback<Event>) => {
    callbacks[key][id] = callback;
  }

  const removeEventListener = () => {
    delete callbacks[key][id];
  }

  return {delegatedHandler, addEventListener, removeEventListener};
}

export default useEventDelegation;
