import styled from 'styled-components';
import { useAssertLogged } from '../hooks/use-assert-logged';
import PageLayout from '../components/layout/page-layout';
import { useAssertAdmin } from '../hooks/use-assert-admin';
import { useTranslation } from 'react-i18next';
import Search from '../components/atoms/search';
import TextButton from '../components/atoms/text-button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NEW_OFFER } from '../constants/routes';
import PageTopSelector from '../components/atoms/pagetop-selector';
import { spacing } from '../styles';
import { useModelActions } from '../store/hooks/use-actions';
import { ID, Offer, User } from '../store/store-types';
import OfferCard from '../components/atoms/offer-card';
import useLogged from '../hooks/use-logged';
import Typography from '../components/atoms/typography';

interface OffersPageProps {

}

const OffersPage : React.FC<OffersPageProps> = (p) => {
  useAssertAdmin();
  const logged = useLogged();
  const {t} = useTranslation();
  const navigate = useNavigate();
  const {load} = useModelActions('offers');
  const {loadTemplates} = useModelActions('notebooks');
  const {loadMany : loadUsers} = useModelActions('users');
  const [_offers, setOffers] = useState<Offer[]>([]);
  const [users, setUsers] = useState<User[]>([]);
  const [query, setQuery] = useState<string | undefined>('');
  const [offerState, setOfferState] = useState<'isDraft' | 'isNotDraft'>('isNotDraft');
  const [showArchived, setShowArchived] = useState<boolean>(false);
  
  const offers = showArchived ? _offers : _offers.filter(({inactive}) => !inactive);


  useEffect(() => {
    (async () => {
      if(logged) {
        await handleRefresh();
        await loadTemplates();
      }
    })()
  }, [logged])

  const drafts = offers.filter(offer => offer.draft);
  const notDrafts = offers.filter(offer => !offer.draft);

  const handleRefresh = async () => {
    const {data : offers} = await load();
    setOffers(offers);

    const usersId = new Set<ID>();
    offers.forEach(offer => usersId.add(offer.creatorId));

    const users = await loadUsers([...usersId.values()]);
    setUsers(users);

  }

  return (
    <PageLayout
      title={t('pages.offers.name')}
      subtitle={t('pages.offers.subtitle')}
      controls={
        <>
          {/* <Search placeholder={t('pages.offers.searchPlaceholder')} onChange={setQuery}/> */}
          <TextButton onClick={() => navigate(NEW_OFFER)} color="dark">{t('pages.offers.createNew')}</TextButton>
        </>
      }
    >
      <Container>
        <SelectorsContainer>
          <PageTopSelector<typeof offerState>
            selected={offerState}
            onChange={setOfferState}
            options={[{id : 'isNotDraft', label : t('pages.offers.published')}, {id : 'isDraft', label : t('pages.offers.drafts', {count : drafts.length})}]}
          />
        </SelectorsContainer>
        <div style={{textAlign : 'right'}}>
          <Typography variant="smallTextBold" color="primary" onClick={() => setShowArchived(s => !s)} style={{cursor : 'pointer'}}>
              {!showArchived ? t('show-archived') : t('hide-archived')}
          </Typography>
        </div>
        <Content>
          {
            (offerState === 'isDraft' ? drafts : notDrafts).map(offer => (
              <OfferCard
                key={offer.id}
                offer={offer}
                user={users.find(user => user.id === offer.creatorId)}
                onRefresh={handleRefresh}
              />
            ))
          }
        </Content>
      </Container>
    </PageLayout>
  )
}

export default OffersPage;

const Container = styled.div`
  padding-top : ${3*spacing}rem;
;`

const SelectorsContainer = styled.div`
  display : flex;
  justify-content: space-between;
  margin-bottom : ${2*spacing}rem;
`

const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  /* max-width: 125rem; */
`