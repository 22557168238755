import axios from "axios";


import { GetFunction, SetFunction, ActionsFactory, ModelStore, Region } from "../store-types";
import configProvider from "../../providers/configprovider";
import { setLoading } from "../helpers/set-loading";
import { setError } from "../helpers/set-error";


const regionsActions : ActionsFactory<ModelStore['actions']['regions'], ModelStore> =  (set : SetFunction<ModelStore>, get : GetFunction<ModelStore>) => ({
  load : async () => {
    setLoading(set, 'regions.load', true);

    try {
      const regionsResponse = await axios.get(`${configProvider('MODEL_API_URL')}/regions?pageSize=1000`);

      if(regionsResponse.status === 200) {
        const {data} : {data : Region[]} = regionsResponse.data || {};

        set(state => {
          state.regions = (data || []).map(region => ({name : region.name, id : region.id, inactive : region.inactive}));
        })
      }

      setLoading(set, 'regions.load', false);

      return;
    }
    catch(error) {}

    setError(set, 'regions.load', 'errors.regions.load')
  },

  create : async (name : string) => {
    setLoading(set, 'regions.create', true);

    try {
      const regionsResponse = await axios.post(`${configProvider('MODEL_API_URL')}/regions`, {name});

      if(regionsResponse.status === 200) {
        const newRegion : Region = regionsResponse.data;

        set(state => {
          state.regions.push(newRegion);
        })
        setLoading(set, 'regions.create', false);

        return newRegion;
      }
    }
    catch(error) {}

    setError(set, 'regions.create', 'errors.regions.create')
    throw 'errors.regions.create'
  },

  archive : async (regionId : number) => {
    setLoading(set, 'regions.archive', true);

    try {
      const regionsResponse = await axios.patch(`${configProvider('MODEL_API_URL')}/regions/${regionId}`, {inactive : true});

      if(regionsResponse.status === 200) {
        set(state => {
          state.regions = state.regions.map(region => region.id !== regionId ? region : {...region, inactive : true});
        })

        setLoading(set, 'regions.archive', false);

        return;
      }
    }
    catch(error) {}

    setError(set, 'regions.archive', 'regions.archive')
    throw 'regions.archive'
  },

  unarchive : async (regionId : number) => {
    setLoading(set, 'regions.unarchive', true);

    try {
      const regionsResponse = await axios.patch(`${configProvider('MODEL_API_URL')}/regions/${regionId}`, {inactive : false});

      if(regionsResponse.status === 200) {
        set(state => {
          state.regions = state.regions.map(region => region.id !== regionId ? region : {...region, inactive : false});
        })

        setLoading(set, 'regions.archive', false);

        return;
      }
    }
    catch(error) {}

    setError(set, 'regions.unarchive', 'regions.unarchive')
    throw 'regions.unarchive'
  },

  rename : async (regionId : number, name : string) => {
    setLoading(set, 'regions.rename', true);

    try {
      const regionsResponse = await axios.patch(`${configProvider('MODEL_API_URL')}/regions/${regionId}`, {name});

      if(regionsResponse.status === 200) {
        set(state => {
          const region = state.regions.find(region => region.id === regionId);
          if(region) {
            region.name = name;
          }
        })

        setLoading(set, 'regions.rename', false);

        return;
      }

      if(regionsResponse.status === 409) {
        setError(set, 'regions.rename', 'regions.rename')
        return;
      }
    }
    catch(error) {}

    setError(set, 'regions.rename', 'regions.rename')
    throw 'regions.rename'
  }
})

export default regionsActions;