const UploadErrorImage : React.FC<{}> = (p) => {
  return (
    <svg width="70" height="44" viewBox="0 0 70 44" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M69.4074 32.249C68.8533 30.9991 67.7936 30.4166 66.4283 30.3911C65.5657 30.3741 64.703 30.3911 63.8575 30.3911C63.8547 30.3685 63.8518 30.3459 63.849 30.3232H61.8838C61.8838 30.3232 61.8924 30.343 61.8952 30.3543H56.511C56.511 30.3543 56.531 30.3346 56.5424 30.3232H43.2318C43.2318 30.3232 43.2318 30.3346 43.2289 30.3402H22.3461C22.3461 30.3402 22.3346 30.3289 22.3289 30.3232H19.4526C19.464 30.3459 19.4726 30.3657 19.484 30.3883H15.2166C13.9941 30.3883 12.7715 30.4137 11.5519 30.3685C11.4662 30.3685 11.3776 30.3487 11.2891 30.3232H8.1471C8.1671 30.3459 8.18424 30.3685 8.20423 30.3883C6.54183 30.3883 5.04795 30.3741 3.55122 30.3939C1.59747 30.4194 0.249268 31.7937 0.249268 33.7279C0.249268 35.665 1.60318 37.0421 3.54551 37.0648C4.69662 37.0789 5.85059 37.0902 6.99885 37.0591C7.48157 37.0478 7.8329 37.175 8.17567 37.5229C9.17539 38.5409 10.2437 39.491 11.2263 40.5232C12.5259 41.889 13.9541 43.0003 15.865 43.3651H54.0888C56.0083 43.0173 57.4336 41.8947 58.7332 40.5345C59.7501 39.4712 60.8269 38.4589 61.9067 37.4607C62.1352 37.2514 62.5065 37.0987 62.8178 37.0902C64.0375 37.0478 65.2629 37.0817 66.4825 37.0704C67.5023 37.0591 68.3735 36.6858 68.999 35.8799C69.8645 34.7657 69.9587 33.5074 69.4046 32.2546L69.4074 32.249ZM55.9768 40.2263C55.2313 40.9558 54.2659 41.2641 53.2119 41.2669C41.0609 41.2669 28.9128 41.2669 16.7619 41.2669C15.685 41.2669 14.7082 40.9417 13.9455 40.1838C12.9344 39.18 11.9803 38.1195 10.992 37.0704H58.9675C57.9706 38.1308 57.0051 39.2139 55.974 40.2291L55.9768 40.2263ZM66.2483 34.9722C63.4005 34.9778 60.5499 34.9722 57.6992 34.9722H3.89113C2.77429 34.9722 2.26871 34.5028 2.37154 33.5583C2.4401 32.9135 2.8 32.5459 3.45982 32.4865C3.59978 32.4724 3.7426 32.4809 3.88256 32.4809C24.634 32.4809 45.3855 32.4809 66.1341 32.4809C67.1338 32.4809 67.5822 32.8485 67.6079 33.6714C67.6337 34.5225 67.1823 34.9693 66.2483 34.9722Z" fill="#FF5C38"/>
    <path d="M29.7341 13.3408C28.5651 13.3305 27.5613 12.6786 27.1145 11.6306C27.0295 11.4316 27.0676 11.2808 27.2202 11.2135C27.3807 11.1426 27.5479 11.2216 27.6275 11.4129C28.3186 13.0756 30.5208 13.2167 31.5098 12.0176C31.669 11.8246 31.7774 11.5888 31.9022 11.3687C31.9707 11.248 32.0724 11.1801 32.1932 11.1992C32.3082 11.2172 32.4022 11.3045 32.4143 11.4226C32.42 11.4774 32.4092 11.5403 32.3868 11.5912C31.9701 12.5478 31.2456 13.1174 30.2188 13.2977C30.0595 13.3258 29.8953 13.3268 29.7338 13.3402L29.7341 13.3408Z" fill="#FF5C38" stroke="#FF5C38" stroke-width="1.4"/>
    <path d="M40.2481 13.3408C39.0791 13.3305 38.0754 12.6786 37.6285 11.6306C37.5435 11.4316 37.5817 11.2808 37.7342 11.2135C37.8947 11.1426 38.062 11.2216 38.1416 11.4129C38.8326 13.0756 41.0348 13.2167 42.0239 12.0176C42.1831 11.8246 42.2915 11.5888 42.4162 11.3687C42.4848 11.248 42.5865 11.1801 42.7072 11.1992C42.8223 11.2172 42.9163 11.3045 42.9283 11.4226C42.934 11.4774 42.9233 11.5403 42.9009 11.5912C42.4841 12.5478 41.7596 13.1174 40.7328 13.2977C40.5736 13.3258 40.4097 13.3274 40.2481 13.3408Z" fill="#FF5C38" stroke="#FF5C38" stroke-width="1.4"/>
    <path d="M49.7932 16.3239C49.7932 24.494 43.17 31.1172 34.9999 31.1172C26.8299 31.1172 20.2067 24.494 20.2067 16.3239C20.2067 8.15383 26.8299 1.53066 34.9999 1.53066C43.17 1.53066 49.7932 8.15383 49.7932 16.3239Z" stroke="#FF5C38" stroke-width="2.2"/>
    <path d="M35.0212 18.885C37.8168 18.9098 40.2172 20.4688 41.2858 22.9748C41.489 23.4507 41.3978 23.8114 41.0331 23.9722C40.6491 24.1418 40.2492 23.953 40.0588 23.4955C38.4063 19.5193 33.1399 19.1818 30.7747 22.0493C30.3939 22.5108 30.1348 23.0747 29.8364 23.6011C29.6724 23.8898 29.4293 24.0522 29.1405 24.0066C28.8654 23.9634 28.6406 23.7546 28.6118 23.4723C28.5982 23.3411 28.6238 23.1907 28.6774 23.0692C29.674 20.7815 31.4066 19.4193 33.8622 18.9882C34.2429 18.921 34.6357 18.9186 35.022 18.8866L35.0212 18.885Z" fill="#FF5C38" stroke="#FF5C38" stroke-width="0.8"/>
    </svg>
  )
}

export default UploadErrorImage;
