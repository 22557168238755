import { colors } from "../../styles";
import IconProps from "./icon-interface";

const PasteIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.primary;

  return (
  <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.3364 0C3.91903 0 2.55971 0.562588 1.55747 1.564C0.555237 2.56541 -0.0078125 3.92362 -0.0078125 5.33983L-0.0078125 11.9399C-0.0078125 12.1246 0.0656288 12.3018 0.196355 12.4324C0.327081 12.563 0.504384 12.6364 0.689259 12.6364C0.874134 12.6364 1.05144 12.563 1.18216 12.4324C1.31289 12.3018 1.38633 12.1246 1.38633 11.9399V5.33983C1.38633 4.29307 1.8025 3.28918 2.54328 2.549C3.28406 1.80883 4.28878 1.393 5.3364 1.393H11.8545C12.0394 1.393 12.2167 1.31962 12.3474 1.189C12.4781 1.05838 12.5516 0.881223 12.5516 0.6965C12.5516 0.511777 12.4781 0.334619 12.3474 0.204C12.2167 0.073381 12.0394 0 11.8545 0L5.3364 0Z" fill={color}/>
    <path d="M9.51842 15.9995C7.99686 15.9997 6.47647 15.9157 4.96421 15.7479C4.54449 15.703 4.15255 15.5167 3.85288 15.2196C3.55322 14.9226 3.36366 14.5324 3.31541 14.1134C2.95867 11.0613 2.95867 7.97817 3.31541 4.92611C3.36335 4.50735 3.55241 4.11734 3.85154 3.82015C4.15066 3.52296 4.54206 3.33626 4.96143 3.29073C7.99007 2.95387 11.0468 2.95387 14.0754 3.29073C14.4951 3.33563 14.8871 3.52193 15.1867 3.81899C15.4864 4.11604 15.676 4.50617 15.7242 4.92518C16.081 7.97724 16.081 11.0604 15.7242 14.1125C15.676 14.5315 15.4864 14.9216 15.1867 15.2187C14.8871 15.5157 14.4951 15.702 14.0754 15.7469C12.5622 15.9151 11.0409 15.9995 9.51842 15.9995ZM9.51842 4.43113C8.04777 4.43114 6.57825 4.51267 5.11664 4.67537C5.01075 4.68753 4.91197 4.73472 4.83601 4.80943C4.76005 4.88415 4.71129 4.9821 4.69747 5.0877C4.35331 8.03209 4.35331 11.0065 4.69747 13.9509C4.71097 14.0566 4.75964 14.1547 4.83566 14.2295C4.91167 14.3043 5.01063 14.3514 5.11664 14.3632C6.57825 14.5259 8.04777 14.6075 9.51842 14.6075C10.9891 14.6075 12.4586 14.5259 13.9202 14.3632C14.0262 14.3514 14.1252 14.3043 14.2012 14.2295C14.2772 14.1547 14.3259 14.0566 14.3394 13.9509C14.6835 11.0065 14.6835 8.03209 14.3394 5.0877C14.3255 4.9821 14.2768 4.88415 14.2008 4.80943C14.1249 4.73472 14.0261 4.68753 13.9202 4.67537C12.4586 4.51267 10.9891 4.43114 9.51842 4.43113Z" fill={color}/>
    <rect x="8.91406" y="6.31836" width="1.22" height="6" rx="0.61" fill={color}/>
    <rect x="12.5195" y="8.71387" width="1.21789" height="6" rx="0.608945" transform="rotate(90 12.5195 8.71387)" fill={color}/>
  </svg>
  
  )
}

export default PasteIcon;
