import { ReactElement, ReactNode, useState } from "react";
import styled from "styled-components";
import Typography from "./typography";
import { colors, shadows, spacing, transitions } from "../../styles";
import ToolButton from "./tool-button";
import ArrowDownIcon from "../../assets/icons/arrow-down";

interface ListProps<T> {
  records : T[];
  renderer ?: (value : T) => ReactElement;
  controls ?: {icon : ReactElement | ((value : T) => ReactElement); callback : (value : T, index : number) => void, color ?: 'dark' | 'light'}[];
  expand ?: (value : T) => ReactElement;
}

const defaultRenderer = <T extends any>(value : T) => {
  if(typeof value === 'string' || typeof value === 'number') {
    return <Typography variant="textRegular" color="text">{value}</Typography>;
  }
  else {
    console.warn('Unable to render value', value);
    return '';
  }
}

const List = <T extends any>(p : ListProps<T>) => {
  const [expanded, setExpanded] = useState<number>(-1);

  return (
    <Container>
      {
        p.records.map(((item, index) => (
          <Item key={`key_${index}`}>
            <Header>
              <Content>
                {p.renderer ? p.renderer(item) : defaultRenderer(item)}
              </Content>
              <Controls>
                {
                  p.controls?.map((control, index) => (
                    <StyledButton key={`control_${index}`} color={control.color} onClick={() => control.callback(item, index)}>{typeof control.icon === 'function' ? control.icon(item) : control.icon}</StyledButton>
                  ))
                }
                {
                  p.expand ?
                    <StyledButton onClick={() => setExpanded(e => e === index ? -1 : index)}><StyledArrowDownIcon $rotated={expanded === index}/></StyledButton> :
                    null
                }
              </Controls>
            </Header>
            {
              p.expand ?
                <Expand $expanded={expanded === index}>
                  {p.expand(item)}
                </Expand> :
                null
            }
          </Item>
        )))
      }
    </Container>
  );
}

export default List;

const Container = styled.div`

`

const Item = styled.div`
  padding: ${spacing}rem;
  border-radius: ${spacing}rem;
  margin-top : ${spacing}rem;
  box-shadow: ${shadows.default};
  background-color: ${colors.background2};
`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const Content = styled.div`

`

const Controls = styled.div`
  display : flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

const StyledButton = styled(ToolButton)`
  margin-left : ${spacing}rem;
`

const StyledArrowDownIcon = styled(ArrowDownIcon)<{$rotated : boolean}>`
  transition : transform ${transitions.fast};

  transform : rotate(${p => p.$rotated ? '180deg' : '0deg'});
`

const Expand = styled.div<{$expanded : boolean}>`
  max-height : ${p => p.$expanded ? '30rem' : '0'};
  transition : max-height ${transitions.fast};
  overflow: ${p => p.$expanded ? 'visible' : 'hidden'};
`