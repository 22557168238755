import styled from 'styled-components';
import { useAssertAdmin } from '../hooks/use-assert-admin';
import PageLayout from '../components/layout/page-layout';
import { useTranslation } from 'react-i18next';
import Typography from '../components/atoms/typography';
import { useModelStore } from '../store/store';
import { Region } from '../store/store-types';
import List from '../components/atoms/list';
import TextButton from '../components/atoms/text-button';
import TrashIcon from '../assets/icons/trash';
import { colors } from '../styles';
import EditIcon from '../assets/icons/edit';
import RegionModal from '../components/molecules/modals/region-modal';
import { useState } from 'react';
import { useModelActions } from '../store/hooks/use-actions';
import HTMLTypography from '../components/atoms/html-typography';
import UnarchiveIcon from '../assets/icons/unarchive';
import ArchiveIcon from '../assets/icons/archive';

interface RegionsPageProps {

}

const RegionsPage : React.FC<RegionsPageProps> = (p) => {
  useAssertAdmin();
  const {t} = useTranslation();
  const _regions = useModelStore(state => state.regions);
  const [modalVisible, setModalVisible] = useState<boolean>(false);
  const [editedItem, setEditedItem] = useState<Region | undefined>();
  const {archive, unarchive} = useModelActions('regions');
  const {showDialog} = useModelActions('modals');
  const [showArchived, setShowArchived] = useState<boolean>(false);

  const regions = showArchived ? _regions : _regions.filter(({inactive}) => !inactive);

  const handleShowCreate = () => {
    setModalVisible(true)
  }
  const handleShowEdit = (region : Region) => {
    setModalVisible(true)
    setEditedItem(region);
  }
  const handleHideModal = () => {
    setModalVisible(false);
    setEditedItem(undefined);
  }

  const handleDelete = (item : Region) => {
    if(item.inactive) {
      unarchive(item.id);
    }
    else {
      showDialog({
        content :<HTMLTypography variant="textRegular" color="text" html={t('pages.regions.deleteDialogContent', {name : item.name})}/>,
        callback : () => archive(item.id)
      })
    }
  }

  return (
    <PageLayout
      title={t('pages.regions.name')}
      subtitle={t('pages.regions.subtitle')}
      controls={<TextButton onClick={handleShowCreate} color="dark">{t('pages.regions.createNew')}</TextButton>}
      modal={
        modalVisible ? <RegionModal onClose={handleHideModal} region={editedItem}/> : null
      }
    >
      <Container>
        <div style={{textAlign : 'right'}}>
          <Typography variant="smallTextBold" color="primary" onClick={() => setShowArchived(s => !s)} style={{cursor : 'pointer'}}>
              {!showArchived ? t('show-archived') : t('hide-archived')}
          </Typography>
        </div>

        <List<Region>
          records={regions}
          renderer={(value) => (
            <Typography variant="textBold" color="text">{value.name}</Typography>
          )}
          controls={[
            {icon : (value) => value.inactive ? <UnarchiveIcon color={colors.accent}/> : <ArchiveIcon color={colors.primary}/>, callback : handleDelete, color : 'light'},
            {icon : <EditIcon color={colors.primary}/>, callback : handleShowEdit, color : 'light'}
          ]}
        />
      </Container>
    </PageLayout>
  )
}

export default RegionsPage;

const Container = styled.div`
`
