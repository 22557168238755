import { useTranslation } from "react-i18next";
import Modal from "../modal";
import UserIcon from "../../../assets/icons/user";
import { colors, spacing, typography } from "../../../styles";
import styled from "styled-components";
import Typography from "../../atoms/typography";
import { useUserStore } from "../../../store/store";
import { useState } from "react";
import TextButton from "../../atoms/text-button";
import { Customer } from "../../../store/store-types";
import CustomersIcon from "../../../assets/icons/customers";
import ToolButton from "../../atoms/tool-button";
import TrashIcon from "../../../assets/icons/trash";

interface CustomerModalProps {
  customer ?: Customer;
  onClose : () => void;
  onSubmit : (firstName : string, lastName : string) => void;
  onDelete : () => void;
}

const CustomerModal : React.FC<CustomerModalProps> = (p) => {
  const {t} = useTranslation();
  // const loading = useUserStore(state => state.process.loading['user.update']);

  const [editedName, setEditedName] = useState<string | undefined>(p.customer?.name);
  const [editedEmail, setEditedEmail] = useState<string | undefined>(p.customer?.email);

  const handleSubmit = () => {
    if(editedName) {
      p.onSubmit(editedName, editedEmail || '')
    }
  }

  return (
    <Modal
      title={t('modals.customer.title')}
      subtitle={t('modals.customer.subtitle')}
      icon={<CustomersIcon color={colors.text}/>}
      onClose={p.onClose}
      controls={[
        <ToolButton key="delete" disabled={!editedName?.trim()} onClick={p.onDelete}><TrashIcon color={colors.primary}/></ToolButton>,
        <TextButton key="validate" disabled={!editedName?.trim()} onClick={handleSubmit}>{t('validate')}</TextButton>
      ]}
    >
      <NameInput
        placeholder={t('modals.customer.namePlaceholder')}
        value={editedName}
        onChange={e => setEditedName(e.target.value)}
      />
      <InputsContainer>
        <EmailInput
          placeholder={t('modals.customer.emailPlaceholder')}
          value={editedEmail}
          onChange={e => setEditedEmail(e.target.value)}
        />
      </InputsContainer>
    </Modal>
  );
}

export default CustomerModal;

const InputsContainer = styled.div`
  min-width : 50rem;
  margin-top: ${spacing}rem;
  background-color: ${colors.background};
  border-radius: ${spacing}rem;
  padding: ${spacing}rem;
  display: flex;
  flex-direction: column;
`

const NameInput = styled.input`
  margin-top: ${spacing}rem;
  color : ${colors.text};
  &::placeholder {
    color : ${colors.tertiaryText};
  }
  ${typography.bigTitle.css};
  border : none;
  background-color: transparent;
  outline: none;
`

const EmailInput = styled.input`
  color : ${colors.text};
  &::placeholder {
    color : ${colors.tertiaryText};
  }
  ${typography.menuBold.css};
  border : none;
  background-color: transparent;
  outline: none;
`