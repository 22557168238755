import { io } from "socket.io-client";
import configProvider from "./configprovider";
import {useModelStore} from '../store/store';

const socket = io(configProvider('PDF_SERVICE_URL'), {transports : ['websocket']});

const tasksUploads : {taskId : string, uploadId : number}[] = [];

socket.on('new-task', (taskId : string, payload : any) => {
  if(payload?.uploadId) {
    tasksUploads.push({taskId, uploadId : payload?.uploadId});
  }
})

socket.on('status-change', (taskId : string, payload : any) => {
  const uploadId = tasksUploads.find(tu => tu.taskId === taskId)?.uploadId;

  if(payload === 'error' && uploadId) {
    useModelStore.getState().actions.rt.importEnd(uploadId);
  }
})

socket.on('new-page', (taskId : string, payload : any) => {
  useModelStore.getState().actions.rt.newPage(payload.page);
  useModelStore.getState().actions.rt.importProgress(payload.uploadId, payload.progress, payload.total);
})

socket.on('import-end', (taskId : string, payload : any) => {
  useModelStore.getState().actions.rt.importEnd(payload.uploadId);
})

socket.on('notebook-generation-progress', (taskId : string, payload : any) => {
  useModelStore.getState().actions.rt.generationProgress(payload);
})


socket.on('notebook-generated', (taskId : string, payload : any) => {
  useModelStore.getState().actions.rt.notebookGenerated(payload);
})


socket.on('attachment-thumbnail-created', (taskId : string, payload : any) => {
  useModelStore.getState().actions.rt.attachmentThumbnailCreated(payload);
})


socket.on('video-screenshot-created', (taskId : string, payload : any) => {
  useModelStore.getState().actions.rt.videoScreenshotCreated(payload);
})