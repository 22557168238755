import styled, { keyframes } from "styled-components";
import { colors, shadows, spacing, transitions } from "../../styles";
import TextButton from "../atoms/text-button";
import { useTranslation } from "react-i18next";
import { useModelStore } from "../../store/store";
import { useModelActions } from "../../store/hooks/use-actions";
import { ReactNode } from "react";

interface DialogProps {
  content ?: ReactNode;
  callback ?: () => void;
  cancelText ?: string | false;
  validateText ?: string | false;
}

const Dialog : React.FC<DialogProps> = (p) => {
  const {t} = useTranslation();
  const {content, callback, validateText, cancelText} = p;
  const {hideDialog} = useModelActions('modals');

  return (
    <Container
      onClick={e => {hideDialog(); e.stopPropagation();}}
      onContextMenu={e => {hideDialog(); e.stopPropagation();e.preventDefault()}}
    >
      <Frame onClick={e => e.stopPropagation()}>
        {content}
        <Footer>
          {
            cancelText === false ? null :
            <TextButton onClick={hideDialog} color="medium">{cancelText || t('modals.cancel')}</TextButton>
          }
          {
            validateText === false ? null :
            <TextButton onClick={() => {hideDialog(); callback?.();}}>{validateText || t('modals.validate')}</TextButton>
          }
        </Footer>
      </Frame>
    </Container>
  );
}

export default Dialog;

const Container = styled.div`
  position : fixed;
  top : 0;
  left : 0;
  width : 100%;
  height : 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  backdrop-filter : blur(0.8rem) brightness(0.8);
`
const animate = keyframes`
 0% { opacity : 0; }
 100% { opacity : 1; }
`

const Frame = styled.div`
  background-color : ${colors.background2};
  border-radius : ${spacing}rem;
  box-shadow: ${shadows.default};
  padding : ${2*spacing}rem;
  animation : ${animate} ${transitions.fast};
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top : ${2*spacing}rem;

  & button {
    margin-left : ${spacing}rem;
  }
`