import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useAssertAdmin } from '../hooks/use-assert-admin';
import PageLayout from '../components/layout/page-layout';
import NotebookNameInput from '../components/atoms/notebook-name-input';
import TextButton from '../components/atoms/text-button';
import Select from '../components/atoms/select';
import { useModelStore } from '../store/store';
import { LIBRARY, NOTEBOOK_TEMPLATE } from '../constants/routes';
import { useModelActions } from '../store/hooks/use-actions';
import { ID, NotebookType } from '../store/store-types';

interface NewNotebookPageProps {

}

const NewNotebookPage : React.FC<NewNotebookPageProps> = (p) => {
  useAssertAdmin();
  const navigate = useNavigate();
  const { type } = useParams();
  const {t} = useTranslation();
  const [name, setName] = useState<string>('');
  const [marketsIds, setMarketsIds] = useState<ID[] | undefined>();
  const markets = useModelStore(state => state.markets.filter(({inactive}) => !inactive));
  const {createTemplate} = useModelActions('notebooks');
  const marketsChoices = markets.map(market => ({label : market.name, value : market.id}));

  const handleCancel = () => {
    navigate(LIBRARY);
  }

  const handleCreate = async () => {
    if(!name || !marketsIds) {return;}

    try {
      const notebookId = await createTemplate(name, marketsIds || [], type === 'generic' ? 'generic' : type === 'annexes' ? 'annexes' : 'offers');
      navigate(NOTEBOOK_TEMPLATE.replace(':id', `${notebookId}`));
    }
    catch(error) {}
  }

  useEffect(() => {
    const types : NotebookType[] = ['annexes', 'generic', 'offers'];
    if(!types.includes(type as NotebookType)) {
      navigate(LIBRARY);
    }
  }, [type])

  return (
    <PageLayout
      breadcrumbs={[t('pages.new-notebook.breadcrumb-' + type)]}
      title={
        <TitleContainer>
          <NotebookNameInput value={name} onChange={setName} placeholder={t('pages.new-notebook.namePlaceholder')}/>
          <Select<ID>
            choices={marketsChoices}
            value={marketsIds}
            onChange={setMarketsIds}
            placeholder='Marché'
            multiselect
          />
        </TitleContainer>
      }
      subtitle={t('pages.new-notebook.subtitle')}
      controls={
        <>
          <TextButton color="light" onClick={handleCancel}>{t('cancel')}</TextButton>
          <TextButton disabled={!name || !marketsIds} color="dark" onClick={handleCreate}>{t('pages.new-notebook.add-content-cta')}</TextButton>
        </>
      }
    >
      <Container>
      </Container>
    </PageLayout>
  )
}

export default NewNotebookPage;

const Container = styled.div`
`
const TitleContainer = styled.div`
  display: flex;
`