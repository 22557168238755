import { useTranslation } from "react-i18next";
import Modal from "../modal";
import { colors, spacing } from "../../../styles";
import styled from "styled-components";
import { useModelStore } from "../../../store/store";
import { useEffect, useState } from "react";
import TextButton from "../../atoms/text-button";
import { Attachment, ID, Video } from "../../../store/store-types";
import { useModelActions } from "../../../store/hooks/use-actions";
import Typography from "../../atoms/typography";
import NotebookCard from "../../atoms/notebook-card";
import configProvider from "../../../providers/configprovider";
import Search from "../../atoms/search";
import AddIcon from "../../../assets/icons/add";
import normalize from "../../../store/helpers/normalize-search-string";
import NotebookLink from "../../atoms/notebook-link";


interface AddContentModalProps {
  onClose : () => void;
  onPrivateVideoSelected : (video : Video) => void;
  onPublicVideoSelected : (video : Video) => void;
  onAttachmentSelected : (attachment : Attachment) => void;
  onPrivateAttachmentSelected : (attachment : Attachment) => void;
  onAddAttachment : () => void;
  onAddPrivateAttachment : () => void;
  onAddVideo : () => void;
  onAddPrivateVideo : () => void;

}

const AddContentModal : React.FC<AddContentModalProps> = (p) => {
  const {t} = useTranslation();
  const {load : loadVideos, loadPrivate : loadPrivateVideos} = useModelActions('videos');
  const {load : loadAttachments, loadPrivate : loadPrivateAttachments} = useModelActions('attachments');
  const publicVideos = useModelStore(state => state.videos.filter(v => !v.inactive));
  const privateVideos = useModelStore(state => state.privateVideos.filter(v => !v.inactive));
  const attachments = useModelStore(state => state.attachments.filter(a => !a.inactive));
  const privateAttachments = useModelStore(state => state.privateAttachments.filter(a => !a.inactive));

  const [selectType, setSelectType] = useState<'publicVideos' | 'privateVideos' | 'attachments' | 'privateAttachments'>('privateVideos');
  const [selectedPrivateVideo, setSelectedPrivateVideo] = useState<ID | undefined>();
  const [selectedPublicVideo, setSelectedPublicVideo] = useState<ID | undefined>();
  const [selectedAttachment, setSelectedAttachment] = useState<ID | undefined>();
  const [selectedPrivateAttachment, setSelectedPrivateAttachment] = useState<ID | undefined>()

  const [search, setSearch] = useState<string>();

  useEffect(() => {
    loadVideos();
    loadPrivateVideos();
    loadAttachments();
    loadPrivateAttachments();
  }, []);

  const disabled =
    (selectType === 'attachments' && !selectedAttachment) ||
    (selectType === 'privateAttachments' && !selectedPrivateAttachment) ||
    (selectType === 'privateVideos' && !selectedPrivateVideo) ||
    (selectType === 'publicVideos' && !selectedPublicVideo);

  const handleSubmit = () => {
    if(selectType === 'privateVideos' && selectedPrivateVideo) {
      const video = privateVideos.find(video => video.id === selectedPrivateVideo)
      video && p.onPrivateVideoSelected(video);
    }
    else if(selectType === 'publicVideos' && selectedPublicVideo) {
      const video = publicVideos.find(video => video.id === selectedPublicVideo)
      video && p.onPublicVideoSelected(video);
    }
    else if(selectType === 'attachments' && selectedAttachment) {
      const attachment = attachments.find(attachment => attachment.id === selectedAttachment)
      attachment && p.onAttachmentSelected(attachment);
    }
    else if(selectType === 'privateAttachments' && selectedPrivateAttachment) {
      const attachment = privateAttachments.find(attachment => attachment.id === selectedPrivateAttachment)
      attachment && p.onPrivateAttachmentSelected(attachment);
    }

    p.onClose();
  }

  const handlePrivateVideoClick = (id : ID) => {
    setSelectedPrivateVideo(id);
    setSelectType('privateVideos');
  }

  const handlePublicVideoClick = (id : ID) => {
    setSelectedPublicVideo(id);
    setSelectType('publicVideos');
  }

  const handleAttachmentClick = (id : ID) => {
    setSelectedAttachment(id);
    setSelectType('attachments');
  }

  const handlePrivateAttachmentClick = (id : ID) => {
    setSelectedPrivateAttachment(id);
    setSelectType('privateAttachments');
  }

  return (
    <Modal
      title={t('modals.addContent.title')}
      subtitle={t('modals.addContent.subtitle')}
      icon={<AddIcon color={colors.text}/>}
      onClose={p.onClose}
      onSubmit={handleSubmit}
      headerControls={
        <StyledSearch key="search" onChange={setSearch} debounce={1} placeholder={t('modals.replaceSlide.searchPlaceholder')}/>
      }
      controls={[
        <TextButton key="validate" type="submit" disabled={disabled}>{t('modals.addContent.validate')}</TextButton>
      ]}
    >
      <Container>
        <Typography variant="mediumTitle" color="text">{t('pages.library.notebook-generiques')}</Typography>
        <NotebookLinksContainer>
          {
            attachments.filter(item => !search || normalize(item.name).match(normalize(search))).map(attachment => (
              <NotebookCard
                key={attachment.id}
                value={selectType === 'attachments' && selectedAttachment === attachment.id}
                imgUrl={attachment.thumbnailStorageName ? `${configProvider('MODEL_API_URL')}/attached-notebooks/${attachment.id}/thumbnail.png` : ''}
                title={attachment.name}
                onChange={() => handleAttachmentClick(attachment.id)}
              />
            ))
          }
          <StyledNotebookLink
            new
            title={t('pages.library.new-notebook-generiques')}
            linkTo={''}
            onClick={p.onAddAttachment}
          />
        </NotebookLinksContainer>

        <Typography variant="mediumTitle" color="text">{t('pages.library.private-attachments')}</Typography>
        <NotebookLinksContainer>
          {
            privateAttachments.filter(item => !search || normalize(item.name).match(normalize(search))).map(attachment => (
              <NotebookCard
                key={attachment.id}
                value={selectType === 'privateAttachments' && selectedPrivateAttachment === attachment.id}
                imgUrl={attachment.thumbnailStorageName ? `${configProvider('MODEL_API_URL')}/private-attached-notebooks/${attachment.id}/thumbnail.png` : ''}
                title={attachment.name}
                onChange={() => handlePrivateAttachmentClick(attachment.id)}
              />
            ))
          }
          <StyledNotebookLink
            new
            title={t('pages.library.new-notebook-generiques')}
            linkTo={''}
            onClick={p.onAddPrivateAttachment}
          />
        </NotebookLinksContainer>

        <Typography variant="mediumTitle" color="text">{t('pages.library.videos')}</Typography>
        <NotebookLinksContainer>
          {
            publicVideos.filter(item => !search || normalize(item.name).match(normalize(search))).map(video => (
              <NotebookCard
                key={video.id}
                value={selectType === 'publicVideos' && selectedPublicVideo === video.id}
                imgUrl={video.thumbnailStorageName ? `${configProvider('MODEL_API_URL')}/public-videos/${video.id}/thumbnail.png` : ''}
                title={video.name}
                onChange={() => handlePublicVideoClick(video.id)}
              />
            ))
          }
          <StyledNotebookLink
            new
            title={t('pages.library.new-notebook-videos')}
            linkTo={''}
            onClick={p.onAddVideo}
          />
        </NotebookLinksContainer>

        <Typography variant="mediumTitle" color="text">{t('pages.library.privateVideos')}</Typography>
        <NotebookLinksContainer>
          {
            privateVideos.filter(item => !search || normalize(item.name).match(normalize(search))).map(video => (
              <NotebookCard
                key={video.id}
                value={selectType === 'privateVideos' && selectedPrivateVideo === video.id}
                imgUrl={video.thumbnailStorageName ? `${configProvider('MODEL_API_URL')}/private-videos/${video.id}/thumbnail.png` : ''}
                title={video.name}
                onChange={() => handlePrivateVideoClick(video.id)}
              />
            ))
          }
          <StyledNotebookLink
            new
            title={t('pages.library.new-notebook-videos')}
            linkTo={''}
            onClick={p.onAddPrivateVideo}
          />
        </NotebookLinksContainer>
      </Container>
    </Modal>
  );
}

export default AddContentModal;


const Container = styled.div`
  width : 108rem;
  height : 60vh;
  overflow: auto;
`

const SlidesContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  place-content: flex-start;
`

const NotebookLinksContainer = styled.div`
  margin-top : ${spacing}rem;
  margin-bottom : 4.4rem;
  display: flex;
  flex-direction : row;
  flex-wrap: wrap;
`

const SlideCard = styled.div`
  width : 20rem;
  background-color : ${colors.background2};
  overflow : hidden;
  margin-right : ${spacing}rem;
  margin-bottom : ${spacing}rem;
  cursor : pointer;

  & > img {
    border : 0.3rem solid ${colors.background2} !important;
  }

  &.selected > img {
    border : 0.3rem solid ${colors.primary} !important;
  }
`

const Image = styled.img`
  width : 20rem;
  height : 11.3rem;
  border-radius: 1rem;
  object-fit: cover;
  border : none;
`

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  padding-left : 0.5rem;
  & span {
    text-decoration-line: none !important;
  }
`

const StyledSearch = styled(Search)`
  margin-left : ${2*spacing}rem;
  & > input {
    background-color: ${colors.background};
  }
`

const FileInput = styled.input`
  position : absolute;
  visibility: hidden;
`

const LoadFromDiskButton = styled(TextButton)`
  margin-left : ${2*spacing}rem;
`

const HalfDomeContainer = styled.div`
  display: flex;
  align-items : center;
  justify-content: center;
  width : 20rem;
  height : 11.3rem;
  background-color: ${colors.lightPrimary};
  border-radius: 1rem;
`

const StyledNotebookLink = styled(NotebookLink)`
 & > div:first-child {
  background-color: ${colors.lightPrimary};
 }
`