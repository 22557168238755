import styled from "styled-components";
import { colors, spacing } from "../../styles";
import Typography from "./typography";
import { useTranslation } from "react-i18next";
import { useModelStore } from "../../store/store";

interface MarketSelectorProps {
  selected : number;
  onChange : (marketId : number) => void;
}

const MarketSelector : React.FC<MarketSelectorProps> = (p) => {
  const {t} = useTranslation();
  const markets = useModelStore(state => state.markets.filter(({inactive}) => !inactive));
  const all = {
    id : 0,
    name : t('pages.markets.all')
  }

  return (
    <Container>
      {
        [all, ...markets].map(market => (
          <Market key={market.id} onClick={() => p.onChange(market.id)} tabIndex={0} role="select">
            <Typography variant={p.selected === market.id ? 'uppercaseUnderlinedTextBold' : 'uppercaseTextRegular'} color='primary'>{market.name}</Typography>
          </Market>
        ))
      }
    </Container>
  );
}

export default MarketSelector;

const Container = styled.div`
  display : flex;
  flex  : 1;
  flex-wrap: wrap;
  justify-content: flex-end;
`

const Market = styled.div`
  margin-right : ${spacing}rem;
  outline: none;
  cursor : pointer;
  flex-wrap: nowrap;
  margin-bottom: ${0.5*spacing}rem;
`