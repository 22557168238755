import styled from 'styled-components';
import PageLayout from '../components/layout/page-layout';
import { useTranslation } from 'react-i18next';
import { colors, shadows, spacing } from '../styles';
import TextButton from '../components/atoms/text-button';
import { useAssertLogged } from '../hooks/use-assert-logged';
import NotebookNameInput from '../components/atoms/notebook-name-input';
import React, { ReactNode, useEffect, useRef, useState } from 'react';
import { useModelActions } from '../store/hooks/use-actions';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Attachment, Customer, ID, NotebookTemplate, Offer, Proposal, ProposalContent, Video } from '../store/store-types';
import { PROPOSAL_NOTEBOOK, PROPOSAL_OFFER, PROPOSAL_OFFER_NOTEBOOK } from '../constants/routes';
import { useModelStore } from '../store/store';
import DocumentIcon from '../assets/icons/document';
import Typography from '../components/atoms/typography';
import configProvider from '../providers/configprovider';
import ToolButton from '../components/atoms/tool-button';
import ViewIcon from '../assets/icons/view';
import Checkbox from '../components/atoms/checkbox';
import EditIcon from '../assets/icons/edit';
import NotebookPreviewModal from '../components/molecules/modals/notebook-preview-modal';
import DatePicker from '../components/molecules/date-picker/date-picker';
import CalendarIcon from '../assets/icons/calendar';
import dateString from '../store/helpers/date-string';
import ProgressBar from '../components/atoms/progress-bar';
import useDialog from '../hooks/use-dialog';
import HTMLTypography from '../components/atoms/html-typography';
import ShareProposalModal from '../components/molecules/modals/share-proposal-modal';
import CustomersIcon from '../assets/icons/customers';
import CustomerModal from '../components/molecules/modals/customer-modal';
import ArrowDownIcon from '../assets/icons/arrow-down';
import RegionIcon from '../assets/icons/region';
import MultiSelect from '../components/molecules/multi-select/multi-select';
import useLogged from '../hooks/use-logged';
import ClosedFoodDome from '../assets/images/closed-food-dome';
import VideoIcon from '../assets/icons/video';
import AddContentModal from '../components/molecules/modals/add-content-modal';
import PlusIcon from '../assets/icons/plus';
import TrashIcon from '../assets/icons/trash';
import SharePicker from '../components/molecules/share-picker/share-picker';
import useAutosave from '../hooks/use-autosave';
import AttachmentModal from '../components/molecules/modals/attachment-modal';
import VideoModal from '../components/molecules/modals/video-modal';
import useProposalOfferNotebookId from '../hooks/use-proposal-offer-id';

interface ProposalPageProps {

}

const ProposalPage : React.FC<ProposalPageProps> = () => {
  useAssertLogged();
  const logged = useLogged();
  const {t} = useTranslation();
  const {id} = useParams();
  const navigate = useNavigate();
  const [proposal, setProposal] = useState<Proposal | undefined>();
  const [proposalContent, setProposalContent] = useState<ProposalContent | undefined>();
  const {loadOne, update, loadContent, saveContent, updateGenerationStatus, clearGeneration} = useModelActions('proposals');
  const {loadOne : loadOffer} = useModelActions('offers');
  const {loadTemplates, loadTemplateContent} = useModelActions('notebooks');
  const {load : loadAttachments, loadPrivate : loadPrivateAttachments} = useModelActions('attachments');
  const {load : loadVideos, loadPrivate : loadPrivateVideos} = useModelActions('videos');
  const {loadOne : loadCustomer, update : updateCustomer, create : createCustomer, uploadLogo} = useModelActions('customers');
  const {load : loadRegions} = useModelActions('regions');

  const [offer, setOffer] = useState<Offer | null>(null);
  const [selectedNotebooks, setSelectedNotebooks] = useState<Record<ID, boolean>>({});
  const [selectedAttachments, setSelectedAttachments] = useState<Record<ID, boolean>>({});
  const [selectedVideos, setSelectedVideos] = useState<Record<ID, boolean>>({});
  const selectedNotebooksIds = Object.entries(selectedNotebooks).filter(([id, selected]) => !!selected).map(([id]) => parseInt(`${id}`));
  const selectedAttachmentsIds = Object.entries(selectedAttachments).filter(([id, selected]) => !!selected).map(([id]) => parseInt(`${id}`));
  const selectedVideosIds = Object.entries(selectedVideos).filter(([id, selected]) => !!selected).map(([id]) => parseInt(`${id}`));

  const notebookTemplates = useModelStore(state => state.notebooks.templates);
  const attachments = useModelStore(state => state.attachments);
  const videos = useModelStore(state => state.videos);
  // const offerNotebook = Object.values(notebookTemplates).find(notebook => notebook.type === 'offers' && notebook.draft === false && offer?.notebooksIds.includes(notebook.id));
  const offerNotebooks = offer?.notebooksIds?.map(nid => notebookTemplates[nid])?.filter(n => !!n && n.type === 'offers' && n.draft === false);
  const annexesNotebooks = Object.values(notebookTemplates).filter(notebook => notebook.type === 'annexes' && notebook.draft === false && offer?.notebooksIds.includes(notebook.id));
  const genericNotebooks = Object.values(attachments).filter(attachment => offer?.attachmentsIds.includes(attachment.id));
  const offerVideos = Object.values(videos).filter(video => offer?.videosIds.includes(video.id));

  const offerNotebookId = useProposalOfferNotebookId(parseInt(`${id}`));

  const progress = useModelStore(state => state.process.progress);
  const generated = useModelStore(state => state.proposals.generated);
  const isGenerated = (notebookId ?: ID) => !!generated[`${id}-${notebookId}`];
  const generationCompleted = isGenerated(offerNotebookId) && annexesNotebooks.every(notebook => !selectedNotebooks[notebook.id] || isGenerated(notebook.id));

  const [showShareModal, setShowShareModal] = useState<boolean>(false);

  const [previewPages, setPreviewPages] = useState<ID[]>();
  const [previewTitle, setPreviewTitle] = useState<string>();

  const [validFrom, setValidFrom] = useState<Date>();
  const [validUntil, setValidUntil] = useState<Date>();
  const [customer, setCustomer] = useState<Customer>();
  const [showCustomerModal, setShowCustomerModal] = useState<boolean>(false);
  const [name, setName] = useState<string>('');

  const logoInputRef = useRef<HTMLInputElement>(null);

  const regions = useModelStore(state => state.regions.filter(({inactive}) => !inactive));
  const [selectedRegions, setSelectedRegions] = useState<ID[]>([]);
  const regionsLabel = selectedRegions.length === 0 ?
    t('pages.proposal.regionsPlaceholder') :
    selectedRegions.length === 1 ?
    <RegionPlaceholder><Typography variant="textRegular" color="primary">{regions.find(({id}) => id === selectedRegions[0])?.name || ''}</Typography></RegionPlaceholder> :
    <RegionPlaceholderContainer><RegionPlaceholder><Typography variant="textRegular" color="primary">{regions.find(({id}) => id === selectedRegions[0])?.name || ''}</Typography></RegionPlaceholder><Typography variant="textRegular" color="primary">(+{selectedRegions.length-1})</Typography></RegionPlaceholderContainer>;


  const [addedPublicVideos, setAddedPublicVideos] = useState<Video[]>([]);
  const [privateVideos, setPrivateVideos] = useState<Video[]>([]);
  const [addedAttachments, setAddedAttachments] = useState<Attachment[]>([]);
  const [privateAttachments, setPrivateAttachments] = useState<Attachment[]>([]);

  const [showAddContent, setShowAddContent] = useState<boolean>(false);
  const [showAttachmentModal, setShowAttachmentModal] = useState<boolean | undefined>();
  const [showPrivateAttachmentModal, setShowPrivateAttachmentModal] = useState<boolean | undefined>();
  const [showVideoModal, setShowVideoModal] = useState<boolean | undefined>();
  const [showPrivateVideoModal, setShowPrivateVideoModal] = useState<boolean | undefined>();

  const privateVideosIds = privateVideos.map(v => v.id);
  const addedPublicVideosIds = addedPublicVideos.map(v => v.id);
  const addedAttachmentsIds = addedAttachments.map(v => v.id);
  const privateAttachmentsIds = privateAttachments.map(v => v.id);


  const isModified = dateString(new Date(proposal?.sentAt ? proposal.sentAt : 0)) !== dateString(validFrom ? validFrom : new Date(0)) ||
    dateString(new Date(proposal?.validUntil ? proposal.validUntil : 0)) !== dateString(validUntil ? validUntil : new Date(0)) ||
    selectedNotebooksIds.length !== (proposalContent?.selectedNotebooksIds || []).length ||
    selectedNotebooksIds.some(id => !(proposalContent?.selectedNotebooksIds || []).includes(id)) ||
    selectedAttachmentsIds.length !== (proposalContent?.selectedAttachmentsIds || []).length ||
    selectedAttachmentsIds.some(id => !(proposalContent?.selectedAttachmentsIds || []).includes(id)) ||
    selectedVideosIds.length !== (proposalContent?.selectedVideosIds || []).length ||
    selectedVideosIds.some(id => !(proposalContent?.selectedVideosIds || []).includes(id)) ||
    privateVideosIds.length !== (proposalContent?.privateVideosIds || []).length ||
    privateVideosIds.some(id => !(proposalContent?.privateVideosIds || []).includes(id)) ||

    addedPublicVideosIds.length !== (proposalContent?.addedPublicVideosIds || []).length ||
    addedPublicVideosIds.some(id => !(proposalContent?.addedPublicVideosIds || []).includes(id)) ||
    addedAttachmentsIds.length !== (proposalContent?.addedAttachmentsIds || []).length ||
    addedAttachmentsIds.some(id => !(proposalContent?.addedAttachmentsIds || []).includes(id)) ||
    privateAttachmentsIds.length !== (proposalContent?.privateAttachmentsIds || []).length ||
    privateAttachmentsIds.some(id => !(proposalContent?.privateAttachmentsIds || []).includes(id)) ||

    (customer && proposal?.customerId != customer?.id) ||
    (selectedRegions.length != proposal?.regionsIds?.length || selectedRegions.some(id => !(proposal?.regionsIds || []).includes(id))) ||
    proposal.name !== name;

  const shouldRegenerateOnUpdate = dateString(new Date(proposal?.sentAt ? proposal.sentAt : 0)) !== dateString(validFrom ? validFrom : new Date(0)) ||
    dateString(new Date(proposal?.validUntil ? proposal.validUntil : 0)) !== dateString(validUntil ? validUntil : new Date(0)) ||
    (customer && proposal?.customerId != customer?.id) ||
    proposal?.name !== name;

  useEffect(() => {
    (async () => {
      if(id && logged) {
        const proposal = await loadOne(parseInt(`${id}`));

        if(!proposal.offerId) {
          navigate(PROPOSAL_OFFER.replace(':id', id))
          return;
        }

        const content = await loadContent(parseInt(`${id}`));

        await loadTemplates();
        const attachments = await loadAttachments();
        const videos = await loadVideos();
        const privateVideos = await loadPrivateVideos();
        const privateAttachments = await loadPrivateAttachments();
        const offer = await loadOffer(proposal.offerId);

        await updateGenerationStatus(parseInt(`${id}`));

        if(proposal.customerId){
          const customer = await loadCustomer(proposal.customerId)
          setCustomer(customer);
        }

        await loadRegions();

        setProposal(proposal);
        setProposalContent(content);
        setOffer(offer);

        setName(proposal.name);
        setValidFrom(proposal.sentAt ? new Date(proposal.sentAt) : undefined);
        setValidUntil(proposal.validUntil ? new Date(proposal.validUntil) : undefined);
        setSelectedNotebooks((content.selectedNotebooksIds || []).reduce((cumul, next) => {cumul[next] = true; return cumul}, {} as Record<ID, boolean>))
        setSelectedAttachments((content.selectedAttachmentsIds || []).reduce((cumul, next) => {cumul[next] = true; return cumul}, {} as Record<ID, boolean>))
        setSelectedVideos((content.selectedVideosIds || []).reduce((cumul, next) => {cumul[next] = true; return cumul}, {} as Record<ID, boolean>))
        setSelectedRegions(proposal.regionsIds || []);
        setPrivateVideos((content.privateVideosIds || []).map(id => privateVideos.find(pv => pv.id === id)).filter(v => !!v) as Video[]);
        setAddedPublicVideos((content.addedPublicVideosIds || []).map(id => videos.find(pv => pv.id === id)).filter(v => !!v) as Video[]);
        setAddedAttachments((content.addedAttachmentsIds || []).map(id => attachments.find(pv => pv.id === id)).filter(v => !!v) as Video[]);
        setPrivateAttachments((content.privateAttachmentsIds || []).map(id => privateAttachments.find(pv => pv.id === id)).filter(v => !!v) as Attachment[]);
      }
    })()
  }, [id, logged])

  const handleViewPdf = (notebookId : number) => {
    window.open(
      `${configProvider('PDF_SERVICE_URL')}/generated/${id}/${notebookId}/file`,
      '_blank'
    )
  }

  const handleSelectNotebook = (id : ID, value : boolean) => {
    if(!proposal?.draft) {return;}
    setSelectedNotebooks(selection => ({
      ...selection,
      [id] : value
    }))
  }


  const handleSelectAttachment = (id : ID, value : boolean) => {
    if(!proposal?.draft) {return;}
    setSelectedAttachments(selection => ({
      ...selection,
      [id] : value
    }))
  }

  const handleSelectVideo = (id : ID, value : boolean) => {
    if(!proposal?.draft) {return;}
    setSelectedVideos(selection => ({
      ...selection,
      [id] : value
    }))
  }

  const handleValidFrom = (date : Date) => {
    const validUntil = new Date(date);
    validUntil.setMonth(date.getMonth() + 3);

    setValidFrom(date);
    setValidUntil(validUntil);
  }

  const handleUpdate = async () => {
    const newProposal = await update(parseInt(`${id}`), {
      ...(validUntil ? {validUntil} : {}),
      ...(validFrom ? {sentAt : validFrom} : {}),
      ...(customer ? {customerId : customer.id} : {}),
      regionsIds : selectedRegions,
      name
    });

    setProposal(newProposal);

    const newContent = await saveContent(parseInt(`${id}`), {
      ...proposalContent,
      selectedNotebooksIds,
      selectedAttachmentsIds,
      selectedVideosIds,
      privateVideosIds,
      addedPublicVideosIds,
      privateAttachmentsIds,
      addedAttachmentsIds
    })

    setProposalContent(newContent);

    if(shouldRegenerateOnUpdate) {
      clearGeneration(parseInt(`${id}`), [offerNotebookId, ...(offer?.notebooksIds || [])]);
    }
  }

  const showConfirmationDialog = useDialog({
    validateText : t('pages.proposal.validateDialogButton'),
    content : <HTMLTypography variant="textRegular" color="text" html={t('pages.proposal.confirmationMessage')}/>,
  });

  const handleValidate = () => {
    showConfirmationDialog(async () => {
      const newProposal = await update(parseInt(`${id}`), {
        draft : false
      });

      setProposal(newProposal);
      setShowShareModal(true);
    });
  }

  const handleShare = () => {
    setShowShareModal(true);
  }

  const handleSubmitCustomer = async (name : string, email : string) => {
    if(proposal) {
      if(customer) {
        const updatedCustomer = await updateCustomer(customer.id, {name, email});
        if(updatedCustomer) {
          setCustomer(updatedCustomer);
        }
      }
      else {
        const customer = await createCustomer({name, email});
        // const updatedProposal = await update(proposal.id, {customerId : customer.id})
        setCustomer(customer);
        // setProposal(updatedProposal);
      }
    }
    setShowCustomerModal(false);
  }

  const handleUploadLogo = () => {
    if(customer && proposal?.draft) {
      logoInputRef.current?.click();
    }
  }

  const handleSelectLogo = async (file : File) => {
    if(customer) {
      const updatedCustomer = await uploadLogo(customer?.id, file);
      setCustomer(updatedCustomer);
    }
  }

  const handleViewAttachment = (attachment : Attachment) => {
    window.open(`${configProvider('MODEL_API_URL')}/attached-notebooks/${attachment.id}/content.pdf`, '_blank')
  }

  const handleViewPrivateAttachment = (attachment : Attachment) => {
    window.open(`${configProvider('MODEL_API_URL')}/private-attached-notebooks/${attachment.id}/content.pdf`, '_blank')
  }

  const handleViewVideo = (video : Video) => {
    window.open(`${configProvider('MODEL_API_URL')}/public-videos/${video.id}/video`, '_blank')
  }

  const handleViewPrivateVideo = (video : Video) => {
    window.open(`${configProvider('MODEL_API_URL')}/private-videos/${video.id}/video`, '_blank')
  }

  const handleRemovePrivateVideo = (video : Video) => {
    setPrivateVideos(videos => videos.filter(v => v.id !== video.id));
  }

  const handleRemoveAddedPublicVideo = (video : Video) => {
    setAddedPublicVideos(videos => videos.filter(v => v.id !== video.id));
  }

  const handleRemovePrivateAttachment = (attachment : Attachment) => {
    setPrivateAttachments(attachments => attachments.filter(a => a.id !== attachment.id));
  }

  const handleRemoveAddedAttachment = (attachment : Attachment) => {
    setAddedAttachments(attachments => attachments.filter(a => a.id !== attachment.id));
  }

  useAutosave(isModified, handleUpdate);


  return (
    <PageLayout
      breadcrumbs={proposal?.draft ? [t('pages.proposal.breadcrumb1')] : undefined}
      title={
        proposal?.draft ?
        <TitleContainer>
          <ProposalNameInput  value={name} onChange={setName} placeholder={t('pages.new-proposal.namePlaceholder')}/>
          <SharePicker proposal={proposal}/>
        </TitleContainer> :
        (proposal?.name || '')
      }
      subtitle={proposal?.draft ? t('pages.proposal.subtitle_edit') : t('pages.proposal.subtitle_view')}
      controls={
        <>
          {
            !proposal?.draft ? null :
            <TextButton disabled={!isModified || !proposal?.draft} onClick={handleUpdate} color="light">{t('pages.proposal.saveDraft')}</TextButton>
          }
          {
            proposal?.draft ?
              <TextButton disabled={!generationCompleted || !validFrom || !validUntil || !customer || isModified} onClick={handleValidate} color="dark">{t('pages.proposal.continue')}</TextButton> :
              <TextButton disabled={!proposal} onClick={handleShare} color="dark">{t('pages.proposal.share')}</TextButton>
          }
        </>
      }
      modal={
        previewPages && previewPages.length && previewTitle ?
          <NotebookPreviewModal title={previewTitle} pages={previewPages} onClose={() => setPreviewPages(undefined)}/> :
        showShareModal ?
          <ShareProposalModal uuid={proposal?.uuid ||''} onClose={() => setShowShareModal(false)} /> :
        showCustomerModal ?
          <CustomerModal customer={customer} onDelete={() => {setShowCustomerModal(false); setCustomer(undefined)}} onClose={() => setShowCustomerModal(false)} onSubmit={handleSubmitCustomer}/> :
        showAttachmentModal ?
          <AttachmentModal onClose={() => setShowAttachmentModal(undefined)}/> :
        showPrivateAttachmentModal ?
          <AttachmentModal private onClose={() => setShowPrivateAttachmentModal(undefined)}/> :
        showVideoModal ?
          <VideoModal onClose={() => setShowVideoModal(undefined)}/> :
        showPrivateVideoModal ?
          <VideoModal private onClose={() => setShowPrivateVideoModal(undefined)}/> :
        showAddContent ?
          <AddContentModal
            onPrivateVideoSelected={video => setPrivateVideos(videos => [...videos, video])}
            onPublicVideoSelected={video => setAddedPublicVideos(videos => [...videos, video])}
            onAttachmentSelected={attachment => setAddedAttachments(attachments => [...attachments, attachment])}
            onPrivateAttachmentSelected={attachment => setPrivateAttachments(attachments => [...attachments, attachment])}
            onClose={() => setShowAddContent(false)}
            onAddAttachment={() => setShowAttachmentModal(true)}
            onAddPrivateAttachment={() => setShowPrivateAttachmentModal(true)}
            onAddVideo={() => setShowVideoModal(true)}
            onAddPrivateVideo={() => setShowPrivateVideoModal(true)}
          /> :
          null
        }
        confirmNavigation={isModified}

    >
      <Container>
        <CardsContainer>
        {
            offerNotebooks && offerNotebooks.length ?
              <NotebookCardContainer>
                <WithLink link={!!proposal?.draft} to={PROPOSAL_OFFER_NOTEBOOK.replace(':id', `${id}`).replace(':nids', offerNotebooks.map(o => o.id).join(','))}>
                  <NotebookTypeTitle>
                    <IconContainer><DocumentIcon color={colors.secondaryText}/></IconContainer>&nbsp;
                    <Typography variant="mediumTitle" color="text">{t('pages.offer-review.offerNotebook')}</Typography>
                  </NotebookTypeTitle>
                  <ThumbnailContainer>
                    <Thumbnail src={`${configProvider('PDF_SERVICE_URL')}/pages/${offerNotebooks[0].thumbnailPageId}/thumbnail`}/>
                    {
                      !proposal?.draft ? null :
                      <EditLabel>
                        <EditIcon />&nbsp;&nbsp;
                        <Typography variant="textItalic" color="whiteText">{t('pages.proposal.editOfferLabel')}</Typography>
                      </EditLabel>
                    }
                    {
                      isGenerated(offerNotebookId) ?
                        <ViewToolButton onClick={e => {e.stopPropagation(); e.preventDefault(); handleViewPdf(offerNotebookId)}}><ViewIcon /></ViewToolButton> :
                        null
                    }
                  </ThumbnailContainer>
                  <Typography variant="uppercaseTextBold" color="secondaryText">{t('pages.proposal.offerNotebookName')}</Typography>
                  <UpdatedAtContainer>
                    {
                      !proposal?.draft ? null :
                      (!isGenerated(offerNotebookId) && progress[`generation-${id}-${offerNotebookId}`]?.progress ) ?
                        <StyledProgressBar total={progress[`generation-${id}-${offerNotebookId}`]?.total} progress={progress[`generation-${id}-${offerNotebookId}`]?.progress}/> :
                      (proposalContent?.notebooksUpdatedAt || {})[offerNotebookId] ?
                        <Typography variant="smallTextItalicUnderlined" color="secondaryText">{t('pages.proposal.updatedAt', {date : new Date((proposalContent?.notebooksUpdatedAt || {})[offerNotebookId]).toLocaleDateString()})}</Typography> :
                        <Typography variant="smallTextItalicUnderlined" color="secondaryText">{t('pages.proposal.notUpdated')}</Typography>
                    }
                  </UpdatedAtContainer>
                </WithLink>
              </NotebookCardContainer> :
              null
          }
          {
            annexesNotebooks.length ?
              annexesNotebooks.filter(notebook => proposal?.draft || selectedNotebooks[notebook.id]).map((notebook, index) => (
                <NotebookCardContainer key={notebook.id}>
                  <WithLink link={!!proposal?.draft}  to={PROPOSAL_NOTEBOOK.replace(':id', `${id}`).replace(':nid', `${notebook.id}`)}>
                    <NotebookTypeTitle>
                      {
                        index === 0 ?
                        <>
                          <IconContainer><DocumentIcon color={colors.secondaryText}/></IconContainer>&nbsp;
                          <Typography variant="mediumTitle" color="text">{t('pages.offer-review.annexesNotebooks', {count : annexesNotebooks.length})}</Typography>
                        </> : null
                      }
                    </NotebookTypeTitle>
                    <ThumbnailContainer>
                      <Thumbnail src={`${configProvider('PDF_SERVICE_URL')}/pages/${notebook.thumbnailPageId}/thumbnail`}/>
                      {
                        !proposal?.draft ? null :
                        <EditLabel>
                          <EditIcon />&nbsp;&nbsp;
                          <Typography variant="textItalic" color="whiteText">{t('pages.proposal.editOfferLabel')}</Typography>
                        </EditLabel>
                      }
                      {
                        isGenerated(notebook.id) ?
                          <ViewToolButton onClick={e => {e.stopPropagation();  e.preventDefault(); handleViewPdf(notebook.id)}}><ViewIcon /></ViewToolButton> :
                          null
                      }
                    </ThumbnailContainer>
                  </WithLink>
                  <LabelContainer onClick={() => handleSelectNotebook(notebook.id, !selectedNotebooks[notebook.id])}>
                    <Typography variant="uppercaseTextBold" color="secondaryText">{notebook.name}</Typography>
                    {
                      !proposal?.draft ? null :
                        <Checkbox onChange={(value) => handleSelectNotebook(notebook.id, value)} value={selectedNotebooks[notebook.id]}/>
                    }
                  </LabelContainer>
                  <UpdatedAtContainer>
                    {
                      !proposal?.draft ? null :
                      (!isGenerated(notebook.id) && progress[`generation-${id}-${notebook.id}`]?.progress ) ?
                        <StyledProgressBar total={progress[`generation-${id}-${notebook.id}`]?.total} progress={progress[`generation-${id}-${notebook.id}`]?.progress}/> :
                      (proposalContent?.notebooksUpdatedAt || {})[notebook.id] ?
                        <Typography variant="smallTextItalicUnderlined" color="secondaryText">{t('pages.proposal.updatedAt', {date : new Date((proposalContent?.notebooksUpdatedAt || {})[notebook.id]).toLocaleDateString()})}</Typography> :
                        <Typography variant="smallTextItalicUnderlined" color="secondaryText">{t('pages.proposal.notUpdated')}</Typography>
                    }
                  </UpdatedAtContainer>
                </NotebookCardContainer>
              )) :
              null
          }
          {
            genericNotebooks.length ?
              genericNotebooks.filter(attachment => proposal?.draft || selectedAttachments[attachment.id]).map((attachment, index) => (
                <NotebookCardContainer key={attachment.id}>
                  <NotebookTypeTitle>
                    {
                      index === 0 ?
                      <>
                        <IconContainer><DocumentIcon color={colors.secondaryText}/></IconContainer>&nbsp;
                        <Typography variant="mediumTitle" color="text">{t('pages.offer-review.genericNotebooks', {count : genericNotebooks.length})}</Typography>
                      </> : null
                    }
                  </NotebookTypeTitle>
                  <ThumbnailContainer onClick={() => handleSelectAttachment(attachment.id, !selectedAttachments[attachment.id])}>
                    {
                      attachment.thumbnailStorageName ?
                        <Thumbnail src={`${configProvider('MODEL_API_URL')}/attached-notebooks/${attachment.id}/thumbnail.png`}/> :
                        <AttachmentCard><ClosedFoodDome /></AttachmentCard>
                    }
                    <ViewToolButton onClick={e => {e.stopPropagation(); handleViewAttachment(attachment)}}><ViewIcon /></ViewToolButton>
                  </ThumbnailContainer>
                  <LabelContainer>
                    <Typography variant="uppercaseTextBold" color="secondaryText">{attachment.name}</Typography>
                    {
                      !proposal?.draft ? null :
                        <Checkbox onChange={(value) => handleSelectAttachment(attachment.id, value)} value={selectedAttachments[attachment.id]}/>
                    }
                  </LabelContainer>
                </NotebookCardContainer>
              )) :
              null
          }
          {
            offerVideos.length ?
            offerVideos.filter(video => proposal?.draft || selectedVideos[video.id]).map((video, index) => (
              <NotebookCardContainer key={video.id}>
                <NotebookTypeTitle>
                  {
                    index === 0 ?
                    <>
                      <IconContainer><DocumentIcon color={colors.secondaryText}/></IconContainer>&nbsp;
                      <Typography variant="mediumTitle" color="text">{t('pages.offer-review.videos', {count : genericNotebooks.length})}</Typography>
                    </> : null
                  }
                </NotebookTypeTitle>
                <ThumbnailContainer onClick={() => handleSelectVideo(video.id, !selectedVideos[video.id])}>
                  {
                    video.thumbnailStorageName ?
                      <Thumbnail src={`${configProvider('MODEL_API_URL')}/public-videos/${video.id}/thumbnail.png`}/> :
                      <AttachmentCard><ClosedFoodDome /></AttachmentCard>
                  }
                  <ViewToolButton onClick={e => {e.stopPropagation(); handleViewVideo(video)}}><VideoIcon /></ViewToolButton>
                </ThumbnailContainer>
                <LabelContainer>
                  <Typography variant="uppercaseTextBold" color="secondaryText">{video.name}</Typography>
                  {
                    !proposal?.draft ? null :
                      <Checkbox onChange={(value) => handleSelectVideo(video.id, value)} value={selectedVideos[video.id]}/>
                  }
                </LabelContainer>
              </NotebookCardContainer>
            )) :
            null
        }


{
          addedAttachments.length ?
          addedAttachments.map((attachment, index) => (
            <NotebookCardContainer key={attachment.id}>
              <NotebookTypeTitle>
                {
                  index === 0 ?
                  <>
                    <IconContainer><DocumentIcon color={colors.secondaryText}/></IconContainer>&nbsp;
                    <Typography variant="mediumTitle" color="text">{t('pages.proposal.customContent', {count : genericNotebooks.length})}</Typography>
                  </> : null
                }
              </NotebookTypeTitle>
              <ThumbnailContainer>
                {
                  attachment.thumbnailStorageName ?
                    <Thumbnail src={`${configProvider('MODEL_API_URL')}/attached-notebooks/${attachment.id}/thumbnail.png`}/> :
                    <AttachmentCard><ClosedFoodDome /></AttachmentCard>
                }
                {
                  !proposal?.draft ? null :
                  <DeleteContentToolButton color="dark" onClick={e => {e.stopPropagation(); handleRemoveAddedAttachment(attachment)}}><TrashIcon color={colors.whiteText}/></DeleteContentToolButton>
                }
                <ViewToolButton onClick={e => {e.stopPropagation(); handleViewAttachment(attachment)}}><ViewIcon /></ViewToolButton>
              </ThumbnailContainer>
              <LabelContainer>
                <Typography variant="uppercaseTextBold" color="secondaryText">{attachment.name}</Typography>
              </LabelContainer>
            </NotebookCardContainer>
          )) :
          null
        }

        {
          privateAttachments.length ?
          privateAttachments.map((attachment, index) => (
            <NotebookCardContainer key={attachment.id}>
              <NotebookTypeTitle>
                {
                  index === 0 && addedAttachments.length === 0 ?
                  <>
                    <IconContainer><DocumentIcon color={colors.secondaryText}/></IconContainer>&nbsp;
                    <Typography variant="mediumTitle" color="text">{t('pages.proposal.customContent', {count : genericNotebooks.length})}</Typography>
                  </> : null
                }
              </NotebookTypeTitle>
              <ThumbnailContainer>
                {
                  attachment.thumbnailStorageName ?
                    <Thumbnail src={`${configProvider('MODEL_API_URL')}/private-attached-notebooks/${attachment.id}/thumbnail.png`}/> :
                    <AttachmentCard><ClosedFoodDome /></AttachmentCard>
                }
                {
                  !proposal?.draft ? null :
                  <DeleteContentToolButton color="dark" onClick={e => {e.stopPropagation(); handleRemovePrivateAttachment(attachment)}}><TrashIcon color={colors.whiteText}/></DeleteContentToolButton>
                }
                <ViewToolButton onClick={e => {e.stopPropagation(); handleViewPrivateAttachment(attachment)}}><ViewIcon /></ViewToolButton>
              </ThumbnailContainer>
              <LabelContainer>
                <Typography variant="uppercaseTextBold" color="secondaryText">{attachment.name}</Typography>
              </LabelContainer>
            </NotebookCardContainer>
          )) :
          null
        }








        {
          addedPublicVideos.length ?
          addedPublicVideos.map((video, index) => (
            <NotebookCardContainer key={video.id}>
              <NotebookTypeTitle>
                {
                  index === 0 && addedAttachments.length === 0 && privateAttachments.length === 0 ?
                  <>
                    <IconContainer><DocumentIcon color={colors.secondaryText}/></IconContainer>&nbsp;
                    <Typography variant="mediumTitle" color="text">{t('pages.proposal.customContent', {count : genericNotebooks.length})}</Typography>
                  </> : null
                }
              </NotebookTypeTitle>
              <ThumbnailContainer>
                {
                  video.thumbnailStorageName ?
                    <Thumbnail src={`${configProvider('MODEL_API_URL')}/public-videos/${video.id}/thumbnail.png`}/> :
                    <AttachmentCard><ClosedFoodDome /></AttachmentCard>
                }
                {
                  !proposal?.draft ? null :
                  <DeleteContentToolButton color="dark" onClick={e => {e.stopPropagation(); handleRemoveAddedPublicVideo(video)}}><TrashIcon color={colors.whiteText}/></DeleteContentToolButton>
                }
                <ViewToolButton onClick={e => {e.stopPropagation(); handleViewVideo(video)}}><VideoIcon /></ViewToolButton>
              </ThumbnailContainer>
              <LabelContainer>
                <Typography variant="uppercaseTextBold" color="secondaryText">{video.name}</Typography>
              </LabelContainer>
            </NotebookCardContainer>
          )) :
          null
        }

        {
          privateVideos.length ?
          privateVideos.map((video, index) => (
            <NotebookCardContainer key={video.id}>
              <NotebookTypeTitle>
                {
                  index === 0 && addedAttachments.length === 0 && privateAttachments.length === 0 && addedPublicVideos.length === 0 ?
                  <>
                    <IconContainer><DocumentIcon color={colors.secondaryText}/></IconContainer>&nbsp;
                    <Typography variant="mediumTitle" color="text">{t('pages.proposal.customContent', {count : genericNotebooks.length})}</Typography>
                  </> : null
                }
              </NotebookTypeTitle>
              <ThumbnailContainer>
                {
                  video.thumbnailStorageName ?
                    <Thumbnail src={`${configProvider('MODEL_API_URL')}/private-videos/${video.id}/thumbnail.png`}/> :
                    <AttachmentCard><ClosedFoodDome /></AttachmentCard>
                }
                {
                  !proposal?.draft ? null :
                  <DeleteContentToolButton color="dark" onClick={e => {e.stopPropagation(); handleRemovePrivateVideo(video)}}><TrashIcon color={colors.whiteText}/></DeleteContentToolButton>
                }
                <ViewToolButton onClick={e => {e.stopPropagation(); handleViewPrivateVideo(video)}}><VideoIcon /></ViewToolButton>
              </ThumbnailContainer>
              <LabelContainer>
                <Typography variant="uppercaseTextBold" color="secondaryText">{video.name}</Typography>
              </LabelContainer>
            </NotebookCardContainer>
          )) :
          null
        }

        <NotebookCardContainer>
          <NotebookTypeTitle>
          {
            privateVideos.length === 0 &&
            addedPublicVideos.length === 0 &&
            privateAttachments.length === 0 &&
            addedAttachments.length === 0 &&
            proposal?.draft ?
            <>
              <IconContainer><DocumentIcon color={colors.secondaryText}/></IconContainer>&nbsp;
              <Typography variant="mediumTitle" color="text">{t('pages.proposal.customContent', {count : genericNotebooks.length})}</Typography>
            </> : null
          }
          </NotebookTypeTitle>
          {
            !proposal?.draft ? null :
            <AddContentCard onClick={() => setShowAddContent(true)}>
              <PlusIcon width="2rem" color={colors.primary}/>
            </AddContentCard>
          }
        </NotebookCardContainer>
      </CardsContainer>
      </Container>
      <Footer>
        <FooterBlock>
          <CalendarIcon width='1.6rem' height='1.6rem'/>
          <Typography variant='smallTextRegular' color="text">{t('pages.proposal.validFrom')}</Typography>
          <DatePicker
            inverted
            disabled={!proposal?.draft}
            value={validFrom}
            onChange={handleValidFrom}
          />
          <Typography variant='smallTextRegular' color="text">{t('pages.proposal.validUntil')}</Typography>
          <DatePicker
            inverted
            disabled={!proposal?.draft}
            value={validUntil}
            onChange={setValidUntil}
            title={t('pages.proposal.validUntilTitle')}
            subtitle={t('pages.proposal.validUntilSubtitle')}
            isActive={
              (date) => !(validFrom && dateString(date) < dateString(validFrom))
            }/>
        </FooterBlock>
        <FooterBlock>
          <RegionIcon width='1.6rem' height='1.6rem' color={colors.primary}/>
          <Typography variant='smallTextRegular' color="text">{t('pages.proposal.regions')}</Typography>
          <MultiSelect
            inverted
            disabled={!proposal?.draft}
            choices={(regions || []).map(region => ({id : region.id, label :region.name}))}
            onChange={setSelectedRegions}
            value={selectedRegions}
            placeholder={regionsLabel}
          />
        </FooterBlock>
        <FooterBlock>
          <CustomersIcon width='1.6rem' height='1.6rem' color={colors.primary}/>
          <Typography variant='smallTextRegular' color="text">{t('pages.proposal.customer')}</Typography>
          <CustomerButton onClick={() => setShowCustomerModal(!!proposal?.draft && true)} className={proposal?.draft ? 'draft' : ''}>
            {
              customer ?
                <Typography variant="textRegular" color='text'>{customer.name}</Typography> :
                <Typography variant="textRegular" color="secondaryText">{t('pages.proposal.addCustomer')}</Typography>
            }
            <ChevronContainer>
              <ArrowDownIcon color={colors.tertiaryText} />
            </ChevronContainer>
          </CustomerButton>
          <LogoButton onClick={handleUploadLogo} className={customer && proposal?.draft ? 'draft' : 'disabled'}>
            {
              customer?.logoId ?
                <CustomerLogo src={`${configProvider('MODEL_API_URL')}/logos/${customer.logoId}/binary`} /> :
                <Typography variant="textRegular" color="secondaryText">{t('pages.proposal.addLogo')}</Typography>
            }
          </LogoButton>
          <FileInput
            ref={logoInputRef}
            type="file"
            accept="image/png, image/jpeg"
            onChange={e => e.target.files?.[0] && handleSelectLogo(e.target.files?.[0])}
            />
        </FooterBlock>
      </Footer>
    </PageLayout>
  )
}

export default ProposalPage;

const Container = styled.div`
  flex: 1;
  margin-bottom: ${3*spacing}rem;
  border-radius: 1.5rem;
  box-shadow: ${shadows.default};
  padding : ${2*spacing}rem;
  background-color: ${colors.background2};
  overflow: auto;
;`

const CardsContainer = styled.div`
  display: flex;
  flex-wrap : wrap;
`

const NotebookCardContainer = styled.div`
  display: inline-block;
  margin-right : ${0.9*spacing}rem;
  margin-bottom : ${2*spacing}rem;
`

const Thumbnail = styled.img`
  width : 23.4rem;
  height : 13.18rem;
  border-radius: 0.5rem;
  margin-bottom: 0.5rem;
`

const IconContainer = styled.div`
  position: relative;
  display: inline-block;
  height: 2rem;
  top: 0.4rem;
`

const NotebookTypeTitle = styled.div`
  height : 3.5rem;
`

const ThumbnailContainer = styled.div`
  position : relative;
`

const ViewToolButton = styled(ToolButton)`
  position : absolute;
  bottom : 1.5rem;
  right : 1rem;
  padding : 0.3rem;
`

const DeleteContentToolButton = styled(ViewToolButton)`
  bottom : 6rem;
  padding : 0.9rem;
`

const LabelContainer = styled.div`
  display : flex;
  justify-content: space-between;
  cursor : pointer;
`
const UpdatedAtContainer = styled.div``

const EditLabel = styled.div`
  position : absolute;
  top : 1rem;
  left : 1rem;
  padding : 0.5rem;
  background-color: ${colors.primary};
  border-radius: 0.5rem;
  color : ${colors.whiteText};
  display: flex;
  align-items: center;

  & svg {
    width : 2rem !important;
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
`

const Footer = styled.div`
  display : flex;
  padding-bottom : ${3*spacing}rem;
  align-items: center;
  justify-content: space-between;
`

const FooterBlock = styled.div`
  display : flex;
  align-items: center;
  justify-content: space-between;

  & > * {
    margin-right : ${0.5*spacing}rem;
  }
`

const StyledProgressBar = styled(ProgressBar)`
  min-width: auto;
  width : 100%;
  margin-top : 0.5rem;
`

const WithLink : React.FC<{link : boolean, to : string, children : ReactNode}> = (p) => {
  if(p.link) {
    return (
      <StyledLink to={p.to}>
        {p.children}
      </StyledLink>
    )
  }
  return <>{p.children}</>
}

const CustomerButton = styled.div`
  display: inline-flex;
  background: ${colors.background2};
  min-height: 2rem;
  border-radius : 1.8rem;
  box-shadow: ${shadows.default};
  align-items: center;
  padding : 0.5rem ${spacing}rem;
  cursor : pointer;

  &.draft:active {
    box-shadow: none;
  }
`

const ChevronContainer = styled.div`
  height : 1rem;
  width : 1rem;
  margin-left : ${spacing}rem;
  transform : rotate(180deg);
`

const LogoButton = styled.div`
  margin-left : ${0.5*spacing}rem;
  display: inline-flex;
  background: ${colors.background2};
  min-height: 4rem;
  border-radius : 1.8rem;
  box-shadow: ${shadows.default};
  align-items: center;
  padding : 0.2rem ${spacing}rem;
  cursor : pointer;

  &.draft:active, &.disabled {
    box-shadow: none;
    cursor : auto;
  }
`

const FileInput = styled.input`
  position : absolute;
  visibility: hidden;
`

const CustomerLogo = styled.img`
  max-width: 5rem;
  max-height: 5rem;
`

const RegionPlaceholder = styled.div`
  color : ${colors.primary};
  max-width: 5rem;
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

const RegionPlaceholderContainer = styled.div`
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
`

const AttachmentCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width : 23.4rem;
  height : 13.18rem;
  border-radius: 1rem;
  background-color : ${colors.background2};
  border : 0.3rem solid ${colors.background2} !important;
  overflow : hidden;
`

const AddContentCard = styled(AttachmentCard)`
  background-color : ${colors.background};
  cursor : pointer;
`

const TitleContainer = styled.div`
  display: flex;
`

const ProposalNameInput = styled(NotebookNameInput)`
  width : 42rem;
`
