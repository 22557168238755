import { useTranslation } from "react-i18next";
import PageLayout from "../components/layout/page-layout"
import { useAssertAdmin } from "../hooks/use-assert-admin";
import { useNavigate, useParams } from "react-router-dom";
import { useModelActions } from "../store/hooks/use-actions";
import { useModelStore } from "../store/store";
import { useEffect, useState } from "react";
import styled from "styled-components";
import Typography from "../components/atoms/typography";
import { colors, shadows, spacing } from "../styles";
import { ID, PageSlide } from "../store/store-types";
import TagsReviewSlideCard from "../components/molecules/tags-review-slide-card";
import TagsIcon from "../assets/icons/tags";
import Checkbox from "../components/atoms/checkbox";
import TextButton from "../components/atoms/text-button";
import ToolButton from "../components/atoms/tool-button";
import CopyIcon from "../assets/icons/copy";
import PasteIcon from "../assets/icons/paste";
import useEventDelegation from "../hooks/use-event-delegation";
import useDialog from "../hooks/use-dialog";
import HTMLTypography from "../components/atoms/html-typography";
import { LIBRARY } from "../constants/routes";
import useLogged from "../hooks/use-logged";
import useAutosave from "../hooks/use-autosave";

interface TagsReviewPageProps  {

}

const TagsReviewPage : React.FC<TagsReviewPageProps> = (p) => {
  useAssertAdmin();
  const logged = useLogged();
  const {t} = useTranslation();
  const navigate = useNavigate();
  let { id } = useParams();
  const {loadTemplate, loadTemplateContent, publish} = useModelActions('notebooks');
  const {selectNotebook, copyTags, setTags : storeTags, isModified, save} = useModelActions('notebookEditor');

  const editedNotebookId = useModelStore(state => state.notebooks.editor.notebookId);
  const template = useModelStore(state => state.notebooks.templates[parseInt(`${id}`)] || {});
  const markets   = useModelStore(state => state.markets.filter(market => template.marketsIds?.includes(market.id) && !market.inactive));
  const filters = useModelStore(state => state.filters.filter(filter => !filter.inactive && template.marketsIds?.some(mid => filter.marketsIds?.includes(mid))));
  const content  = useModelStore(state => state.actions.notebookEditor.current() || state.notebooks.templatesContent[parseInt(`${id}`)] || {});
  const clipboard = useModelStore(state => state.notebooks.editor.tagsClipboard);

  const [selection, setSelection] = useState<number[]>([]);

  const handleSelect = (pageId : number) => {
    setSelection([pageId])
  }

  const handleCtrlSelect = (pageId : number) => {
    let newSelection = selection.includes(pageId) ? selection.filter(pid => pid !== pageId) : [...selection, pageId];

    if(newSelection.length === 0) {
      newSelection = [pageId];
    }

    setSelection(newSelection);
  }

  const handleShiftSelect = (pageId : number) => {
    const firstIndex = (content.slides || []).findIndex(slide => slide.type === 'page' && selection.includes(slide.pageId));
    const lastIndex  = (content.slides || []).findLastIndex(slide => slide.type === 'page' && selection.includes(slide.pageId));
    const targetIndex = (content.slides || []).findLastIndex(slide => slide.type === 'page' && slide.pageId === pageId);

    if(targetIndex <= firstIndex) {
      setSelection((content.slides || []).slice(targetIndex, lastIndex + 1).filter((slide) : slide is PageSlide => slide.type === 'page').map(s => s.pageId));
    }
    else {
      setSelection((content.slides || []).slice(firstIndex, targetIndex + 1).filter((slide) : slide is PageSlide => slide.type === 'page').map(s => s.pageId));
    }
  }


  const showConfirmationDialog = useDialog({
    validateText : t('pages.tags-review.publishButton'),
    content : <HTMLTypography variant="textRegular" color="text" html={t('pages.tags-review.confirmationMessage')}/>,
  });


  const selectedPages = (content.slides || []).filter((slide) : slide is PageSlide => slide.type === 'page' &&  selection.includes(slide.pageId));
  const tagsCounter : Record<number, number> = selectedPages.reduce((cumul, next) => {
    (next.tags || []).forEach(tagId => {
      cumul[tagId] = (cumul[tagId] || 0) + 1;
    })
    return cumul;
  }, {} as Record<number, number>);
  const tags = Object.entries(tagsCounter).filter(([tagId, tagsCounter]) => tagsCounter === selection.length).map(([tagId]) => parseInt(tagId));
  const mixedTags = Object.entries(tagsCounter).filter(([tagId, tagsCounter]) => tagsCounter < selection.length).map(([tagId]) => parseInt(tagId));

  const setFilterValue = (id : ID, value : boolean) => {
    selection.forEach(pageId => {
      const currentIndex = ((content.slides || []).findIndex(slide => slide.type === 'page' && slide.pageId === pageId));
      const currentSlide = ((content.slides || []).find(slide => slide.type === 'page' && slide.pageId === pageId));

      const values = new Set<ID>(currentSlide?.tags || []);

      if(value) {
        values.add(id);
      }
      else {
        values.delete(id);
      }

      storeTags({position : currentIndex}, [...values.values()])
    })
  }


  /*
    Load template metadata and content when page loads
   */
  useEffect(() => {
    (async () => {
      if(id && logged) {
        await loadTemplate(parseInt(id));
        const content = await loadTemplateContent(parseInt(id));

        if(parseInt(id) !== editedNotebookId) {
          // Reset editor state if the id in state is different from the url id
          selectNotebook(parseInt(id));
        }

        // Select first page
        const firstPageId = ((content.slides || []).find(slide => slide.type === 'page') as PageSlide)?.pageId;
        if(firstPageId) {
          handleSelect(firstPageId);
        }
      }
    })();
  }, [id, logged]);

  const handleCopy = () => {
    copyTags(tags);
  }

  const handlePaste = () => {
    if(clipboard) {
      selection.forEach(pageId => {
        const currentIndex = ((content.slides || []).findIndex(slide => slide.type === 'page' && slide.pageId === pageId));
        storeTags({position : currentIndex}, clipboard)
      })
    }
  }


  const handleNext = () => {
    const currentIndex = ((content.slides || []).findLastIndex(slide => slide.type === 'page' && selection.includes(slide.pageId)));
    const remaining = (content.slides || []).slice(currentIndex + 1);
    const firstRemainingPage = remaining.find(slide => slide.type === 'page' && !slide.isSummary) as PageSlide | undefined;
    if(firstRemainingPage) {
      handleSelect(firstRemainingPage.pageId)

      document.querySelector(`#tags-review-slide-card-${firstRemainingPage.pageId}`)?.scrollIntoView({ behavior: "smooth", block: "nearest", inline: "nearest" });
    }
  }

  const hasNext = () => {
    const currentIndex = ((content.slides || []).findLastIndex(slide => slide.type === 'page' && selection.includes(slide.pageId)));
    const remaining = (content.slides || []).slice(currentIndex + 1);
    const firstRemainingPage = remaining.find(slide => slide.type === 'page' && !slide.isSummary) as PageSlide | undefined;
    return !!firstRemainingPage;
  }


  const handleSave = () => {
    if(id) {
      save();
    }
  }

  const handleContinue = () => {
    /*
      TBD : validate content
    */

    if(id) {
      if(template.draft) {
        showConfirmationDialog(async () => {
          await publish(parseInt(id as string));
          navigate(LIBRARY);
        });
      }
      else {
        navigate(LIBRARY);
      }
    }
  }

  useAutosave(isModified(), handleSave);

  return (
    <PageLayout
      breadcrumbs={[t('pages.tags-review.breadcrumb1'), t('pages.tags-review.breadcrumb2'), t('pages.tags-review.breadcrumb3')]}
      title={
        <TitleContainer>
          <TitleTypography variant="bigTitle" color="text">{template.name || ''}</TitleTypography>
          <Typography variant="textItalic" color="secondaryText">{markets?.map(m => m.name).join(', ') || ''}</Typography>
        </TitleContainer>
      }
      subtitle={t('pages.tags-review.subtitle')}
      controls={
        <>
          <TextButton disabled={!isModified()} color="light" onClick={handleSave}>{template.draft ? t('pages.notebook.saveDraft') : t('pages.notebook.save')}</TextButton>
          <TextButton disabled={isModified()} color="dark" onClick={handleContinue}>{template.draft ? t('pages.notebook.next') : t('pages.notebook.updateDone')}</TextButton>
        </>
      }
      showBackArrow
      confirmNavigation={isModified()}
    >
      {
        (content.slides || []).map((slide, index) => (
          slide.type === 'page' && !slide.isSummary?
          <TagsReviewSlideCard
            key={slide.pageId}
            slide={slide}
            index={index}
            selected={selection.includes(slide.pageId)}
            onSelect={() => handleSelect(slide.pageId)}
            onCtrlSelect={() => handleCtrlSelect(slide.pageId)}
            onShiftSelect={() => handleShiftSelect(slide.pageId)}
          /> : null
        ))
      }
      <TagsFrame>
        <Header>
          <IconContainer><TagsIcon /></IconContainer>
            <FrameTitleContainer>
              <Title>
                <Typography variant="mediumTitle" color="text">
                  {
                    !selection.length ?
                      t('pages.tags-review.frameTitle') :
                    selection.length === 1 ?
                      content.slides?.find(slide => slide.type === "page" && slide.pageId === selection[0])?.label || t('pages.tags-review.frameTitle') :
                      t('pages.tags-review.frameTitleMultiple', {number : selection.length})
                  }
                </Typography>
              </Title>
              <SubTitle>
                <Typography variant="smallTextItalic" color="secondaryText">{t('pages.tags-review.frameSubtitle')}</Typography>
              </SubTitle>
            </FrameTitleContainer>
          </Header>
          <Container>
          {
            filters.map(filter => (
              <FilterContainer key={filter.id}>
                <Typography variant="textRegular" color="text">{filter.name}</Typography>
                <CheckboxContainer>
                {
                  filter.values.map(choice => (
                    <Checkbox
                      key={choice.id}
                      variant="smallTextItalic"
                      label={choice.value}
                      onChange={(checked) => setFilterValue(choice.id, checked)}
                      value={!!tags.includes(choice.id)}
                      mixed={mixedTags.includes(choice.id)}
                    />
                  ))
                }
                </CheckboxContainer>
              </FilterContainer>
            ))
          }
        </Container>
        <Footer>
          <ToolButton color="medium" type="button" onClick={handleCopy}><CopyIcon /></ToolButton>
          <ToolButton color="medium" type="button" onClick={handlePaste} disabled={!(clipboard && clipboard.length)}><PasteIcon /></ToolButton>,
          <TextButton disabled={!hasNext()} onClick={handleNext}>{t('pages.tags-review.next')}</TextButton>
        </Footer>
      </TagsFrame>
    </PageLayout>

  )
}

export default TagsReviewPage;

const TitleContainer = styled.div`
  display: flex;
  align-items: baseline;
`

const TitleTypography = styled(Typography)`
  margin-right : ${spacing}rem;
`

const TagsFrame = styled.div`
  position : fixed;
  top: 17.5rem;
  left: 53rem;
  width: 78rem;
  min-height: 10rem;
  max-height: calc(100vh - 20rem);
  display: flex;
  flex-direction: column;
  background-color : ${colors.background2};
  border-radius : ${spacing}rem;
  box-shadow: ${shadows.default};
  padding : ${2*spacing}rem;
`


const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position : relative;
  margin-bottom : ${2*spacing}rem;
`
const IconContainer = styled.div`
  height : 3rem;
  margin-right : ${spacing}rem;
`

const FrameTitleContainer = styled.div`
  flex : 1;
`

const Title = styled.div`

`

const SubTitle = styled.div`
  margin-top: 0.7rem;
`

const Container = styled.div`
  columns : 2;
  overflow: auto;
`

const FilterContainer = styled.div`
  margin-top : ${2*spacing}rem;
  break-inside: avoid;

  &:first-child {
    margin-top: 0;
  }
`

const CheckboxContainer = styled.div`
  margin-top : ${spacing}rem;

  & > div {
    margin-right : ${2*spacing}rem;
    margin-bottom : 1rem;
  }
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top : ${2*spacing}rem;

  & > button {
    margin-left : ${spacing}rem;
  }
`