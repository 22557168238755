import styled from 'styled-components';
import { useAssertLogged } from '../hooks/use-assert-logged';
import PageLayout from '../components/layout/page-layout';
import { useModelStore, useUserStore } from '../store/store';
import { colors, shadows, spacing } from '../styles';
import TextButton from '../components/atoms/text-button';
import { msal } from '../providers/msal';
import Logo from '../assets/images/logo';
import Typography from '../components/atoms/typography';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { DASHBOARD } from '../constants/routes';
import { useModelActions, useUserActions } from '../store/hooks/use-actions';

interface HomePageProps {

}

const HomePage : React.FC<HomePageProps> = (p) => {
  // useAssertLogged();
  const { t } = useTranslation();
  const logged = useUserStore(state => state.user.logged);
  const navigate = useNavigate();
  const { isConnected } = useUserActions('user');
  const {getLoginRedirect, clearLoginRedirect} = useUserActions('user');


  useEffect(() => {
    if(!logged && isConnected()) {
      msal.login();
    }
    else if(logged) {
      const redirect = getLoginRedirect();
      clearLoginRedirect();
      navigate(redirect || DASHBOARD);
    }
  }, [logged])

  const handleLogin = () => {
    msal.login();
  }

  if(!logged && !isConnected()) {
    return (
      <LoginContainer>
        <LoginBox>
          <Center>
            <Logo width="30rem" height="10rem"/>
          </Center>
          <p>
            <Typography variant='mediumTitle'>{t('pages.home.welcome')}</Typography>
          </p>
          <p>
            <Typography variant='textRegular'>{t('pages.home.welcomeText')}</Typography>
          </p>
          <Center>
            <TextButton onClick={handleLogin}>{t('pages.home.loginButton')}</TextButton>
          </Center>
        </LoginBox>
      </LoginContainer>
    )
  }

  return (
    <LoginContainer>
      <Typography variant='bigTitle' color="tertiaryText">{t('pages.home.loading')}</Typography>
    </LoginContainer>
  )
}

export default HomePage;

const LoadingContainer = styled.div`

`

const LoginContainer = styled.div`
  display: flex;
  position: fixed;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width : 100%;
  height : 100%;
  background-color: ${colors.background};
`

const LoginBox = styled.div`
  border-radius: 1.5rem;
  padding: ${2*spacing}rem;
  background-color : ${colors.background2};
  box-shadow: ${shadows.default};
  max-width: 50rem;
  & p {
    margin-bottom: ${2*spacing}rem;
    margin-top: ${2*spacing}rem;
  }
`

const Center = styled.div`
  text-align: center;

  & > button {
    display: inline-flex;
  }
`