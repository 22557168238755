import { colors } from "../../styles";
import IconProps from "./icon-interface";

const UndoIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.text;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.09505 7.50391C4.56381 8.00197 5.0521 8.50979 5.54039 9.01761C6.1068 9.60356 6.67322 10.1797 7.23964 10.7364C7.2787 10.7755 7.6205 11.1466 7.53261 11.6349C7.53261 11.6934 7.45449 11.9571 7.25917 12.1524C6.86854 12.5528 6.20446 12.6017 5.84313 12.1524C4.52474 10.5118 2.92315 8.87113 0.862565 7.23047C0.374274 6.83984 0.38404 6.166 0.862565 5.7656C2.77666 4.17377 4.48568 2.56241 5.7943 0.911993C6.15563 0.443234 6.83924 0.501829 7.22987 0.911993C7.61074 1.31239 7.57167 1.89834 7.18104 2.32803C6.39001 3.21672 5.47203 4.17377 4.17317 5.30661C4.11458 5.35544 4.05598 5.40426 3.99739 5.45309C3.99739 5.47263 4.00715 5.49216 4.01692 5.51169C4.10481 5.51169 4.19271 5.51169 4.2806 5.51169C7.52285 5.51169 10.7651 5.51169 14.0073 5.51169C17.1129 5.51169 19.7106 7.6211 20.3551 10.6583C21.1071 14.1837 18.7242 17.7092 15.1597 18.3049C14.5933 18.4026 14.0073 18.3928 13.4214 18.4123C12.7866 18.4319 12.1518 18.4221 11.5171 18.4123C11.0288 18.4123 10.6284 18.0412 10.5698 17.5822C10.5014 17.0744 10.7846 16.6252 11.2632 16.4885C11.3901 16.4494 11.5366 16.4494 11.6733 16.4494C12.6011 16.4396 13.5386 16.4689 14.4663 16.4103C16.7515 16.2541 18.5973 14.1642 18.5094 11.8692C18.4215 9.5938 16.8199 7.83595 14.564 7.53321C14.3687 7.50391 14.1734 7.50391 13.9878 7.50391C10.7456 7.50391 7.50331 7.50391 4.26107 7.50391C4.19271 7.50391 4.12434 7.50391 4.12434 7.50391H4.09505Z" fill={color}/>
    </svg>
  )
}

export default UndoIcon;
