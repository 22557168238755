import { colors } from "../../styles";
import IconProps from "./icon-interface";

const RedoIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.text;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 21 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M16.8877 7.50135C16.419 7.99937 15.9308 8.50714 15.4425 9.01492C14.8761 9.60082 14.3098 10.1769 13.7434 10.7336C13.7044 10.7726 13.3626 11.1437 13.4505 11.6319C13.4505 11.6905 13.5286 11.9542 13.7239 12.1495C14.1145 12.5498 14.7785 12.5987 15.1398 12.1495C16.4581 10.509 18.0595 8.86845 20.1199 7.22793C20.6082 6.83734 20.5984 6.16355 20.1199 5.76319C18.2158 4.1715 16.5069 2.56029 15.1984 0.910011C14.8371 0.441293 14.1535 0.499883 13.7629 0.910011C13.3821 1.31037 13.4212 1.89627 13.8118 2.32593C14.6027 3.21454 15.5206 4.1715 16.8194 5.30424C16.878 5.35306 16.9365 5.40189 16.9951 5.45071C16.9951 5.47024 16.9854 5.48977 16.9756 5.5093C16.8877 5.5093 16.7998 5.5093 16.712 5.5093C13.47 5.5093 10.228 5.5093 6.98606 5.5093C3.88081 5.5093 1.28333 7.61853 0.638846 10.6554C-0.113055 14.1806 2.26959 17.7057 5.8338 18.3014C6.40017 18.399 6.98606 18.3893 7.57196 18.4088C8.20668 18.4283 8.8414 18.4186 9.47613 18.4088C9.96437 18.4088 10.3647 18.0377 10.4233 17.5788C10.4917 17.071 10.2085 16.6218 9.73001 16.4851C9.60307 16.446 9.4566 16.446 9.31989 16.446C8.39222 16.4363 7.45478 16.4656 6.52711 16.407C4.24211 16.2507 2.39654 14.161 2.48442 11.8663C2.57231 9.59105 4.17376 7.83336 6.42946 7.53065C6.62476 7.50135 6.82006 7.50135 7.00559 7.50135C10.2476 7.50135 13.4895 7.50135 16.7315 7.50135C16.7998 7.50135 16.8682 7.50135 16.8682 7.50135H16.8877Z" fill={color}/>
    </svg>
  )
}

export default RedoIcon;
