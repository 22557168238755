import styled from 'styled-components';
import {colors, shadows, spacing} from '../../styles';
import UploadErrorImage from '../../assets/images/upload-error';
import { useRef } from 'react';
import { useModelActions } from '../../store/hooks/use-actions';
import ToolButton from './tool-button';
import ReplaceSlideIcon from '../../assets/icons/replace-slide';
import Typography from './typography';
import { useTranslation } from 'react-i18next';

interface UploadErrorCardProps {
  className ?: string;
  index : number;
}

const UploadErrorCard : React.FC<UploadErrorCardProps> = (p) => {
  const {t} = useTranslation();
  const {setLabel, setMandatory, removeSlide, replaceSlide, addUpload, save, select, addToSelection, addRangeToSelection, deselect, appendContentSlides, moveSlides, groupAlternatives, extractFromAlternatives, linkSlides} = useModelActions('notebookEditor');
  const {start} = useModelActions('uploads');

  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleSelectFileReplacement = async(file : File) => {
    const uploadId = await start(file);
    replaceSlide({position : p.index}, uploadId);
    save();
  }

  const handelOpenFileDialog = () => {
    fileInputRef.current?.click();
  }



  return (
    <CardContainer className={p.className}>
      <PageCard>
        <ImageContainer>
          <UploadErrorImage />
          <br />
          <Typography variant='smallTextItalic' color='primary'>{t('pages.notebook.erreurDeChargement')}</Typography>
        </ImageContainer>
        <FileInput
          ref={fileInputRef}
          id={'file_error_replace_'+p.index}
          type="file" accept="application/pdf"
          onChange={e => e.target.files?.[0] && handleSelectFileReplacement(e.target.files?.[0])}
          />
          <ReplaceSlideButton color="dark" onClick={handelOpenFileDialog}><ReplaceSlideIcon /></ReplaceSlideButton>
      </PageCard>
    </CardContainer>
  );
};

export default UploadErrorCard;

const CardContainer = styled.div`
  position :relative;
  margin : ${spacing}rem;
  padding-bottom : ${spacing}rem;
`

const PageCard = styled.div`
  display: inline-flex;
  flex-direction: column;
  border-radius: 1.5rem;
  padding : 0.3rem;
  border : 0.3rem solid ${colors.background2};
  background-color: ${colors.background2};
  z-index : 0;
  box-shadow: ${shadows.default};
  position: relative;
`

const ImageContainer = styled.div`
  display: flex;
  align-items : center;
  justify-content: center;
  flex-direction: column;
  width : 20rem;
  height : 11.3rem;
  background-color: ${colors.lightPrimary};
  border-radius: 1rem;
  padding : 1rem;
  text-align: center;
`

const FileInput = styled.input`
  position : absolute;
  visibility: hidden;
`
const ReplaceSlideButton = styled(ToolButton)`
  position : absolute;
  top : 0.8rem;
  right : 0.8rem;
`
