import styled from 'styled-components';
import { useAssertLogged } from '../hooks/use-assert-logged';
import PageLayout from '../components/layout/page-layout';
import { useAssertAdmin } from '../hooks/use-assert-admin';
import { useTranslation } from 'react-i18next';
import Search from '../components/atoms/search';
import TextButton from '../components/atoms/text-button';
import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { NEW_OFFER, OFFER_REVIEW } from '../constants/routes';
import PageTopSelector from '../components/atoms/pagetop-selector';
import { spacing } from '../styles';
import { useModelActions } from '../store/hooks/use-actions';
import { ID, Market, NotebookTemplate, Offer } from '../store/store-types';
import { useModelStore } from '../store/store';
import Typography from '../components/atoms/typography';
import configProvider from '../providers/configprovider';
import NotebookCard from '../components/atoms/notebook-card';
import NotebookNameInput from '../components/atoms/notebook-name-input';
import Select from '../components/atoms/select';
import useLogged from '../hooks/use-logged';
import useAutosave from '../hooks/use-autosave';

interface OfferPageProps {

}

const OfferPage : React.FC<OfferPageProps> = (p) => {
  useAssertAdmin();
  const logged = useLogged();

  const {t} = useTranslation();
  const navigate = useNavigate();
  const { id } = useParams();
  const {loadOne, update} = useModelActions('offers');
  const {loadTemplates} = useModelActions('notebooks');
  const {load : loadAttachments} = useModelActions('attachments');
  const {load : loadVideos} = useModelActions('videos');


  const [offer, setOffer] = useState<Offer | null>(null);
  const [name, setName] = useState<string>('');
  const [market, setMarket] = useState<Market | undefined>(undefined);
  const [selectedOffer, setSelectedOffer] = useState<ID[]>([]);
  const [selectedAnnexes, setSelectedAnnexes] = useState<Record<ID, boolean>>({});

  const [selectedGenerics, setSelectedGenerics] = useState<Record<ID, boolean>>({});
  const [selectedVideos, setSelectedVideos] = useState<Record<ID, boolean>>({});

  const _markets   = useModelStore(state => state.markets);
  const markets = _markets.filter(({inactive}) => !inactive);
  const attachments = useModelStore(state => state.attachments).filter(a => a.marketsIds?.includes(market?.id || -1));
  const videos = useModelStore(state => state.videos).filter(v => v.marketsIds?.includes(market?.id || -1));
  const notebookTemplates = useModelStore(state => state.notebooks.templates);
  const offerNotebooks = Object.values(notebookTemplates).filter(notebook => notebook.type === 'offers' && notebook.draft === false && notebook.marketsIds?.includes(market?.id || -1));
  const annexesNotebooks = Object.values(notebookTemplates).filter(notebook => notebook.type === 'annexes' && notebook.draft === false && notebook.marketsIds?.includes(market?.id || -1));

  const notebooksIds = [
    ...selectedOffer,
    ...(Object.entries(selectedAnnexes || {})).filter(([id, value]) => value).map(([id, _]) => parseInt(`${id}`)).sort()
  ].filter((id) : id is ID => !!id).filter(id => notebookTemplates[id].marketsIds?.includes(market?.id || -1));
  const notebooksIdsString = notebooksIds.join(',');

  const attachmentsIds = [
    ...(Object.entries(selectedGenerics || {})).filter(([id, value]) => value).map(([id, _]) => parseInt(`${id}`)),
  ].filter(id => attachments.find(a => a.id === id)?.marketsIds?.includes(market?.id || -1))
  const attachmentsIdsString = attachmentsIds.join(',')

  const videosIds = [
    ...(Object.entries(selectedVideos || {})).filter(([id, value]) => value).map(([id, _]) => parseInt(`${id}`)),
  ].filter(id => videos.find(a => a.id === id)?.marketsIds?.includes(market?.id || -1))
  const videosIdsString = videosIds.join(',')


  const modified =
    videosIds.length !== offer?.videosIds.length ||
    !videosIds.every(id => id && (offer?.videosIds || []).includes(id)) ||
    attachmentsIds.length !== offer?.attachmentsIds.length ||
    !attachmentsIds.every(id => id && (offer?.attachmentsIds || []).includes(id)) ||
    notebooksIds.length !== offer?.notebooksIds.length ||
    !notebooksIds.every((id, index) => id && (offer?.notebooksIds || [])[index] === id) ||
    name !== offer?.name || market?.id !== offer?.marketId;

  const marketsChoices = markets.map(market => ({label : market.name, value : market.id}));

  useEffect(() => {
    (async () => {
      if(id && logged && markets.length) {
        await loadTemplates();
        await loadAttachments();
        await loadVideos();

        const offer = await loadOne(parseInt(id));
        setOffer(offer);
        setName(offer.name);


        const market = markets.find(market => market.id === offer.marketId);
        setMarket(market);
      }
    })();
  }, [id, logged, _markets]);

  useEffect(() => {
    if(offer && logged) {
      offer.notebooksIds.forEach(nid => {
        if(offerNotebooks.find(n => n.id === nid)) {
          handleSelectOffer(nid, true);
        }
      })

      annexesNotebooks.forEach(notebook => {
        if(offer.notebooksIds.includes(notebook.id)) {
          handleSelectAnnexes(notebook.id, true);
        }
      })

      attachments.forEach(attachment => {
        if(offer.attachmentsIds.includes(attachment.id)) {
          handleSelectGenerics(attachment.id, true);
        }
      })

      videos.forEach(video => {
        if(offer.videosIds.includes(video.id)) {
          handleSelectVideos(video.id, true);
        }
      })
    }
  }, [offer, logged]) //, notebookTemplates, attachments, videos


  const handleSelectOffer = (id : ID, value : boolean) => {
    const selected = selectedOffer.includes(id);

    if(value && !selected) {
      setSelectedOffer(selected => [...selected, id])
    }
    else if (!value && selected) {
      setSelectedOffer(selected => selected.filter(_id => _id !== id))
    }
  }

  const handleSelectAnnexes = (id : ID, value : boolean) => {
    if(!!selectedAnnexes[id] === value) {return;}

    setSelectedAnnexes(selection => ({
      ...selection,
      [id] : value
    }))
  }

  const handleSelectGenerics = (id : ID, value : boolean) => {
    if(!!selectedGenerics[id] === value) {return;}

    setSelectedGenerics(selection => ({
      ...selection,
      [id] : value
    }))
  }

  const handleSelectVideos = (id : ID, value : boolean) => {
    if(!!selectedVideos[id] === value) {return;}
    
    setSelectedVideos(selection => ({
      ...selection,
      [id] : value
    }))
  }

  const handleSave = async () => {
    const newOffer = await update(parseInt(`${id}`), {
      notebooks : notebooksIdsString,
      attachments : attachmentsIdsString,
      videos : videosIdsString,
      thumbnailPageId : offerNotebooks.find(notebook => notebook.id === selectedOffer[0])?.thumbnailPageId,
      name,
      marketId : market?.id
    });
    setOffer(newOffer);
  }

  const handleContinue = () => {
    navigate(OFFER_REVIEW.replace(':id', `${id}`))
  }

  useAutosave(modified, handleSave);

  return (
    <PageLayout
      breadcrumbs={[t('pages.new-offer.breadcrumb1'), t('pages.new-offer.breadcrumb2')]}
      title={
        <TitleContainer>
          <NotebookNameInput value={name} onChange={setName} placeholder={t('pages.new-offer.namePlaceholder')}/>
          <Select<number>
            choices={marketsChoices}
            value={market?.id}
            onChange={(id) => setMarket(markets.find(market => market.id === id))}
            placeholder='Marché'
          />
        </TitleContainer>
      }
      subtitle={t('pages.offer.subtitle')}
      controls={
        <>
          <TextButton disabled={!modified} color="light" onClick={handleSave}>{t('pages.notebook.saveDraft')}</TextButton>
          <TextButton disabled={modified || !notebooksIds.length || !selectedOffer.length || !market || !name.trim()} color="dark" onClick={handleContinue}>{t('pages.notebook.next')}</TextButton>
        </>
      }
      confirmNavigation={modified}
    >
      <Container>
      {
          /* NOTEBOOK OFFRES */
        }
        <Typography variant="mediumTitle" color="text">{t('pages.library.notebook-offres')}</Typography>
        <NotebookLinksContainer>
          {
            [
              ...selectedOffer.map(nid => offerNotebooks.find(n => n.id === nid)).filter((n) : n is NotebookTemplate => !!n && !n.inactive),
              ...offerNotebooks.filter(n => !n.inactive && !selectedOffer.includes(n.id))
            ].map(notebook => (
              <NotebookCard
                key={notebook.id}
                imgUrl={notebook.thumbnailPageId ? `${configProvider('PDF_SERVICE_URL')}/pages/${notebook.thumbnailPageId}/thumbnail` : ''}
                title={notebook.name}
                value={selectedOffer.includes(notebook.id)}
                onChange={(value) => handleSelectOffer(notebook.id, value)}
              />
            ))
          }
        </NotebookLinksContainer>

        {
          /* NOTEBOOK ANNEXES */
        }
        <Typography variant="mediumTitle" color="text">{t('pages.library.notebook-annexes')}</Typography>
        <NotebookLinksContainer>
          {
            annexesNotebooks.filter(n => !n.inactive).map(notebook => (
              <NotebookCard
                key={notebook.id}
                imgUrl={notebook.thumbnailPageId ? `${configProvider('PDF_SERVICE_URL')}/pages/${notebook.thumbnailPageId}/thumbnail` : ''}
                title={notebook.name}
                value={selectedAnnexes[notebook.id]}
                onChange={(value) => handleSelectAnnexes(notebook.id, value)}
              />
            ))
          }
        </NotebookLinksContainer>

        {
          /* NOTEBOOK GENERIQUES */
        }
        <Typography variant="mediumTitle" color="text">{t('pages.library.notebook-generiques')}</Typography>
        <NotebookLinksContainer>
          {
            attachments.filter(n => !n.inactive).map(attachment => (
              <NotebookCard
                key={attachment.id}
                imgUrl={attachment.thumbnailStorageName ? `${configProvider('MODEL_API_URL')}/attached-notebooks/${attachment.id}/thumbnail.png` : ''}
                title={attachment.name}
                value={selectedGenerics[attachment.id]}
                onChange={(value) => handleSelectGenerics(attachment.id, value)}
              />
            ))
          }
        </NotebookLinksContainer>

        {
          /* NOTEBOOK VIDEOS */
        }
        <Typography variant="mediumTitle" color="text">{t('pages.library.videos')}</Typography>
        <NotebookLinksContainer>
          {
            videos.filter(n => !n.inactive).map(video => (
              <NotebookCard
                key={video.id}
                imgUrl={video.thumbnailStorageName ? `${configProvider('MODEL_API_URL')}/public-videos/${video.id}/thumbnail.png` : ''}
                title={video.name}
                value={selectedVideos[video.id]}
                onChange={(value) => handleSelectVideos(video.id, value)}
              />
            ))
          }
        </NotebookLinksContainer>
      </Container>
    </PageLayout>
  )
}

export default OfferPage;

const Container = styled.div`
  padding-top : ${3*spacing}rem;
;`

const TitleContainer = styled.div`
  display: flex;
`
const TitleTypography = styled(Typography)`
  margin-right : ${spacing}rem;
`

const NotebookLinksContainer = styled.div`
  margin-top : ${spacing}rem;
  margin-bottom : 4.4rem;
  display: flex;
  flex-direction : row;
  flex-wrap: wrap;
`
