import { useRef, useState } from 'react';
import styled from 'styled-components';
import { useDrag, useDrop } from 'react-dnd'

import {colors, shadows, spacing, transitions} from '../../styles';
import { DynamicSlideViewMeta, PageSlide, SlideIndex } from '../../store/store-types';
import { useModelActions } from '../../store/hooks/use-actions';
import { useModelStore } from '../../store/store';
import configProvider from '../../providers/configprovider';
import ToolButton from '../atoms/tool-button';
import EditableSlideLabel from '../atoms/editable-slide-label';
import ReplaceSlideIcon from '../../assets/icons/replace-slide';
import ArrowDownIcon from '../../assets/icons/arrow-down';
import { ProposalSlideDragItem, SlideDragItem } from '../../types';
import useContextMenu from '../../hooks/use-context-menu';
import { useTranslation } from 'react-i18next';
import Typography from '../atoms/typography';
import Checkbox from '../atoms/checkbox';
import TextButton from '../atoms/text-button';
import SelectAllIcon from '../../assets/icons/select-all';
import DeselectAllIcon from '../../assets/icons/deselect-all';
import ChoicesIcon from '../../assets/icons/choices';

interface ProposalAlternativeSlideCardProps {
  index : number;
  slide : PageSlide;
  view ?: DynamicSlideViewMeta;
  readonly ?: boolean;
  onShowAlternativesModal : (slide : PageSlide) => void;
  onInsertSlide ?: (slideIndex : SlideIndex | false) => void;
}

const ProposalAlternativeSlideCard : React.FC<ProposalAlternativeSlideCardProps> = (p) => {
  const {t} = useTranslation();
  const [modifiedLabel, setModifiedLabel] = useState<string>(p.slide.label);

  const { setLabel, toggleCollapse, toggleSelection, moveSlides, isSectionSelected, selectSection, deselectSection} = useModelActions('proposalEditor');

  const collapsed = useModelStore(state => !!state.proposals.editor.meta[p.slide.pageId]?.collapsed);
  const selected = useModelStore(state => {
    if(!p.slide.alternativeGroupId) {
      return false;
    }

    const selection = state.actions.proposalEditor.current().selection || {};
    const alternatives = (state.actions.proposalEditor.current().slides || []).filter(slide => slide.alternativeGroupId === p.slide.alternativeGroupId);

    return alternatives.some(slide => slide.type === 'page' && selection[slide.pageId])
  })

  const firstSelected : PageSlide = useModelStore(state => {
    if(!p.slide.alternativeGroupId) {
      return p.slide;
    }

    const selection = state.actions.proposalEditor.current().selection || {};
    const alternatives = (state.actions.proposalEditor.current().slides || []).filter(slide => slide.alternativeGroupId === p.slide.alternativeGroupId);

    return alternatives.find((slide) : slide is PageSlide => slide.type === 'page' && selection[slide.pageId]) || p.slide
  });

  const imgUrl = `${configProvider('PDF_SERVICE_URL')}/pages/${firstSelected.pageId}/thumbnail`;
  const label = firstSelected.label || ''; //`Page ${p.index+1}`;

    /*
      Handle Drag and drop
    */
      const [dragCollectedProps, drag, dragPreview] = useDrag<ProposalSlideDragItem>(() => ({
        type : 'slide',
        item: { id : p.slide.pageId, index : p.index, level : p.slide.level },
        collect: (monitor) => ({
          isDragging: monitor.isDragging(),
        }),
      }), [selected, p.index])

      const [appendDropCollectedProps, appendDropZone] = useDrop<ProposalSlideDragItem, unknown, {isOver : boolean}>({
        accept : 'slide',
        drop : (item) => moveSlides({position : item.index}, {position : p.index}),
        canDrop : (item) => item.id !== p.slide.pageId && p.slide.level === item.level,
        collect : (monitor) => ({
          isOver : monitor.canDrop() && monitor.isOver()
        })
      }, [selected, p.index])

      const [moveDropCollectedProps, moveDropZone] = useDrop<ProposalSlideDragItem, unknown, {isOver : boolean}>({
        accept : 'slide',
        drop : (item) => moveSlides({position : item.index}, {position : p.index}),
        canDrop : (item) => item.id !== p.slide.pageId && p.slide.level === item.level,
        collect : (monitor) => ({
          isOver : monitor.canDrop() && monitor.isOver()
        })
      }, [selected, p.index])

  /*
    Context menu callbacks
   */
    const contextMenu = useContextMenu({
      options : [
        ...(p.onInsertSlide ? [{label : t('pages.proposal-notebook.slideContextMenu.insertSlideAfter'), callback : () => p.onInsertSlide?.({position : p.index})}] : []),
      ],
    });
  


  const handleLabelBlur = () => {
    if(p.slide.label !== modifiedLabel) {
      setLabel({position : p.index}, modifiedLabel);
    }
  }

  const handleCollapse = () => {
    toggleCollapse(p.slide.pageId);
  }


  const handleSelectMouseDown = () => {
    // if(!selected) {
    //   selectionOngoing.current = true
    //   if(e.ctrlKey || e.metaKey) {
    //     addToSelection(p.slide.pageId);
    //   }
    //   else if (e.shiftKey) {
    //     addRangeToSelection(p.slide.pageId);
    //   }
    //   else {
    //     select(p.slide.pageId);
    //   }
    // }
  }

  const handleSelectClick = () => {
    p.onShowAlternativesModal(p.slide);
    // toggleSelection(p.slide.pageId)
    // if(selectionOngoing.current) {
    //   selectionOngoing.current = false;
    // }
    // else {
    //   if(e.ctrlKey || e.metaKey) {
    //     deselect(p.slide.pageId)
    //   }
    //   else if(multiSelection) {
    //     select(p.slide.pageId);
    //   }
    //   else {
    //     deselect(p.slide.pageId)
    //   }
    // }
  }

  return (
    <Row
    >
      {
        /*
         Vertical lines used to highlight links between slides
        */
      }
      <VerticalLine $level={1} $visible={!!p.view?.link1}/>
      <VerticalLine $level={2} $visible={!!p.view?.link2}/>
      <VerticalLine $level={3} $visible={!!p.view?.link3}/>
      <SmallVerticalLine $level={p.slide.level} $visible={p.index > 0 && !p.view?.hidden && (p.view?.selfLink !== false)}/>
      {
        /*
         Actual card
        */
      }
      <CardContainer
        style={{transform : `translateX(${spacing * 2 * (p.slide.level - 1)}rem)`}}
        className={p.view?.hidden ? 'hidden' : 'visible'}
        >
        <FloatingToolbox className={p.view?.hasChildren ? 'visible' : 'hidden'}>
          <StyledToolButton onClick={handleCollapse}><StyledArrowDownIcon $rotated={!collapsed}/></StyledToolButton>
          {
            isSectionSelected({position : p.index}) ?
            <ShowAllButton color='light' onClick={() => deselectSection({position : p.index})}><DeselectAllIcon />Tout déselectionner</ShowAllButton> :
            <ShowAllButton color='light' onClick={() => selectSection({position : p.index})}><SelectAllIcon />Tout selectionner</ShowAllButton>
          }
        </FloatingToolbox>
        <PageCard
          ref={appendDropZone}
          className={[selected ? 'selected' : '', p.slide.mandatory ? 'mandatory' : '', appendDropCollectedProps.isOver ? 'dnd-over' : ''].join(' ')}
          {...contextMenu}
          >
          <div style={{position :'relative'}}>
          <PageThumbnail
            ref={drag}
            src={imgUrl}
            onMouseDown={handleSelectMouseDown}
            onClick={handleSelectClick}
            draggable={false}
          />
          {
            !p.slide.textArea ? null :
            <TextAreaPreview
              style={{
                left : `${p.slide.textArea.x}%`,
                top : `${p.slide.textArea.y}%`,
                width : `${p.slide.textArea.width}%`,
                height : `${p.slide.textArea.height}%`,
              }}
            />
          }
          </div>
          <AlternativeButton onClick={() => p.onShowAlternativesModal(p.slide)}>
            <Typography variant='buttonBold' color="whiteText">{p.view?.alternativesCount}</Typography>
            &nbsp;&nbsp;&nbsp;
            <ChoicesIcon width='1.7rem' height="1.7rem" color={colors.whiteText}/>
          </AlternativeButton>
          <Label>
            <EditableSlideLabel initialValue={label} onChange={setModifiedLabel} onBlur={handleLabelBlur}/>
            <Checkbox value={!!selected} onChange={() => p.onShowAlternativesModal(p.slide) }/>
          </Label>
          {
            !p.slide.mandatory ? null :
            <MandatoryContainer>
              <Typography variant="smallTextItalic" color="primary">{t('pages.notebook.mandatory')}</Typography>
            </MandatoryContainer>
          }
        </PageCard>
        <MoveDropZone
          ref={moveDropZone}
        >
          <MoveDropIndicator
            className={moveDropCollectedProps.isOver ? 'dnd-over' : ''}
          />
        </MoveDropZone>
      </CardContainer>
    </Row>
  );
};

export default ProposalAlternativeSlideCard;

const Row = styled.div`
  position : relative;
`

const CardContainer = styled.div`
  position :relative;
  margin : ${spacing}rem;
  margin-bottom : ${2*spacing}rem;
  transition : transform ${transitions.fast}, max-height ${transitions.slow}, margin-top ${transitions.fast}, margin-bottom ${transitions.fast};
  max-height : 25rem;
  overflow: hidden;

  &.hidden {
    max-height : 0rem;
    margin-top : 0rem;
    margin-bottom : 0rem;
  }
`

const PageCard = styled.div`
  width : 21rem;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  border-radius: 1.5rem;
  padding : 0.3rem;
  padding-bottom : 0;
  border : 0.3rem solid ${colors.background2};
  background-color: ${colors.background2};
  z-index : 0;
  box-shadow: ${shadows.default};

  &.selected {
    border : 0.3rem solid ${colors.primary} !important;
  }

  &.dnd-over {
    border : 0.3rem solid ${colors.accent} !important;
  }

  &.mandatory {
    background-color: ${colors.lightPrimary};
    border : 0.3rem solid ${colors.lightPrimary};
  }
  &.mandatory textarea {
    background-color: ${colors.lightPrimary};
  }
`

const MandatoryContainer = styled.div`
  margin-left : 1rem;
  margin-top : -0.8rem;
  margin-bottom : 1rem;
`

const PageThumbnail = styled.img`
  max-width: 20rem;
  border-radius: 1rem;
`

const Label = styled.div`
  display: flex;
  margin-top : 1rem;
  margin-bottom : 1rem;
  padding-left : 1rem;
`


const FloatingToolbox = styled.div`
  position : absolute;
  left: 21rem;
  top: 10.6rem;
  padding-top : 1rem;
  padding-left : 1rem;
  display: none;

  &.visible {
    display: flex;
  }
`

const StyledToolButton = styled(ToolButton)`
  margin-right: 1rem;
  margin-bottom : 1rem;
`

const ReplaceSlideButton = styled(ToolButton)`
  position : absolute;
  top : 0.8rem;
  right : 0.8rem;
`



const StyledArrowDownIcon = styled(ArrowDownIcon)<{$rotated : boolean}>`
  transform : rotate(${p => p.$rotated ? '180deg' : '0deg'});
  transition : transform ${transitions.fast};
`

const VerticalLine = styled.div<{$level : number, $visible : boolean}>`
  position : absolute;
  top : -${2*spacing}rem;
  left : ${p => (p.$level) * 2 * spacing}rem;
  height : calc(100% + 3*${spacing}rem);
  width : 0.3rem;
  background-color : ${colors.lightSecondary};
  opacity : ${p => p.$visible ? 1 : 0};
  transition : opacity ${transitions.fast};
`

const SmallVerticalLine = styled.div<{$level : number, $visible : boolean}>`
  position : absolute;
  top : -${2*spacing}rem;
  left : ${p => (p.$level) * 2 * spacing}rem;
  height : ${2*spacing}rem;
  width : 0.3rem;
  background-color : ${colors.lightSecondary};
  opacity : ${p => p.$visible ? 1 : 0};
  transition : opacity ${transitions.fast};
`

const TextAreaPreview = styled.div`
  position : absolute;
  border : 0.2rem dashed ${colors.primary};
  pointer-events: none;
`

const MoveDropZone = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position : absolute;
  bottom : 0;
  margin : ${spacing}rem;
  margin-bottom : -${2*spacing}rem;
  height : ${2*spacing}rem;
  width : 100%;
  z-index: 1;
  transition : transform ${transitions.fast};
`

const MoveDropIndicator = styled.div`
  height : 0.7rem;
  width : 17rem;
  margin-left : 2rem;
  border-radius: 0.7rem;
  background-color : ${colors.accent};
  display: none;

  &.dnd-over {
    display: block;
  }
`

const ShowAllButton = styled(TextButton)`
  & > svg {
    padding: 1rem 1rem 1rem 0;
  }
`

const AlternativeButton = styled.div`
  position: absolute;
  left : 50%;
  top  : 50%;
  display: flex;
  align-items: center;
  transform: translateX(-50%) translateY(-100%);
  background-color: ${colors.accent};
  padding : 0.5rem ${spacing}rem;
  border-radius: ${spacing}rem;
  cursor : pointer;
`