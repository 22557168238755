import { useRef, useState } from 'react';
import styled from 'styled-components';
import { useDrag, useDrop } from 'react-dnd'

import {colors, shadows, spacing, transitions} from '../../styles';
import { DynamicSlideViewMeta, PageSlide, SlideIndex } from '../../store/store-types';
import { useModelActions } from '../../store/hooks/use-actions';
import { useModelStore } from '../../store/store';
import configProvider from '../../providers/configprovider';
import ToolButton from '../atoms/tool-button';
import TextIcon from '../../assets/icons/text';
import TrashIcon from '../../assets/icons/trash';
import ChoicesIcon from '../../assets/icons/choices';
import TagsIcon from '../../assets/icons/tags';
import EditableSlideLabel from '../atoms/editable-slide-label';
import ReplaceSlideIcon from '../../assets/icons/replace-slide';
import { SlideDragItem } from '../../types';
import useContextMenu from '../../hooks/use-context-menu';
import { useTranslation } from 'react-i18next';
import Typography from '../atoms/typography';
import ClosedFoodDome from '../../assets/images/closed-food-dome';
import Checkbox from '../atoms/checkbox';

interface ProposalContentLevelSummaryCardProps {
  index : number;
  slide : PageSlide;
  view ?: DynamicSlideViewMeta;
  readonly ?: boolean;
  onInsertSlide ?: (slideIndex : SlideIndex | false) => void;
}

const ProposalContentLevelSummaryCard : React.FC<ProposalContentLevelSummaryCardProps> = (p) => {
  const {t} = useTranslation();

  const {toggleSelection, moveSlides} = useModelActions('proposalEditor');

  const selected  = useModelStore(state => state.actions.proposalEditor.current().selection?.[p.slide.pageId]);

  // const fileInputBeforeRef = useRef<HTMLInputElement>(null);
  // const fileInputAfterRef = useRef<HTMLInputElement>(null);
  // const selectionOngoing = useRef<boolean>(false);

  /*
    Handle Drag and drop
  */
  // const [dragCollectedProps, drag, dragPreview] = useDrag<SlideDragItem>(() => ({
  //   type : 'slide',
  //   item: { id : p.slide.pageId, index : p.index },
  //   collect: (monitor) => ({
  //     isDragging: monitor.isDragging(),
  //   }),
  // }))

  // const [moveDropCollectedProps, moveDropZone] = useDrop<SlideDragItem, unknown, {isOver : boolean}>({
  //   accept : 'slide',
  //   drop : (item) => moveSlides({position : item.index}, {position : p.index}),
  //   canDrop : (item) => item.id !== p.slide.pageId && !selected,
  //   collect : (monitor) => ({
  //     isOver : monitor.canDrop() && monitor.isOver()
  //   })
  // }, [selected, p.index])


  /*
    Context menu callbacks
   */
    // const contextMenu = useContextMenu({
    //   options : [
    //     {label : t('pages.notebook.slideContextMenu.insertLeft'), callback : () => fileInputBeforeRef.current?.click(), disabled : multiSelection},
    //     {label : t('pages.notebook.slideContextMenu.insertRight'), callback : () => fileInputAfterRef.current?.click(), disabled : multiSelection},
    //   ],
    // });
  


  const handleSelectMouseDown = () => {
    // if(!selected) {
    //   selectionOngoing.current = true
    //   if(e.ctrlKey || e.metaKey) {
    //     addToSelection(p.slide.pageId);
    //   }
    //   else if (e.shiftKey) {
    //     addRangeToSelection(p.slide.pageId);
    //   }
    //   else {
    //     select(p.slide.pageId);
    //   }
    // }
  }

  const handleSelectClick = () => {
    toggleSelection(p.slide.pageId)
    // if(selectionOngoing.current) {
    //   selectionOngoing.current = false;
    // }
    // else {
    //   if(e.ctrlKey || e.metaKey) {
    //     deselect(p.slide.pageId)
    //   }
    //   else if(multiSelection) {
    //     select(p.slide.pageId);
    //   }
    //   else {
    //     deselect(p.slide.pageId)
    //   }
    // }
  }

  // const handleSelectFileBefore = async(file : File) => {
  //   const uploadId = await start(file);
  //   addUpload({position : p.index}, uploadId, p.slide.level);
  //   save();
  // }

  // const handleSelectFileAfter = async(file : File) => {
  //   const uploadId = await start(file);
  //   addUpload({position : p.index + 1}, uploadId, p.slide.level);
  //   save();
  // }


  return (
    <CardContainer>
      {/* <FileInput
        ref={fileInputBeforeRef}
        type="file"
        accept="application/pdf"
        onChange={e => e.target.files?.[0] && handleSelectFileBefore(e.target.files?.[0])}
        />
      <FileInput
        ref={fileInputAfterRef}
        type="file"
        accept="application/pdf"
        onChange={e => e.target.files?.[0] && handleSelectFileAfter(e.target.files?.[0])}
        /> */}
      <PageCard
        className={selected ? 'selected' : ''}
        // {...contextMenu}
      >
        <div style={{position :'relative'}}>
          <ImageContainer
            // ref={drag}
            onMouseDown={handleSelectMouseDown}
            onClick={handleSelectClick}
            >
            <ClosedFoodDome />
          </ImageContainer>
        </div>
          <Label>
            <Typography style={{flex : 1}} variant="textRegular" color="secondaryText">{t('pages.notebook.summary')}</Typography>
            <Checkbox value={!!selected} onChange={() => toggleSelection(p.slide.pageId) }/>
          </Label>
          <MandatoryContainer>
            <Typography variant="smallTextItalicUnderlined" color="secondaryText">{t('pages.notebook.summaryLabel')}</Typography>
          </MandatoryContainer>
      </PageCard>
      <MoveDropZone
        // ref={moveDropZone}
      >
        <MoveDropIndicator
          // className={moveDropCollectedProps.isOver ? 'dnd-over' : ''}
        />
      </MoveDropZone>
    </CardContainer>
  );
};

export default ProposalContentLevelSummaryCard;


const CardContainer = styled.div`
  display: inline-block;
  position :relative;
  transition : transform ${transitions.fast}, max-height ${transitions.slow}, margin-top ${transitions.fast}, margin-bottom ${transitions.fast};

  &.hidden {
    max-height : 0rem;
    margin-top : 0rem;
    margin-bottom : 0rem;
  }
`

const PageCard = styled.div`
  width : 21rem;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  border-radius: 1.5rem;
  padding : 0.3rem;
  padding-bottom : 0;
  border : 0.3rem solid ${colors.lightPrimary};
  background-color: ${colors.lightPrimary};
  z-index : 0;
  box-shadow: ${shadows.default};

  &.selected {
    border : 0.3rem solid ${colors.primary} !important;
  }

  &.dnd-over {
    border : 0.3rem solid ${colors.accent} !important;
  }

  &.mandatory {
    background-color: ${colors.lightPrimary};
    border : 0.3rem solid ${colors.lightPrimary};
  }
  &.mandatory textarea {
    background-color: ${colors.lightPrimary};
  }
`

const MandatoryContainer = styled.div`
  margin-left : 1rem;
  margin-top : -0.8rem;
  margin-bottom : 1rem;
`



const Label = styled.div`
  display : flex;
  margin-top : 1rem;
  margin-bottom : 1rem;
  padding-left : 1rem;
`


const MoveDropZone = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -1.5rem;
  height: 100%;
  width: 2rem;
  z-index: 1;
`

const MoveDropIndicator = styled.div`
  height: 14rem;
  width: 0.7rem;
  margin-top: 1rem;
  border-radius: 0.7rem;
  background-color: ${colors.accent};
  display: none;

  &.dnd-over {
    display: block;
  }
`

const ImageContainer = styled.div`
  display: flex;
  align-items : center;
  justify-content: center;
  width : 20rem;
  height : 11.3rem;
  background-color: ${colors.background2};
  border-radius: 1rem;
`


