import { ReactNode, useEffect, useRef, useState } from "react"
import styled from "styled-components";
import { colors, shadows, spacing, transitions } from "../../styles";
import { DynamicSlideViewMeta, PageSlide, SlideIndex } from "../../store/store-types";
import { SlideData } from "../../types";
import { useTranslation } from "react-i18next";
import TextButton from "./text-button";
import EyeIcon from "../../assets/icons/eye";
import ProposalContentLevelSummaryCard from "../molecules/proposal-content-level-summary-card";
import ProposalContentLevelSlideCard from "../molecules/proposal-content-level-slide-card";
import ProposalAlternativeSlideCard from "../molecules/proposal-alternative-slide-card";
import ProposalContentLevelAlternativeSlideCard from "../molecules/proposal-content-level-alternative-slide-card";
import ProposalLinkedSlideCard from "../molecules/proposal-linked-slide-card";

interface ProposalContentLevelContainerProps {
  index : number;
  children ?: ReactNode
  hidden ?: boolean;
  view ?: DynamicSlideViewMeta
  parentLabel : string;
  slides : SlideData[]
  onShowCustomTextModal : (slideIndex : SlideIndex | false) => void;
  onReplaceSlide : (slideIndex : SlideIndex | false) => void;
  onInsertSlide : (slideIndex : SlideIndex | false) => void;
  onShowAlternativesModal : (slide : PageSlide) => void;
}

const ProposalContentLevelContainer : React.FC<ProposalContentLevelContainerProps> = (p) => {
  const [showAll, setShowAll] = useState<boolean>(false);
  const {t} = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState<number>(1000);


  const hiddenCount= p.slides.reduce((count, slide) => {
    return count + (slide.meta?.hidden ? 1 : 0)
  }, 0)

  useEffect(() => {
    if(containerRef.current) {
      setContentHeight(containerRef.current.getBoundingClientRect().height * 1.15);
    }
  }, [containerRef.current, p.children, p.view])


  return (
    <Row>
      {
        /*
        Vertical lines used to highlight links between slides
        */
      }
      <VerticalLine $level={1} $visible={!!p.view?.link1}/>
      <VerticalLine $level={2} $visible={!!p.view?.link2}/>
      <VerticalLine $level={3} $visible={!!p.view?.link3}/>
      <SmallVerticalLine $visible={p.index > 0 && !p.view?.hidden && (p.view?.selfLink !== false)}/>
      <Slider $height={contentHeight} className={p.hidden ? 'hidden' : 'visible'}>
        <Container  ref={containerRef}>
          {
            p.slides.map(slideData =>
              slideData.slide.type === 'page' && slideData.slide.isSummary ?
              <ProposalContentLevelSummaryCard
                key={'mosaic-content-summary' + slideData.slide.pageId}
                index={slideData.index}
                slide={slideData.slide}
                view={{...slideData.meta, hidden : showAll ? false : slideData.meta?.hidden}}
                onInsertSlide={p.onInsertSlide}
            /> :
            slideData.slide.type === 'page' && slideData.slide.alternativeGroupId ?
            <ProposalContentLevelAlternativeSlideCard
              key={'mosaic' + slideData.slide.pageId}
              index={slideData.index}
              slide={slideData.slide}
              view={slideData.meta}
              onShowAlternativesModal={() => p.onShowAlternativesModal(slideData.slide as PageSlide)}
              onInsertSlide={p.onInsertSlide}
            /> :
            slideData.slide.type === 'page' && slideData.slide.linkedGroupdId ?
            <ProposalLinkedSlideCard
              key={'mosaic-content-slide' + slideData.slide.pageId}
              index={slideData.index}
              slide={slideData.slide}
              view={{...slideData.meta, hidden : showAll ? false : slideData.meta?.hidden}}
              onShowCustomTextModal={p.onShowCustomTextModal}
              onInsertSlide={p.onInsertSlide}
            /> :
            slideData.slide.type === 'page' && !slideData.slide.isSummary ?
            (
              <ProposalContentLevelSlideCard
                key={'mosaic-content-slide' + slideData.slide.pageId}
                index={slideData.index}
                slide={slideData.slide}
                view={{...slideData.meta, hidden : showAll ? false : slideData.meta?.hidden}}
                onShowCustomTextModal={p.onShowCustomTextModal}
                onReplaceSlide={p.onReplaceSlide}
                onInsertSlide={p.onInsertSlide}
              />
              ) : null
            )
          }
          {
            hiddenCount ?
              <ShowAllButton color="light" onClick={() => setShowAll(v => !v)}>
                <EyeIcon />
                {
                  !showAll ?
                    t('pages.proposal-notebook.showOthers', {count : hiddenCount}) :
                    t('pages.proposal-notebook.hideOthers', {count : hiddenCount})
                }
              </ShowAllButton> :
              null
          }
        </Container>
      </Slider>
    </Row>
  )
}

export default ProposalContentLevelContainer;

const Row = styled.div`
  position : relative;
`

const Slider = styled.div<{$height : number}>`
  overflow: hidden;

  max-height: calc(30rem + ${p => p.$height}px);
  transition : max-height ${transitions.slow};

  &.hidden {
    max-height: 0rem;
  }
`;

const Container = styled.div`
  display : inline-grid;
  grid-template-columns : repeat(4, 1fr);
  grid-column-gap: 0.8rem;
  grid-row-gap: 0.8rem;
  padding : 0.8rem;
  margin-left : ${spacing}rem;
  border-radius : 2.2rem;
  box-shadow: ${shadows.inset};
  background: rgba(255, 228, 223, 0.30);
  margin-bottom : ${spacing}rem;
  transform : translateX(${spacing * 6}rem);
`

const VerticalLine = styled.div<{$level : number, $visible : boolean}>`
  position : absolute;
  top : -${2*spacing}rem;
  left : ${p => (p.$level) * 2 * spacing}rem;
  height : calc(100% + 3*${spacing}rem);
  width : 0.3rem;
  background-color : ${colors.lightSecondary};
  opacity : ${p => p.$visible ? 1 : 0};
  transition : opacity ${transitions.fast};
`

const SmallVerticalLine = styled.div<{$visible : boolean}>`
  position : absolute;
  top : -${2*spacing}rem;
  left : ${8*spacing}rem;
  height : ${2*spacing}rem;
  width : 0.3rem;
  background-color : ${colors.lightSecondary};
  opacity : ${p => p.$visible ? 1 : 0};
  transition : opacity ${transitions.fast};
`

const ShowAllButton = styled(TextButton)`
  align-self: end;
  color : ${colors.accent};

  & > svg {
    width :1.7rem !important;
    margin-right : 1rem;
  }
`