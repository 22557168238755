import axios from "axios";


import { GetFunction, SetFunction, ActionsFactory, ModelStore, ID, User, Pagination, ListApiResponse } from "../store-types";
import configProvider from "../../providers/configprovider";
import { setLoading } from "../helpers/set-loading";
import { setError } from "../helpers/set-error";
import { useUserStore } from "../store";
import { ADMIN, SUPERADMIN, USER } from "../../constants/roles";
import { PAGE_SIZE } from "../../constants/api";


const usersActions : ActionsFactory<ModelStore['actions']['users'], ModelStore> =  (set : SetFunction<ModelStore>, get : GetFunction<ModelStore>) => ({
  load : async (page : number, query : {role : string, marketId : ID, query ?: string}) => {
    setLoading(set, 'users.load', true);

    try {
      let searchParam : string = '';
      if(query.query?.trim()) {
        searchParam = `&q=${encodeURIComponent(query.query.trim())};firstName,lastName,email`
      }

      const usersResponse = await axios.get(`${configProvider('MODEL_API_URL')}/users?pageSize=${PAGE_SIZE}&page=${page}${searchParam}&role=${query.role}` + (query.marketId ? `&marketId=${query.marketId}` : ''));

      if(usersResponse.status === 200) {
        const data : ListApiResponse<User> = usersResponse.data || {};

        setLoading(set, 'users.load', false);

        return data;
      }
    }
    catch(error) {}

    setError(set, 'users.load', 'errors.users.load')
    throw "error.users.load";
  },

  loadMany : async (ids : ID[]) => {
    setLoading(set, 'users.loadMany', true);

    try {
      const usersResponse = await axios.get(`${configProvider('MODEL_API_URL')}/users?id=${ids.map(id => `${id}`).join(',')}`);

      if(usersResponse.status === 200) {
        const {data} = usersResponse.data || {};

        setLoading(set, 'users.loadMany', false);

        return data;
      }
    }
    catch(error) {}

    setError(set, 'users.loadMany', 'errors.users.loadMany')
    throw "error.users.loadMany";
  },

  create : async (user : Partial<User>) => {
    setLoading(set, 'users.create', true);

    if(!user.email || !user.role) {
      setError(set, 'users.create', 'users.create.missingParams');
      throw 'users.create.missingParams';
    }

    const currentUserRole = useUserStore.getState().user.role;

    if(currentUserRole === USER || (currentUserRole === ADMIN && user.role === SUPERADMIN)) {
      setError(set, 'users.create', 'users.create.missingPermission');
      throw 'users.create.missingPermission';
    }

    try {
      const usersResponse = await axios.post(`${configProvider('MODEL_API_URL')}/users`, {
        email : user.email,
        alternativeEmail : user.alternativeEmail,
        role : user.role,
        marketsIds : user.marketsIds,
        regionsIds: user.regionsIds,
        firstName : user.firstName,
        lastName : user.lastName
      });

      if(usersResponse.status === 200) {
        const newUser : User = usersResponse.data;

        setLoading(set, 'users.create', false);

        return newUser;
      }
    }
    catch(error) {}

    setError(set, 'users.create', 'errors.users.create')
    throw 'errors.users.create'
  },

  delete : async (userId : ID) => {
    setLoading(set, 'users.delete', true);

    try {
      const usersResponse = await axios.delete(`${configProvider('MODEL_API_URL')}/users/${userId}`);

      if(usersResponse.status === 200) {
        setLoading(set, 'users.delete', false);

        return;
      }

      if(usersResponse.status === 409) {
        setError(set, 'users.delete', 'users.deleteConflict')
        return;
      }
    }
    catch(error) {}

    setError(set, 'users.delete', 'users.delete')
    throw 'users.delete'
  },

  update : async (offerId : ID, patch : Partial<User>) => {
    setLoading(set, 'users.update', true);

    try {
      const usersResponse = await axios.patch(`${configProvider('MODEL_API_URL')}/users/${offerId}`, patch);

      if(usersResponse.status === 200) {

        setLoading(set, 'users.update', false);

        return;
      }
    }
    catch(error) {}

    setError(set, 'users.update', 'users.update')
    throw 'users.update'
  },

  search : async (query : string) => {
    setLoading(set, 'users.search', true);
    try {
      const usersResponse = await axios.get(`${configProvider('MODEL_API_URL')}/users?q=${encodeURIComponent(query)};email,firstName,lastName&pageSize=5&page=0`);

      if(usersResponse.status === 200) {
        setLoading(set, 'users.search', false);
        return usersResponse.data.data;
      }
    }
    catch(error) {}

    setLoading(set, 'users.search', false);
    return [];
  }
})

export default usersActions;