import { useTranslation } from "react-i18next";
import TextIcon from "../../../assets/icons/text";
import { useModelActions } from "../../../store/hooks/use-actions";
import { useModelStore } from "../../../store/store"
import { colors, spacing, typography } from "../../../styles";
import Modal from "../modal"
import styled from "styled-components";
import TextButton from "../../atoms/text-button";
import { useState } from "react";
import { SlideIndex } from "../../../store/store-types";

interface TextAreaModalProps {
  onClose : () => void;
  slideIndex : SlideIndex;
}

const CustomTextModal : React.FC<TextAreaModalProps> = (p) => {
  const slide  = useModelStore(state => p.slideIndex ? state.actions.proposalEditor.slideAtIndex(p.slideIndex) : null)
  const {setCustomText} = useModelActions('proposalEditor');
  const {t} = useTranslation();
  const [modifiedContent, setModifiedContent] = useState<string>(slide?.textArea?.content || '')

  const handleSave = () => {
    setCustomText(p.slideIndex, modifiedContent);
    p.onClose();
  }


  return (
    <Modal
      onClose={p.onClose}
      icon={<TextIcon color={colors.text}/>}
      title={t('modals.customText.title')}
      subtitle={t('modals.customText.subtitle')}
      controls={[
        <TextButton key="save" disabled={modifiedContent === (slide?.textArea?.content || '')} onClick={handleSave}>{t('modals.customText.save')}</TextButton>,
      ]}
    >
      <Content>
        <TextArea placeholder={t('modals.customText.placeholder')} value={modifiedContent} onChange={e => setModifiedContent(e.target.value)}/>
      </Content>
    </Modal>
  )
}

export default CustomTextModal;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`

const TextArea = styled.textarea`
  width : 53rem;
  height : 14rem;
  background-color: ${colors.lightPrimary};
  border-radius: ${spacing}rem;
  outline: none;
  resize: none;
  border: none;
  padding : ${spacing}rem;
  ${typography.textRegular.css}
`





