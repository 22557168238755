import { ReactNode, CSSProperties, FC, ReactElement } from "react";
import styled from "styled-components";
import Typography from "../typography";
import TableHeader from "./table-header";
import TableRow from "./table-row";
import TableCell from "./table-cell";

type TableKey<T> = keyof T | '_controls' | '_icon';

interface TableProps<T extends {[key : string] : any}> {
  className ?: string;
  style ?: CSSProperties;
  columns : {key : TableKey<T>, value : ReactNode}[];
  records : T[];
  hideHeader ?: boolean;
  renderers ?: {[K in TableKey<T>] ?: (value : T[K], record : T) => ReactNode};
  highlighed ?: (record : T) => boolean;
}

const defaultRenderer = (value : any) => {
  if(['string', 'number'].includes(typeof value)) {
    return <Typography variant="textRegular" color="text">{value}</Typography>;
  }
  else {
    console.warn('Unable to render value', value);
    return '';
  }
}

const Table = <T extends {[key : string] : any}>(p : TableProps<T>) : ReactElement => {
  return (
    <StyledTable>
      {
        p.hideHeader ? null :
        <thead>
          <tr>
          {p.columns.map(column => (
            <TableHeader key={String(column.key)}>
              {
                typeof column.value === 'string' ?
                  <Typography variant="textItalic" color="tertiaryText" style={{textTransform : "lowercase"}}>
                    {column.value}
                  </Typography> :
                  column.value
              }
            </TableHeader>
          ))}
          </tr>
        </thead>
      }
      <tbody>
        {
          p.records.map((record : T, index) => (
            <TableRow key={`row_${index}`} highlighted={!!p.highlighed?.(record)}>
              {
                p.columns.map(column => (
                  <TableCell key={String(column.key)} className={`key_${String(column.key)}`}>
                    {
                      p.renderers?.[column.key] ?
                        p.renderers?.[column.key]?.(record[column.key], record) :
                        defaultRenderer(record[column.key])
                    }
                  </TableCell>
                ))
              }
            </TableRow>
          ))
        }
      </tbody>
    </StyledTable>
  );
}

export default Table;

const StyledTable = styled.table`
  width : 100%;
  border-collapse: separate;
  border-spacing: 0 1rem;
`