import { useTranslation } from "react-i18next";
import TextIcon from "../../../assets/icons/text";
import { useModelActions } from "../../../store/hooks/use-actions";
import { useModelStore } from "../../../store/store"
import { colors, shadows, spacing } from "../../../styles";
import Modal from "../modal"
import styled from "styled-components";
import configProvider from "../../../providers/configprovider";
import Select from "../../atoms/select";
import { AVAILABLE_FONTS, AVAILABLE_SIZES } from "../../../constants/fonts";
import ToolButton from "../../atoms/tool-button";
import TrashIcon from "../../../assets/icons/trash";
import TextButton from "../../atoms/text-button";
import { useEffect, useRef, useState } from "react";
import SelectDropdown from "../../atoms/select-dropdown";
import ColorDropdown from "../../atoms/color-dropdown";
import { SlideIndex } from "../../../store/store-types";

interface TextAreaModalProps {
  onClose : () => void;
  slideIndex : SlideIndex;
}

const TextAreaModal : React.FC<TextAreaModalProps> = (p) => {
  const slide  = useModelStore(state => p.slideIndex ? state.actions.notebookEditor.slideAtIndex(p.slideIndex) : null)
  const {setTextArea} = useModelActions('notebookEditor');
  const {t} = useTranslation();
  const imageRef = useRef<HTMLImageElement>(null);
  const textArea = slide?.textArea;
  const [start, setStart] = useState<[number, number] | null>(textArea ? [textArea.x, textArea.y] : null); //p.value ? p.value.start : null
  const [end, setEnd] = useState<[number, number] | null>(textArea ? [textArea.x + textArea.width, textArea.y + textArea.height] : null); //p.value ? p.value.end : null
  const [mousePosition, setMousePosition] = useState<[number, number]>([0, 0]);
  
  const [font, setFont] = useState<string>(textArea?.fontFamily || AVAILABLE_FONTS[0].value);
  const [size, setSize] = useState<string>(textArea?.fontSize || '14');
  const [color, setColor] = useState<string>(textArea?.color || "#000");

  useEffect(() => {
    setStart(textArea ? [textArea.x, textArea.y] : null);
    setEnd(textArea ? [textArea.x + textArea.width, textArea.y + textArea.height] : null);
    setFont(textArea?.fontFamily || AVAILABLE_FONTS[0].value);
    setSize(textArea?.fontSize || '14');
    setColor(textArea?.color || "#000");
  }, [p.slideIndex])

  if(!p.slideIndex || !slide || slide.type !== 'page') {return null;}

  const imgUrl = `${configProvider('PDF_SERVICE_URL')}/pages/${slide.pageId}/preview`;

  const handleClick = (e : React.MouseEvent) => {
    e.stopPropagation();
    e.preventDefault();
    if(imageRef.current) {
      const clientRect = imageRef.current?.getBoundingClientRect();
      const x = (e.clientX - clientRect.x)/clientRect.width*100;
      const y = (e.clientY - clientRect.y)/clientRect.height*100;

      if(!start || !!end) {
        setStart([x, y]);
        setEnd(null);
      }
      else {
        const xStart = Math.min(x, start[0]);
        const yStart = Math.min(y, start[1]);
        const xEnd = Math.max(x, start[0]);
        const yEnd = Math.max(y, start[1]);

        setStart([xStart, yStart]);
        setEnd([xEnd, yEnd]);
      }
    }
  }

  const handleMouseMove = (e : React.MouseEvent) => {
    if(imageRef.current) {
      const clientRect = imageRef.current?.getBoundingClientRect();
      const x = (e.clientX - clientRect.x)/clientRect.width*100;
      const y = (e.clientY - clientRect.y)/clientRect.height*100;

      setMousePosition([x, y])
    }
  }

  const handleSave = () => {
    if(start && end) {
      setTextArea(p.slideIndex, {
        x : start[0],
        y : start[1],
        width : end[0] - start[0],
        height : end[1] - start[1],
        color : color,
        fontFamily : font,
        fontSize : size
      })
      p.onClose();
    }
  }

  const handleDelete = () => {
    setTextArea(p.slideIndex, null);
    p.onClose();
  }

  return (
    <Modal
      onClose={p.onClose}
      icon={<TextIcon color={colors.text}/>}
      title={t('modals.textArea.title')}
      subtitle={t('modals.textArea.subtitle')}
      controls={[
        <ToolButton key="delete" disabled={!start || !end} onClick={handleDelete}><TrashIcon color={colors.primary}/></ToolButton>,
        <StyledTextButton key="save" disabled={!start || !end} onClick={handleSave}>{t('modals.textArea.saveButton')}</StyledTextButton>
      ]}
    >
      <Content>
        <PreviewContainer>
          <ImagePreview
            src={imgUrl}
            ref={imageRef}
            onMouseDown={handleClick}
            onMouseUp={handleClick}
            onMouseMove={handleMouseMove}
            />
            {
              start && end ?
              <TextBoxPreview style={{width : `${end[0] - start[0]}%`, height : `${end[1] - start[1]}%`, left : `${start[0]}%`, top : `${start[1]}%`}}></TextBoxPreview> :
              start && !end ?
              <TextBoxPreview style={{width : `${Math.abs(mousePosition[0] - start[0])}%`, height : `${Math.abs(mousePosition[1] - start[1])}%`, left : `${Math.min(start[0], mousePosition[0])}%`, top : `${Math.min(start[1], mousePosition[1])}%`}}></TextBoxPreview> :
              null
            }
          </PreviewContainer>
          <Toolbar>
            <ToolBarRow>
              <ColorDropdown
                value={color}
                onChange={setColor}
              />
              <SizeDropdown
                nullable={false}
                choices={AVAILABLE_SIZES}
                value={size}
                onChange={setSize}
                placeholder={''}
              />
            </ToolBarRow>
            <TypographyDropdown
              nullable={false}
              choices={AVAILABLE_FONTS}
              value={font}
              onChange={setFont}
              placeholder={t('modals.textArea.fontPlaceholder')}
            />
          </Toolbar>
      </Content>
    </Modal>
  )
}

export default TextAreaModal;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`
const Toolbar = styled.div`
  background-color: ${colors.lightPrimary};
  border-top-right-radius : 1.5rem;
  border-bottom-right-radius : 1.5rem;
  padding : 1.1rem;
  margin-top: ${2*spacing}rem;
`

const StyledTextButton = styled(TextButton)`
  margin-left : ${spacing}rem;
`

const PreviewContainer = styled.div`
  width : 90rem;
  position : relative;
`

const ImagePreview = styled.img`
  max-width: 90rem;
  border : 0.05rem solid ${colors.lightSecondary};
  cursor : crosshair;
`

const TextBoxPreview = styled.div`
  border : 0.3rem dashed ${colors.primary};
  position : absolute;
  pointer-events: none;
  font-size : 16px;
`
const ToolBarRow = styled.div`
  display: flex;
  margin-bottom : 1rem;
`;

const SizeDropdown = styled(SelectDropdown)`
  display: block;
  & button {
    width : 5.5rem;
  }
  margin-left : 1rem;
`
const TypographyDropdown = styled(SelectDropdown)`
  & button {
    width : 12rem;
  }
`