import { ReactElement, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import {colors, shadows, spacing, transitions, typography} from '../../styles';
import Typography from './typography';
import Dropdown from './dropdown';
import { DEFAULT_COLORS } from '../../constants/colors';
import { SketchPicker } from 'react-color';


type ColorDropdownProps = {
  className ?: string;
  value : string;
  onChange ?: (value : string) => void;
};

const ColorDropdown : React.FC<ColorDropdownProps> = (p) => {
  const [open, setOpen] = useState<boolean>(false);
  const [color, setColor] = useState<string>(p.value);

  useEffect(() => {
    setColor(p.value)
  }, [p.value])

  const handleSelectColor = (color : string) => {
    p.onChange?.(color);
  }

  return (
    <Dropdown
      className={p.className}
      open={open}
      setOpen={setOpen}
      button={
        <ColorPreview style={{backgroundColor : color}}/>
      }
    >
      <SketchPicker
        color={color}
        disableAlpha
        onChange={color => setColor(color.hex)}
        onChangeComplete={(color) => handleSelectColor(color.hex)}
        presetColors={DEFAULT_COLORS}
      />
    </Dropdown>
  );
};

export default ColorDropdown;

const ColorPreview = styled.div`
  height : 3rem;
  width : 3rem;
  border-radius: 0.5rem;
`
