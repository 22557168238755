import styled from "styled-components";
import { spacing } from "../../styles";
import Typography from "./typography";

interface PageTopSelectorProps<T> {
  selected : T;
  onChange : (id : T) => void;
  options : {id : T, label : string}[];
}

const PageTopSelector = <T extends string>(p : PageTopSelectorProps<T>) => {
  return (
    <Container>
      {
        p.options.map(option => (
          <Role key={option.id} onClick={() => p.onChange(option.id)} tabIndex={0} role="select">
            <Typography variant="mediumTitle" color={p.selected === option.id ? 'primary' : 'tertiaryText'}>{option.label}</Typography>
          </Role>
        ))
      }
    </Container>
  );
}

export default PageTopSelector;

const Container = styled.div`
  display : flex;
`

const Role = styled.div`
  margin-right : ${spacing}rem;
  outline: none;
  cursor : pointer;
`