import { ReactNode, CSSProperties } from "react";
import styled from "styled-components";
import { spacing } from "../../../styles";

interface TableCellProps {
  className ?: string;
  style ?: CSSProperties;
  children : ReactNode;
}

const TableCell : React.FC<TableCellProps> = (p) => {
  return (
    <TD {...p} />
  );
}

export default TableCell;

const TD = styled.td`
  position: relative;
  overflow: hidden;
  padding : ${spacing}rem;

  &:first-child {
    border-top-left-radius: ${spacing}rem;
    border-bottom-left-radius: ${spacing}rem;
  }

  &:last-child {
    border-top-right-radius: ${spacing}rem;
    border-bottom-right-radius: ${spacing}rem;
  }

  &.key__icon, &.key__controls {
    width : 1%
  }
`