import { colors } from "../../styles";
import IconProps from "./icon-interface";

const UserIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.primary;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 12 17" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="5.81053" cy="3.86961" r="2.81961" stroke={color} strokeWidth="1.1"/>
      <path d="M10.695 9.73627C10.5438 9.54867 10.3806 9.3703 10.2078 9.20203C9.12499 8.14935 7.63965 7.5 6 7.5C4.2757 7.5 2.72202 8.21833 1.62731 9.3681C1.46021 9.5434 1.30414 9.72924 1.15953 9.92343C0.430744 10.9045 0 12.1149 0 13.425V14.3771C0.00705415 14.617 0.0141083 14.8568 0.0211625 15.0967C0.0299802 15.4025 0.14461 15.7632 0.452348 16.0198C0.689544 16.2179 0.969946 16.268 1.16305 16.2768C2.86751 16.5729 9.64744 16.5729 10.7047 16.2869C11.1711 16.1837 11.4308 16.0866 11.6094 15.9095C11.8219 15.6986 11.9422 15.3959 11.9475 15.0247C11.9484 14.9636 11.9555 14.9025 11.9603 14.8283C11.9735 14.5932 11.9863 14.3582 11.9996 14.1231V13.4259C11.9996 12.031 11.5111 10.7494 10.6945 9.73715L10.695 9.73627ZM10.9062 13.6719C10.8956 13.8521 10.8846 14.0326 10.874 14.2128C10.87 14.2699 10.8643 14.3169 10.8634 14.3635C10.859 14.6482 10.7607 14.8806 10.587 15.0423C10.441 15.1784 10.2285 15.2527 9.84716 15.3318C8.98258 15.5515 3.43846 15.5515 2.04438 15.3243C1.88655 15.3177 1.65729 15.2791 1.4633 15.1275C1.21155 14.9307 1.11808 14.6539 1.11059 14.4193C1.10486 14.2352 1.09913 14.0511 1.09339 13.867V13.1368C1.09339 12.132 1.44566 11.2037 2.04174 10.4515C2.15989 10.3021 2.28775 10.1598 2.42442 10.0254C3.31942 9.14359 4.59005 8.59221 6 8.59221C7.40995 8.59221 8.55537 9.09043 9.4411 9.89751C9.58263 10.0267 9.71578 10.1633 9.83966 10.3074C10.5076 11.0837 10.9066 12.067 10.9066 13.1368V13.6715L10.9062 13.6719Z" fill={color}/>
    </svg>
  )
}

export default UserIcon;

