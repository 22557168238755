export const LOGIN = '/login';

export const PROFILE = '/espace-personnel';


export const MARKETS = '/marches';
export const REGIONS = '/peritmetres';
export const USERS = '/licences';
export const FILTERS = '/filtres';

export const HOME = '/';

export const DASHBOARD = '/dashboard';
export const LIBRARY = '/bibliotheque';
export const NOTEBOOK_TEMPLATE = '/bibliotheque/:id';
export const NOTEBOOK_TEMPLATE_TAGS = '/bibliotheque/:id/tags';
export const NEW_NOTEBOOK = `${LIBRARY}/nouveau/:type`;
export const OFFERS = '/offres';
export const NEW_OFFER = `${OFFERS}/nouvelle`;
export const OFFER = '/offres/:id';
export const OFFER_REVIEW = '/offres/:id/review';
export const PROPOSALS = '/propositions';
export const NEW_PROPOSAL = '/propositions/nouvelle';
export const PROPOSAL_OFFER = '/propositions/:id/offre';
export const PROPOSAL = '/propositions/:id';
export const PROPOSAL_NOTEBOOK = '/propositions/:id/cahiers/:nid';
export const PROPOSAL_OFFER_NOTEBOOK = '/propositions/:id/offre/:nids';

export const CUSTOMER_VIEW = '/publique/:id';

export const OVERLAY_SETTINGS = '/reglages-pdf';