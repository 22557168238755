import { useTranslation } from "react-i18next";
import Modal from "../modal";
import { colors, spacing, typography } from "../../../styles";
import styled from "styled-components";
import { useModelStore, useUserStore } from "../../../store/store";
import { useState } from "react";
import TextButton from "../../atoms/text-button";
import RegionIcon from "../../../assets/icons/region";
import { Region } from "../../../store/store-types";

interface RegionModalProps {
  onClose : () => void;
  region ?: Region;
}

const RegionModal : React.FC<RegionModalProps> = (p) => {
  const {t} = useTranslation();
  const loading = useUserStore(state => state.process.loading['region.create']);

  const [editedName, setEditedName] = useState<string | undefined>(p.region ? p.region.name : '');
  const {create, rename} = useModelStore(state => state.actions.regions);

  const handleSubmit = () => {
    if(editedName?.trim()) {
      if(p.region) {
        rename(p.region.id, editedName?.trim())
      }
      else {
        create(editedName?.trim());
      }
      p.onClose();
    }
  }

  return (
    <Modal
      title={t('pages.regions.modalTitle')}
      subtitle={t('pages.regions.modalSubtitle')}
      icon={<RegionIcon color={colors.text}/>}
      onClose={p.onClose}
      onSubmit={handleSubmit}
      controls={[
        <TextButton key="validate" type="submit" disabled={loading || !editedName?.trim()}>{t('validate')}</TextButton>
      ]}
    >
      <InputsContainer>
        <NameInput
          placeholder={t('pages.regions.namePlaceholder')}
          value={editedName}
          onChange={e => setEditedName(e.target.value)}
        />
      </InputsContainer>
    </Modal>
  );
}

export default RegionModal;

const InputsContainer = styled.div`
  min-width : 50rem;
  margin-top: ${2*spacing}rem;
  background-color: ${colors.background};
  border-radius: ${spacing}rem;
  padding: ${spacing}rem;
  display: flex;
  flex-direction: column;
`


const NameInput = styled.input`
  color : ${colors.text};
  &::placeholder {
    color : ${colors.tertiaryText};
  }
  ${typography.bigTitle.css};
  border : none;
  background-color: transparent;
  outline: none;
`


