import { ReactElement, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import {colors, ColorsNames, shadows, spacing, transitions, typography} from '../../styles';
import Typography from './typography';
import ArrowDownIcon from '../../assets/icons/arrow-down';
import RemoveCrossIcon from '../../assets/icons/remove-cross';

interface DropdownButtonProps {
  open ?: boolean;
  onClick ?: () => void;
  className ?: string;
  children ?: ReactNode;
  disabled ?: boolean;
  color ?: 'light' | 'dark';
}

const DropdownButton : React.FC<DropdownButtonProps> = (p) => {
  return (
    <Button className={p.className} disabled={!!p.disabled} color={p.color || 'light'} onClick={p.onClick}>
      <Content>
        <ChildrenContainer>
          {p.children}
        </ChildrenContainer>
        <IconContainer>
          <StyledArrowDownIcon color={colors.secondaryText} open={!p.disabled && !!p.open}/>
        </IconContainer>
      </Content>
    </Button>
  )
};

export default DropdownButton;

const Button = styled.button<{disabled : boolean, color : "light" | "dark"}>`
  position : relative;
  border : none;
  border-radius : 1rem;
  box-shadow: ${shadows.default};
  height : 4rem;
  background-color: ${colors.background2};
  padding : 0.5rem;
  transition : box-shadow ${transitions.fast}, color ${transitions.fast}, background-color ${transitions.fast};

  ${
    p => p.disabled ?
      `box-shadow: none;` :
      `box-shadow: ${shadows.default};`
  }

  ${
    p => p.disabled ?
    `background-color: ${colors.background2};` :
    p.color === 'light' ?
      `background-color: ${colors.background2};` :
      `background-color: ${colors.primary};`
  }

  &:active, &:focus {
    outline : none;
  }

  &:active {
    box-shadow: none;
  }

  /*
    Create a larger clickable surface on small button
   */
  &::before {
    content : "";
    position : absolute;
    left : -1rem;
    right : -1rem;
    top : -1rem;
    bottom : -1rem;
  }
`

const Content = styled.div`
  display : flex;
  flex-direction : row;
  align-items : center;
  height : 100%;
  width : 100%;
`

const ChildrenContainer = styled.div`
  flex : 1;
`


const IconContainer = styled.div`
  width : 1rem;
  height : 3.6rem;
  right : 0;
  margin-left : 0.5rem;
`

const StyledArrowDownIcon = styled(ArrowDownIcon)<{open : boolean}>`
  transform : rotate(${p => p.open ? '180deg' : '0deg'});
  transition : transform ${transitions.fast};
`