import { ReactElement } from "react";
import styled from "styled-components";
import { colors, shadows, spacing } from "../../styles";

interface IconChipProps {
  className ?: string;
  content : ReactElement;
  icon : ReactElement;
  onClick ?: () => void;
}

const IconChip : React.FC<IconChipProps> = (p) => {
  return (
    <Chip className={p.className}>
      {p.content}
      <IconContainer onClick={p.onClick}>
        {p.icon}
      </IconContainer>
    </Chip>
  )
}

export default IconChip;

const Chip = styled.div`
  padding-left : ${spacing}rem;
  height : 3.6rem;
  border-radius: 3rem;
  box-shadow: ${shadows.default};
  background-color: ${colors.background2};
  display : inline-flex;
  align-items: center;
`

const IconContainer = styled.div`
  width : 3.6rem;
  height : 3.6;
  padding : 1.3rem;
`