import { useRef, useState } from 'react';
import styled from 'styled-components';
import { useDrag, useDrop } from 'react-dnd'

import {colors, shadows, spacing, transitions} from '../../styles';
import { DynamicSlideViewMeta, PageSlide, SlideIndex } from '../../store/store-types';
import { useModelActions } from '../../store/hooks/use-actions';
import { useModelStore } from '../../store/store';
import configProvider from '../../providers/configprovider';
import ToolButton from '../atoms/tool-button';
import TextIcon from '../../assets/icons/text';
import TrashIcon from '../../assets/icons/trash';
import ChoicesIcon from '../../assets/icons/choices';
import TagsIcon from '../../assets/icons/tags';
import IndentIcon from '../../assets/icons/indent';
import DeindentIcon from '../../assets/icons/deindent';
import EditableSlideLabel from '../atoms/editable-slide-label';
import ReplaceSlideIcon from '../../assets/icons/replace-slide';
import ArrowDownIcon from '../../assets/icons/arrow-down';
import { SlideDragItem } from '../../types';
import useContextMenu from '../../hooks/use-context-menu';
import { useTranslation } from 'react-i18next';
import Typography from '../atoms/typography';
import ConvergeIcon from '../../assets/icons/converge';

interface SlideCardProps {
  index : number;
  slide : PageSlide;
  view ?: DynamicSlideViewMeta;
  readonly ?: boolean;
  onShowAlternativesModal : (slide : PageSlide) => void;
}

const SlideCard : React.FC<SlideCardProps> = (p) => {
  const {t} = useTranslation();
  const imgUrl = `${configProvider('PDF_SERVICE_URL')}/pages/${p.slide.pageId}/thumbnail`;
  const label = p.slide.label || ''; //`Page ${p.index+1}`;

  const {indent, deindent, setColor, setMandatory, addUpload, insertSummary, save, toggleCollapse, select, addToSelection, addRangeToSelection, deselect, ungroupAlternatives, addUploadAfterAlternatives, appendContentSlidesAfterAlternatives, moveSlidesAfterAlternatives, removeAllAlternativeSlides} = useModelActions('notebookEditor');
  const {showColorPicker, hideColorPicker} = useModelActions('modals');
  const {start} = useModelActions('uploads');

  const collapsed          = useModelStore(state => !!state.notebooks.editor.meta[p.slide.pageId]?.collapsed);
  const colorPickerVisible = useModelStore(state => !!state.process.modals.colorPicker.visible);
  const selectionLength    = useModelStore(state => Object.values(state.notebooks.editor.selection || {}).filter((v : boolean)=>!!v).length);
  const multiSelection     = selectionLength > 1;
  const selected           = useModelStore(state => state.notebooks.editor.selection[p.slide.pageId]);

  const fileInputBeforeRef = useRef<HTMLInputElement>(null);
  const fileInputAfterRef = useRef<HTMLInputElement>(null);
  const colorButtonRef = useRef<HTMLDivElement>(null);
  const selectionOngoing = useRef<boolean>(false);

  /*
    Handle Drag and drop
  */
  const [dragCollectedProps, drag, dragPreview] = useDrag<SlideDragItem>(() => ({
    type : 'slide',
    item: { id : p.slide.pageId, index : p.index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  }))

  const [appendDropCollectedProps, appendDropZone] = useDrop<SlideDragItem, unknown, {isOver : boolean}>({
    accept : 'slide',
    drop : () => appendContentSlidesAfterAlternatives({position : p.index}),
    canDrop : (item) => item.id !== p.slide.pageId && !selected,
    collect : (monitor) => ({
      isOver : monitor.canDrop() && monitor.isOver()
    })
  }, [selected, p.index])

  const [moveDropCollectedProps, moveDropZone] = useDrop<SlideDragItem, unknown, {isOver : boolean}>({
    accept : 'slide',
    drop : () => moveSlidesAfterAlternatives({position : p.index}),
    canDrop : (item) => item.id !== p.slide.pageId && !selected,
    collect : (monitor) => ({
      isOver : monitor.canDrop() && monitor.isOver()
    })
  }, [selected, p.index])


  /*
    Context menu callbacks
   */
  const contextMenu = useContextMenu({
    options : [
      {label : t('pages.notebook.slideContextMenu.insertBefore'), callback : () => fileInputBeforeRef.current?.click(), disabled : multiSelection},
      {label : t('pages.notebook.slideContextMenu.insertAfter'), callback : () => fileInputAfterRef.current?.click(), disabled : multiSelection},
      {label : t(!p.slide.mandatory ? 'pages.notebook.slideContextMenu.compulsory' : 'pages.notebook.slideContextMenu.notCompulsory'), callback : () => setMandatory(!p.slide.mandatory)},
      {label : t('pages.notebook.slideContextMenu.summary'), callback : () => insertSummary({position : p.index}), disabled : multiSelection}
    ],
  });


  const handleUngroupAlternative = () => {
    if(p.slide.alternativeGroupId) {
      ungroupAlternatives(p.slide.alternativeGroupId)
    }
  }

  const handleRemove = () => {
    removeAllAlternativeSlides({position : p.index});
  }

  const handleIndent = () => {
    indent({position : p.index});
  }

  const handleDeindent = () => {
    deindent({position : p.index});
  }


  const handleSelectFileBefore = async(file : File) => {
    const uploadId = await start(file);
    addUpload({position : p.index}, uploadId, p.slide.level);
    save();
  }

  const handleSelectFileAfter = async(file : File) => {
    const uploadId = await start(file);
    addUploadAfterAlternatives({position : p.index + 1}, uploadId, p.slide.level);
    save();
  }

  const handleCollapse = () => {
    toggleCollapse(p.slide.pageId);
  }

  const handleShowPicker = () => {
    if(colorPickerVisible) {
      hideColorPicker();
    }
    else {
      if(colorButtonRef.current) {
        const rect = colorButtonRef.current.getBoundingClientRect();
        showColorPicker(rect.left, rect.bottom + window.scrollY, (color : string) => {
          setColor({position : p.index}, color);
        })
      }
    }
  }

  const handleSelectMouseDown = (e : React.MouseEvent<HTMLDivElement>) => {
    if(!selected) {
      selectionOngoing.current = true
      if(e.ctrlKey || e.metaKey) {
        addToSelection(p.slide.pageId);
      }
      else if (e.shiftKey) {
        addRangeToSelection(p.slide.pageId);
      }
      else {
        select(p.slide.pageId);
      }
    }
  }

  const handleSelectClick = (e : React.MouseEvent<HTMLDivElement>) => {
    if(selectionOngoing.current) {
      selectionOngoing.current = false;
    }
    else {
      if(e.ctrlKey || e.metaKey) {
        deselect(p.slide.pageId)
      }
      else if(multiSelection) {
        select(p.slide.pageId);
      }
      else {
        deselect(p.slide.pageId)
      }
    }
  }

  return (
    <Row
    >
      {
        /*
         Vertical lines used to highlight links between slides
        */
      }
      <VerticalLine $level={1} $visible={!!p.view?.link1}/>
      <VerticalLine $level={2} $visible={!!p.view?.link2}/>
      <VerticalLine $level={3} $visible={!!p.view?.link3}/>
      <SmallVerticalLine $level={p.slide.level} $visible={p.index > 0 && !p.view?.hidden}/>
      {
        /*
         Actual card
        */
      }
      <CardContainer
        style={{transform : `translateX(${spacing * 2 * (p.slide.level - 1)}rem)`}}
        className={p.view?.hidden ? 'hidden' : 'visible'}
        >
        <FileInput
          ref={fileInputBeforeRef}
          type="file"
          accept="application/pdf"
          onChange={e => e.target.files?.[0] && handleSelectFileBefore(e.target.files?.[0])}
          />
        <FileInput
          ref={fileInputAfterRef}
          type="file"
          accept="application/pdf"
          onChange={e => e.target.files?.[0] && handleSelectFileAfter(e.target.files?.[0])}
          />

        <FloatingToolbox className={p.view?.hasChildren ? 'visible' : 'hidden'}>
          <div ref={colorButtonRef} onClick={e => e.stopPropagation()}>
            <ColorPickerButton onClick={handleShowPicker} disabled={!!p.readonly || p.slide.level > 1} $visible={p.slide.level === 1}>
              <ColorPicker style={{backgroundColor : p.slide.color}}/>
            </ColorPickerButton>
          </div>
          <StyledToolButton onClick={handleCollapse}><StyledArrowDownIcon $rotated={!collapsed}/></StyledToolButton>
        </FloatingToolbox>
        <ToolBox>
          <div>
            <StyledToolButton disabled={!!p.readonly} onClick={handleRemove}><TrashIcon color={colors.primary}/></StyledToolButton>
            <StyledToolButton disabled={!!p.readonly} onClick={handleUngroupAlternative}>
              <ConvergeIcon />
            </StyledToolButton>
            <StyledToolButton disabled={!!p.readonly || p.slide.level === 3} onClick={handleIndent}><IndentIcon color={colors.primary}/></StyledToolButton>
          </div>
          <div>
            <StyledToolButton disabled={true}><TextIcon color={p.slide.textArea ? colors.accent : colors.primary}/></StyledToolButton>
            <StyledToolButton disabled={true}><TagsIcon color={p.slide.tags?.length ? colors.accent : colors.primary}/></StyledToolButton>
            <StyledToolButton disabled={!!p.readonly || p.slide.level === 1}  onClick={handleDeindent}><DeindentIcon color={colors.primary}/></StyledToolButton>
          </div>
        </ToolBox>
        <PageCard
          ref={appendDropZone}
          className={[selected ? 'selected' : '', appendDropCollectedProps.isOver ? 'dnd-over' : '', p.slide.mandatory ? 'mandatory' : ''].join(' ')}
          {...contextMenu}
          >
          <div style={{position :'relative'}}>
          <PageThumbnail
            ref={drag}
            src={imgUrl}
            onMouseDown={handleSelectMouseDown}
            onClick={handleSelectClick}
          />
          {
            !p.slide.textArea ? null :
            <TextAreaPreview
              style={{
                left : `${p.slide.textArea.x}%`,
                top : `${p.slide.textArea.y}%`,
                width : `${p.slide.textArea.width}%`,
                height : `${p.slide.textArea.height}%`,
              }}
            />
          }
          </div>
          <AlternativeButton onClick={() => p.onShowAlternativesModal(p.slide)}>
            <Typography variant='buttonBold' color="whiteText">{p.view?.alternativesCount}</Typography>
            &nbsp;&nbsp;&nbsp;
            <ChoicesIcon width='1.7rem' height="1.7rem" color={colors.whiteText}/>
          </AlternativeButton>
          <Label>
            <EditableSlideLabel initialValue={label} disabled/>
          </Label>
          {
            !p.slide.mandatory ? null :
            <MandatoryContainer>
              <Typography variant="smallTextItalic" color="primary">{t('pages.notebook.mandatory')}</Typography>
            </MandatoryContainer>
          }
        </PageCard>
      </CardContainer>
      <MoveDropZone
        ref={moveDropZone}
      >
        <MoveDropIndicator
          style={{transform : `translateX(${spacing * 2 * (p.slide.level - 1)}rem)`}}
          className={moveDropCollectedProps.isOver ? 'dnd-over' : ''}
        />
      </MoveDropZone>
    </Row>
  );
};

export default SlideCard;

const Row = styled.div`
  position : relative;
`

const CardContainer = styled.div`
  position :relative;
  margin : ${spacing}rem;
  margin-bottom : ${2*spacing}rem;
  transition : transform ${transitions.fast}, max-height ${transitions.slow}, margin-top ${transitions.fast}, margin-bottom ${transitions.fast};
  max-height : 25rem;
  overflow: hidden;

  &.hidden {
    max-height : 0rem;
    margin-top : 0rem;
    margin-bottom : 0rem;
  }
`

const PageCard = styled.div`
  width : 21rem;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  border-radius: 1.5rem;
  padding : 0.3rem;
  padding-bottom : 0;
  border : 0.3rem solid ${colors.background2};
  background-color: ${colors.background2};
  z-index : 0;
  box-shadow: ${shadows.default};

  &.selected {
    border : 0.3rem solid ${colors.primary} !important;
  }

  &.dnd-over {
    border : 0.3rem solid ${colors.accent} !important;
  }

  &.mandatory {
    background-color: ${colors.lightPrimary};
    border : 0.3rem solid ${colors.lightPrimary};
  }
  &.mandatory textarea {
    background-color: ${colors.lightPrimary};
  }
`

const MandatoryContainer = styled.div`
  margin-left : 1rem;
  margin-top : -0.8rem;
  margin-bottom : 1rem;
`

const PageThumbnail = styled.img`
  max-width: 20rem;
  border-radius: 1rem;
`

const Label = styled.div`
  margin-top : 1rem;
  margin-bottom : 1rem;
  padding-left : 1rem;
  padding-right : 1rem;
`

const ToolBox = styled.div`
  position : absolute;
  background-color: ${colors.lightPrimary};
  left : 21rem;
  top : 0.6rem;
  width : 16rem;
  border-top-right-radius: ${spacing}rem;
  border-bottom-right-radius: ${spacing}rem;
  padding-top : 1rem;
  padding-left : 1rem;
`

const FloatingToolbox = styled.div`
  position : absolute;
  display: flex;
  flex-direction : column;
  left : 37rem;
  top : 0.6rem;
  width : 16rem;
  padding-top : 1rem;
  padding-left : 1rem;

  transform: translateX(-110%);
  transition : transform ${transitions.fast};

  &.visible {
    transform: translateX(0);
  }
`

const StyledToolButton = styled(ToolButton)`
  margin-right: 1rem;
  margin-bottom : 1rem;
`


const FileInput = styled.input`
  position : absolute;
  visibility: hidden;
`
const ColorPickerButton = styled(StyledToolButton)<{$visible : boolean}>`
  padding : 0.5rem;
  opacity : ${p => p.$visible ? 1 : 0};
  transition : opacity ${transitions.fast}
`;

const ColorPicker = styled.div`
  background-color: black;
  width : 100%;
  height : 100%;
  border-radius : 0.5rem;
`

const StyledArrowDownIcon = styled(ArrowDownIcon)<{$rotated : boolean}>`
  transform : rotate(${p => p.$rotated ? '180deg' : '0deg'});
  transition : transform ${transitions.fast};
`

const VerticalLine = styled.div<{$level : number, $visible : boolean}>`
  position : absolute;
  top : -${2*spacing}rem;
  left : ${p => (p.$level) * 2 * spacing}rem;
  height : calc(100% + 3*${spacing}rem);
  width : 0.3rem;
  background-color : ${colors.lightSecondary};
  opacity : ${p => p.$visible ? 1 : 0};
  transition : opacity ${transitions.fast};
`

const SmallVerticalLine = styled.div<{$level : number, $visible : boolean}>`
  position : absolute;
  top : -${2*spacing}rem;
  left : ${p => (p.$level) * 2 * spacing}rem;
  height : ${2*spacing}rem;
  width : 0.3rem;
  background-color : ${colors.lightSecondary};
  opacity : ${p => p.$visible ? 1 : 0};
  transition : opacity ${transitions.fast};
`

const TextAreaPreview = styled.div`
  position : absolute;
  border : 0.2rem dashed ${colors.primary};
  pointer-events: none;
`

const MoveDropZone = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position : absolute;
  bottom : 0;
  margin : ${spacing}rem;
  margin-bottom : -${2*spacing}rem;
  height : ${2*spacing}rem;
  width : 100%;
  z-index: 1;
  transition : transform ${transitions.fast};
`

const MoveDropIndicator = styled.div`
  height : 0.7rem;
  width : 17rem;
  margin-left : 2rem;
  border-radius: 0.7rem;
  background-color : ${colors.accent};
  display: none;

  &.dnd-over {
    display: block;
  }
`

const AlternativeButton = styled.div`
  position: absolute;
  left : 50%;
  top  : 50%;
  display: flex;
  align-items: center;
  transform: translateX(-50%) translateY(-100%);
  background-color: ${colors.accent};
  padding : 0.5rem ${spacing}rem;
  border-radius: ${spacing}rem;
  cursor : pointer;
`