import { useRef, useState } from 'react';
import styled from 'styled-components';
import { useDrag, useDrop } from 'react-dnd'

import {colors, shadows, spacing, transitions} from '../../styles';
import { DynamicSlideViewMeta, PageSlide, SlideIndex } from '../../store/store-types';
import { useModelActions } from '../../store/hooks/use-actions';
import { useModelStore } from '../../store/store';
import configProvider from '../../providers/configprovider';
import ToolButton from '../atoms/tool-button';
import TextIcon from '../../assets/icons/text';
import TrashIcon from '../../assets/icons/trash';
import ChoicesIcon from '../../assets/icons/choices';
import TagsIcon from '../../assets/icons/tags';
import EditableSlideLabel from '../atoms/editable-slide-label';
import ReplaceSlideIcon from '../../assets/icons/replace-slide';
import { ProposalSlideDragItem, SlideDragItem } from '../../types';
import useContextMenu from '../../hooks/use-context-menu';
import { useTranslation } from 'react-i18next';
import Typography from '../atoms/typography';
import Checkbox from '../atoms/checkbox';
import useDndHoverId from '../../hooks/use-broadcast-flag';
import useBroadcastFlag from '../../hooks/use-broadcast-flag';

interface ProposalLinkedSlideCardProps {
  index : number;
  slide : PageSlide;
  view ?: DynamicSlideViewMeta;
  readonly ?: boolean;
  onShowCustomTextModal : (slideIndex : SlideIndex) => void;
  onInsertSlide ?: (slideIndex : SlideIndex | false) => void;
}

const ProposalLinkedSlideCard : React.FC<ProposalLinkedSlideCardProps> = (p) => {
  const {t} = useTranslation();
  const imgUrl = `${configProvider('PDF_SERVICE_URL')}/pages/${p.slide.pageId}/thumbnail`;
  const label = p.slide.label || ''; // `Page ${p.index+1}`;
  const [modifiedLabel, setModifiedLabel] = useState<string>(p.slide.label);

  const {setLabel, toggleSelection, moveSlides} = useModelActions('proposalEditor');
  const {start} = useModelActions('uploads');

  const selected = useModelStore(state => state.actions.proposalEditor.current().selection?.[p.slide.pageId]);

  const [isOver, setIsOver] = useState<boolean>(false);
  const broadcastFlag = useBroadcastFlag(p.slide.linkedGroupdId, setIsOver);

  const fileInputRef = useRef<HTMLInputElement>(null);


  /*
    Handle Drag and drop
  */
    const [dragCollectedProps, drag, dragPreview] = useDrag<ProposalSlideDragItem>(() => ({
      type : 'slide',
      item: { id : p.slide.pageId, index : p.index, level : p.slide.level, linkedGroupdId : p.slide.linkedGroupdId },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
    }), [selected, p.index])

    const [appendDropCollectedProps, appendDropZone] = useDrop<ProposalSlideDragItem, unknown, {isOver : boolean}>({
      accept : 'slide',
      drop : (item) => moveSlides({position : item.index}, {position : p.index}, true),
      canDrop : (item) => item.id !== p.slide.pageId && p.slide.level === item.level && p.slide.linkedGroupdId !== item.linkedGroupdId,
      collect : (monitor) => {
        broadcastFlag(monitor.canDrop() && monitor.isOver());

        return ({
          isOver : monitor.canDrop() && monitor.isOver()
        })
      }
    }, [selected, p.index])

    const [moveDropCollectedProps, moveDropZone] = useDrop<ProposalSlideDragItem, unknown, {isOver : boolean}>({
      accept : 'slide',
      drop : (item) => moveSlides({position : item.index}, {position : p.index}),
      canDrop : (item) => item.id !== p.slide.pageId && p.slide.level === item.level,
      collect : (monitor) => ({
        isOver : monitor.canDrop() && monitor.isOver()
      })
    }, [selected, p.index])
  


  /*
    Context menu callbacks
   */
  const contextMenu = useContextMenu({
    options : [
      {label : t('pages.proposal-notebook.slideContextMenu.insertText'), callback : () => handleTextArea(), disabled : !p.slide.textArea},
      ...(p.onInsertSlide ? [{label : t('pages.proposal-notebook.slideContextMenu.insertSlideAfter'), callback : () => p.onInsertSlide?.({position : p.index})}] : []),
    ],
  });

  const handleLabelBlur = () => {
    if(p.slide.label !== modifiedLabel) {
      setLabel({position : p.index}, modifiedLabel);
    }
  }

  // const handleRemove = () => {
  //   removeSlide({position : p.index});
  // }


  // const handleSelectFileReplacement = async(file : File) => {
  //   const uploadId = await start(file);
  //   replaceSlide({position : p.index}, uploadId);
  //   save();
  // }

  // const handleSelectFileBefore = async(file : File) => {
  //   const uploadId = await start(file);
  //   addUpload({position : p.index}, uploadId, p.slide.level);
  //   save();
  // }

  // const handleSelectFileAfter = async(file : File) => {
  //   const uploadId = await start(file);
  //   addUpload({position : p.index + 1}, uploadId, p.slide.level);
  //   save();
  // }

  const handleTextArea = () => {
    p.onShowCustomTextModal({position : p.index});
  };


  const handleSelectMouseDown = () => {
    // if(!selected) {
    //   selectionOngoing.current = true
    //   if(e.ctrlKey || e.metaKey) {
    //     addToSelection(p.slide.pageId);
    //   }
    //   else if (e.shiftKey) {
    //     addRangeToSelection(p.slide.pageId);
    //   }
    //   else {
    //     select(p.slide.pageId);
    //   }
    // }
  }

  const handleSelectClick = () => {
    toggleSelection(p.slide.pageId)

    // if(selectionOngoing.current) {
    //   selectionOngoing.current = false;
    // }
    // else {
    //   if(e.ctrlKey || e.metaKey) {
    //     deselect(p.slide.pageId)
    //   }
    //   else if(multiSelection) {
    //     select(p.slide.pageId);
    //   }
    //   else {
    //     deselect(p.slide.pageId)
    //   }
    // }
  }

  return (
    <CardContainer
      className={p.view?.hidden ? 'hidden' : 'visible'}
    >
      {/* <FileInput
        ref={fileInputBeforeRef}
        type="file"
        accept="application/pdf"
        onChange={e => e.target.files?.[0] && handleSelectFileBefore(e.target.files?.[0])}
        />
      <FileInput
        ref={fileInputAfterRef}
        type="file"
        accept="application/pdf"
        onChange={e => e.target.files?.[0] && handleSelectFileAfter(e.target.files?.[0])}
        /> */}
      <PageCard
        ref={appendDropZone}
        className={[
          selected ? 'selected' : '',
          p.slide.mandatory ? 'mandatory' : '',
          appendDropCollectedProps.isOver || isOver ? 'dnd-over' : '',
          p.view?.linkedLeftStyle ? `left-${p.view?.linkedLeftStyle}` : '',
          p.view?.linkedRightStyle ? `right-${p.view?.linkedRightStyle}` : ''
        ].join(' ')}
        {...contextMenu}
      >
        <div style={{position :'relative'}}>
        <PageThumbnail
          ref={drag}
          src={imgUrl}
          onMouseDown={handleSelectMouseDown}
          onClick={handleSelectClick}
          draggable={false}
        />
        {
          !p.slide.textArea ? null :
          <TextAreaPreview
            style={{
              left : `${p.slide.textArea.x}%`,
              top : `${p.slide.textArea.y}%`,
              width : `${p.slide.textArea.width}%`,
              height : `${p.slide.textArea.height}%`,
            }}
          />
        }

        </div>
        {/* {
          p.slide.alternativeGroupId ? null :
          <ReplaceSlideButton disabled={!!p.readonly} color="dark" onClick={handelOpenFileDialog}><ReplaceSlideIcon /></ReplaceSlideButton>
        } */}
        <Label>
          <EditableSlideLabel initialValue={label} onChange={setModifiedLabel} onBlur={handleLabelBlur}/>
          <Checkbox value={!!selected} onChange={() => toggleSelection(p.slide.pageId) }/>
        </Label>
        {
          !p.slide.mandatory ? null :
          <MandatoryContainer>
            <Typography variant="smallTextItalic" color="primary">{t('pages.notebook.mandatory')}</Typography>
          </MandatoryContainer>
        }
      </PageCard>
      <MoveDropZone
        ref={moveDropZone}
      >
        <MoveDropIndicator
          className={moveDropCollectedProps.isOver ? 'dnd-over' : ''}
        />
      </MoveDropZone>
    </CardContainer>
  );
};

export default ProposalLinkedSlideCard;


const CardContainer = styled.div`
  display: inline-block;
  position :relative;
  transition : transform ${transitions.fast}, max-width ${transitions.slow}, margin-top ${transitions.fast}, margin-bottom ${transitions.fast};
  overflow: hidden;

  &.hidden {
    display: none;
  }
`

const PageCard = styled.div`
  width : 21rem;
  position: relative;
  display: inline-flex;
  flex-direction: column;
  border-radius: 1.5rem;
  padding : 0.3rem;
  padding-bottom : 0;
  border : 0.3rem solid ${colors.background2};
  background-color: ${colors.background2};
  z-index : 0;
  box-shadow: ${shadows.default};

  &.selected {
    border : 0.3rem solid ${colors.primary} !important;
  }

  &.selected.left-middle {
    border-left-color : transparent !important;
  }

  &.selected.right-middle {
    border-right-color : transparent  !important;
  }

  /* &.dnd-over {
    border : 0.3rem solid ${colors.accent} !important;
  } */

  &.mandatory {
    background-color: ${colors.lightPrimary};
    border : 0.3rem solid ${colors.lightPrimary};
  }
  &.mandatory textarea {
    background-color: ${colors.lightPrimary};
  }

  &.left-none {
    border-left-color : ${colors.lightTertiary};
    border-bottom-color : ${colors.lightTertiary};
    border-top-color : ${colors.lightTertiary};
  }
  &.right-none {
    border-right-color : ${colors.lightTertiary};
    border-bottom-color : ${colors.lightTertiary};
    border-top-color : ${colors.lightTertiary};
  }

  &.left-middle {
    margin-left : -0.4rem;
    padding-left : 0.7rem;
    width : 21.4rem;
    border-top-left-radius : 0;
    border-bottom-left-radius : 0;
    border-bottom-color : ${colors.lightTertiary};
    border-top-color : ${colors.lightTertiary};
  }
  &.right-middle {
    margin-right : -0.4rem;
    padding-right : 0.7rem;
    width : 21.4rem;
    border-top-right-radius : 0;
    border-bottom-right-radius : 0;
    border-bottom-color : ${colors.lightTertiary};
    border-top-color : ${colors.lightTertiary};
  }

  &.left-none.dnd-over {
    border-left-color : ${colors.accent} !important;
    border-bottom-color : ${colors.accent} !important;
    border-top-color : ${colors.accent} !important;
  }
  &.right-none.dnd-over {
    border-right-color : ${colors.accent} !important;
    border-bottom-color : ${colors.accent} !important;
    border-top-color : ${colors.accent} !important;
  }

  &.left-middle.dnd-over {
    border-bottom-color : ${colors.accent} !important;
    border-top-color : ${colors.accent} !important;
  }
  &.right-middle.dnd-over {
    border-bottom-color : ${colors.accent} !important;
    border-top-color : ${colors.accent} !important;
  }


  &.right-middle.left-middle {
    width : 21.8rem;
  }

  &.left-middle, &.right-middle {
    box-shadow: none;
  }
`

const MandatoryContainer = styled.div`
  margin-left : 1rem;
  margin-top : -0.8rem;
  margin-bottom : 1rem;
`


const PageThumbnail = styled.img`
  max-width: 20rem;
  border-radius: 1rem;
`

const Label = styled.div`
  display: flex;
  margin-top : 1rem;
  margin-bottom : 1rem;
  padding-left : 1rem;
`






const TextAreaPreview = styled.div`
  position : absolute;
  border : 0.2rem dashed ${colors.primary};
  pointer-events: none;
`

const MoveDropZone = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: flex-start;
  position: absolute;
  top: 0;
  right: 0;
  margin-right: -1.5rem;
  height: 100%;
  width: 2rem;
  z-index: 1;
`

const MoveDropIndicator = styled.div`
  height: 14rem;
  width: 0.7rem;
  margin-top: 1rem;
  border-radius: 0.7rem;
  background-color: ${colors.accent};
  display: none;

  &.dnd-over {
    display: block;
  }
`