
import { colors } from "../../styles";
import IconProps from "./icon-interface";

const MailIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.primary;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.94115 9.05648C11.197 9.11356 13.4782 9.12784 14.0387 9.06361C14.1244 9.05291 14.4243 9.01366 14.5885 9.18493C14.6778 9.28126 14.6956 9.44539 14.6778 9.7237C14.51 12.4996 13.2034 14.5726 10.7579 15.8785C7.75194 17.4841 3.92132 16.635 1.81858 13.9482C0.619057 12.414 0.108545 10.6728 0.283475 8.73535C0.576217 5.53484 3.12164 2.83385 6.30252 2.34503C6.65595 2.29151 7.02009 2.2701 7.37709 2.24869C7.54131 2.23799 7.66269 2.27367 7.74123 2.34859C7.86975 2.46634 7.86975 2.65544 7.86975 2.72324C7.86975 3.1514 7.87689 5.57765 7.87689 8.89592C7.87689 8.91732 7.87689 8.9423 7.87689 8.96371C7.87689 8.96371 7.87689 9.0208 7.91259 9.0422C7.91973 9.0422 7.92687 9.04934 7.93758 9.04934L7.94115 9.05648ZM7.07007 3.08004C3.86063 3.23346 0.929648 6.04507 1.06531 9.73797C1.1974 13.3452 4.41755 16.189 8.04825 15.8464C11.7254 15.4968 13.8067 12.3533 13.8424 9.86285H13.6175C11.6111 9.86285 9.60835 9.86285 7.602 9.86285C7.19145 9.86285 7.07007 9.74154 7.07007 9.33121C7.07007 7.32599 7.07007 5.32076 7.07007 3.31553V3.0729V3.08004Z" fill={color} stroke={color} stroke-width="0.3"/>
    <path d="M12.4251 8.31433C11.3327 8.31433 10.2367 8.31433 9.14427 8.31433C8.76228 8.31433 8.62662 8.17874 8.62662 7.8041C8.62662 5.60263 8.62662 3.40473 8.62662 1.20326C8.62662 0.825048 8.77656 0.682327 9.16212 0.689463C12.7 0.771527 15.631 3.3405 16.1701 6.8443C16.2165 7.15472 16.2343 7.47227 16.2486 7.78983C16.2664 8.1609 16.1129 8.31433 15.7452 8.31433C14.6385 8.31433 13.5318 8.31433 12.4251 8.31433ZM9.44415 1.52438V7.35096C9.44415 7.42946 9.50841 7.49725 9.59052 7.49725H15.4096C15.2561 4.37166 12.6465 1.69564 9.44415 1.52795V1.52438Z" fill={color} stroke={color} stroke-width="0.3"/>
    </svg>
    
  )
}

export default MailIcon;

