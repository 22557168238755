import { GetFunction, SetFunction, ActionsFactory, ModelStore, ID, NotebookTemplateContent, SlideIndex, Level, Slide, DynamicSlideViewMeta, TextArea, PageSlide, UploadSlide } from "../store-types";
import { current, Draft, produce } from "immer";
import defaultPageName from "../helpers/default-page-name";
import {v4 as uuid} from 'uuid';


const notebooksEditorActions : ActionsFactory<ModelStore['actions']['notebookEditor'], ModelStore> =  (set : SetFunction<ModelStore>, get : GetFunction<ModelStore>) => ({
  resetNotebook : () => {
    set(state => {
      state.notebooks.editor.notebookId = undefined;
      state.notebooks.editor.historyPosition = 0;
      state.notebooks.editor.history = [];
      state.notebooks.editor.lastSaved = undefined;
      state.notebooks.editor.meta = {};
      state.notebooks.editor.selection = {};
    })
  },
  selectNotebook : (notebookId : ID) => {
    set(state => {
      state.notebooks.editor.notebookId = notebookId;
      state.notebooks.editor.historyPosition = 0;
      state.notebooks.editor.history = [state.notebooks.templatesContent[notebookId]];
      state.notebooks.editor.lastSaved = state.notebooks.templatesContent[notebookId];
      state.notebooks.editor.meta = {};
      state.notebooks.editor.selection = {};
    })
  },

  addUpload : (slideIndex : SlideIndex, uploadId : ID, level : Level = 1) => {
    get().actions.notebookEditor.modify((current) => {
      // Store uploadId in order to perform cleanup
      current.uploadIds = [...(current.uploadIds || []), uploadId];

      const slides = [...current.slides || []];
      slides.splice(slideIndex.position, 0, {
        type : 'upload',
        uploadId,
        label : '',
        level,
      })
      current.slides = slides;
    })
  },

  addUploadAfterAlternatives : (slideIndex : SlideIndex, uploadId : ID, level : Level) => {
    get().actions.notebookEditor.modify((current) => {
      // Store uploadId in order to perform cleanup
      current.uploadIds = [...(current.uploadIds || []), uploadId];

      const slides = [...current.slides || []];

      const alternativeGroupId = slides[slideIndex.position]?.alternativeGroupId;
      let lastAlternativeIndex = slideIndex.position;
      slides.forEach((slide, index) => {
        if(slide.alternativeGroupId === alternativeGroupId && index > lastAlternativeIndex) {
          lastAlternativeIndex = index;
        }
      })


      slides.splice(lastAlternativeIndex + 1, 0, {
        type : 'upload',
        uploadId,
        label : '',
        level,
      })
      current.slides = slides;
    })
  },


  replaceSlide : (slideIndex: SlideIndex, uploadId : ID) => {
    get().actions.notebookEditor.modify((current) => {
      // Store uploadId in order to perform cleanup
      current.uploadIds = [...(current.uploadIds || []), uploadId];

      const slides = [...current.slides || []];
      const slide = slides[slideIndex.position];
      slides.splice(slideIndex.position, 1, {
        type : 'upload',
        uploadId,
        label : slide.label,
        level : slide.level
      })
      current.slides = slides;
    })
  },

  appendContentSlides : (slideIndex : SlideIndex) => {
    const selection = get().notebooks.editor.selection;
    const target = get().actions.notebookEditor.slideAtIndex(slideIndex);

    // Do nothing if the target is not a page slide or if it is selected.
    // Normaly the canDrop method of DND should avoid this.
    if(target.type !== 'page' || selection[target.pageId]) {return;}

    get().actions.notebookEditor.modify(current => {
      const slides = current.slides || [];

      // Sorted list of selected slides
      const selectedSlides = slides.filter(slide => slide.type === 'page' && selection[slide.pageId]);
      const modifiedSlides = slides.filter(slide => slide.type !== 'page' || !selection[slide.pageId]);
      const targetIndex = modifiedSlides.findIndex(slide => slide.type === 'page' && slide.pageId === target.pageId);

      selectedSlides.forEach(slide => slide.level = 4);

      modifiedSlides.splice(targetIndex + 1, 0, ...selectedSlides);

      current.slides = modifiedSlides;
    })
  },

  appendContentSlidesAfterAlternatives : (slideIndex : SlideIndex) => {
    const selection = get().notebooks.editor.selection;
    const target = get().actions.notebookEditor.slideAtIndex(slideIndex);

    // Do nothing if the target is not a page slide or if it is selected.
    // Normaly the canDrop method of DND should avoid this.
    if(target.type !== 'page' || selection[target.pageId]) {return;}

    get().actions.notebookEditor.modify(current => {
      const slides = current.slides || [];

      // Sorted list of selected slides
      const selectedSlides = slides.filter(slide => slide.type === 'page' && selection[slide.pageId]);
      const modifiedSlides = slides.filter(slide => slide.type !== 'page' || !selection[slide.pageId]);
      const targetIndex = modifiedSlides.findLastIndex(slide => slide.type === 'page' && slide.alternativeGroupId === target.alternativeGroupId);

      selectedSlides.forEach(slide => {
        slide.level = 4
        slide.alternativeGroupId = target.alternativeGroupId;
        delete slide.linkedGroupdId;
      });

      modifiedSlides.splice(targetIndex + 1, 0, ...selectedSlides);

      current.slides = modifiedSlides;
    })

    get().actions.notebookEditor.select(target.pageId);
  },

  appendContentSlidesAfterLinked : (slideIndex : SlideIndex) => {
    const selection = get().notebooks.editor.selection;
    const target = get().actions.notebookEditor.slideAtIndex(slideIndex);

    // Do nothing if the target is not a page slide or if it is selected.
    // Normaly the canDrop method of DND should avoid this.
    if(target.type !== 'page' || selection[target.pageId]) {return;}

    get().actions.notebookEditor.modify(current => {
      const slides = current.slides || [];

      // Sorted list of selected slides
      const selectedSlides = slides.filter(slide => slide.type === 'page' && selection[slide.pageId]);
      const modifiedSlides = slides.filter(slide => slide.type !== 'page' || !selection[slide.pageId]);
      const targetIndex = modifiedSlides.findIndex(slide => slide.type === 'page' && slide.pageId === target.pageId);

      selectedSlides.forEach(slide => {
        slide.level = 4
        slide.linkedGroupdId = target.linkedGroupdId;
        delete slide.alternativeGroupId;
      });

      modifiedSlides.splice(targetIndex + 1, 0, ...selectedSlides);

      current.slides = modifiedSlides;
    })

    get().actions.notebookEditor.select(target.pageId);
  },

  unGroupSlides : (slideIndex : SlideIndex) => {
    get().actions.notebookEditor.modify(current => {
      const slides = current.slides || [];
      let idx = slideIndex.position;

      while(idx < slides.length && slides[idx].level === 4) {
        slides[idx].level = 3
        idx++;
      }
    })
  },

  moveSlides : (slideIndex : SlideIndex) => {
    const selection = get().notebooks.editor.selection;
    const target = get().actions.notebookEditor.slideAtIndex(slideIndex);

    // Do nothing if the target is not a page slide or if it is selected.
    // Normaly the canDrop method of DND should avoid this.
    if(target.type !== 'page' || selection[target.pageId]) {return;}

    get().actions.notebookEditor.modify(current => {
      const slides = current.slides || [];

      // Sorted list of selected slides
      const selectedSlides = slides.filter(slide => slide.type === 'page' && selection[slide.pageId]);
      const modifiedSlides = slides.filter(slide => slide.type !== 'page' || !selection[slide.pageId]);
      const targetIndex = modifiedSlides.findIndex(slide => slide.type === 'page' && slide.pageId === target.pageId);

      selectedSlides.forEach(slide => slide.level = target.level);

      modifiedSlides.splice(targetIndex + 1, 0, ...selectedSlides);

      current.slides = modifiedSlides;
    })
  },

  moveSlidesAfterAlternatives : (slideIndex : SlideIndex) => {
    const selection = get().notebooks.editor.selection;
    const target = get().actions.notebookEditor.slideAtIndex(slideIndex);

    // Do nothing if the target is not a page slide or if it is selected.
    // Normaly the canDrop method of DND should avoid this.
    if(target.type !== 'page' || selection[target.pageId]) {return;}

    get().actions.notebookEditor.modify(current => {
      const slides = current.slides || [];

      // Sorted list of selected slides
      const selectedSlides = slides.filter(slide => slide.type === 'page' && selection[slide.pageId]);
      const modifiedSlides = slides.filter(slide => slide.type !== 'page' || !selection[slide.pageId]);
      const targetIndex = modifiedSlides.findLastIndex(slide => slide.type === 'page' && slide.alternativeGroupId === target.alternativeGroupId);

      selectedSlides.forEach(slide => slide.level = target.level);

      modifiedSlides.splice(targetIndex + 1, 0, ...selectedSlides);

      current.slides = modifiedSlides;
    })
  },

  moveSlidesAfterLinked : (slideIndex : SlideIndex) => {
    const selection = get().notebooks.editor.selection;
    const target = get().actions.notebookEditor.slideAtIndex(slideIndex);

    // Do nothing if the target is not a page slide or if it is selected.
    // Normaly the canDrop method of DND should avoid this.
    if(target.type !== 'page' || selection[target.pageId]) {return;}

    let lastOfGroup = false;

    get().actions.notebookEditor.modify(current => {
      const slides = current.slides || [];
      lastOfGroup = slides.findLastIndex(slide => slide.type === 'page' && slide.linkedGroupdId === target.linkedGroupdId) === slideIndex.position;

      // Sorted list of selected slides
      const selectedSlides = slides.filter(slide => slide.type === 'page' && selection[slide.pageId]);
      const modifiedSlides = slides.filter(slide => slide.type !== 'page' || !selection[slide.pageId]);
      const targetIndex = modifiedSlides.findIndex(slide => slide.type === 'page' && slide.pageId === target.pageId);

      selectedSlides.forEach(slide => {
        slide.level = 4
        // Do not merge slides into linked group for last slide of group
        if(!lastOfGroup) {
          slide.linkedGroupdId = target.linkedGroupdId;
          delete slide.alternativeGroupId;
        }
      });

      modifiedSlides.splice(targetIndex + 1, 0, ...selectedSlides);

      current.slides = modifiedSlides;
    })

    if(!lastOfGroup) {
      get().actions.notebookEditor.select(target.pageId);
    }
  },

  insertSummary : (slideIndex : SlideIndex) => {
    const target = get().actions.notebookEditor.slideAtIndex(slideIndex);
    const notebookId = get().notebooks.editor.notebookId;

    get().actions.notebookEditor.modify(current => {
      const slides = current.slides || [];

      slides.splice(slideIndex.position + 1, 0, {
        type : 'page',
        isSummary : true,
        pageId : -Date.now(), //`summary_${notebookId}_${Date.now()}`,
        label : 'Sommaire',
        level : target.level
      })
    })
  },

  setLabel : (slideIndex : SlideIndex, label : string) => {
    get().actions.notebookEditor.modify((current) => {
      const slides = current.slides || [];

      const slide = slides[slideIndex.position];
      slide.label = label;
    });
  },

  setColor : (slideIndex : SlideIndex, color : string) => {
    get().actions.notebookEditor.modify(current => {
      const slides = current.slides || [];
      const slide = slides[slideIndex.position];

      if(slide?.alternativeGroupId) {
        slides
          .filter(s => s.alternativeGroupId === slide?.alternativeGroupId)
          .forEach(s => s.color = color)
      }
      else {
        slide.color = color;
      }
    })
  },

  setMandatory : (mandatory : boolean) => {
    get().actions.notebookEditor.modify(current => {
      const selectedPagesIds = Object.entries(get().notebooks.editor.selection).filter(([_, selected]) => selected).map(([pageId]) => pageId);

      if(selectedPagesIds.length) {
        const slides = current.slides;
        slides?.forEach(slide => {
          if(slide.type === 'page' && selectedPagesIds.includes(`${slide.pageId}`)) {
            slide.mandatory = mandatory;
          }
        })
      }
    })
  },

  setTextArea : (slideIndex : SlideIndex, textArea : TextArea | null) => {
    get().actions.notebookEditor.modify(current => {
      const slides = current.slides || [];
      const slide = slides[slideIndex.position];
      slide.textArea = textArea || undefined;
    })
  },

  setTags : (slideIndex : SlideIndex, tags : ID[]) => {
    get().actions.notebookEditor.modify(current => {
      const slides = current.slides || [];
      const slide = slides[slideIndex.position];

      // If slide is part of a linked group, then add tags to all members of the group
      if(slide.linkedGroupdId) {
        slides.forEach(s => {
          if(s.linkedGroupdId === slide.linkedGroupdId) {
            s.tags = tags;
          }
        })
      }
      else {
        slide.tags = tags;
      }
    })
  },

  copyTags : (tags : ID[]) => {
    set(state => {
      state.notebooks.editor.tagsClipboard = tags;
    })
  },

  removeSlide : (slideIndex : SlideIndex) => {
    get().actions.notebookEditor.modify((current) => {
      current.slides?.splice(slideIndex.position, 1);
    })
  },

  removeAllAlternativeSlides : (slideIndex : SlideIndex) => {
    get().actions.notebookEditor.modify((current) => {
      const uuid = current.slides?.[slideIndex.position]?.alternativeGroupId;

      if(!uuid) {return;}

      current.slides = (current.slides || []).filter(slide => slide.alternativeGroupId !== uuid);
    })
  },

  indent : (slideIndex : SlideIndex) => {
    get().actions.notebookEditor.modify((current) => {
      if(current.slides?.[slideIndex.position]) {
        const alternativeGroupId = current.slides?.[slideIndex.position]?.alternativeGroupId;
        const level = current.slides?.[slideIndex.position].level;

        if(alternativeGroupId) {
          current.slides?.filter(slide => slide.alternativeGroupId === alternativeGroupId).forEach(slide => {
            slide.level = level === 3 ? 3 : level+1 as Level;
          })
        }
        else {
          current.slides[slideIndex.position].level = level === 3 ? 3 : level+1 as Level;
        }
      }
    })
  },

  deindent : (slideIndex : SlideIndex) => {
    get().actions.notebookEditor.modify((current) => {
      if(current.slides?.[slideIndex.position]) {
        const alternativeGroupId = current.slides?.[slideIndex.position]?.alternativeGroupId;
        const level = current.slides?.[slideIndex.position].level;

        if(alternativeGroupId) {
          current.slides?.filter(slide => slide.alternativeGroupId === alternativeGroupId).forEach(slide => {
            slide.level = level === 1 ? 1 : level-1 as Level;
          })
        }
        else {
          current.slides[slideIndex.position].level = level === 1 ? 1 : level-1 as Level;
        }
      }
    })
  },

  groupAlternatives : (slideIndex : SlideIndex) => {
    const selection = get().notebooks.editor.selection;
    const target = get().actions.notebookEditor.slideAtIndex(slideIndex);

    // Do nothing if the target is not a page slide.
    if(target.type !== 'page') {return;}

    get().actions.notebookEditor.modify(current => {
      const slides = current.slides || [];

      // Sorted list of selected slides
      const selectedSlides = slides.filter(slide => slide.type === 'page' && selection[slide.pageId] && slide.pageId !== target.pageId);
      const modifiedSlides = slides.filter(slide => slide.type !== 'page' || !selection[slide.pageId] || slide.pageId === target.pageId);
      const targetIndex = modifiedSlides.findIndex(slide => slide.type === 'page' && slide.pageId === target.pageId);

      selectedSlides.forEach(slide => slide.level = target.level);

      modifiedSlides.splice(targetIndex + 1, 0, ...selectedSlides);

      current.slides = modifiedSlides;

      const writableTarget = slides.find(slide => slide.type === 'page' && slide.pageId === target.pageId);

      // Unique id added to slides to group them into an alternative slides menu
      const alternativeGroupId = uuid();
      selectedSlides.forEach(slide => slide.alternativeGroupId = alternativeGroupId);
      writableTarget && (writableTarget.alternativeGroupId = alternativeGroupId);

      // Remove the unique id that groups slides into a linked slides set
      selectedSlides.forEach(slide => delete slide.linkedGroupdId);
      writableTarget && delete writableTarget.linkedGroupdId;
    })
  },

  ungroupAlternatives : (uuid : string) => {
    get().actions.notebookEditor.modify(current => {
      const slides = current.slides || [];

      slides.forEach(slide => {
        if(slide.alternativeGroupId === uuid) {
          delete slide.alternativeGroupId
        }
      })
    });
  },

  alternativeType : (uuid : string, multichoice : boolean) => {
    get().actions.notebookEditor.modify(current => {
      if(!current.multichoiceSlidesGroups) {
        current.multichoiceSlidesGroups = {};
      }

      current.multichoiceSlidesGroups[uuid] = multichoice;
    });
  },

  linkSlides : (slideIndex : SlideIndex) => {
    const selection = get().notebooks.editor.selection;

    get().actions.notebookEditor.modify(current => {
      const slides = current.slides || [];

      // Target is the first slide in selection
      const target : PageSlide | undefined = slides.filter((slide) : slide is PageSlide => slide.type === "page").find((slide) => selection[slide.pageId] && slide.pageId);

      // Sorted list of selected slides
      const selectedSlides = slides.filter(slide => slide.type === 'page' && selection[slide.pageId] && slide.pageId !== target?.pageId);
      const modifiedSlides = slides.filter(slide => slide.type !== 'page' || !selection[slide.pageId] || slide.pageId === target?.pageId);
      const targetIndex = modifiedSlides.findIndex(slide => slide.type === 'page' && slide.pageId === target?.pageId);

      selectedSlides.forEach(slide => slide.level = 4);

      modifiedSlides.splice(targetIndex + 1, 0, ...selectedSlides);

      current.slides = modifiedSlides;

      const writableTarget = slides.find(slide => slide.type === 'page' && slide.pageId === target?.pageId);
      if(writableTarget) {writableTarget.level = 4;}

      // Unique id added to slides to group them into an alternative slides menu
      const linkedGroupdId = uuid();
      selectedSlides.forEach(slide => slide.linkedGroupdId = linkedGroupdId);
      writableTarget && (writableTarget.linkedGroupdId = linkedGroupdId);

      // Remove the unique id that groups slides into a alternatives group
      selectedSlides.forEach(slide => delete slide.alternativeGroupId);
      writableTarget && delete writableTarget.alternativeGroupId;

      selectedSlides.forEach(slide => {
        // Copy targets tag to each selected slide
        slide.tags = target?.tags;

        // Linked slide should not be compulsory
        delete slide.mandatory;
      });
    })
  },

  unlinkSlides : (uuid : string) => {
    get().actions.notebookEditor.modify(current => {
      const slides = current.slides || [];

      slides.forEach(slide => {
        if(slide.linkedGroupdId === uuid) {
          delete slide.linkedGroupdId
        }
      })
    });
  },

  extractFromAlternatives : (slideIndex : SlideIndex) => {
    get().actions.notebookEditor.modify(current => {
      const slides = current.slides || [];
      const target = slides[slideIndex.position];
      const lastAlternativeIndex = slides.findLastIndex(slide => slide.type === 'page' && slide.alternativeGroupId === target?.alternativeGroupId);

      slides.splice(lastAlternativeIndex + 1, 0, target);
      slides.splice(slideIndex.position, 1)

      current.slides = slides;

      delete target.alternativeGroupId;
    })
  },

  modify : (modifier : (content : Draft<NotebookTemplateContent>) => void) => {
    if(get().notebooks.editor.readonly) {return;}

    set(state => {
      const {history, historyPosition} = state.notebooks.editor;
      const current = history[historyPosition];

      const newContent = produce(current, modifier);

      state.notebooks.editor.history = [newContent, ...history.slice(historyPosition)];
      if(historyPosition) {
        state.notebooks.editor.historyPosition = 0;
      }
    })
  },

  modifyHistoryInPlace : (modifier : (content : Draft<NotebookTemplateContent>) => void) => {
    set(state => {
      const {history} = state.notebooks.editor;
      history.forEach(modifier);
    })
  },

  toggleCollapse : (pageId : ID) => {
    set(state => {
      const current = state.actions.notebookEditor.current();
      const alternativeGroupId = (current.slides || []).find(slide => slide.type === 'page' && slide.pageId === pageId)?.alternativeGroupId;
      const collapsed = !state.notebooks.editor.meta[pageId]?.collapsed;

      if(alternativeGroupId) {
        (current.slides || []).filter((s) : s is PageSlide => s.type === 'page' && s.alternativeGroupId === alternativeGroupId).forEach(slide => {
          state.notebooks.editor.meta[slide.pageId] = {...state.notebooks.editor.meta[slide.pageId], collapsed}
        })
      }
      else {
        state.notebooks.editor.meta[pageId] = {...state.notebooks.editor.meta[pageId], collapsed}
      }
    })
  },

  toggleSelection : (pageId : ID) => {
    set(state => {
      const current = state.actions.notebookEditor.current();
      const alternativeGroupId = (current.slides || []).find(slide => slide.type === 'page' && slide.pageId === pageId)?.alternativeGroupId;
      const linkedGroupdId = (current.slides || []).find(slide => slide.type === 'page' && slide.pageId === pageId)?.linkedGroupdId;
      const selectionState = !state.notebooks.editor.selection[pageId];

      if(alternativeGroupId) {
        (current.slides || []).forEach(slide => {
          if(slide.type === 'page' && slide.alternativeGroupId === alternativeGroupId) {
            state.notebooks.editor.selection[slide.pageId] = selectionState;
          }
        })
        state.notebooks.editor.selection[pageId] = selectionState;
      }
      else if (linkedGroupdId) {
        (current.slides || []).forEach(slide => {
          if(slide.type === 'page' && slide.linkedGroupdId === linkedGroupdId) {
            state.notebooks.editor.selection[slide.pageId] = selectionState;
          }
        })
        state.notebooks.editor.selection[pageId] = selectionState;
      }
      else {
        state.notebooks.editor.selection[pageId] = selectionState;
      }
    })
  },

  select : (pageId : ID, value : boolean = true) => {
    set(state => {
      const current = state.actions.notebookEditor.current();
      const alternativeGroupId = (current.slides || []).find(slide => slide.type === 'page' && slide.pageId === pageId)?.alternativeGroupId;
      const linkedGroupdId = (current.slides || []).find(slide => slide.type === 'page' && slide.pageId === pageId)?.linkedGroupdId;

      let pagesIds =
        alternativeGroupId ?
          (current.slides || [])
            .filter((slide) : slide is PageSlide => slide.type === 'page' && slide.alternativeGroupId === alternativeGroupId)
            .map(slide => slide.pageId):
        linkedGroupdId ?
          (current.slides || [])
            .filter((slide) : slide is PageSlide => slide.type === 'page' && slide.linkedGroupdId === linkedGroupdId)
            .map(slide => slide.pageId):
        [pageId];

      state.notebooks.editor.selection = pagesIds.reduce((selection, pageId) => {
        selection[pageId] = value;
        return selection;
      }, {} as Record<string, boolean>);
    })
  },

  addToSelection : (pageId : ID) => {
    set(state => {
      const current = state.actions.notebookEditor.current();
      const alternativeGroupId = (current.slides || []).find(slide => slide.type === 'page' && slide.pageId === pageId)?.alternativeGroupId;
      const linkedGroupdId = (current.slides || []).find(slide => slide.type === 'page' && slide.pageId === pageId)?.linkedGroupdId;

      if(alternativeGroupId) {
        (current.slides || []).forEach(slide => {
          if(slide.type === 'page' && slide.alternativeGroupId === alternativeGroupId) {
            state.notebooks.editor.selection[slide.pageId] = true;
          }
        })
        state.notebooks.editor.selection[pageId] = true;
      }
      else if(linkedGroupdId) {
        (current.slides || []).forEach(slide => {
          if(slide.type === 'page' && slide.linkedGroupdId === linkedGroupdId) {
            state.notebooks.editor.selection[slide.pageId] = true;
          }
        })
        state.notebooks.editor.selection[pageId] = true;
      }
      else {
        state.notebooks.editor.selection[pageId] = true;
      }
    })
  },

  addRangeToSelection : (pageId : ID) => {
    set(state => {
      const slides = state.actions.notebookEditor.current()?.slides || [];
      const selection = state.notebooks.editor.selection;
      // First slide of the current selection
      let first = slides.findIndex(slide => slide.type === 'page' && selection[slide.pageId]);
      // Last slide of the current selection
      let last  = slides.findLastIndex(slide => slide.type === 'page' && selection[slide.pageId]);

      // Push first/last selected slide to the beginning/end of alternative or linked slide groups
      if(slides[first]?.alternativeGroupId) {
        first = slides.findIndex(slide => slide.type === 'page' && slide.alternativeGroupId === slides[first].alternativeGroupId)
      }
      else if(slides[first]?.linkedGroupdId) {
        first = slides.findIndex(slide => slide.type === 'page' && slide.linkedGroupdId === slides[first].linkedGroupdId)
      }
      else if(slides[last]?.alternativeGroupId) {
        last = slides.findLastIndex(slide => slide.type === 'page' && slide.alternativeGroupId === slides[last].alternativeGroupId)
      }
      if(slides[last]?.linkedGroupdId) {
        last = slides.findLastIndex(slide => slide.type === 'page' && slide.linkedGroupdId === slides[last].linkedGroupdId)
      }

      // Clicked slide
      const target = slides.findIndex(slide => slide.type === 'page' && slide.pageId === pageId);

      let firstTarget = target
      let lastTarget = target;

      if(slides[target]?.alternativeGroupId) {
        firstTarget = slides.findIndex(slide => slide.type === 'page' && slide.alternativeGroupId === slides[target].alternativeGroupId)
      }
      if(slides[target]?.linkedGroupdId) {
        firstTarget = slides.findIndex(slide => slide.type === 'page' && slide.linkedGroupdId === slides[target].linkedGroupdId)
      }

      if(slides[target]?.alternativeGroupId) {
        lastTarget = slides.findLastIndex(slide => slide.type === 'page' && slide.alternativeGroupId === slides[target].alternativeGroupId)
      }
      if(slides[target]?.linkedGroupdId) {
        lastTarget = slides.findLastIndex(slide => slide.type === 'page' && slide.linkedGroupdId === slides[target].linkedGroupdId)
      }

      // If there is no selection, then do nothing
      if(first === -1) {
        // state.actions.notebookEditor.select(pageId);
        return;
      }

      let from : number, to : number;
      if(first < target) {
        from = first;
        to   = lastTarget;
      }
      else {
        from = firstTarget;
        to   = last;
      }

      const newSelection : Record<string, boolean> = {};
      slides.forEach((slide, index) => {
        if(slide.type === 'page' && index >= from && index <= to) {
          newSelection[slide.pageId] = true;
        }
      })

      state.notebooks.editor.selection = newSelection;
    })
  },

  deselect : (pageId : ID) => {
    set(state => {
      state.actions.notebookEditor.select(pageId, false);
    })
  },

  undo : () => {
    if(get().notebooks.editor.readonly) {return;}

    set(state => {
      const {history, historyPosition} = state.notebooks.editor;
      if(historyPosition < history.length - 1) {
        state.notebooks.editor.historyPosition++;
      }
    })
  },
  redo : () => {
    if(get().notebooks.editor.readonly) {return;}

    set(state => {
      const {historyPosition} = state.notebooks.editor;
      if(historyPosition > 0) {
        state.notebooks.editor.historyPosition--;
      }
    })
  },
  save : async () => {
    if(get().notebooks.editor.readonly) {return;}

    const current = get().actions.notebookEditor.current();
    const id = get().notebooks.editor.notebookId;
    if(id) {
      set(state => {state.notebooks.editor.readonly = true});
      await get().actions.notebooks.saveTemplateContent(id, current)
      set(state => {state.notebooks.editor.lastSaved = current})
      set(state => {state.notebooks.editor.readonly = false});
    }
  },

  current : () => {
    const state = get();
    const {history, historyPosition} = state.notebooks.editor;
    const current = history[historyPosition];
    return current;
  },

  slideAtIndex : (slideIndex : SlideIndex) => {
    const current = get().actions.notebookEditor.current();
    const slides = current.slides || [];

    const slide = slides[slideIndex.position];
    // if(slideIndex.isAlternative && slide.alternatives) {
    //   return slide.alternatives[slideIndex.alternativePosition];
    // }
    // else {
      return slide;
    // }
  },

  /**
   * Slides metadata used to show/hide collapsed slides, show links
   * between slides etc.
   * This is necessary beacause data is inerently a tree but we decided
   * to store it as a flat array to ease insert/move/delete operations.
   */
  viewMeta : () => {
    const current = get().actions.notebookEditor.current();
    const meta = get().notebooks.editor.meta;
    if(!current) {return [];}

    const slides = current.slides || [];

    // Creates an array of empty meta. Using map to create different objects. Do not use fill,
    // otherwise the same object will be referenced.
    const result : DynamicSlideViewMeta[] = new Array(slides.length || 0).fill(null).map(() => ({}));

    // Last collapsed level. Starts at 10, so that slide[].level < levelOfLastCollapsedSlide, which
    // means that nothing is collapsed.
    let levelOfLastCollapsedSlide : number = 10;

    // Array of bolleans that indicate when a given slide should show a link from a predecessor to a successor
    let slideLinksLevels : boolean[] = [];

    // Store the id of the alternative groups already rendered
    // Only the first slide of an alternative group is rendered
    const renderedAlternativesUuid : Record<string, boolean> = {};

    let positionInContentGroup : number = -1;
    let lastLinkedGroupdId : string | undefined;

    slides.forEach((slide, index) => {
      if(slides[index+1] && slides[index+1].level > slide.level) {
        result[index].hasChildren = true;
      }

      /*
      Handle level collapse by keeping track of the last collapsed level in levelOfLastCollapsedSlide var
      */
      const collapsed = slide.type === 'page' && !!meta[slide.pageId]?.collapsed;
      if(levelOfLastCollapsedSlide > slide.level) {
        if(collapsed) {
          /*
          We are in a lower level than the last collapsted slide. Since we are collapsed
          bring levelOfLastCollapsedSlide down to our level to hide all slides lower that the current one
           */
          levelOfLastCollapsedSlide = slide.level
        }
      }
      else if(levelOfLastCollapsedSlide === slide.level) {
        if(!collapsed) {
          /*
            If the last collapsed level was on the same level as the current slide, and
            the current slide is not collapsed, then push levelOfLastCollapsedSlide away to show the slides
            below.
           */
          levelOfLastCollapsedSlide = 10;
        }
      }
      else {
        /*
          In this case we are in a level higher than the active hideLeve, so we should be hidden
         */
        result[index].hidden = true;
      }


      // Keep only links at level lower than the current one. Set higher level to false
      slideLinksLevels = slideLinksLevels.map((v,i) => i < slide.level && !!v);

      result[index].link1 = !!slideLinksLevels[1];
      result[index].link2 = !!slideLinksLevels[2];
      result[index].link3 = !!slideLinksLevels[3];

      // Iterate through following slides to determine links level
      if(slide.level < 4) {
        let next = index + 1;
        let lowest = 4;
        while(next < slides.length) {
          if(slides[next].level >= slide.level && slides[next].level < lowest) {
            slideLinksLevels[slides[next].level] = true;
            lowest = slides[next].level;
          }

          if(slides[next].level <= slide.level) {
            break
          }

          next++;
        }
      }

      // If slide is part of group of alternative slides...
      if(slide.alternativeGroupId) {
        // ... and is not first one of the group, then just hide it
        if(renderedAlternativesUuid[slide.alternativeGroupId]) {
          result[index].hiddenAlternative = true;
        }
        // ... otherwise, for the first slide, count the number of alternatives
        else {
          renderedAlternativesUuid[slide.alternativeGroupId] = true;
          result[index].alternativesCount = slides.filter(_s => _s.alternativeGroupId === slide.alternativeGroupId).length;
        }

        // Update hasChildren for grouped slides
        const nextSlide = slides[slides.findLastIndex(s => s.alternativeGroupId === slide.alternativeGroupId) + 1];
        if(nextSlide && nextSlide.level > slide.level) {
          result[index].hasChildren = true;
        }
      }

      if(slide.level === 4) {positionInContentGroup++;}
      else {positionInContentGroup = -1;}

      if(slide.level === 4 && slide.linkedGroupdId) {
        result[index].linkedLeftStyle = slide.linkedGroupdId !== lastLinkedGroupdId ? 'none' : 'middle'; //positionInContentGroup % 4 === 0 ? 'first' : 'middle';
        result[index].linkedRightStyle = slide.linkedGroupdId !== slides[index + 1]?.linkedGroupdId ? 'none' : 'middle'; //positionInContentGroup % 4 === 3 ? 'last' : 'middle';
        lastLinkedGroupdId = slide.linkedGroupdId;
      }
      else {
        lastLinkedGroupdId = undefined;
      }
    })

    return result;
  },

  isModified : () => {
    const state = get();
    return state.actions.notebookEditor.current() !== state.notebooks.editor.lastSaved;
  },

  updateUploads : () => {
    let modified = false;

    get().actions.notebookEditor.modifyHistoryInPlace(content => {
      let slides = [...content.slides || []];

      let idx = 0;
      while(idx < slides.length) {
        const slide = slides[idx];

        if(slide.type === 'upload') {
          if(get().uploads[slide.uploadId]?.status === 'done') {
            const newSlides : Slide[] = get().uploads[slide.uploadId].pages.map(page => ({
              type : 'page',
              label : defaultPageName(page.page),
              pageId : page.id,
              level : slide.level
            }))
            slides.splice(idx, 1, ...newSlides);
            modified = true;
          }
          else if (get().uploads[slide.uploadId]?.error && !slide.error) {
            const newSlide : UploadSlide = {
              ...slide,
              error : true
            }
            slides.splice(idx, 1, newSlide);
            modified = true;
          }
        }

        idx++;
      }

      if(modified) {
        content.slides = slides;
      }
    })

    return modified;
  }
})

export default notebooksEditorActions;