import { ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import {colors, ColorsNames, shadows, spacing, typography} from '../../styles';
import { Link } from 'react-router-dom';
import Typography from './typography';
import PlusIcon from '../../assets/icons/plus';
import ClosedFoodDome from '../../assets/images/closed-food-dome';
import useContextMenu, { ContextMenuOptions } from '../../hooks/use-context-menu';
import LockedIcon from '../../assets/icons/locked';

interface NotebookLinkProps {
  linkTo : string;
  onClick ?: (e : any) => void;
  title : string;
  imgUrl ?: string;
  new ?: boolean;
  draft ?: boolean;
  children ?: ReactNode;
  contextMenu ?: ContextMenuOptions<HTMLDivElement>;
  inactive ?: boolean;
  className ?: string;
}

const NotebookLink : React.FC<NotebookLinkProps> = (p) => {
  /*
    Context menu callbacks
   */
  const contextMenu = useContextMenu(p.contextMenu || {options:[]});

  return (
    <StyledLink to={p.linkTo} onClick={p.onClick}>
      <Container className={p.className}>
        <Card $new={!!p.new} {...contextMenu} className={p.inactive ? 'inactive' : ''}>
          {
            p.new ?
              <IconContainer><PlusIcon color={colors.primary}/></IconContainer> :
            p.imgUrl ?
              <Image src={p.imgUrl}/> :
              <ClosedFoodDome />
          }
          {
            p.draft ? <Draft>Brouillon</Draft> : null
          }
          {
            p.children ? p.children : null
          }
        </Card>
        <Title><Typography variant="textRegular" color="secondaryText">{p.title}</Typography></Title>
        {
          p.inactive ? <ArchivedIcon width="3rem" height="3rem" color='white'/> : null
        }
      </Container>
    </StyledLink>
  );
};

export default NotebookLink;


const StyledLink = styled(Link)`
  text-decoration: none;
`

const Container = styled.div`
  position : relative;
  margin-right : 1.5rem;
  margin-bottom : 2.7rem;
`;

const Card = styled.div<{$new : boolean}>`
  display: flex;
  justify-content: center;
  align-items: center;
  width : 20rem;
  height : 11.3rem;
  border-radius: 1rem;
  background-color : ${p => p.$new ? colors.background2 : colors.lightPrimary};
  overflow : hidden;

  &.inactive {
    filter : brightness(0.5);
  }
`

const Title = styled.div`
  margin-top: 0.5rem;
  & span {
    text-decoration-line: none !important;
  }
`

const IconContainer = styled.div`
  width : 1.2rem;
  height : 1.2rem;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Image = styled.img`
  width : 100%;
  height : 100%;
  object-fit: cover;
  border : none;
`

const Draft = styled.div`
  position : absolute;
  right : 1rem;
  top : 1rem;
  background-color: ${colors.accent};
  color : ${colors.whiteText};
  ${typography.smallTextBold.css};
  padding : 0.4rem 1rem;
  border-radius : 2rem;
`

const ArchivedIcon = styled(LockedIcon)`
  position : absolute;
  left : 1rem;
  top : 1rem;
`