import { ReactNode } from "react";
import { Link } from "react-router-dom"
import styled from "styled-components"

interface StyledLinkProps {
  to : string;
  className ?: string;
  children ?: ReactNode;
}

const StyledLink : React.FC<StyledLinkProps> = (p) => {
  return <Styled {...p} />
}

export default StyledLink;

const Styled = styled(Link)`
  cursor : default;
  text-decoration-line: none;
`