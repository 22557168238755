import styled from 'styled-components';
import PageLayout from '../components/layout/page-layout';
import { useTranslation } from 'react-i18next';
import { spacing } from '../styles';
import TextButton from '../components/atoms/text-button';
import { useAssertLogged } from '../hooks/use-assert-logged';
import NotebookNameInput from '../components/atoms/notebook-name-input';
import { useEffect, useState } from 'react';
import { useModelActions } from '../store/hooks/use-actions';
import { useNavigate, useParams } from 'react-router-dom';
import { ID, Offer, Proposal, User } from '../store/store-types';
import { PROPOSAL } from '../constants/routes';
import OfferCard from '../components/atoms/offer-card';
import useLogged from '../hooks/use-logged';

interface ProposalPageProps {

}

const ProposalPageOffer : React.FC<ProposalPageProps> = () => {
  useAssertLogged();
  const logged = useLogged();
  const {t} = useTranslation();
  const {id} = useParams();
  const navigate = useNavigate();
  const {loadOne, update} = useModelActions('proposals');
  const {load : loadOffers, update : updateOffer} = useModelActions('offers');
  const {loadTemplates} = useModelActions('notebooks');
  const [proposal, setProposal] = useState<Proposal | undefined>();
  const [offers, setOffers] = useState<Offer[]>([]);
  const [selected, setSelected] = useState<ID | undefined>();

  useEffect(() => {
    (async () => {
      if(id && logged) {
        const proposal = await loadOne(parseInt(`${id}`));

        if(proposal.offerId) {
          navigate(PROPOSAL.replace(':id', id))
        }

        await loadTemplates();
        const {data : offers} = await loadOffers();

        setProposal(proposal);
        setOffers(offers.filter(offer => !offer.draft && !offer.inactive));
      }
    })()
  }, [id, logged])

  const handleSelectionChange = (id : ID, value : boolean) => {
    setSelected(value ? id : undefined);
  }

  const handleSaveOffer = async () => {
    if(id && selected) {
      await update(parseInt(id), {
        offerId : selected,
        marketId : offers.find(offer => offer.id === selected)?.marketId
      })
      const offer = offers.find(offer => offer.id === selected);
      if(offer) {
        await updateOffer(selected, {
          proposalsCount : offer.proposalsCount + 1
        })
      }
      navigate(PROPOSAL.replace(':id', id))
    }
  }

  return (
    <PageLayout
      breadcrumbs={[t('pages.proposal.breadcrumb1')]}
      title={proposal?.name || ''}
      subtitle={t('pages.proposal.subtitle_offer')}
      controls={
        <>
          <TextButton disabled={!selected} onClick={handleSaveOffer} color="dark">{t('pages.proposal.continue')}</TextButton>
        </>
      }
    >
      <Container>
        {
          offers.map(offer => (
            <OfferCard
              key={offer.id}
              offer={offer}
              selected={selected === offer.id}
              onSelectionChange={(value) => {handleSelectionChange(offer.id, value)}}
              onRefresh={()=>{}}
              noActions
            />
          ))
        }
     </Container>
    </PageLayout>
  )
}

export default ProposalPageOffer;

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
;`

const TitleContainer = styled.div`
  display: flex;
`

const ProposalNameInput = styled(NotebookNameInput)`
  width : 42rem;
`




