import { ReactNode } from "react";
import Modal from "./modal";
import ErrorIcon from "../../assets/icons/error";
import { useTranslation } from "react-i18next";
import TextButton from "../atoms/text-button";
import Typography from "../atoms/typography";
import { spacing } from "../../styles";
import styled from "styled-components";

interface ErrorDialogProps {
  content : ReactNode;
  title ?: string;
  onClose : () => void;
}

const ErrorDialog : React.FC<ErrorDialogProps> = (p) => {
  const {t} = useTranslation();

  const content = typeof p.content === 'string' ?
    // Just use typography for texts
    <Typography variant="textRegular">{p.content}</Typography> :
    Array.isArray(p.content) && p.content.every(c => typeof c === 'string') ?
    // User array of paragraphs for array of strings
    p.content.map(c => <p key={c}><Typography variant="textRegular">{c}</Typography></p>) :
    // and just raw content otherwise
    p.content;

  return (
    <Modal
      persistent
      icon={<ErrorIcon />}
      title={p.title}
      controls={
        <TextButton color="dark" onClick={p.onClose}>{t('errors.continue')}</TextButton>
      }
    >
      <Container>
        {content}
      </Container>
    </Modal>
  )
}

export default ErrorDialog;

// Arrays of string are conterted into arrays of <p/>. Put a margin between them
const Container = styled.div`
  padding-top: ${spacing}rem;
  padding-bottom: ${spacing}rem;

  & p {
    margin-bottom: ${spacing}rem;
  }
`
