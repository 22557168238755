import { useEffect } from 'react';
import {createBrowserRouter, RouterProvider } from "react-router-dom";
import './App.css';
import LoginPage from './pages/login';
import HomePage from './pages/home';
import { useModelStore, useUserStore } from './store/store';
import LibraryPage from './pages/library';
import { CUSTOMER_VIEW, DASHBOARD, FILTERS, LIBRARY, LOGIN, MARKETS, NEW_NOTEBOOK, NEW_OFFER, NEW_PROPOSAL, NOTEBOOK_TEMPLATE, NOTEBOOK_TEMPLATE_TAGS, OFFER, OFFER_REVIEW, OFFERS, OVERLAY_SETTINGS, PROFILE, PROPOSAL, PROPOSAL_NOTEBOOK, PROPOSAL_OFFER, PROPOSAL_OFFER_NOTEBOOK, PROPOSALS, REGIONS, USERS } from './constants/routes';
import NewNotebookPage from './pages/new-notebook';
import MarketsPage from './pages/markets';
import NotebookPage from './pages/notebook';
import ProfilePage from './pages/profile';
import TestsPage from './pages/tests';
import UsersPage from './pages/users';
import RegionsPage from './pages/regions';
import FiltersPage from './pages/filters';
import TagsReviewPage from './pages/tags-review';
import OffersPage from './pages/offers';
import NewOfferPage from './pages/new-offer';
import OfferPage from './pages/offer';
import OfferReviewPage from './pages/offer-review';

import './providers/rtprovider';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ProposalsPage from './pages/proposals';
import NewProposalPage from './pages/new-proposal';
import ProposalPageOffer from './pages/proposal-offer';
import ProposalPage from './pages/proposal';
import ProposalNotebookPage from './pages/proposal-notebook';
import PublicPage from './pages/public';
import SummaryPDF from './pages/summary-pdf';
import { msal } from './providers/msal';
import DashboardPage from './pages/dashboard';
import OverlaySettingsPage from './pages/overlay-settings';

const router = createBrowserRouter([
  {path : '/', Component : HomePage},
  {path : DASHBOARD, Component : DashboardPage},
  {path : LOGIN, Component : LoginPage},
  {path : PROFILE, Component : ProfilePage},
  {path : LIBRARY, Component : LibraryPage},
  {path : NEW_NOTEBOOK, Component : NewNotebookPage},
  {path : NOTEBOOK_TEMPLATE, Component : NotebookPage},
  {path : NOTEBOOK_TEMPLATE_TAGS, Component : TagsReviewPage},
  {path : OFFERS, Component : OffersPage},
  {path : NEW_OFFER, Component : NewOfferPage},
  {path : OFFER, Component : OfferPage},
  {path : OFFER_REVIEW, Component : OfferReviewPage},
  {path : PROPOSALS, Component : ProposalsPage},
  {path : NEW_PROPOSAL, Component : NewProposalPage},
  {path : PROPOSAL_OFFER, Component : ProposalPageOffer},
  {path : PROPOSAL, Component : ProposalPage},
  {path : PROPOSAL_NOTEBOOK, Component : ProposalNotebookPage},
  {path : PROPOSAL_OFFER_NOTEBOOK, Component : ProposalNotebookPage},
  /*
    Admin pages
   */
  {path : MARKETS, Component : MarketsPage},
  {path : REGIONS, Component : RegionsPage},
  {path : FILTERS, Component : FiltersPage},
  {path : USERS,   Component : UsersPage},
  {path : OVERLAY_SETTINGS, Component : OverlaySettingsPage},
  /*
    Public pages
   */
  {path : CUSTOMER_VIEW, Component : PublicPage},

  {
    path : '/tests',
    Component : TestsPage
  }
// Demo routes, to be removed
// {
//   path : '/uploads',
//   Component : UploadsPage
// }, {
//   path : '/documents/:uploadId',
//   Component : UploadsPagesPage
// }, {
//   path : '/compositions',
//   Component : CompositionsPage
// }
])


function App() {
  const init = useModelStore(state => state.init);
  const logged = useUserStore(state => state.user.logged);

  // useEffect(() => {
  //   msal.login()
  // }, []);

  useEffect(() => {
    if(logged) {
      init();
    }
  }, [logged])

  return (
    <DndProvider backend={HTML5Backend}>
      <RouterProvider router={router} />
    </DndProvider>

  );
}

export default App;
