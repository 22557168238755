import styled from 'styled-components';
import { useAssertAdmin } from '../hooks/use-assert-admin';
import PageLayout from '../components/layout/page-layout';
import { useTranslation } from 'react-i18next';
import Typography from '../components/atoms/typography';
import NotebookLink from '../components/atoms/notebook-link';
import { NEW_NOTEBOOK, NOTEBOOK_TEMPLATE } from '../constants/routes';
import { useEffect, useState } from 'react';
import { useModelActions } from '../store/hooks/use-actions';
import { useModelStore, useUserStore } from '../store/store';
import configProvider from '../providers/configprovider';
import { colors, spacing } from '../styles';
import useLogged from '../hooks/use-logged';
import { Attachment, ID, NotebookTemplate, Video } from '../store/store-types';
import AttachmentModal from '../components/molecules/modals/attachment-modal';
import VideoModal from '../components/molecules/modals/video-modal';
import ToolButton from '../components/atoms/tool-button';
import PublishIcon from '../assets/icons/publish';
import ShareIcon from '../assets/icons/share';
import HTMLTypography from '../components/atoms/html-typography';
import ShareModal from '../components/molecules/modals/share-modal';
import { useAssertLogged } from '../hooks/use-assert-logged';
import NotebookPreviewModal from '../components/molecules/modals/notebook-preview-modal';
import useContextMenu from '../hooks/use-context-menu';

interface LibraryPageProps {

}

const LibraryPage : React.FC<LibraryPageProps> = (p) => {
  useAssertLogged();
  const logged = useLogged();
  const role = useUserStore(store => store.user.role);
  const {t} = useTranslation();
  const {loadTemplates, loadTemplateContent, archive : archiveNotebook, unarchive : unarchiveNotebook} = useModelActions('notebooks');
  const {resetNotebook} = useModelActions('notebookEditor');
  const {load : loadAttachments, loadPrivate : loadPrivateAttachments, publish : publishAttachment, share : shareAttachment, archive : archiveAttachment, archivePrivate : archivePrivateAttachment, unarchive : unarchiveAttachment, unarchivePrivate : unarchivePrivateAttachment} = useModelActions('attachments');
  const {load : loadVideos, loadPrivate : loadPrivateVideos, publish : publishVideo, share : shareVideo, archive : archiveVideo, archivePrivate : archivePrivateVideo, unarchive : unarchiveVideo, unarchivePrivate : unarchivePrivateVideo} = useModelActions('videos');

  const notebookTemplates = useModelStore(state => state.notebooks.templates);
  const offerNotebooks = Object.values(notebookTemplates).filter(notebook => notebook.type === 'offers');
  const annexesNotebooks = Object.values(notebookTemplates).filter(notebook => notebook.type === 'annexes');
  const attachments = useModelStore(state => state.attachments);
  const videos = useModelStore(state => state.videos);
  const privateVideos = useModelStore(state => state.privateVideos);
  const privateAttachments = useModelStore(state => state.privateAttachments);

  const [showAttachmentModal, setShowAttachmentModal] = useState<Attachment | true | undefined>();
  const [showPrivateAttachmentModal, setShowPrivateAttachmentModal] = useState<Attachment | true | undefined>();
  const [showVideoModal, setShowVideoModal] = useState<Video | true | undefined>();
  const [showPrivateVideoModal, setShowPrivateVideoModal] = useState<Video | true | undefined>();
  const [showShareVideoModal, setShowShareVideoModal] = useState<Video | false>(false);
  const [showShareAttachmentModal, setShowShareAttachmentModal] = useState<Video | false>(false);

  const [previewPages, setPreviewPages] = useState<ID[]>();
  const [previewTitle, setPreviewTitle] = useState<string>();

  const [showAll, setShowAll] = useState<boolean>(false);

  const {showDialog} = useModelActions('modals');

  useEffect(() => {
    if(logged) {
      loadTemplates();
      resetNotebook();
      loadAttachments();
      loadPrivateAttachments();
      loadVideos();
      loadPrivateVideos();
    }
  }, [logged]);


  const handlePublishVideo = (video : Video) => {
    showDialog({
      content : (
        <HTMLTypography variant="textRegular" color="text" html={t('modals.publishVideoDialog.content', {name : video.name})}/>
      ),
      callback : () => publishVideo(video)
    })
  }

  const handleShareVideo = (userId : ID, video : Video) => {
    shareVideo(video, userId)
  }

  const handlePublishAttachment = (attachment : Attachment) => {
    showDialog({
      content : (
        <HTMLTypography variant="textRegular" color="text" html={t('modals.publishAttachmentDialog.content', {name : attachment.name})}/>
      ),
      callback : () => publishAttachment(attachment)
    })
  }

  const handleShareAttachment = (userId : ID, attachment : Attachment) => {
    shareAttachment(attachment, userId)
  }


  const handleNotebookPreview = async (notebook : NotebookTemplate) => {
      const content = await loadTemplateContent(notebook.id);
      const pagesIds = (content?.slides || []).map(slide => slide.type === 'page' && !slide.isSummary ? slide.pageId : null).filter(p => !!p) as ID[];

      if(pagesIds.length) {
        setPreviewTitle(notebook.name);
        setPreviewPages(pagesIds);
      }
      else {
        setPreviewTitle(undefined);
        setPreviewPages(undefined);
      }
    }

  return (
    <PageLayout
      title={t('pages.library.name')}
      subtitle={t('pages.library.subtitle')}
      modal={
        showAttachmentModal ?
          <AttachmentModal attachment={showAttachmentModal && showAttachmentModal !== true ? showAttachmentModal : undefined} onClose={() => setShowAttachmentModal(undefined)}/> :
        showPrivateAttachmentModal ?
          <AttachmentModal private attachment={showPrivateAttachmentModal && showPrivateAttachmentModal !== true ? showPrivateAttachmentModal : undefined} onClose={() => setShowPrivateAttachmentModal(undefined)}/> :
        showVideoModal ?
          <VideoModal video={showVideoModal && showVideoModal !== true ? showVideoModal : undefined} onClose={() => setShowVideoModal(undefined)}/> :
        showPrivateVideoModal ?
          <VideoModal private video={showPrivateVideoModal && showPrivateVideoModal !== true ? showPrivateVideoModal : undefined} onClose={() => setShowPrivateVideoModal(undefined)}/> :
        showShareVideoModal ?
          <ShareModal onClose={() => setShowShareVideoModal(false)} onShare={(userId : ID) => handleShareVideo(userId, showShareVideoModal)}/> :
        showShareAttachmentModal ?
          <ShareModal onClose={() => setShowShareAttachmentModal(false)} onShare={(userId : ID) => handleShareAttachment(userId, showShareAttachmentModal)}/> :
        previewPages && previewPages.length && previewTitle ?
          <NotebookPreviewModal title={previewTitle} pages={previewPages} onClose={() => setPreviewPages(undefined)}/> :
          null
      }
      controls={
        [
          <Typography variant="smallTextBold" color="primary" onClick={() => setShowAll(s => !s)} style={{cursor : 'pointer'}}>
            {!showAll ? t('pages.library.show-all') : t('pages.library.hide-archived')}
          </Typography>
        ]
      }
    >
      <Container>
        {
          /* NOTEBOOK OFFRES */
        }
        {
          role !== 'superadmin' && role !== 'admin' ? null :
          <>
            <Typography variant="mediumTitle" color="text">{t('pages.library.notebook-offres')}</Typography>&nbsp;&nbsp;
            <NotebookLinksContainer>
              {
                offerNotebooks.filter(n => showAll || !n.inactive).map(notebook => (
                  <NotebookLink
                    key={notebook.id}
                    imgUrl={notebook.thumbnailPageId ? `${configProvider('PDF_SERVICE_URL')}/pages/${notebook.thumbnailPageId}/thumbnail` : ''}
                    title={notebook.name}
                    linkTo={NOTEBOOK_TEMPLATE.replace(':id', `${notebook.id}`)}
                    draft={notebook.draft !== false}
                    inactive={notebook.inactive}
                    contextMenu={{
                      options : [
                        notebook.inactive ?
                        {label:t('pages.library.unarchive'), callback : () => unarchiveNotebook(notebook.id)} :
                        {label:t('pages.library.archive'), callback : () => archiveNotebook(notebook.id)}
                      ]
                    }}
                  />
                ))
              }
              <NotebookLink
                new
                title={t('pages.library.new-notebook-offres')}
                linkTo={NEW_NOTEBOOK.replace(':type', 'offers')}
              />
            </NotebookLinksContainer>
          </>
        }

        {
          /* NOTEBOOK ANNEXES */
        }
        {
          role !== 'superadmin' && role !== 'admin' ? null :
          <>
            <Typography variant="mediumTitle" color="text">{t('pages.library.notebook-annexes')}</Typography>
            <NotebookLinksContainer>
              {
                annexesNotebooks.filter(n => showAll || !n.inactive).map(notebook => (
                  <NotebookLink
                    key={notebook.id}
                    imgUrl={notebook.thumbnailPageId ? `${configProvider('PDF_SERVICE_URL')}/pages/${notebook.thumbnailPageId}/thumbnail` : ''}
                    title={notebook.name}
                    linkTo={NOTEBOOK_TEMPLATE.replace(':id', `${notebook.id}`)}
                    draft={notebook.draft !== false}
                    inactive={notebook.inactive}
                    contextMenu={{
                      options : [
                        notebook.inactive ?
                        {label:t('pages.library.unarchive'), callback : () => unarchiveNotebook(notebook.id)} :
                        {label:t('pages.library.archive'), callback : () => archiveNotebook(notebook.id)}
                      ]
                    }}
                  />
                ))
              }
              <NotebookLink
                new
                title={t('pages.library.new-notebook-annexes')}
                linkTo={NEW_NOTEBOOK.replace(':type', 'annexes')}
                />
            </NotebookLinksContainer>
          </>
        }
        {
          /* NOTEBOOK GENERIQUES (Attachments) */
        }
        {
          role !== 'superadmin' && role !== 'admin' ? null :
          <>
            <Typography variant="mediumTitle" color="text">{t('pages.library.notebook-generiques')}</Typography>
            <NotebookLinksContainer>
              {
                attachments.filter(n => showAll || !n.inactive).map(attachment => (
                  <NotebookLink
                    key={attachment.id}
                    imgUrl={attachment.thumbnailStorageName ? `${configProvider('MODEL_API_URL')}/attached-notebooks/${attachment.id}/thumbnail.png` : ''}
                    title={attachment.name}
                    linkTo={''}
                    inactive={attachment.inactive}
                    onClick={() => {window.open(`${configProvider('MODEL_API_URL')}/attached-notebooks/${attachment.id}/content.pdf`, '_blank')}}
                    contextMenu={{
                      options : [
                        attachment.inactive ?
                        {label:t('pages.library.unarchive'), callback : () => unarchiveAttachment(attachment.id)} :
                        {label:t('pages.library.archive'), callback : () => archiveAttachment(attachment.id)}
                      ]
                    }}
                  />
                ))
              }
              <NotebookLink
                new
                title={t('pages.library.new-notebook-generiques')}
                linkTo={''}
                onClick={() => setShowAttachmentModal(true)}
              />
            </NotebookLinksContainer>
          </>
        }

        <Typography variant="mediumTitle" color="text">{t('pages.library.private-attachments')}</Typography>
        <NotebookLinksContainer>
          {
            privateAttachments.filter(n => showAll || !n.inactive).map(attachment => (
              <NotebookLink
                key={attachment.id}
                imgUrl={attachment.thumbnailStorageName ? `${configProvider('MODEL_API_URL')}/private-attached-notebooks/${attachment.id}/thumbnail.png` : ''}
                title={attachment.name}
                linkTo={''}
                inactive={attachment.inactive}
                onClick={() => {window.open(`${configProvider('MODEL_API_URL')}/private-attached-notebooks/${attachment.id}/content.pdf`, '_blank')}}
                contextMenu={{
                  options : [
                    attachment.inactive ?
                    {label:t('pages.library.unarchive'), callback : () => unarchivePrivateAttachment(attachment.id)} :
                    {label:t('pages.library.archive'), callback : () => archivePrivateAttachment(attachment.id)}
                  ]
                }}
              >
                <PublishToolButton color="dark" onClick={e => {e.stopPropagation(); e.preventDefault(); handlePublishAttachment(attachment);}}><PublishIcon color={colors.whiteText}/></PublishToolButton>
                <ShareToolButton color="accent" onClick={e => {e.stopPropagation(); e.preventDefault(); setShowShareAttachmentModal(attachment);}}><ShareIcon color={colors.whiteText}/></ShareToolButton>
              </NotebookLink>
            ))
          }
          <NotebookLink
            new
            title={t('pages.library.new-notebook-generiques')}
            linkTo={''}
            onClick={() => setShowPrivateAttachmentModal(true)}
          />
        </NotebookLinksContainer>




        {
          /* NOTEBOOK VIDEOS */
        }
        <Typography variant="mediumTitle" color="text">{t('pages.library.videos')}</Typography>
        <NotebookLinksContainer>
          {
            videos.filter(n => showAll || !n.inactive).map(video => (
              <NotebookLink
                key={video.id}
                imgUrl={video.thumbnailStorageName ? `${configProvider('MODEL_API_URL')}/public-videos/${video.id}/thumbnail.png` : ''}
                title={video.name}
                linkTo={''}
                inactive={video.inactive}
                onClick={() => {window.open(`${configProvider('MODEL_API_URL')}/public-videos/${video.id}/video`, '_blank')}}
                contextMenu={{
                  options : [
                    video.inactive ?
                    {label:t('pages.library.unarchive'), callback : () => unarchiveVideo(video.id)} :
                    {label:t('pages.library.archive'), callback : () => archiveVideo(video.id)}
                  ]
                }}
              />
            ))
          }
          {
            role !== 'superadmin' && role !== 'admin' ? null :
            <>
              <NotebookLink
                new
                title={t('pages.library.new-notebook-videos')}
                linkTo={''}
                onClick={() => setShowVideoModal(true)}
              />
            </>
          }
        </NotebookLinksContainer>

        <Typography variant="mediumTitle" color="text">{t('pages.library.privateVideos')}</Typography>
        <NotebookLinksContainer>
          {
            privateVideos.filter(n => showAll || !n.inactive).map(video => (
              <NotebookLink
                key={video.id}
                imgUrl={video.thumbnailStorageName ? `${configProvider('MODEL_API_URL')}/private-videos/${video.id}/thumbnail.png` : ''}
                title={video.name}
                linkTo={''}
                inactive={video.inactive}
                onClick={() => {window.open(`${configProvider('MODEL_API_URL')}/private-videos/${video.id}/video`, '_blank')}}
                contextMenu={{
                  options : [
                    video.inactive ?
                    {label:t('pages.library.unarchive'), callback : () => unarchivePrivateVideo(video.id)} :
                    {label:t('pages.library.archive'), callback : () => archivePrivateVideo(video.id)}
                  ]
                }}
              >
                <PublishToolButton color="dark" onClick={e => {e.stopPropagation(); e.preventDefault(); handlePublishVideo(video);}}><PublishIcon color={colors.whiteText}/></PublishToolButton>
                <ShareToolButton color="accent" onClick={e => {e.stopPropagation(); e.preventDefault(); setShowShareVideoModal(video);}}><ShareIcon color={colors.whiteText}/></ShareToolButton>
              </NotebookLink>
            ))
          }
          <NotebookLink
            new
            title={t('pages.library.new-notebook-videos')}
            linkTo={''}
            onClick={() => setShowPrivateVideoModal(true)}
          />
        </NotebookLinksContainer>
      </Container>
    </PageLayout>
  )
}

export default LibraryPage;

const Container = styled.div`
`

const NotebookLinksContainer = styled.div`
  margin-top : ${spacing}rem;
  margin-bottom : 4.4rem;
  display: flex;
  flex-direction : row;
  flex-wrap: wrap;
`

const PublishToolButton = styled(ToolButton)`
  position: absolute;
  right : 0.5rem;
  top : 6.8rem;
`

const ShareToolButton = styled(ToolButton)`
  position: absolute;
  right : 0.5rem;
  top : 2.3rem;
`