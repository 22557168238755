import { ReactNode } from "react";
import styled, { keyframes } from "styled-components";
import { colors, shadows, spacing, transitions } from "../../styles";
import Typography from "../atoms/typography";
import CloseIcon from "../../assets/icons/close";
import useEventDelegation from "../../hooks/use-event-delegation";

interface ModalProps {
  children : ReactNode;
  onClose ?: () => void;
  onSubmit ?: () => void;
  icon ?: ReactNode;
  title ?: string;
  subtitle ?: string;
  controls ?: ReactNode;
  headerControls ?: ReactNode;
  persistent ?: boolean; // Avoid closing with cross button or click on blurred background
}

const Modal : React.FC<ModalProps> = (p) => {
  const {delegatedHandler} = useEventDelegation('modal-frame');

  const handleClose = () => {
    if(!p.persistent) {
      p.onClose?.();
    }
  }

  return (
    <Container onClick={e => {handleClose(); e.stopPropagation();}} >
      <Frame onClick={e => {e.stopPropagation(); delegatedHandler(e);}}>
        <Header>
          {
            p.icon ? <IconContainer>{p.icon}</IconContainer> : null
          }
          <TitleContainer>
            <Title>
              {p.title ? <Typography variant="mediumTitle" color="text">{p.title}</Typography> : null}
            </Title>
            <SubTitle>
              {p.subtitle ? <Typography variant="smallTextItalic" color="secondaryText">{p.subtitle}</Typography> : null}
            </SubTitle>
          </TitleContainer>
          {
            p.headerControls
          }
          {
            p.persistent ?
              null :
              <CloseContainer onClick={handleClose}>
                <CloseIcon />
              </CloseContainer>
          }
        </Header>
        <form onSubmit={(e) => {p.onSubmit?.(); e.preventDefault();}}>
          {p.children}
          {p.controls ? <Footer>{p.controls}</Footer> : null}
        </form>
      </Frame>
    </Container>
  );
}

export default Modal;

const Container = styled.div`
  position : fixed;
  top : 0;
  left : 0;
  width : 100%;
  height : 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
  backdrop-filter : blur(0.8rem) brightness(0.8)

`

const animate = keyframes`
 0% { opacity : 0; }
 100% { opacity : 1; }
`

const Frame = styled.div`
  min-width : 50rem;
  background-color : ${colors.background2};
  border-radius : ${spacing}rem;
  box-shadow: ${shadows.default};
  padding : ${2*spacing}rem;
  animation : ${animate} ${transitions.fast};

`

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position : relative;
  margin-bottom : ${2*spacing}rem;
`
const IconContainer = styled.div`
  height : 3rem;
  width : 3rem;
  margin-right : ${spacing}rem;
`

const TitleContainer = styled.div`
  flex : 1;
`

const Title = styled.div`

`

const SubTitle = styled.div`
  margin-top: 0.7rem;
`

const CloseContainer = styled.div`
  height : 2rem;
  width : 2rem;
  margin-left : ${spacing}rem;
`

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-top : ${2*spacing}rem;

  & > button {
    margin-left : ${spacing}rem;
  }
`