import styled from 'styled-components';
import { useAssertLogged } from '../hooks/use-assert-logged';
import PageLayout from '../components/layout/page-layout';
import { useAssertAdmin } from '../hooks/use-assert-admin';
import { useTranslation } from 'react-i18next';
import Search from '../components/atoms/search';
import TextButton from '../components/atoms/text-button';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { NEW_OFFER, OFFER, OFFERS } from '../constants/routes';
import { spacing } from '../styles';
import NotebookNameInput from '../components/atoms/notebook-name-input';
import Select from '../components/atoms/select';
import { useModelStore } from '../store/store';
import { useModelActions } from '../store/hooks/use-actions';

interface NewOfferPageProps {

}

const NewOfferPage : React.FC<NewOfferPageProps> = (p) => {
  useAssertAdmin();
  const {t} = useTranslation();
  const navigate = useNavigate();

  const {create} = useModelActions('offers');

  const [name, setName] = useState<string>('');
  const [market, setMarket] = useState<number | undefined>();
  const markets = useModelStore(state => state.markets.filter(({inactive}) => !inactive));
  const marketsChoices = markets.map(market => ({label : market.name, value : market.id}));

  const handleCancel = () => {
    navigate(OFFERS);
  }

  const handleCreate = async () => {
    if(!name.trim() || !market) {return;}

    try {
      const offer = await create({name, marketId : market});
      navigate(OFFER.replace(':id', `${offer.id}`));
    }
    catch(error) {}
  }

  return (
    <PageLayout
    breadcrumbs={[t('pages.new-offer.breadcrumb1'), t('pages.new-offer.breadcrumb2')]}
    title={
      <TitleContainer>
        <NotebookNameInput value={name} onChange={setName} placeholder={t('pages.new-offer.namePlaceholder')}/>
        <Select<number>
          choices={marketsChoices}
          value={market}
          onChange={setMarket}
          placeholder='Marché'
        />
      </TitleContainer>
    }
    subtitle={t('pages.new-offer.subtitle')}
    controls={
      <>
        <TextButton color="light" onClick={handleCancel}>{t('cancel')}</TextButton>
        <TextButton disabled={!name || !market} color="dark" onClick={handleCreate}>{t('pages.new-offer.create')}</TextButton>
      </>
    }
  >
      <Container>
      </Container>
    </PageLayout>
  )
}

export default NewOfferPage;

const Container = styled.div`

`;

const TitleContainer = styled.div`
  display: flex;
`