import { colors } from "../../styles";
import IconProps from "./icon-interface";

const IndentIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.text;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 21 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.4706 12.1128C12.3586 12.2131 12.2693 12.3342 12.2083 12.4686C12.1473 12.6031 12.1158 12.748 12.1158 12.8944C12.1158 13.0409 12.1473 13.1858 12.2083 13.3202C12.2693 13.4546 12.3586 13.5757 12.4706 13.676C12.7024 13.8841 13.0089 14 13.3274 14C13.646 14 13.9525 13.8841 14.1842 13.676L20.6455 7.78162C20.7572 7.68107 20.8462 7.55992 20.9072 7.42554C20.9681 7.29117 20.9997 7.14639 21 7.00002C21.0003 6.85446 20.9691 6.71041 20.9084 6.57682C20.8468 6.44181 20.7574 6.31994 20.6455 6.21842L14.1834 0.320016C13.9516 0.111928 13.6451 -0.00396793 13.3266 -0.00396796C13.0081 -0.00396798 12.7016 0.111928 12.4698 0.320016C12.3578 0.42033 12.2685 0.541428 12.2075 0.675849C12.1464 0.81027 12.115 0.955169 12.115 1.10162C12.115 1.24807 12.1464 1.39296 12.2075 1.52738C12.2685 1.6618 12.3578 1.7829 12.4698 1.88322L16.863 5.89122C11.4193 6.7429 7.96272 6.70937 1.21128 5.89122C1.05434 5.88425 0.89754 5.90767 0.750375 5.96007C0.603209 6.01247 0.468734 6.09275 0.35508 6.19607C0.241427 6.29938 0.15096 6.42358 0.0891482 6.56114C0.0273368 6.69871 -0.00453123 6.84678 -0.00453125 6.99642C-0.00453126 7.14605 0.0273367 7.29412 0.0891481 7.43169C0.15096 7.56926 0.241427 7.69345 0.35508 7.79676C0.468733 7.90008 0.603209 7.98036 0.750374 8.03276C0.89754 8.08516 1.05434 8.10858 1.21128 8.10161C8.06417 8.8905 11.4862 8.88509 16.8638 8.10162L12.4706 12.1128Z" fill={color}/>
    </svg>
  )
}

export default IndentIcon;
