import { useTranslation } from "react-i18next";
import Modal from "../modal";
import { colors, spacing } from "../../../styles";
import styled from "styled-components";
import { useModelStore } from "../../../store/store";
import { useEffect, useRef, useState, MouseEvent } from "react";
import TextButton from "../../atoms/text-button";
import { ID, NotebookTemplateContent, PageSlide, Slide, SlideIndex } from "../../../store/store-types";
import { useModelActions } from "../../../store/hooks/use-actions";
import Typography from "../../atoms/typography";
import ReplaceSlideIcon from "../../../assets/icons/replace-slide";
import NotebookCard from "../../atoms/notebook-card";
import configProvider from "../../../providers/configprovider";
import Search from "../../atoms/search";
import normalize from "../../../store/helpers/normalize-search-string";
import ToolButton from "../../atoms/tool-button";
import DeindentIcon from "../../../assets/icons/deindent";
import { HalfFoodDomeAnimation } from "../../../assets/animations/food-dome";
import UploadErrorImage from "../../../assets/images/upload-error";

interface ReplaceSlideModalProps {
  slideIndex : SlideIndex;
  onClose : () => void;
  insert ?: 'after' | 'before';
  marketId ?: ID;
}

const ReplaceSlideModal : React.FC<ReplaceSlideModalProps> = (p) => {
  const {t} = useTranslation();

  const {loadTemplates, loadTemplateContent} = useModelActions('notebooks');
  const {replaceSlide, insertSlide, slideAtIndex} = useModelActions('proposalEditor');
  const {start} = useModelActions('uploads');
  const notebookTemplates = useModelStore(state => state.notebooks.templates);
  const offerNotebooks = Object.values(notebookTemplates).filter(notebook => !notebook.draft && !notebook.inactive && notebook.type === 'offers');
  const genericNotebooks = Object.values(notebookTemplates).filter(notebook => !notebook.draft && !notebook.inactive && notebook.type === 'generic');
  const annexesNotebooks = Object.values(notebookTemplates).filter(notebook => !notebook.draft && !notebook.inactive && notebook.type === 'annexes');

  const [view, setView] = useState<'library' | 'pages' | 'import'>('library')
  const [notebookContent, setNotebookContent] = useState<NotebookTemplateContent>();
  const [search, setSearch] = useState<string>();
  const [notebookSearch, setNotebookSearch] = useState<string>();
  const [selectedPages, setSelectedPages] = useState<PageSlide[]>([]);
  const [uploadId, setUploadId] = useState<ID>();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const upload = useModelStore(state => uploadId ? state.uploads[uploadId] : null);


  useEffect(() => {
    loadTemplates();
  }, []);

  const disabled = view === 'library' || (view === 'pages' && !selectedPages.length) || (view === 'import' && (upload?.status !== 'done' || !selectedPages.length));

  const handleSubmit = () => {
    if(p.insert && selectedPages.length) {
      insertSlide(p.slideIndex, selectedPages, p.insert === 'before');
    }
    else {
      replaceSlide(p.slideIndex, selectedPages[0]);
    }

    p.onClose();
  }

  const handleNotebookCardClick = (notebookId : ID) => {
    setSelectedPages([])
    setSearch(undefined);
    setNotebookSearch(undefined);
    setNotebookContent({});
    loadTemplateContent(notebookId).then(content => {
      setNotebookContent(content);
    })
    setView('pages')
  }

  const slides : PageSlide[] = (notebookContent?.slides || []).filter(slide => slide.type === 'page' && !slide.isSummary) as PageSlide[];
  const filteredSlides = search ? slides.filter(slide => normalize(slide.label).match(normalize(search))) : slides;

  const handelOpenFileDialog = () => {
    fileInputRef.current?.click();
  }

  const handleSelectFileReplacement = async(file : File) => {
    setView('import');
    setSelectedPages([])
    const uploadId = await start(file);
    setUploadId(uploadId);
  }

  const handleSelectPage = (event :  MouseEvent<HTMLDivElement>, slide : PageSlide, index : number) => {
    if(!p.insert) {
      setSelectedPages([slide])
    }
    else {
      if(event.ctrlKey || event.metaKey) {
        setSelectedPages(selected => [...selected.filter(p => p.pageId !== slide.pageId), slide])
      }
      else if (event.shiftKey) {
        const displayedSlides : PageSlide[] =
          view === 'import' ?
            (upload?.pages || []).map(page => ({type : 'page' as 'page', level : 4, label : `Page ${page.page}`, pageId : page.id})) :
            filteredSlides;

        const selectedIdx = selectedPages.map(({pageId}) => displayedSlides.findIndex(s => s.pageId === pageId)).filter(idx => idx >= 0);

        if(selectedIdx.length > 0) {
          const firstSelectedIndex = Math.min(...selectedIdx, index);
          const lastSelectedIndex = Math.max(...selectedIdx, index);
          const selectedPages = displayedSlides.slice(firstSelectedIndex, lastSelectedIndex + 1);

          setSelectedPages(current => [...current, ...selectedPages.filter(pageId => !current.includes(pageId))])
        }
        else {
          setSelectedPages([slide])
        }
      }
      else {
        setSelectedPages([slide])
      }
    }
  }


  return (
    <Modal
      title={t('modals.replaceSlide.title')}
      subtitle={t('modals.replaceSlide.subtitle')}
      icon={<ReplaceSlideIcon color={colors.text}/>}
      onClose={p.onClose}
      onSubmit={handleSubmit}
      headerControls={
        view === 'library'?
          [
            <LoadFromDiskButton key="load" onClick={handelOpenFileDialog} color='light'>{t('modals.replaceSlide.loadFromDisk')}</LoadFromDiskButton>,
            <StyledSearch key="search" onChange={setNotebookSearch} debounce={1} placeholder={t('modals.replaceSlide.searchPlaceholder')}/>
          ] :
        view === 'pages' ? [
          <ToolButton key="back-button" onClick={() => setView('library')} color="medium"><DeindentIcon color={colors.primary}/></ToolButton>,
          <StyledSearch key="search" onChange={setSearch} debounce={1} placeholder={t('modals.replaceSlide.searchPlaceholder')}/>
        ] : [
          <ToolButton key="back-button" onClick={() => setView('library')} color="medium"><DeindentIcon color={colors.primary}/></ToolButton>,
          <LoadFromDiskButton onClick={handelOpenFileDialog} color='light'>{t('modals.replaceSlide.loadFromDisk')}</LoadFromDiskButton>
        ]
      }
      controls={[
        <TextButton key="validate" type="submit" disabled={disabled}>{p.insert ? t('modals.replaceSlide.insert') : t('modals.replaceSlide.validate')}</TextButton>
      ]}
    >
      <FileInput
        ref={fileInputRef}
        id={'modal_file_replace'}
        type="file" accept="application/pdf"
        onChange={e => e.target.files?.[0] && handleSelectFileReplacement(e.target.files?.[0])}
        />
      {
        view === 'library' ?
          <Container>
            <Typography variant="mediumTitle" color="text">{t('pages.library.notebook-offres')}</Typography>
            <NotebookLinksContainer>
              {
                offerNotebooks.filter(notebook => (!p.marketId || notebook.marketsIds.includes(p.marketId)) && (!notebookSearch || normalize(notebook.name).match(normalize(notebookSearch)))).map(notebook => (
                  <NotebookCard
                    key={notebook.id}
                    imgUrl={notebook.thumbnailPageId ? `${configProvider('PDF_SERVICE_URL')}/pages/${notebook.thumbnailPageId}/thumbnail` : ''}
                    title={notebook.name}
                    onChange={() => handleNotebookCardClick(notebook.id)}
                    noCheckbox
                  />
                ))
              }
            </NotebookLinksContainer>
            <Typography variant="mediumTitle" color="text">{t('pages.library.notebook-annexes')}</Typography>
            <NotebookLinksContainer>
              {
                annexesNotebooks.filter(notebook => (!p.marketId || notebook.marketsIds.includes(p.marketId)) && (!notebookSearch || normalize(notebook.name).match(normalize(notebookSearch)))).map(notebook => (
                  <NotebookCard
                    key={notebook.id}
                    imgUrl={notebook.thumbnailPageId ? `${configProvider('PDF_SERVICE_URL')}/pages/${notebook.thumbnailPageId}/thumbnail` : ''}
                    title={notebook.name}
                    onChange={() => handleNotebookCardClick(notebook.id)}
                    noCheckbox
                  />
                ))
              }
            </NotebookLinksContainer>
            {/* <Typography variant="mediumTitle" color="text">{t('pages.library.notebook-generiques')}</Typography>
            <NotebookLinksContainer>
              {
                genericNotebooks.map(notebook => (
                  <NotebookCard
                    key={notebook.id}
                    imgUrl={notebook.thumbnailPageId ? `${configProvider('PDF_SERVICE_URL')}/pages/${notebook.thumbnailPageId}/thumbnail` : ''}
                    title={notebook.name}
                    onChange={() => handleNotebookCardClick(notebook.id)}
                    noCheckbox
                  />
                ))
              }
            </NotebookLinksContainer> */}
          </Container> :
        view === 'pages' ?
          <SlidesContainer>
            {
              filteredSlides.map((slide, index) => slide.type === 'page' && !slide.isSummary ? (
                <SlideCard key={slide.pageId} onClick={(event) => handleSelectPage(event, slide, index)} className={selectedPages.find(s => s.pageId === slide.pageId) ? 'selected' : ''}>
                  <Image src={slide.pageId ? `${configProvider('PDF_SERVICE_URL')}/pages/${slide.pageId}/thumbnail` : ''}/>
                  <Title>
                    <Typography variant="textRegular" color="secondaryText">{slide.label}</Typography>
                  </Title>
                </SlideCard>
              ) : null)
            }
          </SlidesContainer> :
          <SlidesContainer>
            {
              (upload?.pages || []).map((page, index) => (
                <SlideCard key={page.id} onClick={(event) => handleSelectPage(event, {type : 'page', pageId : page.id, label : 'Page ' + page.page, level : 4}, index)} className={selectedPages.find(s => s.pageId === page.id)  ? 'selected' : ''}>
                  <Image src={page.id ? `${configProvider('PDF_SERVICE_URL')}/pages/${page.id}/thumbnail` : ''}/>
                </SlideCard>
              ))
            }
            {
              upload?.error ?
              <SlideCard style={{cursor : 'auto'}}>
                <HalfDomeContainer>
                  <UploadErrorImage />
                  <br />
                  <Typography variant='smallTextItalic' color='primary'>{t('pages.notebook.erreurDeChargement')}</Typography>
                </HalfDomeContainer>
              </SlideCard> :
              upload?.status !== 'done' ?
              <SlideCard style={{cursor : 'auto'}}>
                <HalfDomeContainer>
                  <HalfFoodDomeAnimation />
                </HalfDomeContainer>
              </SlideCard>
              : null
            }
          </SlidesContainer>
      }
      </Modal>
  );
}

export default ReplaceSlideModal;


const Container = styled.div`
  width : 108rem;
  height : 60vh;
  overflow: auto;
`

const SlidesContainer = styled(Container)`
  display: flex;
  flex-wrap: wrap;
  place-content: flex-start;
`

const NotebookLinksContainer = styled.div`
  margin-top : ${spacing}rem;
  margin-bottom : 4.4rem;
  display: flex;
  flex-direction : row;
  flex-wrap: wrap;
`

const SlideCard = styled.div`
  width : 20rem;
  background-color : ${colors.background2};
  overflow : hidden;
  margin-right : ${spacing}rem;
  margin-bottom : ${spacing}rem;
  cursor : pointer;

  & > img {
    border : 0.3rem solid ${colors.background2} !important;
  }

  &.selected > img {
    border : 0.3rem solid ${colors.primary} !important;
  }
`

const Image = styled.img`
  width : 20rem;
  height : 11.3rem;
  border-radius: 1rem;
  object-fit: cover;
  border : none;
`

const Title = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 0.5rem;
  padding-left : 0.5rem;
  & span {
    text-decoration-line: none !important;
  }
`

const StyledSearch = styled(Search)`
  margin-left : ${2*spacing}rem;
  & > input {
    background-color: ${colors.background};
  }
`

const FileInput = styled.input`
  position : absolute;
  visibility: hidden;
`

const LoadFromDiskButton = styled(TextButton)`
  margin-left : ${2*spacing}rem;
`

const HalfDomeContainer = styled.div`
  display: flex;
  align-items : center;
  justify-content: center;
  flex-direction: column;
  width : 20rem;
  height : 11.3rem;
  background-color: ${colors.lightPrimary};
  border-radius: 1rem;
  padding: 1rem;
  text-align: center;
`
