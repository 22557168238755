import { colors } from "../../styles";
import IconProps from "./icon-interface";

const ErrorIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.text;

  return (
  <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 24 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.720089 17.5C0.87658 16.8779 1.25545 16.3622 1.58491 15.822C4.13818 10.7635 7.0868 5.85242 10.4966 1.12137C10.9249 0.417445 11.5262 0 12.3663 0C13.0911 0 13.6841 0.294667 14.0795 0.900372C14.2854 1.21141 14.4336 1.4406 14.4583 1.48971C14.8043 2.17726 16.8551 5.27127 18.5765 7.89054C18.5765 7.89054 21.3192 12.0486 23.6336 16.616C23.7901 16.927 24.1608 17.6964 23.9219 18.4576C23.8066 18.826 23.5842 19.1534 23.2548 19.4317C22.9006 19.7263 22.4888 19.8819 22.0276 19.9064C21.9534 19.9064 21.8793 19.9064 21.7969 19.9064C15.5126 20.1683 9.22825 20.1602 2.93567 19.9064C1.98849 19.8655 1.29663 19.5053 0.884817 18.6459C0.810689 18.4822 0.769508 18.3103 0.711853 18.1384V17.5081L0.720089 17.5ZM21.7146 18.4003C21.7146 18.4003 21.8464 18.4085 21.9864 18.3922C22.3982 18.3349 22.6123 17.942 22.4311 17.5654C22.3735 17.4427 22.3076 17.3608 22.3076 17.3526C22.184 16.8042 14.3842 3.4296 12.9264 1.89897C12.8275 1.79256 12.6381 1.50608 12.3663 1.50608C12.1604 1.50608 11.9627 1.63704 11.798 1.90715C8.27283 6.86739 5.17596 12.0486 2.40031 17.3936C2.36736 17.4509 2.32618 17.5081 2.30147 17.5736C2.13674 17.942 2.33442 18.3267 2.738 18.3922C2.88625 18.4167 3.0098 18.3922 3.03451 18.4003C3.77578 18.695 20.9898 18.695 21.7146 18.4003Z" fill={color}/>
    <path d="M11.4356 9.89589H11.4603C11.4933 10.8372 11.5262 11.7785 11.5509 12.7198C11.5509 12.9408 11.6003 13.1536 11.8062 13.2355C12.0204 13.3173 12.2757 13.391 12.5063 13.3664C13.0417 13.3009 13.2229 13.0472 13.1982 12.597C13.1899 12.3842 13.1982 12.1796 13.2147 11.9668C13.2723 10.3788 13.3382 8.79089 13.3794 7.20296C13.3794 6.99015 13.33 6.74459 13.2147 6.56452C12.9923 6.21255 12.4075 6.10615 11.9298 6.27803C11.518 6.42537 11.345 6.69548 11.3532 7.19478C11.3779 8.09515 11.4027 9.00371 11.4356 9.90408V9.89589ZM13.4617 15.4209C13.4617 14.8479 12.9593 14.3486 12.391 14.3486C11.7898 14.3486 11.3121 14.8316 11.3203 15.4291C11.3203 16.0266 11.8145 16.5095 12.4075 16.5014C12.9758 16.5014 13.47 15.9857 13.47 15.4127L13.4617 15.4209Z" fill={color}/>
    <path d="M11.4356 9.8959C11.4109 8.99553 11.378 8.08697 11.3532 7.1866C11.3368 6.6873 11.5097 6.41719 11.9298 6.26986C12.3993 6.09797 12.9923 6.21256 13.2147 6.55634C13.33 6.73642 13.3794 6.98197 13.3794 7.19479C13.3382 8.78272 13.2723 10.3706 13.2147 11.9586C13.2147 12.1714 13.19 12.3842 13.1982 12.5888C13.2229 13.039 13.0417 13.2928 12.5063 13.3582C12.284 13.3828 12.0286 13.3173 11.8062 13.2273C11.6003 13.1454 11.5509 12.9326 11.5509 12.7116C11.5262 11.7703 11.4933 10.829 11.4603 9.88772H11.4356V9.8959Z" fill={color}/>
    <path d="M13.4618 15.421C13.4618 15.9858 12.9676 16.5014 12.3993 16.5096C11.8063 16.5096 11.3203 16.0349 11.3121 15.4373C11.3121 14.8398 11.7898 14.3569 12.3828 14.3569C12.9511 14.3569 13.4535 14.848 13.4535 15.4292L13.4618 15.421Z" fill={color}/>
  </svg>
  )
}

export default ErrorIcon;


