import { colors } from "../../styles";
import IconProps from "./icon-interface";

const CloseIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.secondaryText;

  return (
  <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21 21L1 1" stroke={color} strokeWidth="2" strokeLinecap="round"/>
    <path d="M1 21L21 1" stroke={color} strokeWidth="2" strokeLinecap="round"/>
  </svg>
  )
}

export default CloseIcon;

