import { ReactElement, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import {colors, shadows, spacing, transitions, typography} from '../../styles';
import Typography from './typography';
import Dropdown from './dropdown';


type SelectDropdownProps = {
  className ?: string;
  choices : {label : string; value : string}[];
  placeholder : string;
} & ({
  nullable : true;
  value ?: string;
  onChange ?: (value ?: string) => void;
} | {
  nullable ?: false;
  value : string;
  onChange ?: (value : string) => void;
})

const SelectDropdown : React.FC<SelectDropdownProps> = (p) => {
  const [open, setOpen] = useState<boolean>(false);
  const selected = p.choices.find(choice => choice.value === p.value);

  const handleSelect = (value : string) => {
    if(!p.onChange) {return;}

    if(value === selected?.value && p.nullable) {
      p.onChange();
    }
    else {
      p.onChange(value);
    }
    setOpen(false);
  }

  return (
    <Dropdown
      className={p.className}
      open={open}
      setOpen={setOpen}
      button={
        <ButtonLabel>
          <FadingTypography fade={!selected} variant="buttonBold" color="primary">{selected?.label || ''}</FadingTypography>
          <FadingTypography fade={!!selected} variant="buttonRegular" color="tertiaryText" main>{p.placeholder}</FadingTypography>
        </ButtonLabel>
      }
    >
      <ChoicesContainer>
        {
          p.choices.map(choice => (
            <Option key={choice.value} className={choice.value === selected?.value ? 'selected' : ''} onClick={() => handleSelect(choice.value)}><Typography variant="buttonRegular" color="secondaryText">{choice.label}</Typography></Option>
          ))
        }
      </ChoicesContainer>
    </Dropdown>
  );
};

export default SelectDropdown;

const ChoicesContainer = styled.div`
  display: flex;
  flex-direction: column;
`
const Option = styled.div`
  flex-shrink: 0;
  height : 3.6rem;
  display: flex;
  align-items: center;
  padding-left : 1.8rem;
  padding-right : 3.2rem;
  white-space: nowrap;

  &:hover, &.selected {
    background-color: ${colors.primary};
  }
  
  &:hover span, &.selected span {
    color : white !important;
  }
`

const ButtonLabel = styled.div`
  display : flex;
  align-items : center;
  justify-content: flex-start;
  height : 100%;
  position: relative;
  padding-left : 0.5rem;
`;

const FadingTypography = styled(Typography)<{main ?: boolean, fade : boolean}>`
  width : 100%;
  text-align: left;
  white-space : nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position : ${p => p.main ? 'relative' : 'absolute'};
  transition: opacity ${transitions.fast};
  opacity : ${p => p.fade ? '0' : '1'};
`