import { SketchPicker } from "react-color"
import styled from "styled-components"
import { colors, shadows, spacing } from "../../styles"
import { useModelStore } from "../../store/store"
import { useModelActions } from "../../store/hooks/use-actions"
import { useEffect, useState } from "react"
import useEventDelegation from "../../hooks/use-event-delegation"

const DEFAULT_COLORS = [colors.primary, colors.secondary, colors.tertiary, colors.accent, colors.lightPrimary, colors.lightSecondary, colors.lightAccent, colors.lightTertiary];

const ColorPicker : React.FC<{}> = (p) => {
  const state = useModelStore(state => state.process.modals.colorPicker);
  const [color, setColor] = useState<any>(state.initialColor)
  const {hideColorPicker} = useModelActions('modals')
  const {addEventListener, removeEventListener} = useEventDelegation('page');

  useEffect(() => {
    const handler = () => hideColorPicker();

    addEventListener(handler);
    window.addEventListener('resize', handler);

    return () => {
      removeEventListener();
      window.removeEventListener('resize', handler);
    }
  }, [])

  if(!state.visible || !state.x || !state.y || !state.callback) {return null;}

  const handleSelectColor = (color : string) => {
    state.callback?.(color);
  }

  return (
    <ColorPickerContainer style={{left : state.x + 'px', top : state.y + 'px'}} onClick={e => e.stopPropagation()}>
      <SketchPicker
        color={color}
        disableAlpha
        onChange={setColor}
        onChangeComplete={(color) => handleSelectColor(color.hex)}
        presetColors={DEFAULT_COLORS}
      />
    </ColorPickerContainer>
  )
}

export default ColorPicker;

const ColorPickerContainer = styled.div`
  position : absolute;

  & .sketch-picker {
    box-shadow: ${shadows.default} !important;
    border-radius: 1rem !important;
  }
`