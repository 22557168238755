
import { colors } from "../../styles";
import IconProps from "./icon-interface";

const AddIcon : React.FC<IconProps> = (p) => {
  const color = p.color || colors.secondaryText;

  return (
    <svg className={p.className} style={{width : p.width || '100%', height : p.height || '100%'}} viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5 1.95898V5.95898M5 5.95898V9.95898M5 5.95898H9M5 5.95898H1" stroke={color} strokeWidth="2" strokeLinecap="round"/>
    </svg>
  )
}

export default AddIcon;
