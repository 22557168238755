import { ReactNode } from "react"
import styled from "styled-components";
import { colors, shadows, spacing, transitions } from "../../styles";
import { DynamicSlideViewMeta } from "../../store/store-types";

interface ListViewContentLevelContainerProps {
  index : number;
  children : ReactNode
  hidden ?: boolean;
  view ?: DynamicSlideViewMeta
}

const ListViewContentLevelContainer : React.FC<ListViewContentLevelContainerProps> = (p) => {
  return (
    <Row>
      <Slider className={p.hidden ? 'hidden' : 'visible'}>
        <Container>
          {p.children}
        </Container>
      </Slider>
    </Row>
  )
}

export default ListViewContentLevelContainer;

const Row = styled.div`
  position : relative;
`

const Slider = styled.div`
  overflow: hidden;

  max-height: 100rem;
  transition : max-height ${transitions.fast};

  &.hidden {
    max-height: 0rem;
  }
`;

const Container = styled.div`
  width : 62rem;
  padding : 0.8rem;
  margin-left : ${spacing}rem;
  border-radius : 2.2rem;
  box-shadow: ${shadows.default};
  background: ${colors.background2};
  margin-bottom : ${spacing}rem;
  transform : translateX(${spacing * 6}rem);
`
